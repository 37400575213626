import "../styles/head.css";
import logo_walltrip from "../iconos/logos/walltrip.png";
import ico_paquetes from "../iconos/icono-paquetes.png";
import ico_vuelos from "../iconos/icono-vuelos.png";
import ico_hoteles from "../iconos/hotel.png";
import ico_actividades from "../iconos/actividades.png";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useToggleVisibility } from "../hooks/useToggleVisibility";
import clsx from "clsx";
import { Login } from "./auth/Login";
import { ModalGeneric } from "../utils/ModalGeneric";
import { useAuth } from "./auth/useAuth";
import { saveTourOperator } from "../utils/saveTourOperator";
import { useTourOperators } from "../hooks/useTourOperators";
import { useState, useEffect } from "react";
import { useUserStore } from "../hooks/useUserStore";

const ALL_HEADER_OPTIONS = [
  {
    label: "Vuelos",
    path: "flights",
    image: ico_vuelos,
    alt: "vuelos-icono",
  },
  {
    label: "Paquetes",
    path: "packages",
    image: ico_paquetes,
    alt: "paquetes-icono",
  },
  {
    label: "Hoteles",
    path: "hotels",
    image: ico_hoteles,
    alt: "hoteles-icono",
  },
  {
    label: "Actividades",
    path: "search-activities",
    image: ico_actividades,
    alt: "actividades-icono",
  },
];

const generateHeaderOptions = (permissions) =>
  ALL_HEADER_OPTIONS.filter(({ label }) => permissions.includes(label));

const Head = ({ noOptions }) => {
  const tourOP = saveTourOperator((st) => st.tourOP);
  const { tour_op_id, type } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { getTourOperatorByID, tourOperator } = useTourOperators(
    (st) => st.getTourOperatorByID
  );
  const [isVisible, toggleVisibility] = useToggleVisibility();
  const { logOut } = useAuth();
  const [opertatorPermissions, setOperatorPermissions] = useState(null);
  const { user } = useUserStore();
  const token =
    localStorage.getItem("token") || sessionStorage.getItem("token");

  const headerOptions =
    opertatorPermissions && generateHeaderOptions(opertatorPermissions);

  const firstNavigableRoute = () => {
    if (!opertatorPermissions) return "flights";

    const [firstModule] = opertatorPermissions;
    return headerOptions.find(({ label }) => label === firstModule).path;
  };

  useEffect(() => {
    if (tourOP?.id) {
      getTourOperatorByID({ tourOperatorID: tourOP?.id });
    }

    // MANEJO DE "MODULES" SEGÚN PERMISOS DE OPERADORA
    if (tourOP.TourOperatorModules) {
      const acceptedModules = tourOP.TourOperatorModules.slice(0, 4)
        .filter(({ active }) => active)
        .map(({ name }) => name);

      setOperatorPermissions(acceptedModules);
    }
  }, [tourOP?.id]);

  // MANEJO DE "PATHS" SEGÚN PERMISOS DE OPERADORA
  useEffect(() => {
    if (!opertatorPermissions) return;

    const findCurrentPathModule = headerOptions.find(
      ({ path }) => path === type
    );

    const pathToCheck = ALL_HEADER_OPTIONS.map(({ path }) =>
      path !== "search-activities" ? path : null
    );

    const path = firstNavigableRoute();
    if (
      (!findCurrentPathModule && pathToCheck.includes(type)) ||
      // CHECKEAR QUE TENGA ALGÚN TYPE
      window.location.href.split("/").length === 4
    ) {
      navigate(`/${tour_op_id}/${path}`);
    }
  }, [opertatorPermissions]);

  // MANEJO DE isVisible SEGÚN RUTA PARA MODAL DE LOGIN
  useEffect(() => {
    const { pathname } = location;

    if (isVisible && !pathname.includes("login")) {
      navigate(`/${tour_op_id}/login`);
    } else if (!isVisible && pathname.includes("login")) {
      toggleVisibility();
    }
  }, [isVisible, location.pathname]);

  const closeModal = () => {
    toggleVisibility();
    navigate(`/${tour_op_id}/${firstNavigableRoute()}`);
  };

  return (
    <div className="bg-white mx-auto max-w-[1338.46px]">
      <div className="flex justify-between items-center w-full py-4 ">
        <Link
          className="cursor-pointer"
          to={`/${tour_op_id || "walltrip"}/${firstNavigableRoute()}`}
        >
          <img
            className="max-w-[128px] max-h-16 text-black "
            src={tourOP?.logo !== "-" ? tourOP?.logo : logo_walltrip}
            alt={tourOP?.name}
          />
        </Link>
        <div className="flex gap-3">
          <Link
            to={`/${tour_op_id || "walltrip"}/history`}
            className="border max-h-8 flex items-center px-6 py-1 border-[#8D99AE] rounded-md font-medium text-sm cursor-pointer"
          >
            Mis viajes
          </Link>
          {!token ? (
            <Link
              to={`/${tour_op_id}/login`}
              className=" max-h-8 flex items-center px-6 py-1 bg-[#CEDEF1] rounded-md font-medium text-sm cursor-pointer"
            >
              Iniciar sesión
            </Link>
          ) : (
            <button
              onClick={logOut}
              className=" max-h-8 flex items-center px-6 py-1 bg-[#CEDEF1] rounded-md font-medium text-sm cursor-pointer"
            >
              Cerrar sesión
            </button>
          )}
          <button className="flex items-center px-2 py-1 text-sm font-low rounded-md max-h-8 cursor-pointer">
            Contacto
          </button>

          {token && user?.role_id !== 3 ? (
            <Link
              to={`/${tour_op_id || "walltrip"}/panel/sales/bookings/flight`}
              className="border max-h-8 flex items-center px-6 py-1 border-[#8D99AE] rounded-md font-medium text-sm cursor-pointer"
            >
              Panel
            </Link>
          ) : null}
        </div>
      </div>

      {!noOptions && opertatorPermissions ? (
        <div className="relative flex items-end justify-start w-full gap-4 py-2 pl-44">
          {location?.pathname.includes("search-flight") ||
          location?.search.includes("flights") ||
          location?.pathname.includes("search-packages") ||
          location?.search.includes("package") ||
          location?.pathname.includes("package-detail") ||
          location?.pathname.includes("search-hotels") ||
          location?.pathname.includes("hotels-request") ||
          location?.search.includes("hotels") ||
          location?.pathname.includes("search-activities") ||
          location?.search.includes("activity") ? (
            <div className="w-full absolute bottom-[8px] left-0 bg-[#DAE0E6] rounded-full h-6" />
          ) : null}
          {/* header icons */}
          {headerOptions
            .filter(({ label }) =>
              tourOperator?.TourOperatorModules.slice(0, 4).some(
                (item) => item.name === label && item.active
              )
            )
            .map(({ label, image, alt, path }, index) => (
              <HeaderEl
                key={index}
                image={image}
                label={label}
                alt={alt}
                link={`/${tour_op_id || "walltrip"}/${path}`}
              />
            ))}
          {!tourOperator &&
            headerOptions.map(({ label, image, alt, path }, index) => (
              <HeaderEl
                key={index}
                image={image}
                label={label}
                alt={alt}
                link={`/${tour_op_id || "walltrip"}/${path}`}
              />
            ))}
        </div>
      ) : (
        <div className="h-24 my-0.5" />
      )}

      <ModalGeneric show={isVisible} toggle={closeModal}>
        <Login closeModal={closeModal} />
      </ModalGeneric>
    </div>
  );
};

const HeaderEl = ({ image, label, alt, link }) => {
  const params = useParams();
  const location = useLocation();

  return (
    <NavLink
      className={({ isActive, isPending }) =>
        isPending
          ? "flex flex-col items-center gap-2"
          : isActive
          ? "flex flex-col items-center font-medium gap-2"
          : "flex flex-col items-center gap-2"
      }
      to={link}
    >
      <img
        className={`w-8 relative ${label === "Vuelos" && "h-6"}`}
        src={image}
        alt={alt}
      />
      <p
        className={clsx(
          "text-black relative px-4 py-1 h-6 flex items-center justify-center rounded-md",
          {
            "bg-[#091832] text-white":
              (location?.pathname.includes("search-flight") ||
                location?.search.includes("flights")) &&
              label === "Vuelos",
          },
          {
            "bg-[#091832] text-white":
              (location?.pathname.includes("search-packages") ||
                location?.search.includes("package") ||
                location?.pathname.includes("package-detail")) &&
              label === "Paquetes",
          },
          {
            "bg-[#091832] text-white":
              (location?.pathname.includes("search-hotels") ||
                location?.search.includes("hoteles") ||
                location?.pathname.includes("hotels-request")) &&
              label === "Hoteles",
          },
          {
            "bg-[#091832] text-white":
              (location?.pathname.includes("search-activities") ||
                location?.search.includes("actividad")) &&
              label === "Actividades",
          },
          {
            "bg-[#CEDEF1]":
              (link.toLowerCase().includes(params?.type?.toLowerCase()) &&
                location.pathname !== "/") ||
              (label === "Vuelos" && location.pathname === "/"),
          }
        )}
      >
        {label}
      </p>
    </NavLink>
  );
};

export default Head;
