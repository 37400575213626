/* eslint-disable react-hooks/exhaustive-deps */
import info_outlined from "../../iconos/info_outlined.png";
import inProcess from "../../iconos/inProcess.png";
import proveedoresVideo from "../../previews/proveedores.mp4";
import crm from "../../previews/crm.mp4";
import liquidacion from "../../previews/liquidacion.mp4";
import { VideoPlayer } from "../../utils/VideoPlayer";
import { Link, useLocation } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";

export const VoteFeaturePreview = () => {
  const { pathname } = useLocation();
  const type = pathname?.split("/")?.pop();
  const [video, setActualVideo] = useState(proveedoresVideo);

  const texts = {
    proveedores: `En esta pantalla podrás agregar proveedores nuevos, ver un listado
    detallado de los que estén cargados y editarlos. También tendrás
    una opción para comunicarte directamente con ellos.`,
    crm: `
    En esta pantalla podrás obtener datos de 'pedidos de vuelos' de potenciales clientes que te ofrecemos para tu agente se encargue de cotizar el producto y convertirlo en una venta.
    `,
    ventas: `En esta pantalla podremos ver los estados de los saldos y pagos de agencias y clientes en cada reserva. Pudiendo imprimir o exportar los documentos necesarios para enviar.`,
  };

  const videos = {
    crm: crm,
    ventas: liquidacion,
    proveedores: proveedoresVideo,
  };

  const votes = {
    crm: "CRM",
    ventas: "Liquidacion",
    proveedores: "Proveedores",
  };

  useEffect(() => setActualVideo(videos[type]), [pathname]);

  return (
    <div className="my-8 mx-auto flex flex-col gap-6 max-w-[1200px]">
      <div className="flex flex-col  w-full ">
        <div className="flex gap-4">
          <div className="max-w-[592px] justify-between h-[56px] flex gap-2 pl-6 py-1 rounded-[7px] w-full items-center border border-[#C9C9C9]">
            <img
              className="w-[20.3px] h-[20px]"
              src={info_outlined}
              alt="informacion"
            />
            <p className="w-full max-w-[525px] font-[400] text-[12px]">
              {texts?.[type] || ""}
            </p>
          </div>
          <div className="border border-[#C9C9C9] h-[56px] w-[176px] rounded-[7px] flex items-center justify-center p-1">
            <span className="flex justify-between items-center gap-2">
              <img
                className="w-[18.79px] h-[18.79px]"
                src={inProcess}
                alt="inProcess"
              />
              <p className="text-[16.27px] text-[#24242380]">EN PROCESO</p>
            </span>
          </div>
          <Link
            to={`https://wa.me/543512051784?text=quiero votar por: ${
              votes?.[type] || ""
            }`}
          >
            <div className="bg-[#FAE73E] h-[56px] w-[204.53px] rounded-[7px] text-center flex items-center justify-center py-1">
              <p className="text-[16.27px] whitespace-nowrap font-medium ">
                Votar funcionalidad
              </p>
            </div>
          </Link>
        </div>
      </div>
      <div>
        <VideoPlayer h={"760px"} src={video} />
      </div>
    </div>
  );
};
