import { format } from "date-fns";
import { Link, useParams } from "react-router-dom";
import eye from "../../iconos/eye-blue.png";
// import config from "../../iconos/configIcon.png";
import trash from "../../iconos/delete.png";
import numberWithCommas from "../../utils/numberWithCommas";

export default function FlightBookingTableRow({
  index,
  v,
  type,
  bookingStatus,
  bookingStatusKeys,
  mutateEstadoReserva,
  openModal,
  currency,
}) {
  const { tour_op_id } = useParams();
  const { status, id, creation_date, Packages, travelers_packages } = v;
  const { oricode, departure_date, price, descode } = Packages?.[0] ?? {};
  const { first_name, last_name } = travelers_packages?.[0] ?? {};

  return (
    <div
      className={`grid grid-flow-row grid-cols-10 py-2.5 px-8 items-center text-gray rounded-lg ${
        index % 2 === 1 && "bg-lightGray2"
      }`}
    >
      <p>{id}</p>
      <p>{oricode}</p>
      <p>{descode}</p>
      <p>
        {departure_date ? format(new Date(departure_date), "dd/MM/yyyy") : "-"}
      </p>
      <p className="whitespace-nowrap overflow-hidden mr-4 col-span-2">{`${last_name} ${first_name}`}</p>
      <p>
        {currency ?? "USD"} {price && numberWithCommas(Number(price), 2)}
      </p>
      <p className="mx-auto">
        {creation_date ? format(new Date(creation_date), "dd/MM/yyyy") : "-"}
      </p>

      <p>
        <select
          className={`rounded-full p-1 pl-2 text-sm ${
            status === bookingStatusKeys[1] && "bg-[#F8DEBF] text-[#E47D01]"
          } ${
            status === bookingStatusKeys[2] &&
            "bg-[#28B15C40]/25 text-[#28B15C]"
          } ${
            status === bookingStatusKeys[3] && "bg-[#DFB72B]/20 text-[#DFB72B]"
          } ${
            status === bookingStatusKeys[4] && "bg-[#BA27FF]/25 text-[#BA27FF]"
          } ${
            status === bookingStatusKeys[5] && "bg-[#2898b1]/25 text-[#2898b1]"
          }
          `}
          value={status}
          onChange={(e) => mutateEstadoReserva({ reserva: v, e })}
        >
          {bookingStatus.slice(1).map(({ key, label }) => (
            <option
              key={key}
              value={key}
              className="text-black bg-white text-sm"
            >
              {label}
            </option>
          ))}
        </select>
      </p>

      <div className="ml-auto">
        <div className="flex justify-center gap-1">
          <Link to={`/${tour_op_id}/panel/sales/bookings/${type}/${id}`}>
            <img src={eye} alt="eye" className="h-7" />
          </Link>
          {/* <img src={config} alt="config" className="h-4 mt-[5px]" /> */}
          <img
            src={trash}
            alt="Delete"
            className="h-7 cursor-pointer"
            onClick={() => openModal(v, "delete")}
          />
        </div>
      </div>
    </div>
  );
}
