import React from "react";
import Londres from "../../iconos/recomendados/Londres.jpg";
import Madrid from "../../iconos/recomendados/Madrid.jpg";
import Miami from "../../iconos/recomendados/Miami.jpg";
import Rio from "../../iconos/recomendados/Rio de Janeiro.webp";
import RecommendationCardFlight from "./RecommendationCardFlight";
import RecommendationCardPackages from "./RecommendationCardPackage";

const data = [
  {
    image: Londres,
    title: "Londres",
    descode: "LHR",
  },
  {
    image: Madrid,
    title: "Madrid",
    descode: "MAD",
  },
  {
    image: Miami,
    title: "Miami",
    descode: "MIA",
  },
  {
    image: Rio,
    title: "Río de Janeiro",
    descode: "GIG",
  },
];

export default function RecommendationWrapper({
  isFlight,
  packages,
  currencies,
}) {
  return (
    <div className="flex flex-col w-full max-w-6xl px-3 pl-6 m-auto">
      <h2 className="text-2xl font-medium py-4 ">
        Te recomendamos los siguientes {isFlight ? "Vuelos" : "Paquetes"}!
      </h2>
      <div className="flex justify-between">
        {isFlight
          ? data.map(({ image, title, descode }, index) => (
              <RecommendationCardFlight
                key={index}
                {...{ image, title, descode }}
              />
            ))
          : packages
              ?.slice(0, 4)
              ?.map((pack) => (
                <RecommendationCardPackages
                  key={pack.id}
                  pack={pack}
                  currencies={currencies}
                />
              ))}
      </div>
    </div>
  );
}
