import { useState, useEffect } from "react";
import { get, patch, destroy } from "../services/axios.service";
import { saveTourOperator } from "../utils/saveTourOperator";

export const useUsers = () => {
  const [users, setUsers] = useState([]);
  const tourOP = saveTourOperator((st) => st.tourOP);

  const getUsersByTourOperator = async () => {
    try {
      const response = await get("v2/users/" + tourOP?.id);

      setUsers(response.data.response);
    } catch (error) {
      throw new Error("Ocurrió un error (getUsersByTourOperator): ", error);
    }
  };

  const updateUser = async (id, data) => {
    try {
      const response = await patch(`v2/users/${id}`, { data: data });

      console.log("UPDATE RESPONSE: ", response);
      return response;
    } catch (error) {
      throw new Error("Ocurrió un error (UpdateUser): ", error);
    }
  };

  const deleteUser = async (id) => {
    try {
      const response = await destroy(`v2/users/${id}`);
      return response;
    } catch (error) {
      throw new Error("Ocurrió un error (deleteUser): ", error);
    }
  };

  useEffect(() => {
    getUsersByTourOperator();
  }, [tourOP]);

  return {
    users,
    setUsers,
    updateUser,
    deleteUser,
    getUsersByTourOperator,
  };
};
