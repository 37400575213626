import { useState } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import useAgencies from "../../../hooks/useAgencies";

const categoryOptions = [
  { value: "Travel Agency", label: "Agencia de Viaje" },
  { value: "TourOperator", label: "Operador de Turismo" },
  { value: "Freelance", label: "Freelance" },
  { value: "Otro", label: "Otro" },
];

const taxTypeOptions = [
  { value: "CUIT", label: "CUIT" },
  { value: "ICIFSR", label: "CIF" },
  { value: "EIN", label: "EIN" },
];

const UpdateAgencyModal = ({ agency, closeModal }) => {
  const [dataAgency, setDataAgency] = useState({});
  const { updateAgency } = useAgencies();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resetOptions: {
      keepDirtyValues: true,
    },
    defaultValues: agency,
  });

  const onSubmit = async (data) => {
    updateAgency({ id: agency.id, agency: dataAgency }).then(() =>
      closeModal()
    );
  };

  const handleChange = (data) => {
    setDataAgency({ ...dataAgency, [data.target.name]: data.target.value });
  };

  return (
    <>
      <h1 className="text-darkBlue font-semibold text-xl text-center">
        Editar Agencia
      </h1>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onChange={handleChange}
        className="max-w-[80vw]"
      >
        <section className="grid grid-cols-3 gap-2 my-2">
          <div className="w-full relative flex flex-col">
            <label>
              Nombre <span className="text-red-400">*</span>
            </label>
            <input className="addPackInput" name="name" {...register("name")} />
            <span className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
              <ErrorMessage errors={errors} name="name" as="p" />
            </span>
          </div>

          <div className="w-full relative flex flex-col">
            <label>
              Email <span className="text-red-400">*</span>
            </label>
            <input
              className="addPackInput"
              name="email"
              {...register("email")}
            />
            <span className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
              <ErrorMessage errors={errors} name="email" as="p" />
            </span>
          </div>

          <div className="w-full relative flex flex-col">
            <label>Teléfono</label>
            <input
              className="addPackInput"
              name="phone"
              {...register("phone")}
            />
            <span className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
              <ErrorMessage errors={errors} name="phone" as="p" />
            </span>
          </div>

          <div className="w-full relative flex flex-col">
            <label>Categoría</label>
            <select
              className="addPackInput"
              name="category"
              {...register("category")}
            >
              {categoryOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            <span className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
              <ErrorMessage errors={errors} name="category" as="p" />
            </span>
          </div>

          <div className="w-full relative flex flex-col">
            <label>Logo</label>
            <input className="addPackInput" name="logo" {...register("logo")} />
            <span className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
              <ErrorMessage errors={errors} name="logo" as="p" />
            </span>
          </div>

          <div className="w-full relative flex flex-col">
            <label>Licencia/Legajo</label>
            <input
              className="addPackInput"
              name="license_number"
              {...register("license_number")}
            />
            <span className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
              <ErrorMessage errors={errors} name="license_number" as="p" />
            </span>
          </div>
        </section>

        <section className="grid grid-cols-4 gap-2 my-2">
          <h2 className="col-span-4 text-darkBlue font-medium text-lg text-center">
            Ubicación
          </h2>
          <div className="w-full relative flex flex-col">
            <label>Dirección</label>
            <input
              className="addPackInput"
              name="address"
              {...register("address")}
            />
            <span className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
              <ErrorMessage errors={errors} name="address" as="p" />
            </span>
          </div>

          <div className="w-full relative flex flex-col">
            <label>Ciudad</label>
            <input className="addPackInput" name="city" {...register("city")} />
            <span className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
              <ErrorMessage errors={errors} name="city" as="p" />
            </span>
          </div>

          <div className="w-full relative flex flex-col">
            <label>Provincia/Región</label>
            <input
              className="addPackInput"
              name="state"
              {...register("state")}
            />
            <span className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
              <ErrorMessage errors={errors} name="state" as="p" />
            </span>
          </div>

          <div className="w-full relative flex flex-col">
            <label>País</label>
            <input
              className="addPackInput"
              name="country"
              {...register("country")}
            />
            <span className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
              <ErrorMessage errors={errors} name="country" as="p" />
            </span>
          </div>
        </section>

        <section className="grid grid-cols-3 gap-2 my-2">
          <h2 className="col-span-3 text-darkBlue font-medium text-lg text-center">
            Impuesto
          </h2>
          <div className="w-full relative flex flex-col">
            <label>Nombre</label>
            <input
              className="addPackInput"
              name="tax_name"
              {...register("tax_name")}
            />
            <span className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
              <ErrorMessage errors={errors} name="tax_name" as="p" />
            </span>
          </div>

          <div className="w-full relative flex flex-col">
            <label>Tipo</label>
            <select
              className="addPackInput"
              name="tax_type"
              {...register("tax_type")}
            >
              {taxTypeOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            <span className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
              <ErrorMessage errors={errors} name="tax_type" as="p" />
            </span>
          </div>

          <div className="w-full relative flex flex-col">
            <label>ID</label>
            <input
              className="addPackInput"
              name="tax_id"
              {...register("tax_id")}
            />
            <span className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
              <ErrorMessage errors={errors} name="tax_id" as="p" />
            </span>
          </div>
        </section>

        <section className="grid grid-cols-3 gap-2">
          <h2 className="col-span-3 text-darkBlue font-medium text-lg text-center">
            Contacto
          </h2>
          <div className="w-full relative flex flex-col">
            <label>Nombre</label>
            <input
              className="addPackInput"
              name="contact_name"
              {...register("contact_name")}
            />
            <span className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
              <ErrorMessage errors={errors} name="contact_name" as="p" />
            </span>
          </div>

          <div className="w-full relative flex flex-col">
            <label>Apellido</label>
            <input
              className="addPackInput"
              name="contact_lastname"
              {...register("contact_lastname")}
            />
            <span className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
              <ErrorMessage errors={errors} name="contact_lastname" as="p" />
            </span>
          </div>

          <div className="w-full relative flex flex-col">
            <label>Cargo</label>
            <input
              className="addPackInput"
              name="contact_position"
              {...register("contact_position")}
            />
            <span className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
              <ErrorMessage errors={errors} name="contact_position" as="p" />
            </span>
          </div>
        </section>

        <button className="font-semibold text-lg ml-auto mt-4 button w-1/4 bg-brightBlue text-white hover:shadow-lg transition-all">
          Guardar
        </button>
      </form>
    </>
  );
};

export default UpdateAgencyModal;
