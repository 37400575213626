import { z } from "zod";

export const newUserSchema = z.object({
  email: z.string().nonempty("Email es requerido").email("Email inválido"),
  password: z
    .string()
    .nonempty("Contraseña es requerida")
    .min(8, "La contraseña debe contener al menos 8 caracteres")
    .max(16, "La contraseña debe contener máximo 16 caracteres")
    .regex(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[a-zA-Z0-9!@#$%^&*()_+]{8,16}$/,
      "Debe contener minúsculas, mayúsculas, números y caracteres especiales"
    ),
  rePassword: z
    .string()
    .nonempty("Contraseña es requerida")
    .min(8, "La contraseña debe contener al menos 8 caracteres")
    .max(16, "La contraseña debe contener máximo 16 caracteres")
    .regex(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[a-zA-Z0-9!@#$%^&*()_+]{8,16}$/,
      "Debe contener minúsculas, mayúsculas, números y caracteres especiales"
    ),
  username: z.string().optional(),
  tour_op_id: z.number().default(1),
});