import React, { useState } from "react";
import { AutoComplete } from "../../utils/AutoComplete";
import { useForm } from "react-hook-form";
import { useToggleVisibility } from "../../hooks/useToggleVisibility";
import { format } from "date-fns";
import { usePackages } from "../../hooks/usePackages";
import { Toaster, toast } from "react-hot-toast";
import { Link, useNavigate, useParams } from "react-router-dom";
import SEO from "../../utils/SEO";
import NewCalendar from "../../utils/NewCalendar";
import { saveTourOperator } from "../../utils/saveTourOperator";
import { ErrorMessage } from "@hookform/error-message";
import { zodResolver } from "@hookform/resolvers/zod";
import useGetCurrencies from "../../hooks/useGetCurrencies";
import { packageSchema as schema } from "../../schemas/packages";

const packagesCategoriesFromScratch = [
  { id: 3, title: "Aventura" },
  { id: 6, title: "Cultural" },
  { id: 1, title: "Deportivo" },
  { id: 2, title: "Educativo" },
  { id: 5, title: "Quinceañera" },
  { id: 4, title: "Religioso" },
];

export const FormAddPaquete = () => {
  const { tour_op_id } = useParams();
  const tourOP = saveTourOperator((st) => st.tourOP);
  const { createPackage, packagesCategories } = usePackages();
  const { currencies } = useGetCurrencies();
  const [openFsalida, toggleSalida] = useToggleVisibility();
  const [rangeDate, setRangeDate] = useState();
  const [oricodeInput, setOricodeInput] = useState("");
  const [descodeInput, setDescodeInput] = useState("");
  const [photoSource, setPhotoSource] = useState("");
  const [pictures, setPictures] = useState([]);
  const [pictureError, setPictureError] = useState(false);
  const [flyerDataUrl, setFlyerDataUrl] = useState(null);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({ resolver: zodResolver(schema) });
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    if (pictures.length === 0) {
      setPictureError("Foto requerida");
      return;
    }

    toast.promise(
      createPackage({
        package: {
          price: data.price,
          creation_date: new Date().toISOString(),
          title: data.name,
          descode: data.descode,
          description: data.description.replace(/\n/g, "<br>"),
          departure_date: data.fsalida + "T17:00:00.000Z",
          arrival_date: data.fsalida + "T17:00:00.000Z", // Por ahora no tenemos
          return_date: data.fvuelta + "T17:00:00.000Z",
          oricode: data.oricode,
          stock: data.cupos,
          original_stock: data.cupos,
          availability: "Y", // Y ó N
          fare: 1, // Preguntar qué es esto
          country_id: 1,
          tour_op_id: tourOP.id,
          portrait: pictures?.[0]?.url,
          provider_id: 1,
          package_category_id: data.category || 1,
          currency_type_id: data.currency_type_id || 1,
          tax_package: data.tax_package,
          flyer: data?.flyer,
        },
        pictures,
      }).then((e) => {
        navigate(`/${tour_op_id}/panel/packages/my-packages`, {
          replace: true,
        });
      }).catch(e => {
        console.log(e)
        throw new Error()
      }),
      {
        loading: "Cargando...",
        error: <b>Hubo un error, intenta de nuevo.</b>,
      }
    );
  };

  const handleAddPicture = () => {
    if (photoSource.length > 255)
      return setPictureError("El máximo es de 255 carácteres.");
    if (photoSource.length === 0)
      return setPictureError("Complete el campo con una URL válida.");

    setPictures((curr) => {
      return [...curr, { url: photoSource }];
    });
    setValue("portrait", pictures?.[0] ?? photoSource);
    setPhotoSource("");
    setPictureError(false);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setFlyerDataUrl(e.target.result);
        setValue("flyer", e.target.result);
      };

      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <SEO title={"Agregar Paquete"} />
      <div className="w-full">
        <header className="flex justify-between my-16 max-w-[1200px] mx-auto">
          <h1 className="text-darkBlue font-semibold text-3xl">
            Agregar Paquete
          </h1>
          <Link
            to={`/${tour_op_id}/panel/packages/my-packages`}
            className="ml-auto button text-center border-2 text-darkBlue font-medium border-gray hover:border-darkBlue transition-colors"
          >
            Volver a Mis Paquetes
          </Link>
        </header>

        <form
          onSubmit={handleSubmit(onSubmit)}
          className="my-10 mt-16 mx-auto max-w-[1200px] bg-white rounded-lg p-8"
        >
          <div>
            <Toaster />
          </div>
          <div className="grid grid-cols-12 gap-12">
            <section className="flex flex-col gap-4 col-span-5">
              <h2 className="text-darkBlue font-medium">INFORMACIÓN GENERAL</h2>
              <div className="grid grid-cols-2 gap-4">
                <label htmlFor="name" className="col-span-2 relative">
                  <input
                    placeholder="Ingresar el nombre completo del paquete"
                    className="addPackInput w-full"
                    name="name"
                    {...register("name")}
                  />
                  <div className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
                    <ErrorMessage errors={errors} name="name" as="p" />
                  </div>
                </label>

                <label htmlFor="oricode" className="relative">
                  <AutoComplete
                    justCities
                    notIcon
                    classname="addPackInput w-full"
                    register={register}
                    name="oricode"
                    placeholder="Origen"
                    handleAction={(d) => setValue("oricode", d.toUpperCase())}
                    inputState={[oricodeInput, setOricodeInput]}
                  />
                  <div className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
                    <ErrorMessage errors={errors} name="oricode" as="p" />
                  </div>
                </label>

                <label htmlFor="descode" className="relative">
                  <AutoComplete
                    justCities
                    notIcon
                    classname="addPackInput w-full"
                    register={register}
                    name="descode"
                    placeholder="Destino"
                    handleAction={(d) => setValue("descode", d.toUpperCase())}
                    inputState={[descodeInput, setDescodeInput]}
                  />
                  <div className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
                    <ErrorMessage errors={errors} name="descode" as="p" />
                  </div>
                </label>

                <div className="flex flex-col">
                  <div className="flex flex-col items-start gap-2">
                    <label htmlFor="fsalida" className="relative w-full">
                      <input
                        autoComplete="off"
                        name="fsalida"
                        placeholder="Ida"
                        onClick={() => toggleSalida()}
                        value={
                          watch("fsalida")
                            ? format(
                                new Date(watch("fsalida") + "T00:00:00"),
                                "dd/MM/yyyy"
                              )
                            : ""
                        }
                        className="addPackInput w-full"
                      />
                      <div className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
                        <ErrorMessage errors={errors} name="fsalida" as="p" />
                      </div>
                      {openFsalida ? (
                        <NewCalendar
                          handlers={[
                            rangeDate,
                            (date) => {
                              setRangeDate(date);
                              setValue(
                                "fsalida",
                                date?.from
                                  ? format(date?.from, "yyyy-MM-dd")
                                  : ""
                              );
                              setValue(
                                "fvuelta",
                                date?.to ? format(date?.to, "yyyy-MM-dd") : ""
                              );
                              date?.from && date?.to && toggleSalida();
                            },
                          ]}
                          mode={"range"}
                          numberOfMonths={2}
                          close={toggleSalida}
                        />
                      ) : null}
                    </label>
                  </div>
                </div>

                <div className="flex flex-col">
                  <div className="flex flex-col items-start gap-2">
                    <label htmlFor="fvuelta" className="relative w-full">
                      <input
                        autoComplete="off"
                        name="fvuelta"
                        placeholder="Vuelta"
                        onClick={() => toggleSalida()}
                        value={
                          watch("fvuelta")
                            ? format(
                                new Date(watch("fvuelta") + "T00:00:00"),
                                "dd/MM/yyyy"
                              )
                            : ""
                        }
                        className="addPackInput w-full"
                      />
                      <div className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
                        <ErrorMessage errors={errors} name="fvuelta" as="p" />
                      </div>
                    </label>
                  </div>
                </div>

                <select
                  className="addPackInput"
                  name="category"
                  {...register("category", { valueAsNumber: true })}
                >
                  {packagesCategories &&
                  packagesCategories?.data?.response?.length > 0
                    ? packagesCategories?.data?.response?.map((element) => (
                        <option key={element.id} value={element.id}>
                          {element.title}
                        </option>
                      ))
                    : packagesCategoriesFromScratch?.map((element) => (
                        <option key={element.id} value={element.id}>
                          {element.title}
                        </option>
                      ))}
                </select>
                <div className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
                  <ErrorMessage errors={errors} name="category" as="p" />
                </div>

                <label htmlFor="cupos" className="relative w-full">
                  <input
                    placeholder="Cupos Totales Disponibles"
                    className="addPackInput w-full"
                    name="cupos"
                    {...register("cupos")}
                  />
                  <div className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
                    <ErrorMessage errors={errors} name="cupos" as="p" />
                  </div>
                </label>

                <div className="flex flex-col col-span-2">
                  <label
                    htmlFor="description"
                    className="text-darkBlue mt-3 my-2 relative w-full"
                  >
                    <p className="font-medium">DESCRIPCIÓN</p>
                    <textarea
                      id="description"
                      name="description"
                      {...register("description")}
                      className="addPackInput w-full"
                    />
                    <div className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
                      <ErrorMessage errors={errors} name="description" as="p" />
                    </div>
                  </label>
                </div>
              </div>

              <div>
                <h3 className="text-darkBlue font-medium mt-3 mb-2">
                  PRECIO POR PERSONA
                </h3>
                <div className="grid grid-cols-3 gap-4">
                  <label className="relative w-full">
                    <p>Moneda</p>
                    <select
                      name="currency_type_id"
                      id="currency_type_id"
                      className="addPackInput w-full"
                      {...register("currency_type_id", { valueAsNumber: true })}
                    >
                      <option value="something">- Elegir Moneda -</option>
                      {currencies?.map(({ name, symbol, id }) => {
                        return (
                          <option key={id} value={id}>
                            {name} ({symbol})
                          </option>
                        );
                      })}
                    </select>
                    <div className="absolute text-red-600 text-xs -bottom-[15px] whitespace-nowrap">
                      <ErrorMessage
                        errors={errors}
                        name="currency_type_id"
                        as="p"
                      />
                    </div>
                  </label>
                  <label htmlFor="price" className="relative w-full">
                    <p>Tarifa</p>
                    <input
                      type="number"
                      placeholder="Por pasajero"
                      className="addPackInput w-full"
                      name="price"
                      {...register("price")}
                    />
                    <div className="absolute text-red-600 text-xs -bottom-[15px] whitespace-nowrap">
                      <ErrorMessage errors={errors} name="price" as="p" />
                    </div>
                  </label>
                  <label htmlFor="tax_package" className="relative w-full">
                    <p>Impuesto</p>
                    <input
                      type="number"
                      placeholder="Por pasajero"
                      className="addPackInput w-full"
                      name="tax_package"
                      {...register("tax_package")}
                    />
                    <div className="absolute text-red-600 text-xs -bottom-[15px] whitespace-nowrap">
                      <ErrorMessage errors={errors} name="tax_package" as="p" />
                    </div>
                  </label>
                </div>
              </div>
            </section>

            <section className="relative col-span-7 pb-16">
              <h2 className="text-darkBlue font-medium mb-4">
                FOTOS DEL PAQUETE
              </h2>
              <div className="flex gap-2 items-center mb-4">
                <label htmlFor="photo" className="relative w-full">
                  <input
                    placeholder="Link a imagen"
                    className="addPackInput w-full"
                    name="photo"
                    value={photoSource}
                    onChange={(e) => {
                      setPhotoSource(e.target.value);
                      if (e.target.value.length > 255)
                        return setPictureError(
                          "El máximo es de 255 carácteres."
                        );
                      else if (pictureError) return setPictureError(false);
                    }}
                  />
                  <div className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
                    {pictureError ? (
                      <p>{pictureError}</p>
                    ) : (
                      <ErrorMessage errors={errors} name="portrait" as="p" />
                    )}
                  </div>
                </label>
                <button
                  type="button"
                  onClick={handleAddPicture}
                  className={`py-1.5 px-8 rounded bg-darkBlue text-white ${
                    pictureError === "Foto requerida" && "animate-bounce"
                  }`}
                >
                  Agregar
                </button>
              </div>

              {pictures && (
                <div className="flex flex-col gap-1">
                  {pictures.map((pic, index) => {
                    return (
                      <img
                        key={`Foto ${index}`}
                        src={pic.url}
                        alt={`Foto ${index}`}
                        className="max-h-60 w-fit rounded"
                      />
                    );
                  })}
                </div>
              )}

              <h2 className="text-darkBlue font-medium my-4">FLYER</h2>
              <div className="flex flex-col gap-2 items-center mb-4">
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                  className="addPackInput w-full"
                />

                {flyerDataUrl && (
                  <img
                    src={flyerDataUrl}
                    alt="Selected"
                    className="max-w-[16rem] max-h-[500px]"
                  />
                )}
              </div>

              <button className="button ml-auto absolute bottom-0 right-0 text-lg w-1/2 bg-darkBlue text-white hover:shadow-lg transition-all">
                Guardar Paquete
              </button>
            </section>
          </div>
        </form>
      </div>
    </>
  );
};
