import axios from "axios";
import { useEffect, useState } from "react";
import { getLocalStorageValue, getSessionStorageValue } from "./useCookie";

export const useFunctionalities = () => {
  const BASE_URL = process.env.REACT_APP_API_URL;
  const [functionalities, setFunctionalities] = useState();

  const token =
    getLocalStorageValue("token") || getSessionStorageValue("token");

  const headers = {
    headers: { Authorization: `${token}` },
  };

  const postFunctionality = async ({ functionality }) => {
    return await axios.post(
      BASE_URL + "v2/functionalities",
      { functionality },
      headers
    );
  };

  const getFunctionalities = async () => {
    const res = await axios
      .get(BASE_URL + "v2/functionalities", headers)
      .then((e) => {
        // console.log(e);
        e?.data && setFunctionalities(e?.data?.response);
      })
      .catch((error) => console.log(error));
    if (res) {
      return res?.data || {};
    }
  };

  const deleteFunctionality = async (id) => {
    return await axios.delete(BASE_URL + `v2/functionalities/${id}`, headers);
  };

  const voteFunctionality = async ({ functionality_id, votesquantity }) => {
    return await axios.patch(
      BASE_URL + `v2/functionalities/votes/${functionality_id}`,
      {
        votesquantity,
      },
      headers
    );
  };

  const editFunctionality = async ({ functionality_id, functionality }) => {
    return await axios.patch(
      BASE_URL + `v2/functionalities/${functionality_id}`,
      { functionality },
      headers
    );
  };

  useEffect(() => {
    getFunctionalities();
  }, []);

  return {
    functionalities,
    postFunctionality,
    getFunctionalities,
    deleteFunctionality,
    voteFunctionality,
    editFunctionality,
  };
};
