import { useEffect } from "react";
import { useTravelers } from "../../hooks/useTravelers";
import SEO from "../../utils/SEO";
import TravelersTable from "./TravelersTable";

export default function Travelers() {
  const { travelers, getTravelers } = useTravelers();

  useEffect(() => {
    getTravelers();
  }, []);

  return (
    <>
      <SEO title={"Pasajeros"} />
      <main className="flex flex-col w-full mt-8 font-body">
        <header className="mb-4">
          <h1 className="font-[700] text-darkBlue text-3xl">Pasajeros</h1>
          <p>Gestión de Pasajeros</p>
        </header>

        <TravelersTable travelers={travelers} />
      </main>
    </>
  );
}
