/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState } from "react";
import useGetAirports from "../hooks/useGetAirports";
import { useEffect } from "react";
import clsx from "clsx";
import useOnClickOutside from "../hooks/useClickOutside";

export default function AutoCompleteCountryName({
  classname,
  placeholder,
  register,
  name,
  handleAction,
  inputState,
}) {
  const { getAirports, airports } = useGetAirports();
  const [selected, setSelected] = useState("s");
  const [inputValue, setInputValue] = inputState;
  const ref = useRef(null);

  useOnClickOutside(ref, () => setSelected("s"));

  useEffect(() => {
    getAirports();
  }, []);

  const listOfOptions =
    airports &&
    airports
      .map((item) => ({
        country_id: item.City.Country.id,
        country_name: item.City.Country.name,
      }))
      .filter((item) => {
        return item?.country_name
          ?.toLowerCase()
          ?.includes(inputValue.toLowerCase());
      })
      .sort((a, b) => {
        const lowerInputValue = inputValue.toLowerCase();
        const lowerA = a?.country_name && a?.country_name?.toLowerCase();
        const lowerB = b?.country_name && b?.country_name?.toLowerCase();

        if (!lowerA) {
          if (!lowerB) return 0;
          else return 1;
        }
        if (!lowerB) return 0;

        if (
          lowerA.startsWith(lowerInputValue) &&
          !lowerB.startsWith(lowerInputValue)
        ) {
          return -1;
        } else if (
          !lowerA.startsWith(lowerInputValue) &&
          lowerB.startsWith(lowerInputValue)
        ) {
          return 1;
        } else {
          return 0;
        }
      })
      .sort((a, b) => {
        if (a.country_name === "Argentina") return -1;
        return 0;
      });

  function removeDuplicateObjects(array) {
    const seenObjects = new Set();
    return array.filter((obj) => {
      const key = JSON.stringify(obj);
      if (!seenObjects.has(key)) {
        seenObjects.add(key);
        return true;
      }
      return false;
    });
  }

  const countryOptions = removeDuplicateObjects(listOfOptions);

  return (
    <section className="relative buscador-centro-ubicacion-input-div">
      <input
        className={clsx(
          { [classname]: classname?.length },
          {
            "py-4 pl-4 text-sm buscador-centro-ubicacion-input focus:outline-none":
              !classname?.length,
          }
        )}
        type="text"
        placeholder={placeholder ?? ""}
        {...register(name)}
        value={inputValue}
        name={name}
        autoComplete="off"
        onChange={(e) => {
          setInputValue(e.target.value);
          setSelected("");
        }}
        onBlur={(e) => handleAction(e.target.value)}
      />

      <section className="absolute z-50 bg-white max-h-[220px] overflow-y-auto overflow-x-hidden top-12 rounded-md shadow-xl w-40">
        <div ref={ref} className="flex flex-col">
          {!selected.length
            ? countryOptions
                .slice(0, 5)
                .map(({ country_id, country_name }, index) => {
                  return (
                    <div key={country_id + index}>
                      <p
                        key={index}
                        onClick={(e) => {
                          setSelected(country_id);
                          setInputValue(country_id.toString());
                          handleAction(country_id);
                        }}
                        className="px-1 pt-1 m-0 w-full cursor-pointer whitespace-pre-wrap hover:bg-slate-100"
                      >
                        {country_name}
                      </p>
                    </div>
                  );
                })
            : null}
        </div>
      </section>
    </section>
  );
}
