import React, { useState } from "react";
import { AutoComplete } from "../../utils/AutoComplete";
import { useForm } from "react-hook-form";
import { useToggleVisibility } from "../../hooks/useToggleVisibility";
import { format } from "date-fns";
import { usePackages } from "../../hooks/usePackages";
import { Toaster, toast } from "react-hot-toast";
import { Link, useParams } from "react-router-dom";
import SEO from "../../utils/SEO";
import NewCalendar from "../../utils/NewCalendar";
import { initialWeekdays } from "./weekdays";

export const FormAddActividad = () => {
  const { operador } = useParams();
  const { register, handleSubmit, setValue, watch, reset } = useForm();
  const { createPackage } = usePackages();

  const [openFsalida, toggleSalida] = useToggleVisibility();
  const [rangeDate, setRangeDate] = useState();
  const [oricodeInput, setOricodeInput] = useState("");
  const [duration, setDuration] = useState("hours");
  const [weekdays, setWeekDays] = useState(initialWeekdays);

  const [photoSource, setPhotoSource] = useState("");

  const onSubmit = async (data) => {
    console.log(data);
    toast.promise(
      createPackage({
        paquete: {
          ...data,
          adultos: Number(data.adultos),
        },
      }).then((e) => reset()),
      {
        loading: "Cargando...",
        success: <b>Actividad creada con exito</b>,
        error: <b>Hubo un error, intenta de nuevo.</b>,
      }
    );
  };

  const handleDaySelection = (evt, label, selected) => {
    evt.preventDefault();
    setWeekDays((curr) => {
      return {
        ...curr,
        [label]: {
          label,
          selected: !selected,
        },
      };
    });
  };

  return (
    <>
      <SEO title={"Agregar Actividad"} />
      <div className="w-full">
        <header className="flex justify-between mt-12 mb-8 max-w-[1000px] mx-auto">
          <h1 className="text-darkBlue font-semibold text-3xl">
            Agregar Actividad
          </h1>
          <Link
            to={`/${operador}/panel/activities/my-activities`}
            className="ml-auto button text-center border-2 text-darkBlue font-medium border-gray hover:border-darkBlue transition-colors"
          >
            Volver a Mis Actividades
          </Link>
        </header>

        <form
          // onSubmit={handleSubmit(onSubmit)}
          className="mx-auto max-w-[1000px] bg-white rounded-lg p-8"
        >
          <div>
            <Toaster />
          </div>
          <div className="grid grid-cols-12 gap-12">
            <section className="flex flex-col gap-4 col-span-5">
              <h2 className="text-darkBlue font-medium">INFORMACIÓN GENERAL</h2>
              <div className="grid grid-cols-2 gap-3">
                <input
                  type="text"
                  placeholder="Ingresar el nombre completo de la actividad"
                  className="addPackInput col-span-2"
                  name="name"
                  {...register("name")}
                />
                <AutoComplete
                  justCities
                  notIcon
                  classname="addPackInput w-full"
                  register={register}
                  name="oricode"
                  placeholder="Ciudad"
                  handleAction={(d) => setValue("oricode", d.toUpperCase())}
                  inputState={[oricodeInput, setOricodeInput]}
                />
                <select
                  className="addPackInput"
                  name="duration"
                  {...register("duration")}
                  onChange={(evt) => setDuration(evt.target.value)}
                >
                  <option value="hours">En el día</option>
                  <option value="days">Más de un día</option>
                </select>
                {duration === "days" && (
                  <>
                    <div className="flex flex-col">
                      <div className="flex flex-col items-start gap-2">
                        <input
                          autoComplete="off"
                          name="fsalida"
                          placeholder="Ida"
                          onClick={() => toggleSalida()}
                          value={
                            watch("fsalida")
                              ? format(
                                  new Date(watch("fsalida") + "T00:00:00"),
                                  "dd/MM/yyyy"
                                )
                              : ""
                          }
                          className="addPackInput w-full"
                        />
                        {openFsalida ? (
                          <NewCalendar
                            handlers={[
                              rangeDate,
                              (date) => {
                                setRangeDate(date);
                                setValue(
                                  "fsalida",
                                  date?.from
                                    ? format(date?.from, "yyyy-MM-dd")
                                    : ""
                                );
                                setValue(
                                  "fvuelta",
                                  date?.to ? format(date?.to, "yyyy-MM-dd") : ""
                                );
                                date?.from && date?.to && toggleSalida();
                              },
                            ]}
                            mode={"range"}
                            numberOfMonths={2}
                            close={toggleSalida}
                          />
                        ) : null}
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <div className="flex flex-col items-start gap-2">
                        <input
                          autoComplete="off"
                          name="fvuelta"
                          placeholder="Vuelta"
                          onClick={() => toggleSalida()}
                          value={
                            watch("fvuelta")
                              ? format(
                                  new Date(watch("fvuelta") + "T00:00:00"),
                                  "dd/MM/yyyy"
                                )
                              : ""
                          }
                          className="addPackInput w-full"
                        />
                      </div>
                    </div>
                  </>
                )}
                <select
                  className="addPackInput"
                  name="activityType"
                  {...register("activityType")}
                >
                  <option value="trekking">Trekking</option>
                  <option value="safari">Safari</option>
                  <option value="surf">Surf</option>
                  <option value="buceo">Buceo</option>
                  <option value="escalada">Escalada</option>
                </select>
                <input
                  type="text"
                  placeholder="Cupos Totales Disponibles"
                  className="addPackInput"
                  name="cupos"
                  {...register("cupos")}
                />

                {duration === "hours" && (
                  <div className="col-span-2 flex flex-col gap-2">
                    <h3 className="text-darkBlue font-medium mt-3">HORARIOS</h3>
                    <div className="w-full flex justify-between gap-1">
                      {Object.keys(weekdays).map((day) => {
                        const { label, selected } = weekdays[day];

                        return (
                          <button
                            key={label}
                            className={`border rounded w-8 h-8 transition-colors ${
                              selected
                                ? "bg-black border-white text-white"
                                : "bg-transparent border-gray2"
                            }`}
                            onClick={(evt) =>
                              handleDaySelection(evt, label, selected)
                            }
                          >
                            {label}
                          </button>
                        );
                      })}
                    </div>
                    <div className="w-full flex gap-3 justify-between items-center">
                      <input
                        type="time"
                        name="schedule"
                        className="addPackInput w-full"
                        {...register("schedule")}
                      />
                      <input
                        type="time"
                        name="schedule"
                        className="addPackInput w-full"
                        {...register("schedule")}
                      />
                    </div>
                  </div>
                )}

                <div className="flex flex-col col-span-2">
                  <label
                    htmlFor="description"
                    className="text-darkBlue font-medium mt-3 my-2"
                  >
                    DESCRIPCIÓN
                  </label>
                  <textarea
                    id="description"
                    name="description"
                    {...register("description")}
                    className="addPackInput"
                  />
                </div>
              </div>

              <div>
                <h3 className="text-darkBlue font-medium mt-3 mb-2">PRECIO</h3>
                <div className="grid grid-cols-2 gap-4">
                  {/* <input
                  type="number"
                  placeholder="Impuestos a cobrar"
                  className="addPackInput"
                  name="taxes"
                  {...register("taxes")}
                /> */}
                  <input
                    type="number"
                    placeholder="Precio por pasajero"
                    className="addPackInput"
                    name="price"
                    {...register("price")}
                  />
                </div>
              </div>
            </section>

            <section className="relative col-span-7 pb-16">
              <h2 className="text-darkBlue font-medium mb-4">
                FOTOS DE LA ACTIVIDAD
              </h2>
              <input
                type="text"
                placeholder="Link a imagen"
                className="addPackInput col-span-2 mb-4 w-48"
                name="photo"
                {...register("photo")}
                value={photoSource}
                onChange={(e) => setPhotoSource(e.target.value)}
              />
              {photoSource && (
                <img src={photoSource} alt="foto 1" className="h-60" />
              )}

              {/* <section className="grid grid-cols-2 gap-y-3 gap-x-6">
              <div className="photo h-60" />
              <div className="grid grid-rows-2 gap-3">
              <div className="photo" />
              <div className="photo" />
              </div>
              <div className="photo h-16" />
              <div className="photo h-16" />
              <div className="photo h-16" />
              <div className="photo h-16" />
            </section> */}
              <button className="button ml-auto absolute bottom-0 right-0 text-lg w-1/2 bg-darkBlue text-white hover:shadow-lg transition-all">
                Guardar Actividad
              </button>
            </section>
          </div>
        </form>
      </div>
    </>
  );
};
