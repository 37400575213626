import React, { useEffect, useMemo, useState } from "react";
import useCreateAccount from "../../hooks/useCreateAccount";
import clsx from "clsx";
import { saveTourOperator } from "../../utils/saveTourOperator";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { newUserSchema } from "../../schemas/newUser";

export default function CreateAccount({
  state,
  justCreateAccount,
  closeModal,
}) {
  const tourOP = saveTourOperator((st) => st.tourOP);
  const [showLogin, setShowLogin] = state;
  const { createNewAccount, responseErrors } = useCreateAccount();
  const [checkTerms, setCheckTerms] = useState(false);
  const [termsError, setTermsErrors] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({ resolver: zodResolver(newUserSchema) });

  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    setValue("tour_op_id", tourOP.id);
  }, [tourOP]);
  
  const onSubmit = (data) => {
    if (!checkTerms) {
      setTermsErrors("Es necesario aceptar los 'Términos y Condiciones'");
      return;
    }
    try {
      createNewAccount({ ...data, terms: checkTerms }).then(() =>
        !justCreateAccount ? setShowLogin(!showLogin) : closeModal()
      );
    } catch (validationError) {
      console.log(validationError.issues);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const formatedErrors = useMemo(() => {
    return (
      responseErrors &&
      responseErrors.reduce((acc, err) => {
        acc[err.path] = err.msg;
        return acc;
      }, {})
    );
  }, [responseErrors]);

  return (
    <section>
      <h2 className="text-center text-2xl font-medium">Crear Usuario</h2>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col gap-6 mt-2"
      >
        <div className="relative">
          <input
            className="bg-[#E8E8E8] py-1 px-3 rounded-md outline-none w-full"
            type="text"
            name="username"
            placeholder="Nombre de Usuario"
            {...register("username")}
          />
          <div className="absolute -bottom-4 text-red-600 text-xs whitespace-nowrap">
            {formatedErrors?.username ? (
              <p>{formatedErrors.username}</p>
            ) : (
              <ErrorMessage errors={errors} name="username" as="p" />
            )}
          </div>
        </div>
        <div className="relative">
          <input
            className="bg-[#E8E8E8] py-1 px-3 rounded-md outline- w-full"
            type="email"
            name="email"
            placeholder="Email"
            {...register("email")}
          />
          <div className="absolute -bottom-4 text-red-600 text-xs whitespace-nowrap">
            {formatedErrors?.email ? (
              <p>{formatedErrors.email}</p>
            ) : (
              <ErrorMessage errors={errors} name="email" as="p" />
            )}
          </div>
        </div>

        <div className="relative">
          <input
            className="bg-[#E8E8E8] py-1 px-3 rounded-md outline- w-full"
            type={showPassword ? "text" : "password"}
            name="password"
            placeholder="Contraseña"
            autoComplete="off"
            {...register("password")}
          />
          <div className="absolute -bottom-4 text-red-600 text-xs whitespace-nowrap">
            {formatedErrors?.password ? (
              <p>{formatedErrors.password}</p>
            ) : (
              <ErrorMessage errors={errors} name="password" as="p" />
            )}
          </div>
        </div>

        <div className="relative">
          <input
            className="bg-[#E8E8E8] py-1 px-3 rounded-md outline-none w-full"
            type={showPassword ? "text" : "password"}
            name="rePassword"
            placeholder="Confirmar Contraseña"
            autoComplete="off"
            {...register("rePassword")}
          />
          <div className="absolute -bottom-4 text-red-600 text-xs whitespace-nowrap">
            {formatedErrors?.rePassword ? (
              <p>{formatedErrors.rePassword}</p>
            ) : watch("password") !== watch("rePassword") &&
              watch("rePassword").length >= 8 ? (
              <p>Las contraseñas no coinciden</p>
            ) : (
              <ErrorMessage errors={errors} name="rePassword" as="p" />
            )}
          </div>
        </div>

        <label className="flex items-center gap-2">
          <input type="checkbox" onChange={togglePasswordVisibility} />
          <p>Mostrar Contraseña</p>
        </label>

        <div className="relative flex items-center gap-2">
          <input
            type="checkbox"
            name="terms"
            checked={checkTerms}
            onChange={() => {
              if (!checkTerms) {
                setTermsErrors("");
              }
              setCheckTerms(!checkTerms);
            }}
          />
          <a
            href="https://thewalltrip.com/terminos"
            target="_blank"
            rel="noreferrer"
            className="hover:underline"
          >
            Acepto los términos y condiciones.
          </a>
          <div className="absolute -bottom-4 text-red-600 text-xs whitespace-nowrap">
            {termsError && <p>{termsError}</p>}
          </div>
        </div>

        <div className="flex items-center justify-between gap-6">
          {!justCreateAccount && (
            <button
              onClick={() => setShowLogin(!showLogin)}
              className="text-sm underline"
            >
              Ya tengo cuenta
            </button>
          )}
          <button
            id="createAccount"
            type="submit"
            className={clsx(
              "bg-[#CEDEF1] ml-auto py-2 px-12 text-center font-medium rounded-md flex items-center gap-3"
            )}
          >
            Crear Cuenta
            {/* {loading ? (
              <svg
                className="animate-spin -ml-1 mr-3 h-5 w-5 text-gray"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  stroke="currentColor"
                  stroke-width="4"
                  cx="12"
                  cy="12"
                  r="10"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            ) : null} */}
          </button>
        </div>
      </form>
    </section>
  );
}
