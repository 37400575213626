import searchIcon from "../../iconos/person_search.svg";
import { Link, useParams } from "react-router-dom";
import { useStoreSearchInput } from "../../componentes/booking/useStoreSearchInput";

export default function SidebarPanelSearcher({ isHovered }) {
  const { tour_op_id } = useParams();
  const searchInput = useStoreSearchInput((st) => st.searchInput);
  const setSearchInput = useStoreSearchInput((st) => st.setSearchInput);

  return (
    <section className="relative gap-2 items-center">
      <Link
        to={`/${tour_op_id}/panel/sales/bookings/flight`}
        className={`absolute w-6 max-w-[1/4] left-4 ${
          searchInput.length > 0 && isHovered
            ? "cursor-pointer animate-bounce"
            : "pointer-events-none"
        }`}
      >
        <img src={searchIcon} alt="Búsqueda" />
      </Link>
      <input
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        placeholder="Nombre, Apellido o ID"
        className={`w-3/4 ml-12 border rounded text-xs px-2 py-1 transition-transform ${
          isHovered ? "scale-100" : "scale-0"
        }`}
      />
    </section>
  );
}
