import { format } from "date-fns";
import { useCopy } from "../../hooks/utils/useCopy";
import { defaultDateFormat } from "../../utils/commons";
import { adaptPassenger } from "./Pasajeros";
import numberWithCommas from "../../utils/numberWithCommas";

const translateMonth = (month) => {
  if (month === "1") return "ENE";
  if (month === "2") return "FEB";
  if (month === "3") return "MAR";
  if (month === "4") return "ABR";
  if (month === "5") return "MAY";
  if (month === "6") return "JUN";
  if (month === "7") return "JUL";
  if (month === "8") return "AGO";
  if (month === "9") return "SEP";
  if (month === "10") return "OCT";
  if (month === "11") return "NOV";
  else return "DIC";
};

export default function useMessage(
  parsedFlights,
  ciudadesFormatted,
  Travelers
) {
  const [isCopied, copyToClipboard] = useCopy();

  const { travelerPricings, itineraries } = parsedFlights;
  const idas = itineraries?.[0]?.segments;
  const vueltas = itineraries?.[1]?.segments;
  let salida = idas?.[0];
  let llegada = idas?.at(-1);
  let ida = idas?.[0];
  let vuelta = vueltas?.[0];

  const copyItinerarytoAmadeus = (e) => {
    const diaSalida = format(new Date(salida?.departure?.at), "d");
    const diaLlegada = format(new Date(llegada?.arrival?.at), "d");
    const mesSalida = translateMonth(
      format(new Date(salida?.departure?.at), "M")
    );
    const mesLlegada = translateMonth(
      format(new Date(llegada?.arrival?.at), "M")
    );
    const lugarSalida = salida?.departure?.iataCode;
    const lugarLlegada = llegada?.arrival?.iataCode;

    const text = `AN${diaSalida}${mesSalida}${lugarSalida}${lugarLlegada}*${diaLlegada}${mesLlegada}`;

    copyToClipboard(text);

    const originalText = e.target.textContent;
    e.target.textContent = "Copiado";
    e.target.classList.add("border-green-600");
    e.target.classList.add("text-green-600");

    setTimeout(() => {
      e.target.classList.remove("border-green-600");
      e.target.classList.remove("text-green-600");
      e.target.textContent = originalText;
    }, 2000);
  };

  const copyItinerarytoWpp = (e) => {
    const capitalizeString = (word) =>
      word.charAt(0).toUpperCase() + word.toLowerCase().slice(1);

    const passengerText = Travelers.map((v) => {
      console.log(v);
      let { first_name, last_name, date_of_birth, TravelerDocument } = v;
      date_of_birth = format(new Date(date_of_birth), defaultDateFormat);

      return `    ${capitalizeString(first_name)} ${capitalizeString(
        last_name
      )} - ${date_of_birth} - ${TravelerDocument?.number}\n`;
    }).join("    ");

    const vuelostext = (flights, type) => {
      return flights.map((flight, index) => {
        if ((type === "ida") & (index > 0)) ida = idas[index];
        else if ((type === "vuelta") & (index > 0)) vuelta = vueltas[index];

        let isScale = "";
        if (index > 0) isScale = `    <${index}° Escala>\n`;

        const flightClass = travelerPricings?.[0]?.fareDetailsBySegment.filter(
          (el) => el.segmentId === flight.id
        );

        const text = `
          ${flight?.carrierCode} ${flight?.number} ${flightClass?.[0]?.class}
      ${flight.departure.iataCode} ${
          ciudadesFormatted?.[flight?.departure?.iataCode]?.aeropuerto
        }
      ${format(new Date(flight?.departure?.at), "dd/MM/yyyy")} ${format(
          new Date(flight?.departure?.at),
          "H:mm"
        )}hs
        >
        ${flight.arrival.iataCode}_${
          ciudadesFormatted?.[flight?.arrival?.iataCode]?.aeropuerto
        }
      ${format(new Date(flight?.arrival?.at), "dd/MM/yyyy")} ${format(
          new Date(flight?.arrival?.at),
          "H:mm"
        )}hs\n\n`;

        if (index === 0) return text;
        return isScale + text;
      });
    };

    const idaText = vuelostext(idas, "ida").join("  ");
    const vueltaText = vuelostext(vueltas, "vuelta").join(" ");

    const passengerTypeCounter = {
      ADULT: 0,
      CHILD: 0,
      HELD_INFANT: 0,
    };

    const tarifaPerPassenger = travelerPricings
      ?.map((el, index) => {
        passengerTypeCounter[el.travelerType] += 1;
        const traveler = adaptPassenger(el.travelerType);
        const indexTraveler = passengerTypeCounter[el.travelerType];
        const { base, total } = el.price;

        return `
      ${traveler} ${indexTraveler} $${numberWithCommas(Number(base), 2)}
    IMP ${index + 1} $${numberWithCommas(Number(total - base), 2)}
    PRECIO ${traveler} ${indexTraveler} $${numberWithCommas(
          Number(total),
          2
        )}\n`;
      })
      .join("   ");

    const totalPrice = travelerPricings?.reduce(
      (acc, el) => (acc += Number(el.price.total)),
      0
    );

    const tarifaText = `
          ${tarifaPerPassenger}
          PRECIO TOTAL $${numberWithCommas(totalPrice, 2)}
    `;

    const text = `
    Hola! Esta es la información de su vuelo:\n
    * PASAJEROS *
    ${passengerText}
    * IDA *
    ${idaText}
    * VUELTA *
    ${vueltaText}
    * TARIFA *${tarifaText}`;

    copyToClipboard(text);
    const originalText = e.target.textContent;
    e.target.textContent = "Copiado";
    e.target.classList.add("border-green-600");
    e.target.classList.add("text-green-600");

    setTimeout(() => {
      e.target.classList.remove("border-green-600");
      e.target.classList.remove("text-green-600");
      e.target.textContent = originalText;
    }, 2000);
  };

  return { copyItinerarytoAmadeus, copyItinerarytoWpp };
}
