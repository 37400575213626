import { useState } from "react";
import SelectedQuoteFlight from "./SelectedQuoteFlight";
import numberWithCommas from "../../utils/numberWithCommas";
import whatsapp from "../../iconos/whatsapp_logo.png";
import email_blue from "../../iconos/email_blue.png";
import email_white from "../../iconos/email_white.png";
import textMessage from "./textMessage";
import SelectedQuotePassenger from "./SelectedQuotePassenger";

export default function SelectedQuote({
  selectedOption,
  original,
  routeOption,
  exchangeRate,
  setSelectedOption,
  passengers,
}) {
  const [client, setClient] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const [passengersInfo, setPassengersInfo] = useState({});
  const [openPassenger, setOpenPassenger] = useState(0);
  const [displayPassengers, setDisplayPassengers] = useState(false);
  const { dolar, euro } = exchangeRate;

  const sumOfPassengers = Object.entries(passengers)
    .reduce((acc, [type, number]) => {
      return [
        ...acc,
        new Array(number)
          .fill(type)
          .map((passenger, index) => `${passenger}-${index + 1}`),
      ];
    }, [])
    .filter((item) => item.length > 0)
    .flat();

  const deleteOption = (idToDelete, stageIndex) => {
    setSelectedOption((curr) => {
      const { origin, destination } = curr;

      if (origin && destination) {
        const filterOption = (arr) => arr.filter((item) => item !== idToDelete);

        const copyOrigin = origin;
        const copyDestination = destination;
        copyOrigin[stageIndex] = filterOption(origin[stageIndex]);
        copyDestination[stageIndex] = filterOption(destination[stageIndex]);

        return {
          origin: copyOrigin,
          destination: copyDestination,
        };
      } else {
        curr.filter((opt) => opt.id !== idToDelete);
      }
    });
  };

  return (
    <aside
      className={`flex flex-col gap-4 w-full h-fit pb-6 ${
        routeOption === "multidestination" && "mt-2"
      } ${
        ((routeOption === "roundtrip" &&
          (selectedOption[0].length === 1 || sumOfPassengers.length <= 1)) ||
          (routeOption === "oneway" &&
            (selectedOption[0].length <= 2 || sumOfPassengers.length <= 2))) &&
        "sticky top-4"
      }`}
    >
      {selectedOption.map((section, sectionIndex) => {
        if (section.length === 0) return null;

        return (
          <div key={section.id - sectionIndex} className="text-center">
            {routeOption === "multidestination" && (
              <h2 className="font-bold text-2xl">Tramo {sectionIndex + 1}</h2>
            )}

            <div className="rounded-lg bg-white p-4 grid gap-2">
              {section.map((opt, index) => {
                const [ida, vuelta] = opt.itineraries;
                const { total, currency } = opt.price;
                const totalToARS = (number) =>
                  currency === "ARS" ? number : euro * number;

                return (
                  <section
                    key={opt.id - index}
                    className="relative flex flex-col gap-2 items-center"
                  >
                    <button
                      className="bg-red-400/70 text-white text-xs font-bold rounded-full w-4 h-4 absolute top-0 right-0"
                      onClick={() => deleteOption(opt.id, sectionIndex)}
                    >
                      X
                    </button>
                    <h2 className="font-[700] text-darkBlue text-lg">
                      Selección {index + 1}
                    </h2>
                    <p className="text-brightBlue font-[700] text-3xl -mt-1">
                      {/* {currency === "EUR" ? "~" : ""}
                      {numberWithCommas(totalToARS(Number(total)), 0)}
                      <span className="text-xl text-brightBlue">ARS</span> */}

                      {/* ---------------------------- */}
                      {numberWithCommas(Number(total), 0)}
                      <span className="text-xl text-brightBlue">EUR</span>
                      {/* ---------------------------- */}
                    </p>
                    <SelectedQuoteFlight
                      flights={ida}
                      original={original[sectionIndex]}
                    />
                    {routeOption === "roundtrip" && (
                      <SelectedQuoteFlight
                        flights={vuelta}
                        original={original[sectionIndex]}
                      />
                    )}
                  </section>
                );
              })}
            </div>
          </div>
        );
      })}

      <label htmlFor="passengersDisplay" className="flex gap-4 items-center">
        <input
          id="passengersDisplay"
          type="checkbox"
          checked={displayPassengers}
          onChange={() => setDisplayPassengers(!displayPassengers)}
          className="h-5 w-5"
        />
        <h2 className="font-[700] text-darkBlue text-xl text-center">
          ¿Tenemos datos de los pasajeros?
        </h2>
      </label>

      {displayPassengers && (
        <section className="flex flex-col gap-2">
          {sumOfPassengers.map((item, index) => {
            return (
              <SelectedQuotePassenger
                key={`${item}-${index}`}
                index={index}
                passenger={item}
                clientState={[passengersInfo, setPassengersInfo]}
                openPassengerState={[openPassenger, setOpenPassenger]}
                isOpen={index === openPassenger}
              />
            );
          })}
        </section>
      )}

      <section>
        <h2 className="font-[700] text-darkBlue text-xl mb-2 text-center">
          Datos del Cliente
        </h2>
        <div className="flex flex-col gap-2">
          <input
            type="text"
            className="bg-lightBlue2 rounded-md w-full p-2 border border-brightBlue"
            placeholder="Nombre del Cliente"
            value={client.name}
            onChange={(e) =>
              setClient((curr) => {
                return { ...curr, name: e.target.value };
              })
            }
          />
          <input
            type="tel"
            className="bg-lightBlue2 rounded-md w-full p-2 border border-brightBlue"
            placeholder="Teléfono"
            value={client.phone}
            onChange={(e) =>
              setClient((curr) => {
                return { ...curr, phone: e.target.value };
              })
            }
          />
          <input
            type="email"
            className="bg-lightBlue2 rounded-md w-full p-2 border border-brightBlue"
            placeholder="cliente@email.com"
            value={client.email}
            onChange={(e) =>
              setClient((curr) => {
                return { ...curr, email: e.target.value };
              })
            }
          />
        </div>
      </section>

      <section className="flex flex-col">
        <h2 className="font-[700] text-darkBlue text-xl mb-2 text-center">
          Enviar Cotización
        </h2>
        <div className="grid grid-cols-2 gap-2">
          <a
            href={`https://api.whatsapp.com/send?phone=${
              client.phone
            }&text=${textMessage(
              selectedOption,
              routeOption === "roundtrip",
              client.name,
              "wpp",
              [dolar, euro]
            )}`}
            target="_blank"
            rel="noreferrer"
            className={`bg-brightBlue border border-brightBlue py-1.5 rounded hover:opacity-90 shadow-md transition-all ${
              !client.phone && "bg-lightBlue2 pointer-events-none shadow-none"
            }`}
          >
            <img src={whatsapp} alt="whatsapp" className="mx-auto h-6" />
          </a>
          <a
            href={`mailto:${client.email}?subject=Cotización&body=${textMessage(
              selectedOption,
              routeOption === "roundtrip",
              client.name,
              "mail",
              [dolar, euro]
            )}`}
            target="_blank"
            rel="noreferrer"
            className={`bg-brightBlue border border-brightBlue py-1.5 rounded hover:opacity-90 shadow-md transition-all ${
              !client.email && "bg-lightBlue2 pointer-events-none shadow-none"
            }`}
          >
            <img
              src={!client.email ? email_blue : email_white}
              alt="email"
              className="m-auto h-5 transition-all"
            />
          </a>
        </div>
      </section>
    </aside>
  );
}
