import { useSelectFlights } from "../flights/useSelectFlights";
import TramoModalFlight from "./TramoModalFlight";
import mochila from "../../iconos/mochila-green.png";
import mano from "../../iconos/equimano-green.png";
import valija from "../../iconos/equivalija-green.png";
import valijaGris from "../../iconos/equivalija.png";

export default function TramoModal({
  data,
  duration,
  flights,
  formattedCities,
  section = "searchFlights",
  title,
  type,
}) {
  const storedFlight = useSelectFlights((st) => st.flight);
  const flight = section === "searchFlights" ? storedFlight : flights;
  const flightIndex =
    section === "searchFlights" ? type : title === "Ida" ? 0 : 1;
  const scales = flight?.[flightIndex]?.segments?.length - 1;

  const firstPassenger = data?.[0];
  const flightsDetails =
    type === "IDA"
      ? firstPassenger.fareDetailsBySegment.slice(0, scales + 1)
      : firstPassenger.fareDetailsBySegment.slice(scales + 2);

  const includedBags = flightsDetails
    .map((el) => el.includedCheckedBags.quantity)
    .sort((a, b) => a - b)?.[0];

  const translatedCabin =
    data[0].fareDetailsBySegment[0].cabin === "ECONOMY"
      ? "Económica"
      : "Ejecutiva";

  const translatedDuration =
    duration &&
    duration
      .split("H")
      .map((el) => {
        const regex = /\d+/g;
        const numbers = el.match(regex);

        return numbers ? numbers.map(Number) : null;
      })
      .flat();

  return (
    <section className="text-darkBlue">
      <header className="w-full mb-3">
        {title && <p className="font-[700] text-xl">{title}</p>}
        <span className="flex items-center gap-1 font-medium">
          <p className="text-sm">
            {data.length} {data.length === 1 ? "pasajero" : "pasajeros"} /
          </p>
          <p className="text-sm font-mediumcapitalize">
            Clase {translatedCabin}
          </p>
        </span>
      </header>

      <section>
        {flight &&
          flight?.[flightIndex]?.segments.map((fl, index) => {
            return (
              <TramoModalFlight
                key={fl.id}
                index={index}
                flights={flight?.[flightIndex]?.segments}
                translatedCabin={translatedCabin}
                formattedCities={formattedCities}
              />
            );
          })}
      </section>

      <section className="my-3 h-9 rounded-lg border border-brightBlue grid justify-center items-center">
        <p>
          Duración:{" "}
          <span className="font-[700] text-darkBlue">
            {translatedDuration[0]}h {translatedDuration[1]}m
          </span>
        </p>
      </section>

      <section className="p-2 rounded-lg border-2 border-gray2 ">
        <header>
          <h2 className="text-center font-medium mb-2">Equipaje</h2>
        </header>
        <div className="grid grid-cols-3 gap-3 text-xs text-gray">
          <div className="flex gap-2 items-center">
            <img src={mochila} alt="mochila" className="h-4" />
            <div className="">
              <p className="text-brightGreen">Incluye una mochila o cartera</p>
              <p>Debe caber bajo el asiento delantero.</p>
            </div>
          </div>
          <div className="flex gap-2 items-center mx-auto">
            <img src={mano} alt="Valija de mano" className="h-5" />
            <div className="">
              <p className="text-brightGreen">Incluye equipaje de mano</p>
              <p>Debe caber en el compartimiento superior del avión.</p>
            </div>
          </div>
          <div className="flex gap-2 items-center ml-auto">
            <img
              src={includedBags > 0 ? valija : valijaGris}
              alt="Valija de despacho"
              className="h-6"
            />
            <div className="">
              <p className={`${includedBags > 0 && "text-brightGreen"}`}>
                {includedBags > 0
                  ? "Incluye equipaje para despachar"
                  : "No incluye equipaje para despachar"}
              </p>
              <p>Debe caber en el compartimiento superior del avión.</p>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
}
