import copy from "../../iconos/copy-icon.png";
import edit from "../../iconos/edit_icon.png";
import eye from "../../iconos/eye-blue.png";
import trash from "../../iconos/delete.png";
import { format } from "date-fns";
import numberWithCommas from "../../utils/numberWithCommas";
import { Link, useParams } from "react-router-dom";

export default function TableRowPaquete({
  index,
  pack,
  openModal,
  setSelectedModal,
}) {
  const { operador } = useParams();

  return (
    <div
      className={`tableGrid py-2 px-8 items-center text-gray rounded-lg ${
        index % 2 === 1 && "bg-lightGray2"
      }`}
    >
      <p className="whitespace-nowrap overflow-hidden mr-4 col-span-3">
        {pack.name}
      </p>
      <p className="capitalize col-span-2">{pack.oricode.toLowerCase()}</p>
      <p className="capitalize col-span-2">{pack.descode.toLowerCase()}</p>
      <p className="col-span-2">
        {pack?.fsalida ? format(new Date(pack.fsalida), "dd/MM/yyyy") : "-"}
      </p>
      <p className="col-span-2">
        {pack.cupos} / {pack.cupos_original}
      </p>
      <p className="col-span-2">
        USD {numberWithCommas(Number(pack.price), 0)}
      </p>
      <div className="grid grid-cols-3 items-center gap-0.5">
        <div className="w-7 h-7 cursor-pointer hover:bg-[#EEE] rounded-full transition-colors">
          <img
            src={copy}
            alt="copy"
            onClick={() => {
              openModal(pack);
              setSelectedModal("copy");
            }}
          />
        </div>
        <Link
          to={`/${operador}/panel/activities/update-activity/${pack.id}`}
          className="h-7 w-7 hover:bg-[#EEE] rounded-full transition-colors"
        >
          <img src={edit} alt="edit" />
        </Link>
        <div className="w-7 h-7 cursor-pointer hover:bg-[#EEE] rounded-full transition-colors">
          <img
            src={trash}
            alt="delete"
            onClick={() => {
              openModal(pack);
              setSelectedModal("delete");
            }}
          />
        </div>
      </div>
      <div className="h-7 w-7 ml-auto hover:bg-[#EEE] rounded-full transition-colors">
        <img
          src={eye}
          alt="view"
          onClick={() => {
            openModal(pack);
            setSelectedModal("view");
          }}
        />
      </div>
    </div>
  );
}
