import { useState } from "react";
import useHotelOfferStore from "./HotelStore";
import { useLocationQuery } from "../../hooks/useLocationQuery";
import { useParams } from "react-router-dom";
import { Hotel } from "@mui/icons-material";
import HotelsItemTabs from "./HotelsItemTabs";
import StarTwoToneIcon from "@mui/icons-material/StarTwoTone";

const HotelsItem = ({ entry }) => {
  const [open, setOpen] = useState(false);
  const { parsedHash, changeQuery } = useLocationQuery();
  const { tour_op_id } = useParams();
  const setHotelOffer = useHotelOfferStore((state) => state.setHotelOffer);

  //Function to redirect to the hotel request page and set the entry to the store
  const handleReserve = () => {
    setHotelOffer({
      ...entry,
      checkInDay: parsedHash?.departureDate,
      checkOutDay: parsedHash?.returnDate,
    });
    //Redirect to the hotel request page
    changeQuery({
      route: `${tour_op_id}/hotels-request`,
      go: true,
    });
  };

  const { HotelInfo, HotelOptions } = entry;
  const { Name, HotelCategory, TimeInformation } = HotelInfo[0];
  const { Prices, HotelRooms } = HotelOptions[0].HotelOption[0];
  const { CheckIn, CheckOut } = TimeInformation[0].CheckTime[0].$;
  const { $, TotalFixAmounts } = Prices?.[0].Price[0];

  const stars = new Array(Number(HotelCategory?.[0]?._?.at(0))).fill(
    <StarTwoToneIcon className="text-yellow-500" />
  );

  return (
    <div className="font-body flex flex-col justify-between w-full gap-4 bg-lightBlue/30 rounded-lg p-4">
      {/* This is the top part of the item */}
      <div className="flex gap-4 grow">
        <img
          src={
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS6z2w405wzA5e8STiKuvvCIOftLxYLS1PSk1BmwXpRXw&s"
          }
          alt="Hotel"
          className="rounded min-w-[40%]"
        />
        <div className="flex flex-col gap-2 w-full grow justify-between">
          <header className="flex items-center">
            <h1 className=" text-xl mr-2">{Name?.[0]}</h1>
            <div>
              {stars.map((star, index) => (
                <span key={index}>{star}</span>
              ))}
            </div>
            <p className="ml-auto">
              Desde <span>{TotalFixAmounts?.[0].$.Gross}</span>{" "}
              <span>{$.Currency}</span>
            </p>
          </header>

          <h4>{HotelRooms?.[0].HotelRoom?.[0]?.RoomCategory?.[0]?._ || ""}</h4>
          <p>Check-In desde {CheckIn}hs</p>
          <p>Check-Out hasta {CheckOut}hs</p>
          <button
            onClick={() => setOpen(!open)}
            className="button outline w-full"
          >
            {`${open ? "-" : "+"} info`}
          </button>
        </div>
      </div>
      {/* This is the bottom part of the item */}
      <div className={`${open ? "h-fit" : " h-0"} overflow-hidden`}>
        <HotelsItemTabs
          HotelInfo={HotelInfo[0]}
          HotelOption={HotelOptions[0].HotelOption[0]}
          handleReserve={handleReserve}
        />
      </div>
    </div>
  );
};

export default HotelsItem;
