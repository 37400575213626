import React from "react";
import { Link, useParams } from "react-router-dom";
import logo_walltrip from "../../iconos/logos/walltrip.png";
import ministerio from "../../iconos/ministerio.png";
import fiscal from "../../iconos/fiscal.png";
import { FiInstagram } from "react-icons/fi";
import { FaWhatsapp } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { saveTourOperator } from "../../utils/saveTourOperator";

export const Footer = () => {
  const params = useParams();
  const tourOP = saveTourOperator((st) => st.tourOP);
  const {
    license,
    logo,
    file,
    email,
    TourOperatorPhones,
    TourOperatorSocialMedia,
    TourOperatorAddresses,
    TourOperatorContacts,
  } = tourOP;
  const { street_name, postal_code, city_name } =
    TourOperatorAddresses?.[0] || {};
  const { country_calling_code, number } = TourOperatorPhones?.[0] || {};
  const ig = TourOperatorSocialMedia?.find(
    (red) => red?.redSocial === "Instagram"
  )?.url;
  const fb = TourOperatorSocialMedia?.find(
    (red) => red?.redSocial === "Facebook"
  )?.url;
  const wpp = TourOperatorSocialMedia?.find(
    (red) => red?.redSocial === "Whatsapp"
  )?.url;

  return (
    <div className="w-full bg-[#fff] py-14 mt-30 px-8">
      <div className="w-11/12 bg-[gray] mx-auto my-6 h-[1px]" />
      <div className="w-full flex gap-20 justify-between mx-auto max-w-[1338.46px]">
        <Link className="h-fit" to={`/${params.tour_op_id}/Flights`}>
          <img
            className="text-black max-w-[208px] max-h-24"
            src={logo || logo_walltrip}
            alt=""
          />
        </Link>
        <div className="flex flex-col gap-0 leading-0">
          <p className="text-[.9rem] m-0">Institucional</p>
          <p className="text-[.9rem] m-0">Condiciones Generales</p>
          <p className="text-[.9rem] m-0">Políticas de privacidad</p>
          <p className="text-[.9rem] m-0">Botón de arrepentimiento</p>
        </div>
        {/* social media */}
        <div className="flex flex-col gap-5">
          <ul className="flex gap-4 h-6">
            {ig && (
              <li>
                <a target="_blank" href={ig} rel="noreferrer">
                  <FiInstagram fontSize={24} />
                </a>
              </li>
            )}
            {wpp && (
              <li>
                <a target="_blank" href={wpp} rel="noreferrer">
                  <FaWhatsapp fontSize={24} />
                </a>
              </li>
            )}
            {fb && (
              <li className="relative right-1.5">
                <a target="_blank" href={fb} rel="noreferrer">
                  <FaFacebookF fontSize={24} />
                </a>
              </li>
            )}
          </ul>
          <div className="grid gap-1">
            {license && <p className="m-0 text-sm">{license}</p>}
            <span className="flex gap-1 pt-1.5">
              <a href={file} target="_blank" rel="noreferrer">
                <img className="w-10" src={fiscal} alt="fiscal" />
              </a>
              <img className="w-32 h-12" src={ministerio} alt="ministerio" />
            </span>
          </div>
        </div>

        <div>
          <h3 className="text-xl font-medium mb-4">Contacto</h3>
          <p>
            {TourOperatorAddresses?.length > 0
              ? `${street_name}, ${postal_code} ${city_name}`
              : "Justo José de Urquiza 2063, X5001FTH Córdoba"}
          </p>
          <p>
            {TourOperatorContacts?.length > 0
              ? `${
                  country_calling_code.length <= 2 ? "+" : ""
                }${country_calling_code} ${number}`
              : "+54 12312 123123"}
          </p>
          <p>{email || "wearethewalltrip@gmail.com"}</p>
        </div>
      </div>
    </div>
  );
};
