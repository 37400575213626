import { z } from "zod";

export const agencySchema = z.object({
  name: z.string().min(1, { message: "Campo Requerido" }),
  email: z.string().min(1, { message: "Campo Requerido" }).email(),
  address: z.string().min(1, { message: "Campo Requerido" }).optional(),
  phone: z
    .string()
    .regex(/^[+]?\d+([.]\d+)?$/, { message: "El valor debe ser numérico" })
    .optional(),
  city: z.string().optional(),
  state: z.string().optional(),
  country: z.string().optional(),
  logo: z.string().optional(),
  category: z.string().optional(),
  tax_name: z.string().optional(),
  tax_type: z.string().optional(),
  tax_id: z.string().optional(),
  license_number: z.string().optional(),
  contact_name: z.string().optional(),
  contact_lastname: z.string().optional(),
  contact_position: z.string().optional(),
});
