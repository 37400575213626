import OptionQuote from "./OptionQuote";

export default function SearchQuotesResults({
  index,
  flights,
  options,
  original,
  exchangeRate,
  numberOfSection,
  routeOption,
  wider,
  loading,
  state,
}) {
  const details = {};
  original.map((opt) => {
    let totalBase = 0;
    let totalFee = 0;
    const { currency } = opt.travelerPricings[0].price;

    const passengerPricings = opt.travelerPricings.map((el) => {
      totalBase += Number(el.price.base);
      totalFee += Number(el.price.total) - Number(el.price.base);
      const { fareDetailsBySegment, ...rest } = el;

      return rest;
    });

    return opt.travelerPricings[0].fareDetailsBySegment.map((flight) => {
      const key = `${flight.segmentId}-${opt.price.grandTotal}`;

      return (details[key] = {
        id: opt.id,
        travelerPricings: passengerPricings,
        flight,
        totalPrices: {
          totalBase,
          totalFee,
          currency,
        },
      });
    });
  });

  const numberOfPassengers = original?.[0]?.travelerPricings?.length

  return (
    <section
      className={`pb-6 w-full ${
        numberOfSection === index ? "block" : "hidden"
      }`}
    >
      {!loading && flights !== undefined && options.length ? (
        <section className="flex flex-col gap-4 2xl:gap-6 transition-all duration-300">
          {options.map(([grouped, options], optionIndex) => {
            return (
              <OptionQuote
                key={optionIndex - grouped}
                sectionIndex={index}
                price={grouped}
                exchangeRate={exchangeRate}
                options={options}
                details={details}
                numberOfPassengers={numberOfPassengers}
                isRoundtrip={routeOption === "roundtrip"}
                original={original}
                wider={!wider}
                state={state}
              />
            );
          })}
        </section>
      ) : (
        !loading &&
        flights !== undefined &&
        options.length === 0 && <div>No se encontraron cotizaciones.</div>
      )}
    </section>
  );
}
