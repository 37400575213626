import masinfo from "../../iconos/iconomasinfo.png";
import checkedBags from "../../iconos/checked_bags.png";
import uncheckedBags from "../../iconos/unchecked_bags.png";
import chevron_down_escala from "../../iconos/chevron_down_escala.png";
import clsx from "clsx";
import { AIRLINES_DICTIONARY } from "../../utils/dictionaries/airlinesDictionary";

export default function FlightSegment({
  airlinesFormatted,
  ciudadesFormatted,
  contarEscalas,
  destino,
  flight,
  index,
  item,
  llegada,
  opcionVuelo,
  origen,
  original,
  salida,
  selectFlight,
  selectOpcionesVuelo,
  setOriginal,
  setScalesSelected,
  toggleVisibility,
  totalPrice,
  type,
}) {
  const selectSegment = () => {
    selectFlight({
      type,
      fl: origen?.[index] || destino?.[index],
      totalPrice,
    });
    setOriginal(original?.find((x) => x.price.total === totalPrice));
    selectOpcionesVuelo({ original, opcionVuelo });
  };
  const details = {};
  original.map((opt) =>
    opt.travelerPricings[0].fareDetailsBySegment.map(
      (flight) =>
        (details[flight.segmentId] = {
          id: opt.id,
          checkedBags: flight?.includedCheckedBags?.quantity,
        })
    )
  );

  const data =
    AIRLINES_DICTIONARY[salida?.carrierCode || llegada?.carrierCode] ?? null;

  return (
    <>
      {item ? (
        <section
          onClick={selectSegment}
          className={clsx(
            " relative z-10 w-full border-2 hover:cursor-pointer p-4 rounded-2xl flex justify-between gap-8 items-center transition-all",
            {
              "bg-lightBlue/80 border-[#091832]": flight?.[type] === item,
            },
            {
              "bg-white border-[#CEDEF1] hover:border-[#091832]":
                flight?.[type] !== item,
            }
          )}
        >
          <div className="opcion-vuelo-segmento-check">
            <input
              name={item.id}
              id={index}
              type="radio"
              checked={flight?.[type] === item}
              readOnly
            />
          </div>
          <div className="opcion-vuelo-segmento-aerolinea">
            {data ? (
              <img src={data.img} alt={data.label} className="w-16 mr-1 ml-2" />
            ) : (
              <span className="w-fit px-2 font-medium whitespace-nowrap rounded border border-gray bg-white">
                {salida?.carrierCode || llegada?.carrierCode}
              </span>
            )}
          </div>
          <div className="flex flex-col items-center">
            <span>
              <span className="flex flex-col items-center gap-1 opcion-vuelo-segmento-destino-code">
                <p className="text-[20px] m-0 font-medium">
                  {salida?.departure?.iataCode}
                </p>
                <p className="text-[14px] font-low m-0">
                  {ciudadesFormatted?.[salida?.departure?.iataCode]?.airport}
                </p>
              </span>
            </span>

            <span>
              <p className="text-sm text-center">
                {salida?.departure?.at?.substring(11, 16)}
              </p>
            </span>
          </div>
          {contarEscalas(item.segments) ? (
            <div className="text-sm opcion-vuelo-segmento-paradas text-gray">
              <p>Escalas</p>
              {contarEscalas(item.segments)}
            </div>
          ) : (
            <p className="text-sm opcion-vuelo-segmento-paradas text-gray">-</p>
          )}

          <div className="opcion-vuelo-segmento-destino">
            <span className="flex flex-col gap-1 opcion-vuelo-segmento-destino-code">
              <p className="text-[20px] m-0 font-medium">
                {llegada?.arrival?.iataCode}
              </p>
              <p className="text-[14px] font-low m-0">
                {ciudadesFormatted?.[llegada?.arrival?.iataCode]?.airport}
              </p>
            </span>
            <span>
              <p className="text-sm">
                {llegada?.arrival?.at?.substring(11, 16)}
              </p>
            </span>
          </div>
          <div className="flex items-start">
            <span className="opcion-vuelo-segmento-equipaje mr-2">
              <div className="flex flex-col gap-1 items-center">
                <img
                  src={
                    details?.[salida?.id]?.checkedBags > 0
                      ? checkedBags
                      : uncheckedBags
                  }
                  alt="Valijas"
                  className="h-16"
                />
                <p className="text-[12px] m-0 font-medium ml-auto">
                  PC: {details?.[salida?.id]?.checkedBags}
                </p>
              </div>
            </span>
            <div
              onClick={() => {
                setScalesSelected({
                  item,
                  id: salida?.id || llegada?.id,
                });
                toggleVisibility();
              }}
              className="pt-3 pl-2 h-full"
            >
              <div className="relative my-auto">
                <img className="w-8 mt-3" src={masinfo} alt="masinfo" />
                <img
                  className="absolute top-[12px] left-[9px] w-4"
                  src={chevron_down_escala}
                  alt="chevron_down_escala"
                />
              </div>
            </div>
          </div>
        </section>
      ) : null}
    </>
  );
}
