import { z } from "zod";

export default function setTwoSeacherSchema(type, hasRetunDate) {
  return z.object({
    originLocationCode:
      type === "Flights"
        ? z
            .string({ required_error: "Campo requerido" })
            .min(3, { message: "Mínimo 3 caracteres" })
        : z.string().optional(),
    destinationLocationCode: z
      .string({ required_error: "Campo requerido" })
      .min(3, { message: "Mínimo 3 caracteres" }),
    departureDate: z.string({ required_error: "Campo requerido" }).min(1),
    returnDate: hasRetunDate
      ? z.string({ required_error: "Campo requerido" }).min(1)
      : z.string().optional(),
    adults: z
      .string({
        required_error: "Campo requerido",
      })
      .regex(/^\+?([1-9]\d*)$/, { message: "Número positivo" }),
    children: z.string().optional(),
    infants: z.string().optional(),
  });
}
