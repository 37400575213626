import { Link, useParams } from "react-router-dom";
import SEO from "../../utils/SEO";
import edit from "../../iconos/edit_icon.png";

export const PanelDesign = () => {
  const { tour_op_id } = useParams();

  return (
    <>
      <SEO title={"Diseño Web"} />
      <main className="relative font-body max-w-7xl mx-auto">
        <header className="my-8 flex justify-between items-center">
          <div>
            <h1 className="text-darkBlue font-[700] text-3xl">Diseño Web</h1>
            <p className="text-darkBlue/70 text-lg">
              Controlá, personalizá y gestioná el diseño de la página.
            </p>
          </div>
        </header>

        <section className="bg-white rounded-3xl p-8">
          <flex className="flex justify-between items-center rounded-xl p-4 bg-lightGray">
            <p>Caja Header</p>
            <div className="flex gap-2">
              <button className="border rounded-md w-10 h-10">Sí</button>
              <button className="border rounded-md w-10 h-10">No</button>
            </div>
          </flex>
          <flex className="flex justify-between items-center rounded-xl p-4">
            <p>Caja Motor de Búsqueda</p>
            <div className="flex gap-2">
              <button className="border rounded-md w-10 h-10">Sí</button>
              <button className="border rounded-md w-10 h-10">No</button>
            </div>
          </flex>
          <flex className="flex justify-between items-center rounded-xl p-4 bg-lightGray">
            <p>Caja Beneficios</p>
            <div className="flex gap-2">
              <button className="border rounded-md w-10 h-10">Sí</button>
              <button className="border rounded-md w-10 h-10">No</button>
            </div>
          </flex>
          <flex className="flex justify-between items-center rounded-xl p-4">
            <p>Caja Recomendados</p>
            <div className="flex gap-2">
              <button className="border rounded-md w-10 h-10">Sí</button>
              <button className="border rounded-md w-10 h-10">No</button>
            </div>
          </flex>
          <flex className="flex justify-between items-center rounded-xl p-4 bg-lightGray">
            <p>Caja Pie de Página</p>
            <div className="flex gap-2">
              <button className="border rounded-md w-10 h-10">Sí</button>
              <button className="border rounded-md w-10 h-10">No</button>
            </div>
          </flex>
          <flex className="flex justify-between items-center rounded-xl p-4">
            <p>Editar/Subir Banner Promocional</p>
            <div className="flex items-center gap-8">
              <p className="text-gray">Banner 900x300px Promoción Junio.jpg</p>
              <img src={edit} alt="Pencil" />
            </div>
          </flex>
          <flex className="flex justify-between items-center rounded-xl p-4 bg-lightGray">
            <p>Sección Recomendados</p>
            <div className="flex items-center gap-8">
              <p className="text-gray">Editar recomendados</p>
              <img src={edit} alt="Pencil" />
            </div>
          </flex>
          <flex className="flex justify-between items-center rounded-xl p-4">
            <p>Páginas, Blog, Específicos</p>
            <div className="flex items-center gap-8">
              <p className="text-gray">Editar/Crear</p>
              <img src={edit} alt="Pencil" />
            </div>
          </flex>
        </section>
        <Link
          to={`/${tour_op_id}/panel/profile/fees`}
          className="absolute left-0 mt-8 text-center text-xl px-8 py-4 bg-brightBlue text-white font-semibold rounded"
        >
          Anterior
        </Link>
      </main>
    </>
  );
};
