import React from "react";
import fallback from "../../iconos/fallback.png";
import destino from "../../iconos/destino_gris.png";
// import { useToggleVisibility } from "../../hooks/useToggleVisibility";
// import { ModalGeneric } from "../../utils/ModalGeneric";

export function CardActividad({
  actividad,
  selectedActivity,
  setSelectedActivity,
}) {
  // const [isVisible, toggleVisibility] = useToggleVisibility();
  const { name, id, slug, image, territories } = actividad;

  const handleSelect = (id) => {
    setSelectedActivity((curr) => {
      if (curr?.id === id) return null;
      else return actividad;
    });
  };

  return (
    <>
      <section
        onClick={() => handleSelect(id)}
        className={`{font-body flex justify-between items-center p-2 rounded-2xl border-2 gap-8 cursor-pointer transition-all ${
          selectedActivity?.id === id
            ? "border-black hover:border-black"
            : "border-lightGray2 hover:border-lightBlue"
        }}`}
      >
        <div className="w-1/3 max-w-[275px]">
          <img
            className="my-auto rounded-lg max-h-48"
            src={image ?? fallback}
            alt="paquete_imagen"
          />
        </div>

        <div className="w-3/4 flex flex-col h-full justify-between py-2">
          <span className="flex flex-col gap-1">
            <h2
              className={`text-[#242423] font-[700] capitalize transition-all ${
                selectedActivity ? "text-xl" : "text-2xl"
              }`}
            >
              {name.toLowerCase()}
            </h2>
            <span className="flex items-center gap-1">
              <img
                className={`transition-all ${selectedActivity ? "w-3" : "w-4"}`}
                src={destino}
                alt="destino"
              />
              <p
                className={`text-[#8C8C8C] transition-all ${
                  selectedActivity ? "text-sm" : "text-base"
                }`}
              >
                {territories[0].name}, {territories[0].province_name}
              </p>
            </span>
          </span>
          <p
            className={`text-[#242423] font-[500] capitalize pb-2 transition-all ${
              selectedActivity ? "text-base" : "text-lg"
            }`}
          >
            {slug.split("-").join(" - ")}
          </p>
        </div>
      </section>

      {/* <ModalGeneric show={isVisible} toggle={toggleVisibility}>
        <section className="py-3 px-4">
          <h2 className="font-medium text-2xl mb-4 capitalize">
            {name.toLowerCase()}
          </h2>
          <img
            src={image ?? fallback}
            alt="bg"
            className="max-h-64 mx-auto rounded-md"
          />
          <h2 className="font-medium text-2xl mb-4 mt-4">Descripción</h2>
          <p>{slug}</p>
          <p className="font-medium mt-4">
            Cupos Disponibles: <span>{cupos}</span>
          </p>

          <div className="flex justify-between mt-4">
            <div>
              <p className="font-medium mb-2">Incluye</p>
              <span className="flex items-center gap-4">
                <img src={gym} alt="gym" className="w-4" />
                <img src={swim} alt="swim" className="w-4" />
                <img src={dinner} alt="dinner" className="w-4" />
                <img src={shop} alt="shop" className="w-4" />
                <img src={wifi} alt="wifi" className="w-4" />
              </span>
            </div>
            <div>
              <p className="font-medium mb-2">Fechas</p>
              <span className="flex items-center gap-4">
                <p>{format(new Date(fsalida), "dd/MM/yyyy")}</p>-
                <p>{format(new Date(fvuelta), "dd/MM/yyyy")}</p>
              </span>
            </div>
          </div>
        </section>
      </ModalGeneric> */}
    </>
  );
}
