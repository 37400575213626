import React from "react";
import arrow from "../../iconos/arrow_origen.png";
import destino from "../../iconos/destino_icon.png";
import passenger from "../../iconos/passenger.png";
import { useState } from "react";
import { useLocationQuery } from "../../hooks/useLocationQuery";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { AutoComplete } from "../../utils/AutoComplete";
import { useLocation, useParams } from "react-router-dom";
import { useToggleVisibility } from "../../hooks/useToggleVisibility";
import { zodResolver } from "@hookform/resolvers/zod";
import { format } from "date-fns";
import NewCalendar from "../../utils/NewCalendar";
import setTwoSeacherSchema from "../../schemas/stepTwoSearcher";

export const BuscadorPaquetes = () => {
  const { parsedHash, changeQuery } = useLocationQuery();
  const location = useLocation();
  const { tour_op_id } = useParams();
  const type = location.pathname.includes("search-flight")
    ? "Flights"
    : "Packages";

  const [rangeDate, setRangeDate] = useState();
  const [openFsalida, toggleSalida] = useToggleVisibility();
  const hasRetunDate = !!parsedHash?.returnDate;
  const navegar = (data) => {
    changeQuery({
      query: data,
      route: location.pathname.includes("search-flight")
        ? `${tour_op_id}/search-flight`
        : `${tour_op_id}/search-packages`,
      go: true,
    });
  };

  const schema = setTwoSeacherSchema(type, hasRetunDate);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      departureDate: parsedHash?.departureDate,
      returnDate: parsedHash?.returnDate,
      originLocationCode: parsedHash?.originLocationCode,
      destinationLocationCode: parsedHash?.destinationLocationCode,
      adults: parsedHash?.adults || "1",
      children: parsedHash?.children,
      infants: parsedHash?.infants,
    },
  });

  const [oricodeInput, setOricodeInput] = useState(
    parsedHash.originLocationCode ?? ""
  );
  const [descodeInput, setDescodeInput] = useState(
    parsedHash.destinationLocationCode ?? ""
  );

  const onSubmit = (data) => {
    navegar({
      ...data,
      // ...{ opcionrutas: opcionrutas },
    });
  };

  // const disableVuelta = (date) =>
  //   isBefore(date, new Date(watch("departureDate") + "T00:00:00"));

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="rounded-lg pr-4 pb-4 w-[230px]"
    >
      <span className="flex flex-col gap-1 pb-[26px] leading-0">
        <h2 className="text-[#242423] whitespace-nowrap text-xl font-[700] pl-2 m-0">
          {location?.pathname?.includes("search-flight")
            ? "Buscá tu vuelo"
            : location?.pathname?.includes("search-packages")
            ? "Paquetes turísticos"
            : "Actividades turísticas"}
        </h2>
        <h3 className="pl-2 m-0">
          {location?.pathname?.includes("search-flight")
            ? "¿A dónde querés ir?"
            : ""}{" "}
        </h3>
      </span>
      {/* Form */}
      <div className="flex flex-col gap-3">
        <div className="relative border border-[#8D99AE] flex gap-4 px-2 py-2 rounded-lg items-center">
          <div className="flex items-center justify-center rounded-md px-4 py-3 bg-lightBlue/80">
            <img className="w-4" src={arrow} alt="origen" />
          </div>
          <AutoComplete
            justCities={type === "Packages" ? true : false}
            defaultValue={parsedHash.originLocationCode}
            name="originLocationCode"
            placeholder="Origen"
            handleAction={(d) =>
              setValue("originLocationCode", d.toUpperCase())
            }
            register={register}
            classname="text-[#24242380] text-sm bg-inherit w-full outline-none"
            notIcon={true}
            inputState={[oricodeInput, setOricodeInput]}
          />
          <div className="absolute text-red-600 text-xs bottom-0.5 left-16 whitespace-nowrap">
            <ErrorMessage errors={errors} name="originLocationCode" as="p" />
          </div>
        </div>
        <div className="relative border border-[#8D99AE] flex gap-4 px-2 py-2 rounded-lg items-center">
          <div className="flex items-center justify-center rounded-md px-4 py-3 bg-lightBlue/80">
            <img className="w-4" src={destino} alt="destino" />
          </div>
          <AutoComplete
            justCities={type === "Packages" ? true : false}
            defaultValue={parsedHash.destinationLocationCode}
            name="destinationLocationCode"
            placeholder="Destino"
            handleAction={(d) =>
              setValue("destinationLocationCode", d.toUpperCase())
            }
            register={register}
            classname="text-[#24242380] text-sm bg-inherit w-full outline-none"
            notIcon={true}
            inputState={[descodeInput, setDescodeInput]}
          />
          <div className="absolute text-red-600 text-xs bottom-0.5 left-16 whitespace-nowrap">
            <ErrorMessage
              errors={errors}
              name="destinationLocationCode"
              as="p"
            />
          </div>
        </div>

        <div className="relative border border-[#8D99AE] rounded-lg justify-center flex items-center">
          <div className="py-10 text-center text-[#24242380] rounded-tl-xl rounded-bl-xl">
            <div className="flex flex-col h-5 items-start gap-2">
              <input
                name="departureDate"
                placeholder="Ida"
                onClick={() => toggleSalida()}
                value={
                  !watch("departureDate")
                    ? parsedHash?.departureDate
                      ? format(
                          new Date(parsedHash?.departureDate + "T00:00:00"),
                          "dd/MM/yyyy"
                        )
                      : "Ida"
                    : format(
                        new Date(watch("departureDate") + "T00:00:00"),
                        "dd/MM/yyyy"
                      )
                }
                className="flex items-center justify-center w-full px-2 text-sm text-center outline-none  bg-inherit h-7"
                readOnly
              />
              {openFsalida ? (
                <NewCalendar
                  handlers={[
                    rangeDate,
                    (date) => {
                      setRangeDate(date);
                      if (hasRetunDate) {
                        setValue(
                          "departureDate",
                          date?.from ? format(date?.from, "yyyy-MM-dd") : ""
                        );
                        setValue(
                          "returnDate",
                          date?.to ? format(date?.to, "yyyy-MM-dd") : ""
                        );
                        date?.from && date?.to && toggleSalida();
                      } else {
                        setValue(
                          "departureDate",
                          date ? format(date, "yyyy-MM-dd") : ""
                        );
                        date && toggleSalida();
                      }
                    },
                  ]}
                  mode={hasRetunDate ? "range" : "single"}
                  numberOfMonths={2}
                  close={toggleSalida}
                />
              ) : null}
            </div>
            <div className="absolute text-red-600 text-xs bottom-0.5 left-2 whitespace-nowrap">
              <ErrorMessage errors={errors} name="departureDate" as="p" />
            </div>
          </div>
          {hasRetunDate && <div className="w-[1px] h-2 bg-[#DAE0E6] py-8" />}
          {hasRetunDate && (
            <div className="py-10 text-center text-[#24242380] rounded-tr-xl rounded-br-xl">
              <div className="flex items-center h-5 gap-3">
                <input
                  name="returnDate"
                  placeholder="Vuelta"
                  onClick={() => toggleSalida()}
                  value={
                    !watch("returnDate")
                      ? parsedHash?.returnDate
                        ? format(
                            new Date(parsedHash?.returnDate + "T00:00:00"),
                            "dd/MM/yyyy"
                          )
                        : "Vuelta"
                      : format(
                          new Date(watch("returnDate") + "T00:00:00"),
                          "dd/MM/yyyy"
                        )
                  }
                  className="flex items-center justify-center w-full px-2 text-sm text-center outline-none bg-inherit h-7"
                  readOnly
                />
              </div>
              <div className="absolute text-red-600 text-xs bottom-0.5 right-2 whitespace-nowrap">
                <ErrorMessage errors={errors} name="returnDate" as="p" />
              </div>
            </div>
          )}
        </div>

        {/* passengers */}
        <div className="relative border border-[#8D99AE] flex flex-col gap-4 px-2 py-2 rounded-lg items-center">
          <div className="flex gap-4 items-center">
            <div className="flex items-center justify-center rounded-md p-3 bg-lightBlue/80">
              <img className="w-8" src={passenger} alt="pasajeros" />
            </div>
            <p className="text-sm">Adultos:</p>
            <input
              type="number"
              min={1}
              name="adults"
              {...register("adults")}
              className="text-[#24242380] text-xs bg-inherit w-full outline-none"
              placeholder="Adultos"
            />
            <div className="absolute text-red-600 text-xs bottom-0.5 left-16 whitespace-nowrap">
              <ErrorMessage errors={errors} name="adults" as="p" />
            </div>
          </div>

          <div className="flex gap-4 items-center">
            <div className="flex items-center justify-center rounded-md p-3 bg-lightBlue/80">
              <img className="w-8" src={passenger} alt="pasajeros" />
            </div>
            <p className="text-sm">Niños:</p>
            <input
              type="number"
              min={0}
              name="children"
              {...register("children")}
              className="text-[#24242380] text-xs bg-inherit w-full outline-none"
              placeholder="0"
            />
            <div className="absolute text-red-600 text-xs bottom-0.5 left-16 whitespace-nowrap">
              <ErrorMessage errors={errors} name="children" as="p" />
            </div>
          </div>

          <div className="flex gap-4 items-center">
            <div className="flex items-center justify-center rounded-md p-3 bg-lightBlue/80">
              <img className="w-8" src={passenger} alt="pasajeros" />
            </div>
            <p className="text-sm">Bebés:</p>
            <input
              type="number"
              min={0}
              name="infants"
              {...register("infants")}
              className="text-[#24242380] text-xs bg-inherit w-full outline-none"
              placeholder="0"
            />
            <div className="absolute text-red-600 text-xs bottom-0.5 left-16 whitespace-nowrap">
              <ErrorMessage errors={errors} name="infants" as="p" />
            </div>
          </div>
        </div>
        <button
          type="submit"
          className="bg-lightBlue/80 font-medium rounded-lg mt-1 px-4 py-3 text-center shadow-[0px 4px 4px rgba(0, 0, 0, 0.25)] cursor-pointer hover:opacity-80"
        >
          Buscar
        </button>
      </div>
    </form>
  );
};
