import { ModalGeneric } from "../../../utils/ModalGeneric";
import { useToggleVisibility } from "../../../hooks/useToggleVisibility";
import { useFunctionalities } from "../../../hooks/useFunctionalities";
import CreateFeature from "../../voteFeature/CreateFunctionality";
import { Toaster, toast } from "react-hot-toast";


const HEADERS = ["Nombre", "Descripción", "Estado", "Votación", "Ver"];

export const TableFeaturesVotes = () => {
  const [isVisible, toggleVisibility] = useToggleVisibility();
  const { functionalities, getFunctionalities, postFunctionality } =
    useFunctionalities();

  const createFunctionality = (functionality) => {
    toast.promise(
      postFunctionality({ functionality }).then(() => {
        getFunctionalities();
        toggleVisibility();
      }),
      {
        loading: "Cargando...",
        success: <b>Funcionalidad cargada con éxito</b>,
        error: <b>Hubo un error, intenta de nuevo.</b>,
      }
    );
  };
  console.log(functionalities);
  return (
    <section className="flex flex-col gap-4">
      <div className="fix">
        <Toaster />
      </div>
      <div className="flex justify-between">
        <h2 className="font-[500] text-[28px]">Gestión</h2>
        <button
          onClick={toggleVisibility}
          className="button text-center bg-darkBlue text-white whitespace-nowrap px-2 hover:shadow-md transition-all"
        >
          + Agregar Nuevo
        </button>
      </div>
      <section className="w-full my-2">
        <header className="grid grid-cols-5 gap-4 border border-[#D9D9D9] py-2">
          {(HEADERS || []).map((h, index) => (
            <p key={index} className="px-2 text-center font-[500] text-[18px]">
              {h}
            </p>
          ))}
        </header>
        <section className="flex flex-col gap-2 mt-4">
          {(functionalities || [])?.map((f, index) => (
            <div key={index} className="grid grid-cols-5 gap-4">
              <span className="text-center text-[16px] font-[400]">
                <input
                  readOnly
                  defaultValue={f.name}
                  className="w-full addPackInput"
                />
              </span>
              <span className="text-center text-[16px]">
                {" "}
                <input
                  readOnly
                  defaultValue={f.description}
                  className="w-full addPackInput"
                />
              </span>
              <span className="text-center text-[16px] mx-auto ">
                <input
                  readOnly
                  defaultValue={f.status}
                  className="w-full addPackInput"
                />
              </span>
              <span className="text-center text-[16px]">
                <input
                  readOnly
                  value={f.vote === "Y" ? "Sí" : "No"}
                  className="w-full addPackInput"
                />
              </span>
              <span className="text-center text-[16px] underline text-[#335EF8]">
                <a href={f.link} target="_blank" rel="noreferrer">
                  Link
                </a>
              </span>
            </div>
          ))}
        </section>
      </section>

      <ModalGeneric show={isVisible} toggle={toggleVisibility}>
        <CreateFeature createFunctionality={createFunctionality} />
      </ModalGeneric>
    </section>
  );
};
