export const Andalucia = {
  "summary_count": 204,
  "list": [
    {
      "id": 18051,
      "name": "Córdoba Ecuestre",
      "slug": "cordoba-actividades-guiadas-cordoba-ecuestre",
      "register": "AIAT/CO/00001-6",
      "image": "https://multimedia.andalucia.org/content_images/main_image_79694.jpeg",
      "territories": [
        {
          "id": 2143,
          "code": "140214",
          "name": "Córdoba",
          "slug": "cordoba",
          "image": "https://multimedia.andalucia.org/destination/photo_262.jpg",
          "habitants": 325453,
          "territory_type": {
            "id": 5,
            "code": "TIPTERMUNICIPIO",
            "name": "Municipio"
          },
          "province_id": 23,
          "province_name": "Córdoba"
        }
      ],
      "vial_name": "Calle Caballerizas Reales",
      "address_number": "1",
      "postal_code": "14004",
      "x_coord": -4.783244,
      "y_coord": 37.876733,
      "resource_type": {
        "id": 19,
        "code": "TIPRECACTGUI",
        "name": "Empresas de actividades guiadas"
      },
      "private_plans": 0
    },
    {
      "id": 23768,
      "name": "Vandelvira Turismo",
      "slug": "ubeda-actividades-guiadas-vandelvira-turismo",
      "tags": "Arte,Casas con encanto,Ciudades Patrimonio de la Humanidad,Flamenco,Guía Intérprete,Judío,Mudéjar,Museos,Renacimento,Sefard,Tours,Tours Privados,Visita Culturales,Visitas guiadas,Visitas Turísticas",
      "register": "AIAT/JA/00001-4",
      "image": "https://multimedia.andalucia.org/content_images/main_image_68675.jpeg",
      "territories": [
        {
          "id": 3665,
          "code": "230927",
          "name": "Úbeda",
          "slug": "ubeda",
          "image": "https://multimedia.andalucia.org/destination/photo_687.jpg",
          "habitants": 34462,
          "territory_type": {
            "id": 5,
            "code": "TIPTERMUNICIPIO",
            "name": "Municipio"
          },
          "province_id": 26,
          "province_name": "Jaén"
        }
      ],
      "vial_name": "Calle Real",
      "address_number": "61",
      "postal_code": "23400",
      "extra_address": "Vandelvira Turismo, la mejor forma de conocer Úbeda y también somos tu tienda de regalos y artesanía.",
      "x_coord": -3.36893,
      "y_coord": 38.009394,
      "resource_type": {
        "id": 19,
        "code": "TIPRECACTGUI",
        "name": "Empresas de actividades guiadas"
      },
      "private_plans": 0
    },
    {
      "id": 23769,
      "name": "Apus Internacional Cazorla",
      "slug": "cazorla-actividades-guiadas-apus-internacional-cazorla",
      "image": "https://multimedia.andalucia.org/content_images/main_image_68686.jpeg",
      "territories": [
        {
          "id": 3605,
          "code": "230281",
          "name": "Cazorla",
          "slug": "cazorla",
          "image": "https://multimedia.andalucia.org/destination/photo_237.jpg",
          "habitants": 8527,
          "territory_type": {
            "id": 5,
            "code": "TIPTERMUNICIPIO",
            "name": "Municipio"
          },
          "province_id": 26,
          "province_name": "Jaén"
        }
      ],
      "vial_name": "Avenida del Guadalquivir",
      "address_number": "101",
      "postal_code": "23470",
      "x_coord": -3.006925,
      "y_coord": 37.914207,
      "resource_type": {
        "id": 19,
        "code": "TIPRECACTGUI",
        "name": "Empresas de actividades guiadas"
      },
      "private_plans": 0
    },
    {
      "id": 23770,
      "name": "Ábside Turismo",
      "slug": "baeza-actividades-guiadas-abside-turismo",
      "tags": "seniors",
      "image": "https://multimedia.andalucia.org/content_images/main_image_68701.jpeg",
      "territories": [
        {
          "id": 3587,
          "code": "230098",
          "name": "Baeza",
          "slug": "baeza",
          "image": "https://multimedia.andalucia.org/destination/photo_118.jpg",
          "habitants": 16012,
          "territory_type": {
            "id": 5,
            "code": "TIPTERMUNICIPIO",
            "name": "Municipio"
          },
          "province_id": 26,
          "province_name": "Jaén"
        }
      ],
      "vial_name": "Calle Alonso Rodríguez",
      "address_number": "28",
      "postal_code": "23440",
      "x_coord": -3.476663,
      "y_coord": 37.997894,
      "resource_type": {
        "id": 19,
        "code": "TIPRECACTGUI",
        "name": "Empresas de actividades guiadas"
      },
      "private_plans": 0
    },
    {
      "id": 23772,
      "name": "Culmina Servicios Turísticos y Culturales",
      "slug": "linares-actividades-guiadas-culmina-servicios-turisticos-y-culturales",
      "tags": "familias, seniors",
      "register": "3793",
      "image": "https://multimedia.andalucia.org/fotos/image_211324.jpeg",
      "territories": [
        {
          "id": 3631,
          "code": "230559",
          "name": "Linares",
          "slug": "linares",
          "image": "https://multimedia.andalucia.org/destination/photo_435.jpg",
          "habitants": 61340,
          "territory_type": {
            "id": 5,
            "code": "TIPTERMUNICIPIO",
            "name": "Municipio"
          },
          "province_id": 26,
          "province_name": "Jaén"
        }
      ],
      "vial_name": "Plaza Ayuntamiento",
      "postal_code": "23700",
      "extra_address": "La Plaza del Ayuntamiento suele ser el punto de encuentro para el inicio de nuestros recorridos, indicándose siempre tras la confirmación de la reserva por si hubiera algún cambio.",
      "x_coord": -3.635853,
      "y_coord": 38.093336,
      "resource_type": {
        "id": 19,
        "code": "TIPRECACTGUI",
        "name": "Empresas de actividades guiadas"
      },
      "private_plans": 0
    },
    {
      "id": 23773,
      "name": "Academia Pradoventura",
      "slug": "prado-del-rey-actividades-guiadas-academia-pradoventura",
      "tags": "jovenes, singles",
      "image": "https://multimedia.andalucia.org/content_images/main_image_68772.jpeg",
      "territories": [
        {
          "id": 1868,
          "code": "110260",
          "name": "Prado del Rey",
          "slug": "prado-del-rey",
          "image": "https://multimedia.andalucia.org/destination/photo_572.jpg",
          "habitants": 5785,
          "territory_type": {
            "id": 5,
            "code": "TIPTERMUNICIPIO",
            "name": "Municipio"
          },
          "province_id": 22,
          "province_name": "Cádiz"
        }
      ],
      "vial_name": "Calle Teniente Peñalver",
      "address_number": "2",
      "postal_code": "11660",
      "x_coord": -5.556526,
      "y_coord": 36.787972,
      "resource_type": {
        "id": 19,
        "code": "TIPRECACTGUI",
        "name": "Empresas de actividades guiadas"
      },
      "private_plans": 0
    },
    {
      "id": 23774,
      "name": "Cuatricicletas Rodando en Grupo",
      "slug": "olvera-actividades-guiadas-cuatricicletas-rodando-en-grupo",
      "tags": "familias, jovenes",
      "image": "https://multimedia.andalucia.org/content_images/main_image_68826.jpeg",
      "territories": [
        {
          "id": 1866,
          "code": "110244",
          "name": "Olvera",
          "slug": "olvera",
          "image": "https://multimedia.andalucia.org/fotos/image_290370.jpeg",
          "habitants": 8629,
          "territory_type": {
            "id": 5,
            "code": "TIPTERMUNICIPIO",
            "name": "Municipio"
          },
          "province_id": 22,
          "province_name": "Cádiz"
        }
      ],
      "vial_name": "Calle Alameño",
      "address_number": "10",
      "postal_code": "11690",
      "x_coord": -5.248837,
      "y_coord": 36.934558,
      "resource_type": {
        "id": 19,
        "code": "TIPRECACTGUI",
        "name": "Empresas de actividades guiadas"
      },
      "private_plans": 0
    },
    {
      "id": 23775,
      "name": "Sights and Bikes",
      "slug": "cadiz-actividades-guiadas-sights-and-bikes",
      "tags": "familias, jovenes, parejas, seniors, singles",
      "image": "https://multimedia.andalucia.org/content_images/main_image_68841.jpeg",
      "territories": [
        {
          "id": 1854,
          "code": "110125",
          "name": "Cádiz",
          "slug": "cadiz",
          "image": "https://multimedia.andalucia.org/destination/photo_176.jpg",
          "habitants": 127200,
          "territory_type": {
            "id": 5,
            "code": "TIPTERMUNICIPIO",
            "name": "Municipio"
          },
          "province_id": 22,
          "province_name": "Cádiz"
        }
      ],
      "vial_name": "Calle Cardenal Zapata",
      "address_number": "6",
      "postal_code": "11004",
      "x_coord": -6.29465,
      "y_coord": 36.532276,
      "resource_type": {
        "id": 19,
        "code": "TIPRECACTGUI",
        "name": "Empresas de actividades guiadas"
      },
      "private_plans": 0
    },
    {
      "id": 23776,
      "name": "Viajar en el Tiempo",
      "slug": "almeria-actividades-guiadas-viajar-en-el-tiempo",
      "image": "https://multimedia.andalucia.org/content_images/main_image_68847.jpeg",
      "territories": [
        {
          "id": 364,
          "code": "040139",
          "name": "Almería",
          "slug": "almeria",
          "image": "https://multimedia.andalucia.org/destination/photo_75.jpg",
          "habitants": 187521,
          "territory_type": {
            "id": 5,
            "code": "TIPTERMUNICIPIO",
            "name": "Municipio"
          },
          "province_id": 21,
          "province_name": "Almería"
        }
      ],
      "vial_name": "Calle Sorrento",
      "address_number": "18, 9-4",
      "postal_code": "04007",
      "x_coord": -2.450944,
      "y_coord": 36.827805,
      "resource_type": {
        "id": 19,
        "code": "TIPRECACTGUI",
        "name": "Empresas de actividades guiadas"
      },
      "private_plans": 0
    },
    {
      "id": 23777,
      "name": "Travel Innova",
      "slug": "el-puerto-de-santa-maria-actividades-guiadas-travel-innova",
      "tags": "familias, jovenes, parejas, seniors, singles",
      "image": "https://multimedia.andalucia.org/content_images/main_image_68854.jpeg",
      "territories": [
        {
          "id": 1869,
          "code": "110276",
          "name": "Puerto de Santa María, El",
          "slug": "el-puerto-de-santa-maria",
          "image": "https://multimedia.andalucia.org/destination/photo_582.jpg",
          "habitants": 88503,
          "territory_type": {
            "id": 5,
            "code": "TIPTERMUNICIPIO",
            "name": "Municipio"
          },
          "province_id": 22,
          "province_name": "Cádiz"
        }
      ],
      "vial_name": "Calle Manantial",
      "address_number": "13",
      "postal_code": "11500",
      "extra_address": "Pol.Ind. Las Salinas de San Jóse Bajo",
      "x_coord": -6.209195,
      "y_coord": 36.608874,
      "resource_type": {
        "id": 19,
        "code": "TIPRECACTGUI",
        "name": "Empresas de actividades guiadas"
      },
      "private_plans": 0
    },
    {
      "id": 23778,
      "name": "InnovAlpujarra",
      "slug": "laujar-de-andarax-actividades-guiadas-innovalpujarra",
      "tags": "singles, seniors",
      "image": "https://multimedia.andalucia.org/content_images/main_image_68857.jpeg",
      "territories": [
        {
          "id": 407,
          "code": "040577",
          "name": "Laujar de Andarax",
          "slug": "laujar-de-andarax",
          "image": "https://multimedia.andalucia.org/destination/photo_429.jpg",
          "habitants": 1784,
          "territory_type": {
            "id": 5,
            "code": "TIPTERMUNICIPIO",
            "name": "Municipio"
          },
          "province_id": 21,
          "province_name": "Almería"
        }
      ],
      "vial_name": "Calle Villaespesa",
      "address_number": "4",
      "postal_code": "04470",
      "extra_address": "Junto a la Plaza Mayor de la Alpujarra",
      "x_coord": -2.896751,
      "y_coord": 36.993727,
      "resource_type": {
        "id": 19,
        "code": "TIPRECACTGUI",
        "name": "Empresas de actividades guiadas"
      },
      "private_plans": 0
    },
    {
      "id": 23779,
      "name": "Oz Nature Wildlife",
      "slug": "huercal-de-almeria-actividades-guiadas-oz-nature-wildlife",
      "tags": "seniors,parejas,singles",
      "register": "EC/AL/00001",
      "image": "https://multimedia.andalucia.org/content_images/main_image_68860.jpeg",
      "territories": [
        {
          "id": 402,
          "code": "040524",
          "name": "Huércal de Almería",
          "slug": "huercal-de-almeria",
          "image": "https://multimedia.andalucia.org/destination/photo_383.jpg",
          "habitants": 14937,
          "territory_type": {
            "id": 5,
            "code": "TIPTERMUNICIPIO",
            "name": "Municipio"
          },
          "province_id": 21,
          "province_name": "Almería"
        }
      ],
      "vial_name": "Calle Murillo",
      "address_number": "23",
      "postal_code": "04230",
      "x_coord": -2.443463,
      "y_coord": 36.885228,
      "resource_type": {
        "id": 19,
        "code": "TIPRECACTGUI",
        "name": "Empresas de actividades guiadas"
      },
      "private_plans": 0
    },
    {
      "id": 23780,
      "name": "Terra Traditions Consulting",
      "slug": "jerez-de-la-frontera-actividades-guiadas-terra-traditions-consulting",
      "tags": "seniors,parejas,jovenes,singles",
      "image": "https://multimedia.andalucia.org/content_images/main_image_68869.jpeg",
      "territories": [
        {
          "id": 1862,
          "code": "110201",
          "name": "Jerez de la Frontera",
          "slug": "jerez-de-la-frontera",
          "image": "https://multimedia.andalucia.org/destination/photo_410.jpg",
          "habitants": 205364,
          "territory_type": {
            "id": 5,
            "code": "TIPTERMUNICIPIO",
            "name": "Municipio"
          },
          "province_id": 22,
          "province_name": "Cádiz"
        }
      ],
      "vial_name": "Calle Sevilla",
      "address_number": "8",
      "postal_code": "11402",
      "x_coord": -6.137208,
      "y_coord": 36.687887,
      "resource_type": {
        "id": 19,
        "code": "TIPRECACTGUI",
        "name": "Empresas de actividades guiadas"
      },
      "private_plans": 0
    },
    {
      "id": 23781,
      "name": "Málaga Bike Tours",
      "slug": "malaga-actividades-guiadas-malaga-bike-tours",
      "tags": "familias, jovenes",
      "image": "https://multimedia.andalucia.org/content_images/main_image_68880.jpeg",
      "territories": [
        {
          "id": 4603,
          "code": "290672",
          "name": "Málaga",
          "slug": "malaga",
          "image": "https://multimedia.andalucia.org/fotos/image_185671.jpeg",
          "habitants": 566447,
          "territory_type": {
            "id": 5,
            "code": "TIPTERMUNICIPIO",
            "name": "Municipio"
          },
          "province_id": 27,
          "province_name": "Málaga"
        }
      ],
      "vial_name": "Calle Trinidad Grund",
      "address_number": "4",
      "postal_code": "29001",
      "x_coord": -4.421332,
      "y_coord": 36.718264,
      "resource_type": {
        "id": 19,
        "code": "TIPRECACTGUI",
        "name": "Empresas de actividades guiadas"
      },
      "private_plans": 0
    },
    {
      "id": 23783,
      "name": "Gaia Almería",
      "slug": "pozo-de-los-frailes-actividades-guiadas-gaia-almeria",
      "register": "EC/AL/00003",
      "image": "https://multimedia.andalucia.org/content_images/main_image_68883.jpeg",
      "territories": [
        {
          "id": 8292,
          "code": "0406690018",
          "name": "Pozo de los Frailes",
          "slug": "pozo-de-los-frailes",
          "habitants": 0,
          "territory_type": {
            "id": 6,
            "code": "TIPTERPEDANIA",
            "name": "Pedanía"
          },
          "parent_territory_id": 418,
          "parent_territory_name": "Níjar",
          "province_id": 21,
          "province_name": "Almería"
        }
      ],
      "vial_name": "Calle Noria",
      "address_number": "1",
      "postal_code": "04117",
      "x_coord": -2.108822,
      "y_coord": 36.788239,
      "resource_type": {
        "id": 19,
        "code": "TIPRECACTGUI",
        "name": "Empresas de actividades guiadas"
      },
      "private_plans": 0
    },
    {
      "id": 23786,
      "name": "Andalucía Experiencies",
      "slug": "mijas-costa-actividades-guiadas-andalucia-experiencies",
      "image": "https://multimedia.andalucia.org/content_images/main_image_68900.jpeg",
      "territories": [
        {
          "id": 8307,
          "code": "2907050001",
          "name": "Mijas Costa",
          "slug": "mijas-costa",
          "habitants": 0,
          "territory_type": {
            "id": 6,
            "code": "TIPTERPEDANIA",
            "name": "Pedanía"
          },
          "parent_territory_id": 4606,
          "parent_territory_name": "Mijas",
          "province_id": 27,
          "province_name": "Málaga"
        }
      ],
      "vial_name": "Urbanización Oasis",
      "address_number": "23",
      "postal_code": "29649",
      "x_coord": -4.668807,
      "y_coord": 36.506146,
      "resource_type": {
        "id": 19,
        "code": "TIPRECACTGUI",
        "name": "Empresas de actividades guiadas"
      },
      "private_plans": 0
    },
    {
      "id": 23787,
      "name": "Duquesa Activities",
      "slug": "sabinillas-actividades-guiadas-duquesa-activities",
      "tags": "jovenes, parejas",
      "image": "https://multimedia.andalucia.org/content_images/main_image_68904.jpeg",
      "territories": [
        {
          "id": 8324,
          "code": "2906880001",
          "name": "Sabinillas",
          "slug": "sabinillas",
          "habitants": 0,
          "territory_type": {
            "id": 6,
            "code": "TIPTERPEDANIA",
            "name": "Pedanía"
          },
          "parent_territory_id": 4604,
          "parent_territory_name": "Manilva",
          "province_id": 27,
          "province_name": "Málaga"
        }
      ],
      "vial_name": "Puerto de la Duquesa",
      "address_number": "s/n",
      "postal_code": "29692",
      "x_coord": -5.231736,
      "y_coord": 36.356559,
      "resource_type": {
        "id": 19,
        "code": "TIPRECACTGUI",
        "name": "Empresas de actividades guiadas"
      },
      "private_plans": 0
    },
    {
      "id": 23788,
      "name": "Segway Málaga Tours",
      "slug": "malaga-actividades-guiadas-segway-malaga-tours",
      "tags": "jovenes, parejas, seniors, singles,cruceros,cumpleaños,despedidas de solter@,familias,Málaga",
      "register": "AT/MA/00412",
      "image": "https://multimedia.andalucia.org/media/30C5694873514DF5BFE17164EAF1B940/img/C02477AB581D405EB72BC3FEE642E57F/1624372456365793458521021581976945.jpg?responsive",
      "territories": [
        {
          "id": 4603,
          "code": "290672",
          "name": "Málaga",
          "slug": "malaga",
          "image": "https://multimedia.andalucia.org/fotos/image_185671.jpeg",
          "habitants": 566447,
          "territory_type": {
            "id": 5,
            "code": "TIPTERMUNICIPIO",
            "name": "Municipio"
          },
          "province_id": 27,
          "province_name": "Málaga"
        }
      ],
      "vial_name": "Calle Trinidad Grund",
      "address_number": "7",
      "postal_code": "29001",
      "extra_address": "No te olvides de traer calzado cómodo y ganas de divertirte.",
      "x_coord": -4.421437,
      "y_coord": 36.717265,
      "resource_type": {
        "id": 19,
        "code": "TIPRECACTGUI",
        "name": "Empresas de actividades guiadas"
      },
      "private_plans": 0
    },
    {
      "id": 23789,
      "name": "Excursiones Ejidobus",
      "slug": "roquetas-de-mar-actividades-guiadas-excursiones-ejidobus",
      "image": "https://multimedia.andalucia.org/content_images/main_image_68907.jpeg",
      "territories": [
        {
          "id": 431,
          "code": "040792",
          "name": "Roquetas de Mar",
          "slug": "roquetas-de-mar",
          "image": "https://multimedia.andalucia.org/destination/photo_603.jpg",
          "habitants": 82665,
          "territory_type": {
            "id": 5,
            "code": "TIPTERMUNICIPIO",
            "name": "Municipio"
          },
          "province_id": 21,
          "province_name": "Almería"
        }
      ],
      "vial_name": "Calle Americo Vespucio",
      "address_number": "31",
      "postal_code": "04740",
      "x_coord": -2.61532,
      "y_coord": 36.741517,
      "resource_type": {
        "id": 19,
        "code": "TIPRECACTGUI",
        "name": "Empresas de actividades guiadas"
      },
      "private_plans": 0
    },
    {
      "id": 23790,
      "name": "Ayamonte Ocio y Turismo",
      "slug": "ayamonte-actividades-guiadas-ayamonte-ocio-y-turismo",
      "tags": "familias",
      "image": "https://multimedia.andalucia.org/content_images/main_image_68923.jpeg",
      "territories": [
        {
          "id": 3307,
          "code": "210101",
          "name": "Ayamonte",
          "slug": "ayamonte",
          "image": "https://multimedia.andalucia.org/destination/photo_112.jpg",
          "habitants": 19738,
          "territory_type": {
            "id": 5,
            "code": "TIPTERMUNICIPIO",
            "name": "Municipio"
          },
          "province_id": 25,
          "province_name": "Huelva"
        }
      ],
      "vial_name": "Paseo de los Gavilanes",
      "address_number": "s/n",
      "postal_code": "21004",
      "x_coord": -7.374656,
      "y_coord": 37.172857,
      "resource_type": {
        "id": 19,
        "code": "TIPRECACTGUI",
        "name": "Empresas de actividades guiadas"
      },
      "private_plans": 0
    },
    {
      "id": 23791,
      "name": "SerOdiel",
      "slug": "huelva-actividades-guiadas-serodiel",
      "tags": "familias, jovenes, parejas, seniors, singles",
      "image": "https://multimedia.andalucia.org/content_images/main_image_68924.jpeg",
      "territories": [
        {
          "id": 3338,
          "code": "210412",
          "name": "Huelva",
          "slug": "huelva",
          "image": "https://multimedia.andalucia.org/destination/photo_381.jpg",
          "habitants": 148027,
          "territory_type": {
            "id": 5,
            "code": "TIPTERMUNICIPIO",
            "name": "Municipio"
          },
          "province_id": 25,
          "province_name": "Huelva"
        }
      ],
      "vial_name": "Calle Coscoja",
      "address_number": "nave 7",
      "postal_code": "21003",
      "extra_address": "Polígono Industrial Mirador del Odiel",
      "x_coord": -6.953307,
      "y_coord": 37.241551,
      "resource_type": {
        "id": 19,
        "code": "TIPRECACTGUI",
        "name": "Empresas de actividades guiadas"
      },
      "private_plans": 0
    },
    {
      "id": 23792,
      "name": "Natureway Segway & Tours",
      "slug": "huelva-actividades-guiadas-natureway-segway-tours",
      "tags": "familias, jovenes, parejas, singles",
      "register": "EC/HU/00002",
      "image": "https://multimedia.andalucia.org/content_images/main_image_68925.jpeg",
      "territories": [
        {
          "id": 3338,
          "code": "210412",
          "name": "Huelva",
          "slug": "huelva",
          "image": "https://multimedia.andalucia.org/destination/photo_381.jpg",
          "habitants": 148027,
          "territory_type": {
            "id": 5,
            "code": "TIPTERMUNICIPIO",
            "name": "Municipio"
          },
          "province_id": 25,
          "province_name": "Huelva"
        }
      ],
      "vial_name": "Calle Méndez Núñez",
      "address_number": "13",
      "postal_code": "21001",
      "x_coord": -6.952329,
      "y_coord": 37.258057,
      "resource_type": {
        "id": 19,
        "code": "TIPRECACTGUI",
        "name": "Empresas de actividades guiadas"
      },
      "private_plans": 0
    },
    {
      "id": 23793,
      "name": "Migraciones Servicios Turísticos",
      "slug": "punta-umbria-actividades-guiadas-migraciones-servicios-turisticos",
      "tags": "seniors, singles,familias",
      "register": "AIAT/HU/00015-5",
      "image": "https://multimedia.andalucia.org/content_images/main_image_68928.jpeg",
      "territories": [
        {
          "id": 3307,
          "code": "210101",
          "name": "Ayamonte",
          "slug": "ayamonte",
          "image": "https://multimedia.andalucia.org/destination/photo_112.jpg",
          "habitants": 19738,
          "territory_type": {
            "id": 5,
            "code": "TIPTERMUNICIPIO",
            "name": "Municipio"
          },
          "province_id": 25,
          "province_name": "Huelva"
        }
      ],
      "vial_name": "Avenida Alcalde Narciso Martín Navarro",
      "address_number": "10",
      "postal_code": "21400",
      "extra_address": "Ayamonte",
      "x_coord": -7.404141,
      "y_coord": 37.211443,
      "resource_type": {
        "id": 19,
        "code": "TIPRECACTGUI",
        "name": "Empresas de actividades guiadas"
      },
      "private_plans": 0
    },
    {
      "id": 23794,
      "name": "Limitezero",
      "slug": "sanlucar-de-guadiana-actividades-guiadas-limitezero",
      "tags": "jovenes, singles",
      "image": "https://multimedia.andalucia.org/content_images/main_image_68933.jpeg",
      "territories": [
        {
          "id": 3363,
          "code": "210656",
          "name": "Sanlúcar de Guadiana",
          "slug": "sanlucar-de-guadiana",
          "image": "https://multimedia.andalucia.org/destination/photo_624.jpg",
          "habitants": 370,
          "territory_type": {
            "id": 5,
            "code": "TIPTERMUNICIPIO",
            "name": "Municipio"
          },
          "province_id": 25,
          "province_name": "Huelva"
        }
      ],
      "vial_name": "Avenida Portugal",
      "address_number": "12",
      "postal_code": "21595",
      "extra_address": "colina junto al castillo",
      "x_coord": -7.468204,
      "y_coord": 37.472902,
      "resource_type": {
        "id": 19,
        "code": "TIPRECACTGUI",
        "name": "Empresas de actividades guiadas"
      },
      "private_plans": 0
    },
    {
      "id": 23796,
      "name": "Grupo Sentire",
      "slug": "bollullos-par-del-condado-actividades-guiadas-grupo-sentire",
      "tags": "familias, jovenes, seniors",
      "image": "https://multimedia.andalucia.org/content_images/main_image_68937.jpeg",
      "territories": [
        {
          "id": 3310,
          "code": "210139",
          "name": "Bollullos Par del Condado",
          "slug": "bollullos-par-del-condado",
          "image": "https://multimedia.andalucia.org/destination/photo_159.jpg",
          "habitants": 13891,
          "territory_type": {
            "id": 5,
            "code": "TIPTERMUNICIPIO",
            "name": "Municipio"
          },
          "province_id": 25,
          "province_name": "Huelva"
        }
      ],
      "vial_name": "Calle Miraflores",
      "address_number": "77",
      "postal_code": "21710",
      "x_coord": -6.543875,
      "y_coord": 37.340935,
      "resource_type": {
        "id": 19,
        "code": "TIPRECACTGUI",
        "name": "Empresas de actividades guiadas"
      },
      "private_plans": 0
    },
    {
      "id": 23798,
      "name": "Oasis Ecológico",
      "slug": "punta-umbria-actividades-guiadas-oasis-ecologico",
      "register": "EC/HU/00003",
      "image": "https://multimedia.andalucia.org/content_images/main_image_68943.jpeg",
      "territories": [
        {
          "id": 3357,
          "code": "210600",
          "name": "Punta Umbría",
          "slug": "punta-umbria",
          "image": "https://multimedia.andalucia.org/destination/photo_588.jpg",
          "habitants": 14515,
          "territory_type": {
            "id": 5,
            "code": "TIPTERMUNICIPIO",
            "name": "Municipio"
          },
          "province_id": 25,
          "province_name": "Huelva"
        }
      ],
      "vial_name": "Urbanización Playas del Sur",
      "address_number": "31",
      "postal_code": "21459",
      "extra_address": "El Portil",
      "x_coord": -7.049545,
      "y_coord": 37.211463,
      "resource_type": {
        "id": 19,
        "code": "TIPRECACTGUI",
        "name": "Empresas de actividades guiadas"
      },
      "private_plans": 0
    },
    {
      "id": 23802,
      "name": "Huelvatours.com",
      "slug": "ayamonte-actividades-guiadas-huelvatourscom",
      "image": "https://multimedia.andalucia.org/content_images/main_image_68962.png",
      "territories": [
        {
          "id": 3307,
          "code": "210101",
          "name": "Ayamonte",
          "slug": "ayamonte",
          "image": "https://multimedia.andalucia.org/destination/photo_112.jpg",
          "habitants": 19738,
          "territory_type": {
            "id": 5,
            "code": "TIPTERMUNICIPIO",
            "name": "Municipio"
          },
          "province_id": 25,
          "province_name": "Huelva"
        }
      ],
      "vial_name": "Calle Prudencio Gutiérrez Pallarés",
      "address_number": "8-3ºB",
      "postal_code": "21400",
      "x_coord": -7.405674,
      "y_coord": 37.220547,
      "resource_type": {
        "id": 19,
        "code": "TIPRECACTGUI",
        "name": "Empresas de actividades guiadas"
      },
      "private_plans": 0
    },
    {
      "id": 23803,
      "name": "Iberian Lynx Land",
      "slug": "andujar-actividades-guiadas-iberian-lynx-land",
      "tags": "singles, jovenes",
      "register": "AT/JA/00060",
      "image": "https://multimedia.andalucia.org/content_images/main_image_68966.jpeg",
      "territories": [
        {
          "id": 3583,
          "code": "230050",
          "name": "Andújar",
          "slug": "andujar",
          "image": "https://multimedia.andalucia.org/destination/photo_89.jpg",
          "habitants": 38979,
          "territory_type": {
            "id": 5,
            "code": "TIPTERMUNICIPIO",
            "name": "Municipio"
          },
          "province_id": 26,
          "province_name": "Jaén"
        }
      ],
      "vial_name": "Calle Ollerías",
      "address_number": "38 - 3º",
      "postal_code": "23740",
      "x_coord": -4.05454,
      "y_coord": 38.039413,
      "resource_type": {
        "id": 19,
        "code": "TIPRECACTGUI",
        "name": "Empresas de actividades guiadas"
      },
      "private_plans": 0
    },
    {
      "id": 23804,
      "name": "Natures",
      "slug": "actividades-guiadas-natures",
      "tags": "seniors,actividades,aire libre,andalucia,astroturismo,ciencia,cursos,escapada,familias,formación,fotografía,geoturismo,guias,naturaleza,planes fin de semana,rutas,senderismo,turismo,visitas guiadas",
      "register": "AT/SE/00087 - EC/SE/00005",
      "image": "https://multimedia.andalucia.org/fotos/image_188653.jpeg",
      "territories": [
        {
          "id": 6227,
          "code": "410858",
          "name": "Salteras",
          "slug": "salteras",
          "image": "https://multimedia.andalucia.org/destination/photo_613.jpg",
          "habitants": 2913,
          "territory_type": {
            "id": 5,
            "code": "TIPTERMUNICIPIO",
            "name": "Municipio"
          },
          "province_id": 28,
          "province_name": "Sevilla"
        },
        {
          "id": 6233,
          "code": "410917",
          "name": "Sevilla",
          "slug": "sevilla",
          "image": "https://multimedia.andalucia.org/fotos/image_150751.jpeg",
          "habitants": 701927,
          "territory_type": {
            "id": 5,
            "code": "TIPTERMUNICIPIO",
            "name": "Municipio"
          },
          "province_id": 28,
          "province_name": "Sevilla"
        }
      ],
      "vial_name": "Avenida de Salteras",
      "address_number": "265",
      "postal_code": "41909",
      "extra_address": "Edificio Vía Plata, Bajo 14",
      "x_coord": -6.05599,
      "y_coord": 37.462755,
      "resource_type": {
        "id": 19,
        "code": "TIPRECACTGUI",
        "name": "Empresas de actividades guiadas"
      },
      "private_plans": 0
    },
    {
      "id": 23805,
      "name": "Heart of Andalusia",
      "slug": "estepa-actividades-guiadas-heart-of-andalusia",
      "tags": "jovenes, seniors, singles",
      "image": "https://multimedia.andalucia.org/content_images/main_image_68991.jpeg",
      "territories": [
        {
          "id": 6182,
          "code": "410418",
          "name": "Estepa",
          "slug": "estepa",
          "image": "https://multimedia.andalucia.org/destination/photo_310.jpg",
          "habitants": 11654,
          "territory_type": {
            "id": 5,
            "code": "TIPTERMUNICIPIO",
            "name": "Municipio"
          },
          "province_id": 28,
          "province_name": "Sevilla"
        }
      ],
      "vial_name": "Avenida de España",
      "address_number": "9",
      "postal_code": "41560",
      "x_coord": -4.882997,
      "y_coord": 37.286117,
      "resource_type": {
        "id": 19,
        "code": "TIPRECACTGUI",
        "name": "Empresas de actividades guiadas"
      },
      "private_plans": 0
    },
    {
      "id": 23807,
      "name": "Vía de Escape",
      "slug": "alcala-de-guadaira-actividades-guiadas-via-de-escape",
      "tags": "seniors",
      "image": "https://multimedia.andalucia.org/content_images/main_image_68994.jpeg",
      "territories": [
        {
          "id": 6143,
          "code": "410042",
          "name": "Alcalá de Guadaíra",
          "slug": "alcala-de-guadaira",
          "image": "https://multimedia.andalucia.org/destination/photo_36.jpg",
          "habitants": 68452,
          "territory_type": {
            "id": 5,
            "code": "TIPTERMUNICIPIO",
            "name": "Municipio"
          },
          "province_id": 28,
          "province_name": "Sevilla"
        }
      ],
      "vial_name": "Calle La Plata",
      "address_number": "21-23",
      "postal_code": "41500",
      "x_coord": -5.845012,
      "y_coord": 37.338223,
      "resource_type": {
        "id": 19,
        "code": "TIPRECACTGUI",
        "name": "Empresas de actividades guiadas"
      },
      "private_plans": 0
    },
    {
      "id": 23808,
      "name": "Foodies Andalucía",
      "slug": "sevilla-actividades-guiadas-foodies-andalucia",
      "tags": "jovenes,seniors",
      "image": "https://multimedia.andalucia.org/content_images/main_image_68997.jpeg",
      "territories": [
        {
          "id": 6233,
          "code": "410917",
          "name": "Sevilla",
          "slug": "sevilla",
          "image": "https://multimedia.andalucia.org/fotos/image_150751.jpeg",
          "habitants": 701927,
          "territory_type": {
            "id": 5,
            "code": "TIPTERMUNICIPIO",
            "name": "Municipio"
          },
          "province_id": 28,
          "province_name": "Sevilla"
        }
      ],
      "vial_name": "Alcazarquivir",
      "x_coord": -5.985191,
      "y_coord": 37.42196,
      "resource_type": {
        "id": 19,
        "code": "TIPRECACTGUI",
        "name": "Empresas de actividades guiadas"
      },
      "private_plans": 0
    },
    {
      "id": 23809,
      "name": "Náutica y Turismo del Estrecho - Nature Tarifa",
      "slug": "sevilla-actividades-guiadas-nautica-y-turismo-del-estrecho-nature-tarifa",
      "tags": "jovenes,seniors,singles",
      "register": "AIAT/SE/00002-1",
      "image": "https://multimedia.andalucia.org/content_images/main_image_69000.jpeg",
      "territories": [
        {
          "id": 6233,
          "code": "410917",
          "name": "Sevilla",
          "slug": "sevilla",
          "image": "https://multimedia.andalucia.org/fotos/image_150751.jpeg",
          "habitants": 701927,
          "territory_type": {
            "id": 5,
            "code": "TIPTERMUNICIPIO",
            "name": "Municipio"
          },
          "province_id": 28,
          "province_name": "Sevilla"
        }
      ],
      "vial_name": "Calle Genaro Parladé",
      "address_number": "1-4º C",
      "postal_code": "41013",
      "x_coord": -5.983334,
      "y_coord": 37.366667,
      "resource_type": {
        "id": 19,
        "code": "TIPRECACTGUI",
        "name": "Empresas de actividades guiadas"
      },
      "private_plans": 0
    },
    {
      "id": 23810,
      "name": "Cyclotour",
      "slug": "mairena-de-aljarafe-actividades-guiadas-cyclotour",
      "tags": "familias, jovenes, parejas, seniors, singles",
      "image": "https://multimedia.andalucia.org/content_images/main_image_69007.jpeg",
      "territories": [
        {
          "id": 6201,
          "code": "410592",
          "name": "Mairena del Aljarafe",
          "slug": "mairena-de-aljarafe",
          "image": "https://multimedia.andalucia.org/destination/photo_456.jpg",
          "habitants": 31793,
          "territory_type": {
            "id": 5,
            "code": "TIPTERMUNICIPIO",
            "name": "Municipio"
          },
          "province_id": 28,
          "province_name": "Sevilla"
        }
      ],
      "vial_name": "Calle Tomás de Ibarra",
      "address_number": "8",
      "postal_code": "41001",
      "x_coord": -6.03441,
      "y_coord": 37.3537,
      "resource_type": {
        "id": 19,
        "code": "TIPRECACTGUI",
        "name": "Empresas de actividades guiadas"
      },
      "private_plans": 0
    },
    {
      "id": 23811,
      "name": "Sevilla de Ópera",
      "slug": "sevilla-actividades-guiadas-sevilla-de-opera",
      "tags": "seniors,parejas,singles",
      "register": "AIAT/SE/00003-2",
      "image": "https://multimedia.andalucia.org/content_images/main_image_69012.jpeg",
      "territories": [
        {
          "id": 6233,
          "code": "410917",
          "name": "Sevilla",
          "slug": "sevilla",
          "image": "https://multimedia.andalucia.org/fotos/image_150751.jpeg",
          "habitants": 701927,
          "territory_type": {
            "id": 5,
            "code": "TIPTERMUNICIPIO",
            "name": "Municipio"
          },
          "province_id": 28,
          "province_name": "Sevilla"
        }
      ],
      "vial_name": "Calle Pastor y Landero",
      "address_number": "8",
      "postal_code": "41001",
      "extra_address": "Mercado del Arenal",
      "x_coord": -5.999854,
      "y_coord": 37.387521,
      "resource_type": {
        "id": 19,
        "code": "TIPRECACTGUI",
        "name": "Empresas de actividades guiadas"
      },
      "private_plans": 0
    },
    {
      "id": 23813,
      "name": "Diver Cultura",
      "slug": "mairena-del-alcor-actividades-guiadas-diver-cultura",
      "tags": "familias, seniors",
      "image": "https://multimedia.andalucia.org/content_images/main_image_69019.png",
      "territories": [
        {
          "id": 6200,
          "code": "410589",
          "name": "Mairena del Alcor",
          "slug": "mairena-del-alcor",
          "image": "https://multimedia.andalucia.org/destination/photo_455.jpg",
          "habitants": 20510,
          "territory_type": {
            "id": 5,
            "code": "TIPTERMUNICIPIO",
            "name": "Municipio"
          },
          "province_id": 28,
          "province_name": "Sevilla"
        }
      ],
      "vial_name": "Calle Dama de Noche",
      "address_number": "4",
      "postal_code": "41510",
      "x_coord": -5.745659,
      "y_coord": 37.372861,
      "resource_type": {
        "id": 19,
        "code": "TIPRECACTGUI",
        "name": "Empresas de actividades guiadas"
      },
      "private_plans": 0
    },
    {
      "id": 23815,
      "name": "Dinamo Cultura",
      "slug": "santiponce-actividades-guiadas-dinamo-cultura",
      "tags": "seniors, singles",
      "image": "https://multimedia.andalucia.org/content_images/main_image_69031.jpeg",
      "territories": [
        {
          "id": 6231,
          "code": "410896",
          "name": "Santiponce",
          "slug": "santiponce",
          "image": "https://multimedia.andalucia.org/destination/photo_638.jpg",
          "habitants": 6841,
          "territory_type": {
            "id": 5,
            "code": "TIPTERMUNICIPIO",
            "name": "Municipio"
          },
          "province_id": 28,
          "province_name": "Sevilla"
        }
      ],
      "vial_name": "Plaza Constitución",
      "address_number": "11",
      "postal_code": "41790",
      "extra_address": "Edificio Cotidiana Vita",
      "x_coord": -6.039337,
      "y_coord": 37.4386,
      "resource_type": {
        "id": 19,
        "code": "TIPRECACTGUI",
        "name": "Empresas de actividades guiadas"
      },
      "private_plans": 0
    },
    {
      "id": 23817,
      "name": "Segway Cazorla",
      "slug": "cazorla-actividades-guiadas-segway-cazorla",
      "tags": "familias, jovenes, parejas, seniors, singles",
      "image": "https://multimedia.andalucia.org/content_images/main_image_69041.png",
      "territories": [
        {
          "id": 3605,
          "code": "230281",
          "name": "Cazorla",
          "slug": "cazorla",
          "image": "https://multimedia.andalucia.org/destination/photo_237.jpg",
          "habitants": 8527,
          "territory_type": {
            "id": 5,
            "code": "TIPTERMUNICIPIO",
            "name": "Municipio"
          },
          "province_id": 26,
          "province_name": "Jaén"
        }
      ],
      "vial_name": "Plaza de Santa María",
      "address_number": "5",
      "postal_code": "23470",
      "x_coord": -3.000048,
      "y_coord": 37.909194,
      "resource_type": {
        "id": 19,
        "code": "TIPRECACTGUI",
        "name": "Empresas de actividades guiadas"
      },
      "private_plans": 0
    },
    {
      "id": 23819,
      "name": "PanchoTours",
      "slug": "sevilla-actividades-guiadas-panchotours",
      "tags": "jovenes,singles,parejas,seniors",
      "image": "https://multimedia.andalucia.org/content_images/main_image_69043.jpeg",
      "territories": [
        {
          "id": 6233,
          "code": "410917",
          "name": "Sevilla",
          "slug": "sevilla",
          "image": "https://multimedia.andalucia.org/fotos/image_150751.jpeg",
          "habitants": 701927,
          "territory_type": {
            "id": 5,
            "code": "TIPTERMUNICIPIO",
            "name": "Municipio"
          },
          "province_id": 28,
          "province_name": "Sevilla"
        }
      ],
      "vial_name": "Calle Muñoz Olive",
      "address_number": "1",
      "postal_code": "41001",
      "extra_address": "Portal 2, primera planta",
      "x_coord": -5.995135,
      "y_coord": 37.390079,
      "resource_type": {
        "id": 19,
        "code": "TIPRECACTGUI",
        "name": "Empresas de actividades guiadas"
      },
      "private_plans": 0
    },
    {
      "id": 23820,
      "name": "Trenes Cazorla",
      "slug": "cazorla-actividades-guiadas-trenes-cazorla",
      "tags": "familias, seniors, singles",
      "image": "https://multimedia.andalucia.org/content_images/main_image_69047.jpeg",
      "territories": [
        {
          "id": 3605,
          "code": "230281",
          "name": "Cazorla",
          "slug": "cazorla",
          "image": "https://multimedia.andalucia.org/destination/photo_237.jpg",
          "habitants": 8527,
          "territory_type": {
            "id": 5,
            "code": "TIPTERMUNICIPIO",
            "name": "Municipio"
          },
          "province_id": 26,
          "province_name": "Jaén"
        }
      ],
      "vial_name": "Calle del Guadalquivir",
      "address_number": "18",
      "postal_code": "23470",
      "x_coord": -3.006608,
      "y_coord": 37.915669,
      "resource_type": {
        "id": 19,
        "code": "TIPRECACTGUI",
        "name": "Empresas de actividades guiadas"
      },
      "private_plans": 0
    },
    {
      "id": 23823,
      "name": "Granatours",
      "slug": "granada-actividades-guiadas-granatours",
      "image": "https://multimedia.andalucia.org/content_images/main_image_69061.jpeg",
      "territories": [
        {
          "id": 2826,
          "code": "180877",
          "name": "Granada",
          "slug": "granada",
          "image": "https://multimedia.andalucia.org/destination/photo_354.jpg",
          "habitants": 236988,
          "territory_type": {
            "id": 5,
            "code": "TIPTERMUNICIPIO",
            "name": "Municipio"
          },
          "province_id": 24,
          "province_name": "Granada"
        }
      ],
      "vial_name": "Calle Santa Lucía",
      "address_number": "5",
      "postal_code": "18010",
      "x_coord": -3.599535,
      "y_coord": 37.180521,
      "resource_type": {
        "id": 19,
        "code": "TIPRECACTGUI",
        "name": "Empresas de actividades guiadas"
      },
      "private_plans": 0
    },
    {
      "id": 23825,
      "name": "Alpasín",
      "slug": "adamuz-actividades-guiadas-alpasin",
      "tags": "seniors, singles,ecoturismo,fotografia,jovenes,wildlife",
      "register": "AT/CO/00072  EC/CO/00001",
      "image": "https://multimedia.andalucia.org/fotos/image_241215.jpeg",
      "territories": [
        {
          "id": 2123,
          "code": "140018",
          "name": "Adamuz",
          "slug": "adamuz",
          "image": "https://multimedia.andalucia.org/destination/photo_17.jpg",
          "habitants": 4472,
          "territory_type": {
            "id": 5,
            "code": "TIPTERMUNICIPIO",
            "name": "Municipio"
          },
          "province_id": 23,
          "province_name": "Córdoba"
        }
      ],
      "vial_name": "Avenida de Andalucía",
      "address_number": "14",
      "postal_code": "14430",
      "x_coord": -4.520805,
      "y_coord": 38.027095,
      "resource_type": {
        "id": 19,
        "code": "TIPRECACTGUI",
        "name": "Empresas de actividades guiadas"
      },
      "private_plans": 0
    },
    {
      "id": 23826,
      "name": "Eventúrate",
      "slug": "cordoba-actividades-guiadas-eventurate",
      "tags": "familias, seniors",
      "image": "https://multimedia.andalucia.org/content_images/main_image_69092.jpeg",
      "territories": [
        {
          "id": 2143,
          "code": "140214",
          "name": "Córdoba",
          "slug": "cordoba",
          "image": "https://multimedia.andalucia.org/destination/photo_262.jpg",
          "habitants": 325453,
          "territory_type": {
            "id": 5,
            "code": "TIPTERMUNICIPIO",
            "name": "Municipio"
          },
          "province_id": 23,
          "province_name": "Córdoba"
        }
      ],
      "vial_name": "Calle San Pablo",
      "address_number": "23",
      "postal_code": "14002",
      "x_coord": -4.774036,
      "y_coord": 37.886076,
      "resource_type": {
        "id": 19,
        "code": "TIPRECACTGUI",
        "name": "Empresas de actividades guiadas"
      },
      "private_plans": 0
    },
    {
      "id": 23827,
      "name": "Cádiz Industria Viva",
      "slug": "cadiz-actividades-guiadas-cadiz-industria-viva",
      "tags": "astroturismo, familias, seniors",
      "register": "AIAT",
      "image": "https://multimedia.andalucia.org/content_images/main_image_69097.jpeg",
      "territories": [
        {
          "id": 1854,
          "code": "110125",
          "name": "Cádiz",
          "slug": "cadiz",
          "image": "https://multimedia.andalucia.org/destination/photo_176.jpg",
          "habitants": 127200,
          "territory_type": {
            "id": 5,
            "code": "TIPTERMUNICIPIO",
            "name": "Municipio"
          },
          "province_id": 22,
          "province_name": "Cádiz"
        }
      ],
      "vial_name": "Calle Antonio López",
      "address_number": "4",
      "postal_code": "11004",
      "x_coord": -6.295856,
      "y_coord": 36.535248,
      "resource_type": {
        "id": 19,
        "code": "TIPRECACTGUI",
        "name": "Empresas de actividades guiadas"
      },
      "private_plans": 0
    },
    {
      "id": 23828,
      "name": "Tour in Cádiz",
      "slug": "cadiz-actividades-guiadas-tour-in-cadiz",
      "tags": "familias, jovenes, seniors",
      "image": "https://multimedia.andalucia.org/content_images/main_image_69106.jpeg",
      "territories": [
        {
          "id": 1854,
          "code": "110125",
          "name": "Cádiz",
          "slug": "cadiz",
          "image": "https://multimedia.andalucia.org/destination/photo_176.jpg",
          "habitants": 127200,
          "territory_type": {
            "id": 5,
            "code": "TIPTERMUNICIPIO",
            "name": "Municipio"
          },
          "province_id": 22,
          "province_name": "Cádiz"
        }
      ],
      "vial_name": "Avenida de la Ilustración",
      "address_number": "6-3ª planta",
      "postal_code": "11011",
      "extra_address": "Edificio Astarté",
      "x_coord": -6.120789,
      "y_coord": 36.709339,
      "resource_type": {
        "id": 19,
        "code": "TIPRECACTGUI",
        "name": "Empresas de actividades guiadas"
      },
      "private_plans": 0
    },
    {
      "id": 23829,
      "name": "Bike and Wine",
      "slug": "jerez-de-la-frontera-actividades-guiadas-bike-and-wine",
      "tags": "jovenes, singles",
      "image": "https://multimedia.andalucia.org/content_images/main_image_69116.jpeg",
      "territories": [
        {
          "id": 1862,
          "code": "110201",
          "name": "Jerez de la Frontera",
          "slug": "jerez-de-la-frontera",
          "image": "https://multimedia.andalucia.org/destination/photo_410.jpg",
          "habitants": 205364,
          "territory_type": {
            "id": 5,
            "code": "TIPTERMUNICIPIO",
            "name": "Municipio"
          },
          "province_id": 22,
          "province_name": "Cádiz"
        }
      ],
      "vial_name": "Calle Santo Domingo",
      "address_number": "6",
      "postal_code": "11402",
      "x_coord": -6.135303,
      "y_coord": 36.688521,
      "resource_type": {
        "id": 19,
        "code": "TIPRECACTGUI",
        "name": "Empresas de actividades guiadas"
      },
      "private_plans": 0
    },
    {
      "id": 23832,
      "name": "Pópulo Servicios Turísticos",
      "slug": "baeza-actividades-guiadas-populo-servicios-turisticos",
      "tags": "familias, seniors",
      "image": "https://multimedia.andalucia.org/content_images/main_image_69477.jpeg",
      "territories": [
        {
          "id": 3587,
          "code": "230098",
          "name": "Baeza",
          "slug": "baeza",
          "image": "https://multimedia.andalucia.org/destination/photo_118.jpg",
          "habitants": 16012,
          "territory_type": {
            "id": 5,
            "code": "TIPTERMUNICIPIO",
            "name": "Municipio"
          },
          "province_id": 26,
          "province_name": "Jaén"
        }
      ],
      "vial_name": "Plaza de los Leones",
      "address_number": "1",
      "postal_code": "23440",
      "x_coord": -3.470448,
      "y_coord": 37.991732,
      "resource_type": {
        "id": 19,
        "code": "TIPRECACTGUI",
        "name": "Empresas de actividades guiadas"
      },
      "private_plans": 0
    },
    {
      "id": 23833,
      "name": "Visit Cordoba by Konexion Tours",
      "slug": "cordoba-actividades-guiadas-konexion-tours",
      "tags": "Guías locales oficiales,Guided Tours,Guides Qualifiés,Local Qualified Guides,Visitas guiadas,Visites Guidées",
      "register": "Ci-An: 145033-2",
      "image": "https://multimedia.andalucia.org/media/30C5694873514DF5BFE17164EAF1B940/img/9BD678E8985D44D6AE1E350175F8B86A/1591618851100mezquita-catedral-de-cordoba941263150069189744.jpg?responsive",
      "territories": [
        {
          "id": 2143,
          "code": "140214",
          "name": "Córdoba",
          "slug": "cordoba",
          "image": "https://multimedia.andalucia.org/destination/photo_262.jpg",
          "habitants": 325453,
          "territory_type": {
            "id": 5,
            "code": "TIPTERMUNICIPIO",
            "name": "Municipio"
          },
          "province_id": 23,
          "province_name": "Córdoba"
        }
      ],
      "vial_name": "Calle Cronista Salcedo Hierro",
      "address_number": "2A",
      "postal_code": "14001",
      "extra_address": "Portal 2A - Escalera 1 - 2º C",
      "x_coord": -4.780094,
      "y_coord": 37.892021,
      "resource_type": {
        "id": 19,
        "code": "TIPRECACTGUI",
        "name": "Empresas de actividades guiadas"
      },
      "private_plans": 0
    },
    {
      "id": 23834,
      "name": "Turismo Marinero",
      "slug": "estepona-actividades-guiadas-turismo-marinero",
      "tags": "familias, jovenes, seniors",
      "image": "https://multimedia.andalucia.org/content_images/main_image_71384.jpeg",
      "territories": [
        {
          "id": 4587,
          "code": "290516",
          "name": "Estepona",
          "slug": "estepona",
          "image": "https://multimedia.andalucia.org/destination/photo_311.jpg",
          "habitants": 70000,
          "territory_type": {
            "id": 5,
            "code": "TIPTERMUNICIPIO",
            "name": "Municipio"
          },
          "province_id": 27,
          "province_name": "Málaga"
        }
      ],
      "vial_name": "Puerto Pesquero",
      "address_number": "s/n",
      "extra_address": "Debajo de la lonja de pescadores",
      "x_coord": -5.154051,
      "y_coord": 36.41672,
      "resource_type": {
        "id": 19,
        "code": "TIPRECACTGUI",
        "name": "Empresas de actividades guiadas"
      },
      "private_plans": 0
    },
    {
      "id": 23835,
      "name": "Descubre el Sur",
      "slug": "cadiz-actividades-guiadas-descubre-el-sur",
      "tags": "familias, jovenes, parejas, seniors",
      "image": "https://multimedia.andalucia.org/content_images/main_image_72959.jpeg",
      "territories": [
        {
          "id": 1854,
          "code": "110125",
          "name": "Cádiz",
          "slug": "cadiz",
          "image": "https://multimedia.andalucia.org/destination/photo_176.jpg",
          "habitants": 127200,
          "territory_type": {
            "id": 5,
            "code": "TIPTERMUNICIPIO",
            "name": "Municipio"
          },
          "province_id": 22,
          "province_name": "Cádiz"
        }
      ],
      "x_coord": -6.288596,
      "y_coord": 36.527061,
      "resource_type": {
        "id": 19,
        "code": "TIPRECACTGUI",
        "name": "Empresas de actividades guiadas"
      },
      "private_plans": 0
    }
  ]
}