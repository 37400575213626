import _ from "lodash";
import numberWithCommas from "../../utils/numberWithCommas";
import { saveExchangeRate } from "../../utils/saveExchangeRate";
import { useSelectFlights } from "../flights/useSelectFlights";
import { passengerTypes, taxesTypes } from "../../utils/commons";
import { useSelectPaquetes } from "../packages/useSelectPaquetes";
import ticket from "../../iconos/fi-rr-ticket.png";

export const PurchaseDetail = ({
  type,
  selectedAmenities,
  commissions,
  currencies,
  numberOfPassengers,
}) => {
  const flight = useSelectFlights((st) => st.flight);
  const { travelerPricings, price } = useSelectFlights((st) => st.original);
  const packageSelected = useSelectPaquetes((st) => st.packageSelected);
  const exchangeRate = saveExchangeRate((st) => st.exchangeRate);

  const breakdownPrices = [
    ...(price ? price?.fees : []),
    ...(travelerPricings?.length
      ? travelerPricings?.map((v) => ({
          amount: v?.price?.base,
          type: v?.travelerType,
          taxes: v?.price?.total - v?.price?.base,
        }))
      : []),
  ];

  const breakpricesFormatted = breakdownPrices
    ?.filter((x) => x?.amount > 0)
    ?.reduce((prev, acc) => {
      prev[acc?.type] = {
        amount: (prev[acc?.type]?.amount || 0) + parseFloat(acc?.amount || 0),
        taxes: (prev[acc?.type]?.taxes || 0) + parseFloat(acc?.taxes || 0),
      };
      return prev;
    }, {});

  const breakpricesQ = breakdownPrices?.reduce(
    (prev, acc) => ({
      ...prev,
      [acc.type]: _.reduce(acc, (p, a) => (p || 0) + 1, 1),
    }),
    {}
  );

  const pricePerPassenger = travelerPricings?.map((passenger) => {
    const { travelerId, travelerType, price } = passenger;
    return {
      travelerId,
      travelerType,
      price,
    };
  });

  const totalPercentageCommissions =
    commissions.length > 0 &&
    commissions.reduce((acc, { percentage }) => {
      return (acc += Number(percentage));
    }, 0);

  let totalPrice = Number(
    type === "flights"
      ? flight?.totalPrice
      : packageSelected?.price + Number(packageSelected?.tax_package ?? "0")
  );
  totalPrice += (totalPrice * Number(totalPercentageCommissions)) / 100;

  const passengerTypeCounter = {
    ADULT: 0,
    CHILD: 0,
    HELD_INFANT: 0,
  };

  const translateTravelerType = (traveler) => {
    if (traveler === "ADULT") return "Adulto";
    if (traveler === "CHILD") return "Niño";
    return "Bebé";
  };

  const translateCurrency = (id) =>
    currencies?.find((currency) => currency.id === id)?.symbol;

  const totalToARS = (number) =>
    price.currency === "ARS" ? number : exchangeRate.euro * number;

  return (
    <section className="relative flex flex-col gap-4">
      <div className="w-full px-8 py-3 flex gap-4 bg-lightBlue rounded-lg">
        <img src={ticket} alt="ticket" />
        <p className="font-medium text-xl">Detalle del pago</p>
      </div>

      {flight && (
        <div className="grid gap-2 px-4">
          {type === "flights" ? (
            pricePerPassenger?.map((passenger) => {
              const { travelerId, travelerType, price } = passenger;
              passengerTypeCounter[travelerType] += 1;

              return (
                <div key={travelerId}>
                  <span className="flex justify-between">
                    <p>
                      {translateTravelerType(travelerType)}{" "}
                      {passengerTypeCounter[travelerType]}
                    </p>
                    {/* DESCOMENTAR CUANDO HAYA CONVERSOR DE DIVISAS */}
                    {/* <p>
                      ARS {price?.currency === "EUR" ? "~" : ""}{" "}
                      {numberWithCommas(totalToARS(Number(price.base)), 2)}
                    </p> */}

                    {/* ------------------------------------ */}
                    <p>EUR {numberWithCommas(Number(price.base), 2)}</p>
                    {/* ------------------------------------ */}
                  </span>
                  <span className="flex justify-between">
                    <p>Impuestos</p>
                    {/* <p>
                      ARS {price?.currency === "EUR" ? "~" : ""}{" "}
                      {numberWithCommas(
                        totalToARS(Number(price.total - price.base)),
                        2
                      )}
                    </p> */}

                    {/* ------------------------------------ */}
                    <p>
                      EUR{" "}
                      {numberWithCommas(Number(price.total - price.base), 2)}
                    </p>
                    {/* ------------------------------------ */}
                  </span>

                  {commissions.length > 0 && (
                    <span className="flex flex-col justify-between mt-2">
                      <h3 className="font-semibold">Comisiones</h3>
                      {commissions.map(({ id, name, percentage }) => (
                        <span key={id} className="flex justify-between">
                          <p>
                            {name} ({percentage}%)
                          </p>
                          {/* <p>
                            ARS {price?.currency === "EUR" ? "~" : ""}{" "}
                            {numberWithCommas(
                              totalToARS(
                                Number((price.total * Number(percentage)) / 100)
                              ),
                              2
                            )}
                          </p> */}

                          {/* ---------------------------------------- */}
                          <p>
                            EUR{" "}
                            {numberWithCommas(
                              Number((price.total * Number(percentage)) / 100),
                              2
                            )}
                          </p>
                          {/* ---------------------------------------- */}
                        </span>
                      ))}
                    </span>
                  )}
                </div>
              );
            })
          ) : (
            <section>
              {numberOfPassengers.map(({ travelerType, travelerId }, index) => {
                return (
                  <div key={travelerId} className="flex justify-between gap-8">
                    <p className="whitespace-nowrap font-semibold">
                      <span className="w-[10ch]">{index + 1})</span>{" "}
                      {passengerTypes?.[travelerType]}
                    </p>
                    <div className="grid grid-cols-2 w-full">
                      <p>Tarifa</p>
                      <p className="text-right">
                        {translateCurrency(packageSelected.currency_type_id) ??
                          "USD"}{" "}
                        {numberWithCommas(Number(packageSelected?.price), 2)}
                      </p>
                      <p>Impuesto</p>
                      <p className="text-right">
                        {translateCurrency(packageSelected.currency_type_id) ??
                          "USD"}{" "}
                        {numberWithCommas(
                          Number(packageSelected?.tax_package ?? "0"),
                          2
                        )}
                      </p>
                    </div>
                  </div>
                );
              })}

              {commissions.length > 0 && (
                <span className="flex flex-col justify-between mt-2">
                  <h3 className="font-semibold">Comisiones</h3>
                  {commissions.map(({ id, name, percentage }) => (
                    <span key={id} className="flex justify-between">
                      <p>
                        {name} ({percentage}%)
                      </p>
                      <p>
                        {translateCurrency(packageSelected.currency_type_id) ??
                          "USD"}{" "}
                        {numberWithCommas(
                          Number(
                            (packageSelected?.price *
                              numberOfPassengers.length *
                              Number(percentage)) /
                              100
                          ),
                          2
                        )}
                      </p>
                    </span>
                  ))}
                </span>
              )}
            </section>
          )}
        </div>
      )}

      {selectedAmenities && (
        <div className="px-4">
          {Object.keys(selectedAmenities || {}).map((segmentId) => {
            if (selectedAmenities[segmentId].length === 0) return <></>;

            return (
              <div key={segmentId} className="flex gap-3">
                <h1 className="font-medium">Vuelo {segmentId}:</h1>
                {selectedAmenities[segmentId].map((amenity, index) => {
                  return (
                    <p
                      key={`${segmentId}-${index}`}
                      className="capitalize text-sm"
                    >
                      {amenity.toLowerCase()}
                    </p>
                  );
                })}
              </div>
            );
          })}
        </div>
      )}

      <div className="h-[1px] mx-auto w-[95%] bg-black" />
      <div className="flex flex-col gap-3 px-4 my-1">
        <span className="flex items-center mb-2 justify-between">
          <p className="font-bold text-black">TOTAL</p>
          <p className="text-2xl font-bold text-[#242423]">
            {/* ---------------- SUSTITUIR LINEA 266 y  269 ------------- */}
            {/* : `ARS ${price?.currency === "EUR" ? "~" : ""}`} */}
            {/* : numberWithCommas(
                totalToARS(totalPrice * pricePerPassenger.length),
                2
              )} */}
            {type === "packages"
              ? translateCurrency(packageSelected.currency_type_id) ?? "USD"
              : "EUR "}
            {type === "packages"
              ? numberWithCommas(totalPrice * numberOfPassengers.length, 2)
              : numberWithCommas(totalPrice * pricePerPassenger.length, 2)}
          </p>
        </span>

        {!packageSelected ? (
          <div className="flex flex-col gap-3">
            {_.keys(breakpricesFormatted).map((l) => (
              <>
                <span className="flex justify-between">
                  <p>
                    {taxesTypes?.[l]} x{breakpricesQ?.[l]}
                  </p>
                  <p>${numberWithCommas(breakpricesFormatted?.[l]?.amount)}</p>
                </span>
                <span className="flex justify-between">
                  <p>Impuestos x{breakpricesQ?.[l]} </p>
                  <p>${numberWithCommas(breakpricesFormatted?.[l]?.taxes)}</p>
                </span>
              </>
            ))}
          </div>
        ) : null}
      </div>
    </section>
  );
};
