import React from "react";
import { ClientsWrapper } from "./clients/ClientsWrapper";

export const StaffUser = () => {
  return (
    <div className="flex flex-col gap-2 px-6 pt-2 pb-4 mt-2 rounded-xl">
      <ClientsWrapper />
    </div>
  );
};
