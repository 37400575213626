/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState } from "react";
import clsx from "clsx";
import useOnClickOutside from "../hooks/useClickOutside";
import { COUNTRY_ISO_3166 } from "./dictionaries/countryISODictionary";

const formattedCountries = Object.keys(COUNTRY_ISO_3166).map((code) => {
  const name = COUNTRY_ISO_3166[code];
  return { code, name };
});

export default function AutoCompleteCountryISO3166({
  className,
  register,
  name,
  bgColor = "white",
  returnCode = false,
  handleAction,
  inputState,
}) {
  const [selected, setSelected] = useState("s");
  const [inputValue, setInputValue] = inputState;
  const ref = useRef(null);

  useOnClickOutside(ref, () => setSelected("s"));

  const countryOptions = formattedCountries.filter((item) => {
    return item?.name?.toLowerCase()?.includes(inputValue.toLowerCase());
  });

  return (
    <section className="relative buscador-centro-ubicacion-input-div">
      <input
        className={clsx(
          { [className]: className?.length },
          {
            "py-4 pl-4 text-sm buscador-centro-ubicacion-input focus:outline-none":
              !className?.length,
          }
        )}
        type="text"
        {...register(name)}
        value={inputValue}
        name={name}
        autoComplete="off"
        onChange={(e) => {
          setInputValue(e.target.value);
          setSelected("");
          handleAction(e.target.value);
        }}
        // onBlur={(e) => handleAction(e.target.value)}
      />

      <section
        className={`absolute z-50 bg-${bgColor} max-h-[180px] overflow-y-auto overflow-x-hidden top-9 rounded-md w-[300px] border-darkBlue transition-all ${
          !selected.length ? "border" : "border-none"
        }`}
      >
        <div ref={ref} className="flex flex-col gap-1">
          {!selected.length
            ? countryOptions.slice(0, 10).map(({ code, name }, index) => {
                return (
                  <div key={code + index}>
                    <p
                      key={index}
                      onClick={() => {
                        setSelected(code);
                        setInputValue(returnCode ? code : name);
                        handleAction(code);
                      }}
                      className="px-2 pt-1 m-0 w-full cursor-pointer whitespace-pre-wrap transition-colors hover:bg-lightBlue"
                    >
                      {name}
                    </p>
                  </div>
                );
              })
            : null}
        </div>
      </section>
    </section>
  );
}
