import numberWithCommas from "../../utils/numberWithCommas";
import { adaptPassenger } from "./Pasajeros";
import TableHeader from "./BookingDetailHeader";

export default function Tarifa({
  tarifa,
  emisionDate,
  selectedAmenities,
  commissions,
  handleDisplay,
}) {
  const passengerTypeCounter = {
    ADULT: 0,
    CHILD: 0,
    HELD_INFANT: 0,
  };

  const date = emisionDate?.split("-");
  const emisionDateFormated = date && `${date[2]}/${date[1]}/${date[0]}`;
  const totalPercentageCommissions =
    commissions.length > 0 &&
    commissions.reduce((acc, { percentage }) => {
      return (acc += Number(percentage));
    }, 0);
  console.log(tarifa);
  let totalPrice =
    tarifa && tarifa.reduce((acc, el) => (acc += Number(el.price.total)), 0);

  return (
    <section>
      <TableHeader title={"tarifa"} handleDisplay={handleDisplay} />

      <section
        id="tarifa-body"
        className="mt-2 px-8 overflow-y-hidden transition-transform duration-200 origin-top"
      >
        <div className="flex">
          <div className="grid gap-1 border-r-2 border-gray2 pr-4">
            {tarifa &&
              tarifa.map((el) => {
                passengerTypeCounter[el.travelerType] += 1;
                const passengerTypeIndex = `${adaptPassenger(el.travelerType)} x
              ${passengerTypeCounter[el.travelerType]}`;

                return (
                  <div key={passengerTypeIndex}>
                    <div className="grid grid-cols-2">
                      <p className="font-medium text-darkBlue ">
                        {passengerTypeIndex}
                      </p>
                      <p>{numberWithCommas(Number(el.price.base), 2)}</p>
                    </div>
                    <div className="grid grid-cols-2">
                      <p className="font-medium text-darkBlue">IMP</p>
                      <p>
                        {numberWithCommas(
                          Number(el.price.total - el.price.base),
                          2
                        )}
                      </p>
                    </div>
                  </div>
                );
              })}
          </div>

          <section className="grid gap-1 px-4">
            <div className="grid grid-cols-3 gap-4 items-center">
              <p className="font-medium text-darkBlue">COM</p>
              <p className="col-span-2 text-sm">0.00%</p>
            </div>
            <div className="grid grid-cols-3 gap-4 items-center">
              <p className="font-medium text-darkBlue">OVR</p>
              <p className="col-span-2 text-sm">(0.00 %)</p>
            </div>
            <div className="grid grid-cols-3 gap-4 items-center">
              <p className="font-medium text-darkBlue">GDS</p>
              <p className="col-span-2 text-sm">TRF Publicada</p>
            </div>
            <div className="grid grid-cols-3 gap-4 items-center">
              <p className="font-medium text-darkBlue">LTkD</p>
              {/* <p className="col-s2an-3 text-sm">19May / VC: DL</p> */}
            </div>
          </section>

          {commissions.length > 0 && (
            <div className="px-4 border-l-2 border-gray2">
              {commissions.length > 0 && (
                <span className="flex flex-col justify-between mt-2">
                  <h3 className="font-semibold">Comisiones</h3>
                  {commissions.map(({ id, name, percentage }) => (
                    <span key={id} className="flex justify-between ">
                      <p className="font-medium text-darkBlue">
                        {name} ({percentage}%)
                      </p>
                      <p className="text-sm">
                        ${" "}
                        {numberWithCommas(
                          Number((totalPrice * Number(percentage)) / 100),
                          2
                        )}
                      </p>
                    </span>
                  ))}
                </span>
              )}
            </div>
          )}

          <section className="ml-4 flex flex-col justify-center gap-2">
            <div className="flex items-center py-2 px-4 border border-gray2 rounded-sm">
              <p className="text-sm mr-4 ">FINAL</p>
              <p className="text-3xl font-medium ">
                {numberWithCommas(
                  totalPrice +
                    (totalPrice * Number(totalPercentageCommissions)) / 100,
                  2
                )}
                <span className="text-xs font-low">
                  {tarifa?.[0]?.price?.currency}
                </span>
              </p>
            </div>
            <div className="flex gap-1 items-center">
              <p className="text-sm">Fecha Límite de Emisión</p>
              <span className="text-xl font-medium">{emisionDateFormated}</span>
            </div>
          </section>
        </div>

        {Object.keys(selectedAmenities).length > 0 && (
          <div className="mt-2 pt-2 border-t border-gray2">
            {Object.keys(selectedAmenities || {}).map((segmentId) => {
              if (selectedAmenities[segmentId].length === 0) return <></>;

              return (
                <div key={segmentId} className="flex gap-4">
                  <h1 className="font-semibold text-lg">Vuelo {segmentId}:</h1>
                  {selectedAmenities[segmentId].map((amenity, index) => {
                    return (
                      <p key={`${segmentId}-${index}`} className="capitalize">
                        {amenity.toLowerCase()}
                      </p>
                    );
                  })}
                </div>
              );
            })}
          </div>
        )}
      </section>
    </section>
  );
}
