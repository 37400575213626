export default function TableHeaderPaquetes() {
  return (
    <header className="bg-lightBlue tableGrid py-2 px-8 rounded-lg">
      <h4 className="font-medium text-brightBlue col-span-4">Nombre</h4>
      <h4 className="font-medium text-brightBlue col-span-3">Origen</h4>
      <h4 className="font-medium text-brightBlue col-span-3">Destino</h4>
      <h4 className="font-medium text-brightBlue col-span-2">Fecha Salida</h4>
      <h4 className="font-medium text-brightBlue col-span-2">Dispo / Cupos</h4>
      <h4 className="font-medium text-brightBlue col-span-2">Tarifa</h4>
      <h4 className="font-medium text-brightBlue col-span-2">Impuesto</h4>
      <h4 className="font-medium text-brightBlue col-span-2">Precio</h4>
      <h4 className="font-medium text-brightBlue col-span-3">Acciones</h4>
    </header>
  );
}
