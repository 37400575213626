import copy from "../../iconos/copy-icon.png";
import edit from "../../iconos/edit_icon.png";
import eye from "../../iconos/eye-blue.png";
import trash from "../../iconos/delete.png";
import { format } from "date-fns";
import numberWithCommas from "../../utils/numberWithCommas";
import { Link, useParams } from "react-router-dom";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import { useCopy } from "../../hooks/utils/useCopy";

export default function TableRowPaquete({
  index,
  pack,
  currency,
  openModal,
  setSelectedModal,
}) {
  const { tour_op_id } = useParams();
  const [isCopied, copyToClipboard] = useCopy();
  const currentHREF = window.location.href.split("/").slice(0, -3).join("/");

  return (
    <div
      className={`text-sm xl:text-base tableGrid py-2 px-8 items-center text-gray rounded-lg ${
        index % 2 === 1 && "bg-lightGray2"
      }`}
    >
      <p className="whitespace-nowrap overflow-hidden mr-4 col-span-4">
        {pack.title}
      </p>
      <p className="capitalize col-span-3">{pack.oricode.toLowerCase()}</p>
      <p className="capitalize col-span-3">{pack.descode.toLowerCase()}</p>
      <p className="col-span-2">
        {pack?.departure_date
          ? format(new Date(pack.departure_date), "dd/MM/yyyy")
          : "-"}
      </p>
      <p className="col-span-2">
        {pack.original_stock} / {pack.stock}
      </p>
      <p className="col-span-2">
        <span className="hidden xl:inline">{currency ?? "USD"}</span>{" "}
        {numberWithCommas(Number(pack.price), 0)}
      </p>
      <p className="col-span-2">
        <span className="hidden xl:inline">{currency ?? "USD"}</span>{" "}
        {numberWithCommas(Number(pack.tax_package ?? "0"), 0)}
      </p>
      <p className="col-span-2">
        <span className="inline xl:inline">{currency ?? "USD"}</span>{" "}
        {numberWithCommas(
          Number(pack.price) + Number(pack.tax_package ?? "0"),
          0
        )}
      </p>
      <div className="col-span-3 flex items-center">
        <button
          className="relative mr-0.5 w-7 h-7 cursor-pointer hover:bg-[#EEE] rounded-full transition-colors group/copy"
          onClick={() => {
            openModal(pack);
            setSelectedModal("copy");
          }}
        >
          <img src={copy} alt="copy" />
          <span className="absolute whitespace-nowrap bg-white border bottom-full -left-4 py-0.5 px-1 rounded scale-0 transition-transform group-hover/copy:scale-100">
            Duplicar
          </span>
        </button>
        <Link
          to={`/${tour_op_id}/panel/packages/update-package/${pack.id}`}
          className="relative h-7 w-7 hover:bg-[#EEE] rounded-full transition-colors group/edit"
        >
          <img src={edit} alt="edit" className="mt-1 xl:mt-0" />
          <span className="absolute whitespace-nowrap bg-white border bottom-full -left-4 py-0.5 px-1 rounded scale-0 transition-transform group-hover/edit:scale-100">
            Editar
          </span>
        </Link>
        <button
          className="relative w-7 h-7 cursor-pointer hover:bg-[#EEE] rounded-full transition-colors group/delete"
          onClick={() => {
            openModal(pack);
            setSelectedModal("delete");
          }}
        >
          <img src={trash} alt="delete" />
          <span className="absolute whitespace-nowrap bg-white border bottom-full -left-4 py-0.5 px-1 rounded scale-0 transition-transform group-hover/delete:scale-100">
            Eliminar
          </span>
        </button>
        <button
          className="relative mx-0.5 h-7 w-7 hover:bg-[#EEE] rounded-full transition-colors group/send"
          onClick={() => {
            openModal(pack);
            setSelectedModal("email");
          }}
        >
          <MailOutlinedIcon
            sx={{
              color: "#A0AFCD",
              height: "20px",
              width: "20px",
            }}
            alt="Mail"
          />
          <span className="absolute whitespace-nowrap bg-white border bottom-full -left-4 py-0.5 px-1 rounded scale-0 transition-transform group-hover/send:scale-100">
            Enviar a Agencias
          </span>
        </button>
        <button
          to={`/${tour_op_id}/package-detail/${pack.id}`}
          className="relative mx-0.5 h-7 w-7 hover:bg-[#EEE] rounded-full transition-colors group/link"
          onClick={() =>
            copyToClipboard(`${currentHREF}/package-detail/${pack.id}`)
          }
        >
          <LinkOutlinedIcon
            sx={{
              color: "#A0AFCD",
              height: "24px",
              width: "24px",
            }}
            alt="Copiar"
          />
          <span className="absolute whitespace-nowrap bg-white border bottom-full -left-4 py-0.5 px-1 rounded scale-0 transition-transform group-hover/link:scale-100">
            Copiar Enlace
          </span>
        </button>
        <button
          className="relative h-[28px] w-[28px] hover:bg-[#EEE] rounded-full transition-colors cursor-pointer group/view"
          onClick={() => {
            openModal(pack);
            setSelectedModal("view");
          }}
        >
          <img src={eye} alt="view" />
          <span className="absolute whitespace-nowrap bg-white border -top-8 -left-0 py-0.5 px-1 rounded scale-0 transition-transform group-hover/view:scale-100">
            Ver
          </span>
        </button>
      </div>
    </div>
  );
}
