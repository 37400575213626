import axios from "axios";
import { getLocalStorageValue, getSessionStorageValue } from "./useCookie";
import { useState } from "react";
import { saveTourOperator } from "../utils/saveTourOperator";

export default function useAgencies() {
  const BASE_URL = process.env.REACT_APP_API_URL;
  const tourOP = saveTourOperator((st) => st.tourOP);
  const [agencies, setAgencies] = useState();
  const [allAgencies, setAllAgencies] = useState();
  const [agenciesStates, setAgenciesStates] = useState();

  const token =
    getLocalStorageValue("token") || getSessionStorageValue("token");

  const headers = {
    headers: { Authorization: `${token}` },
  };

  const postAgency = async ({ agency }) => {
    return await axios.post(
      BASE_URL + "v2/agencies",
      { agencies: { ...agency, tour_op_id: tourOP.id } },
      headers
    );
  };

  const getAllAgencies = async () => {
    return await axios
      .get(BASE_URL + "v2/agencies", headers)
      .then((e) => {
        setAllAgencies(e.data.response);
      })
      .catch((e) => console.log(e));
  };

  const getAgenciesByTourOperator = async (states = []) => {
    const query = states?.length > 0 ? `?state=${JSON.stringify(states)}` : "";

    return await axios
      .get(BASE_URL + `v2/agencies/${tourOP.id}${query}`, headers)
      .then((e) => {
        setAgencies(e.data.response);
      })
      .catch((err) => console.log(err));
  };

  const updateAgency = async ({ id, agency }) => {
    return await axios.patch(
      BASE_URL + `v2/agencies/${id}`,
      { agency },
      headers
    );
  };

  const deleteAgency = async ({ id }) => {
    return await axios.delete(BASE_URL + `v2/agencies/${id}`, headers);
  };

  const getStatesByAgencies = async () => {
    return await axios
      .get(BASE_URL + `v2/agencies/${tourOP.id}`, headers)
      .then((e) => {
        setAgenciesStates([
          ...new Set(
            e.data.response
              .map(({ state }) => state?.toUpperCase())
              .filter((state) => !!state)
          ),
        ]);
      })
      .catch((err) => console.log(err));
  };

  return {
    agencies,
    allAgencies,
    agenciesStates,
    postAgency,
    getAgenciesByTourOperator,
    getAllAgencies,
    getStatesByAgencies,
    updateAgency,
    deleteAgency,
  };
}
