import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { saveTourOperator } from "../utils/saveTourOperator";
import { getLocalStorageValue, getSessionStorageValue } from "./useCookie";

export const usePackages = () => {
  const BASE_URL = process.env.REACT_APP_API_URL;
  const tourOP = saveTourOperator((st) => st.tourOP);
  const [loading, setLoading] = useState(false);
  const [individualPackage, setPackage] = useState([]);
  const [packages, setPackages] = useState([]);
  const [packagesCategories, setPackagesCategories] = useState([]);

  const token =
    getLocalStorageValue("token") || getSessionStorageValue("token");

  const headers = {
    headers: { Authorization: `${token}` },
  };

  // const querys = QueryString.stringify(
  //   _.omitBy({ ...parsedHash }, (a) => a !== 0 && !a)
  // ).replace(/\s/g, "");

  const createPackage = async (pack) => {
    const response = await axios.post(BASE_URL + "v2/packages", pack, headers);
    return response;
  };

  const updatePackage = async (pack) => {
    const { id, fechaCreacion, ...restOfPackage } = pack;

    return await axios.patch(
      BASE_URL + `v2/packages/${id}`,
      {
        package: { ...restOfPackage },
      },
      headers
    );
  };

  const deletePackage = async (id) => {
    return await axios.delete(BASE_URL + `v2/packages/${id}`, headers);
  };

  const getPackages = async () => {
    setLoading(true);
    axios
      .get(BASE_URL + "v2/packages/" + tourOP.id)
      .then((e) => {
        setPackages(e.data.response);
      })
      // .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  const getPackage = async (package_id) => {
    setLoading(true);

    axios
      .get(BASE_URL + "v2/packages/detail/" + package_id)
      .then((e) => {
        setPackage(e.data.response);
      })
      // .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  const getPackagesCategories = async () => {
    try {
      const response = await axios.get(
        BASE_URL + "v2/packages/categories",
        headers
      );
      setPackagesCategories(response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getPackagesCategories();
  }, []);
  // useEffect(() => {
  //   setLoading(true);
  //   axios
  //     .get(BASE_URL + "packages?" + querys)
  //     .then((e) => {
  //       setPackages(e.data);
  //       setLoading(false);
  //     })
  //     .catch(() => setLoading(false));
  // }, [querys]);
  return {
    individualPackage,
    packages,
    loading,
    packagesCategories,
    getPackage,
    getPackages,
    createPackage,
    updatePackage,
    deletePackage,
    getPackagesCategories,
  };
};
