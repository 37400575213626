import axios from "axios";
import { useState } from "react";

export default function useGetAirports() {
  const BASE_URL = process.env.REACT_APP_API_URL;
  const [airports, setAirports] = useState([]);

  const getAirports = async () => {
    const res = await axios
      .get(BASE_URL + "v2/airports")
      .then((e) => {
        // console.log(e);
        e?.data && setAirports(e?.data?.response);
      })
      .catch((error) => console.log(error));
    if (res) {
      return res?.data || {};
    }
  };

  return { getAirports, airports: airports || [] };
}
