import { format } from "date-fns";
import { defaultDateFormat } from "../../utils/commons";
import { Link, useParams } from "react-router-dom";
import edit from "../../iconos/edit_icon.png";
import trash from "../../iconos/delete.png";

export default function InvoiceFilePayment({ payment, openModal }) {
  const { tour_op_id } = useParams();
  const { pax_group, code, payment_date, to_pay } = payment;

  return (
    <div className="grid grid-cols-10 p-1 pl-0">
      <button className="col-span-2 mr-auto py-1 px-4 my-auto bg-brightBlue text-white rounded transition-all hover:shadow-md hover:scale-105">
        Exportar
      </button>
      <p className="text-center my-auto">{pax_group}</p>
      <p className="col-span-2 text-center my-auto">{code}</p>
      <p className="text-center my-auto">
        {format(new Date(payment_date), defaultDateFormat)}
      </p>
      <p className="text-center my-auto">00,00.00</p>
      <p className="text-center my-auto">00,00.00</p>
      <p className="text-center my-auto">00,00.00</p>
      <div className="flex justify-around">
        <Link
          to={`/${tour_op_id}/panel/invoicing/update-payment/${payment.id}`}
          className="h-7 w-7 hover:bg-[#EEE] rounded-full transition-colors"
        >
          <img src={edit} alt="eliminar pago" className="w-7 h-7" />
        </Link>
        <button
          onClick={() => openModal(payment)}
          className="w-7 h-7 cursor-pointer hover:bg-[#EEE] rounded-full transition-colors"
        >
          <img src={trash} alt="eliminar pago" className="w-7 h-7" />
        </button>
      </div>
      <div />
    </div>
  );
}
