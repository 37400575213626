import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import walltrip_logo from "../../iconos/logos/walltrip.png";
import mini_walltrip_logo from "../../iconos/mini_walltrip_logo.png";
import help from "../../iconos/help.svg";
import info from "../../iconos/plus.png";
import test_user from "../../iconos/test_user.png";
// import config from "../../iconos/user.png";
import { sidebarOptions } from "./sidebarOptions";
import clsx from "clsx";
import { saveTourOperator } from "../../utils/saveTourOperator";
import SidebarPanelSearcher from "./SidebarPanelSearcher";
// import useAgencies from "../../hooks/useAgencies";
import { useEffect } from "react";

export const SidebarPanel = ({ hoverState }) => {
  const [isHovered, setIsHovered] = hoverState;
  const { tour_op_id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const tourOP = saveTourOperator((st) => st.tourOP);
  const userData = JSON.parse(
    localStorage.getItem("userData") || sessionStorage.getItem("userData")
  );
  // const { agencies, getAgenciesByTourOperator } = useAgencies();

  useEffect(() => {
    // DESCOMENTAR EL DÍA QUE SE IMPLEMENTE EL LOGO DE AGENCIA
    // getAgenciesByTourOperator();

    // REDIRECCIÓN A 'LOGIN' EN CASO DE NO TENES SESIÓN EN SUPERADMIN
    if (!userData) {
      navigate(`/${tour_op_id}/login`);
      return;
    }

    // REDIRECCIÓN A HOME EN CASO DE ROL NO PERMITIDO EN SUPERADMIN
    if (userData?.role_id === 3) {
      navigate(`/${tour_op_id}/flights`);
      return;
    }
  }, [userData]);

  // VER DÓNDE VA EL LOGO DE LA AGENCIA
  // const agency = agencies?.find(({ id }) => id === userData?.agency_id);

  const MenuLink = ({
    link: mainLink,
    label,
    icon,
    state,
    children,
    isHovered,
  }) => (
    <>
      <div className={`relative flex flex-col ${children && "gap-4"}`}>
        <NavLink to={mainLink}>
          {() => (
            <div className="flex ">
              <span
                className={clsx("flex gap-4", {
                  "opacity-60": state === "show",
                })}
              >
                <img src={icon} alt={label} className="w-6 absolute left-4" />
                <p
                  className={`transition-transform font-medium ml-[60px] ${
                    isHovered ? "scale-100" : "scale-0"
                  }`}
                >
                  {label}
                </p>
              </span>
              {/* tag */}
              {/* {state && isHovered ? <Tag type={state} /> : null} */}
            </div>
          )}
        </NavLink>
        {location.pathname.includes(mainLink.split("/").at(0)) && isHovered ? (
          <span className="flex flex-col gap-4 pl-8">
            <span className="flex flex-col gap-2">
              {children?.map(({ link, label }) => (
                <NavLink
                  key={label}
                  to={link}
                  className={({ isActive }) =>
                    isActive
                      ? ` bg-lightGray3 rounded-full whitespace-nowrap max-w-[170px] text-center p-2 font-medium text-sm transition-all delay-100 ${
                          isHovered ? "scale-y-100" : "scale-y-0"
                        }`
                      : "text-sm text-center"
                  }
                >
                  <p>{label}</p>
                </NavLink>
              ))}
            </span>
          </span>
        ) : null}
      </div>
    </>
  );

  return (
    <aside
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={clsx(
        "fixed flex flex-col transition-all duration-300 h-[100vh] mr-3 rounded-r-[25px] py-8 px-4 bg-white",
        {
          "w-[92px]": !isHovered,
        },
        {
          "w-[237px]": isHovered,
        }
      )}
    >
      <Link to={`/${tour_op_id || "walltrip"}/flights`}>
        <div>
          {isHovered ? (
            <img
              src={walltrip_logo}
              alt="walltrip"
              className="w-40 h-14 mx-auto"
            />
          ) : (
            <img
              src={mini_walltrip_logo}
              alt="walltrip"
              className="w-16 h-14 mx-auto"
            />
          )}
        </div>
      </Link>

      <section className="flex h-full flex-col justify-between">
        {/* options links */}
        <ul className="flex flex-col gap-8 my-16">
          <SidebarPanelSearcher isHovered={isHovered} />
          {sidebarOptions.map((option, index) => (
            <li className="relative" key={index}>
              {location.pathname.includes(option.link) ? (
                <div className="absolute -left-4 bg-[#242423] h-6 w-[5px] rounded-r-[6px]" />
              ) : null}
              <MenuLink {...option} isHovered={isHovered} />
            </li>
          ))}
        </ul>
        <div className="flex flex-col justify-between">
          <ul className="flex flex-col gap-8">
            {tourOP?.logo?.length > 2 && (
              <li>
                <img
                  src={tourOP?.logo}
                  alt={tourOP?.name}
                  className="max-h-12 mx-auto"
                />
              </li>
            )}
            <li className="flex flex-col gap-8">
              <MenuLink
                link={"config/faq"}
                label={"Ayuda"}
                icon={help}
                isHovered={isHovered}
              />
              {/* <MenuLink link={"config"} label={"Perfil"} icon={config} /> */}
            </li>
            <li className="flex flex-col gap-1">
              <p className="text-xs text-[#333533] ml-5">V1.1</p>
              <div className="w-full h-[1px] my-1 mt-3 mb-6 bg-[#CECECE]" />
            </li>
          </ul>
          {/* user */}
          <div className="flex h-[52px] gap-3 mt-4 group/options">
            <img
              src={test_user}
              alt="user_image"
              className="w-[56px] h-[52px] mt-2 absolute left-4 bottom-8"
            />
            <p
              className={`grid items-center text-xl font-medium transition-all delay-75 origin-left ml-16 ${
                isHovered ? "scale-x-100" : "scale-x-0"
              }`}
            >
              {userData?.username || "Usuario"}
            </p>

            <div className="absolute rounded-lg p-4 pl-3 bg-white shadow-md scale-0 left-[200px] bottom-4 grid transition-transform group-hover/options:scale-100">
              {tour_op_id === "walltrip" && (
                <Link
                  to={"profile/admin"}
                  className={`pl-1 py-1.5 whitespace-nowrap transition-all duration-250 ${
                    location.pathname.includes("profile/admin")
                      ? "border-l border-black"
                      : "border-l border-transparent hover:border-gray/50"
                  }`}
                >
                  Operadoras
                </Link>
              )}
              <Link
                to={"profile/my-tour-operator"}
                className={`pl-1 py-1.5 whitespace-nowrap transition-all duration-250 ${
                  location.pathname.includes("my-tour-operator")
                    ? "border-l border-black"
                    : "border-l border-transparent hover:border-gray/50"
                }`}
              >
                Operadora
              </Link>
              {userData?.agency_id !== 1 && (
                <Link
                  to={"profile/my-agency"}
                  className={`pl-1 py-1.5 whitespace-nowrap transition-all duration-250 ${
                    location.pathname.includes("my-agency")
                      ? "border-l border-black"
                      : "border-l border-transparent hover:border-gray/50"
                  }`}
                >
                  Agencia
                </Link>
              )}
              <Link
                to={"profile/fees"}
                className={`pl-1 py-1.5 whitespace-nowrap transition-all duration-250 ${
                  location.pathname.includes("fees")
                    ? "border-l border-black"
                    : "border-l border-transparent hover:border-gray/50"
                }`}
              >
                Fees & Comisiones
              </Link>
              {userData?.agency_id === 1 && (
                <Link
                  to={"profile/agencies"}
                  className={`pl-1 py-1.5 whitespace-nowrap transition-all duration-250 ${
                    location.pathname.includes("agencies")
                      ? "border-l border-black"
                      : "border-l border-transparent hover:border-gray/50"
                  }`}
                >
                  Agencias
                </Link>
              )}
              <Link
                to={"profile/users"}
                className={`pl-1 py-1.5 whitespace-nowrap transition-all duration-250 ${
                  location.pathname.includes("users")
                    ? "border-l border-black"
                    : "border-l border-transparent hover:border-gray/50"
                }`}
              >
                Usuarios
              </Link>
              {/* <Link
                to={"profile/design"}
                className={`pl-1 py-1.5 whitespace-nowrap transition-all duration-250 ${
                  location.pathname.includes("design")
                    ? "border-l border-black"
                    : "border-l border-transparent hover:border-gray/50"
                }`}
              >
                Diseño Web
              </Link> */}
            </div>
          </div>
        </div>
      </section>
    </aside>
  );
};
