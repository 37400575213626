import SEO from "../../utils/SEO";
import { useToggleVisibility } from "../../hooks/useToggleVisibility";
import { ModalGeneric } from "../../utils/ModalGeneric";
import { Link, useParams } from "react-router-dom";
import { useEffect } from "react";
import FormAddAgency from "./FormAddAgency";
import useAgencies from "../../hooks/useAgencies";
import AgenciesTable from "./AgenciesTable";

export const PanelAgencies = () => {
  const { tour_op_id } = useParams();
  const [isVisible, toggleVisibility] = useToggleVisibility();
  const { agencies, deleteAgency, getAgenciesByTourOperator } = useAgencies();

  useEffect(() => {
    getAgenciesByTourOperator();
  }, [tour_op_id]);

  const closeModalAddAgency = () => {
    toggleVisibility();
    getAgenciesByTourOperator();
  };

  return (
    <>
      <SEO title={"Administrador de Agencias"} />
      <main className="relative font-body max-w-7xl mx-auto">
        <section className="my-8">
          <header className="flex justify-between items-center">
            <h2 className="text-darkBlue font-[700] text-3xl">Agencias</h2>
            <button
              className="bg-darkBlue text-white font-semibold py-2 px-4 rounded"
              onClick={toggleVisibility}
            >
              Agregar Agencia
            </button>
          </header>

          <AgenciesTable
            useAgencies={{ agencies, deleteAgency, getAgenciesByTourOperator }}
          />
        </section>

        <ModalGeneric show={isVisible} toggle={toggleVisibility}>
          <FormAddAgency closeModal={closeModalAddAgency} />
        </ModalGeneric>

        <Link
          to={`/${tour_op_id}/panel/profile/fees`}
          className="absolute left-0 mt-8 text-center text-xl px-8 py-4 bg-brightBlue text-white font-semibold rounded"
        >
          Anterior
        </Link>
        <Link
          to={`/${tour_op_id}/panel/profile/users`}
          className="absolute right-0 mt-8 text-center text-xl px-8 py-4 bg-brightBlue text-white font-semibold rounded"
        >
          Siguiente
        </Link>
      </main>
    </>
  );
};
