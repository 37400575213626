import arrow from "../../iconos/chevron_down_gray.png";
import { AIRLINES_DICTIONARY } from "../../utils/dictionaries/airlinesDictionary";
import { SCALES_DICTIONARY } from "../../utils/dictionaries/scalesDIctionary";
import { translateToHex } from "../SearchFilters";

const FILTERS_BY_CHECKBOX = [
  {
    label: "Vuelos Directos",
    key: "directo",
  },
  // {
  //   label: "Sin escala en USA",
  //   key: "sin_USA",
  // },
  {
    label: "Equipaje Incluído",
    key: "equipaje",
  },
  {
    label: "Clase Ejecutiva",
    key: "business",
  },
];

const handleDisplay = (item) => {
  const body = document.getElementById(`${item}-body`);
  const arrow = document.getElementById(`${item}-arrow`);

  body.classList.toggle("scale-y-0");
  body.classList.toggle("h-0");
  arrow.classList.toggle("-rotate-180");
  arrow.classList.toggle("rotate-0");
};

export default function SearchQuotesFilters({
  filterState,
  showFilters,
  minmaxDuration,
  register,
}) {
  const [filters, setFilters] = filterState;
  const [min, max] = minmaxDuration;

  const handleFilters = (key) => {
    setFilters((curr) => {
      return {
        ...curr,
        [key]: !curr[key],
      };
    });
  };

  const handleChecks = (evt, filter) => {
    setFilters((curr) => {
      return {
        ...curr,
        [filter]: {
          ...curr[filter],
          [evt.target.id]: evt.target.checked,
        },
      };
    });
  };

  const handleRange = (evt) => {
    setFilters((curr) => {
      return {
        ...curr,
        maxDuration: evt.target.value,
      };
    });
  };

  return (
    <section
      className={`relative flex items-center justify-end gap-16 transition-all origin-right ${
        showFilters ? "scale-100 h-fit mt-3 top-0" : `scale-0 h-0 top-0`
      }`}
    >
      <div className="flex gap-4 items-center">
        {FILTERS_BY_CHECKBOX.map((check) => {
          return (
            <label
              key={check.key}
              htmlFor={check.key}
              className="flex gap-2 items-center"
            >
              <input
                type="checkbox"
                name={check.label}
                id={check.key}
                checked={filters[check.key]}
                onChange={() => handleFilters(check.key)}
              />
              <span className="text-white">{check.label}</span>
            </label>
          );
        })}

        <label className="flex gap-2 items-center bg-[#EFEFEF] rounded-md p-1 px-2">
          <p className="font-medium">Cabina</p>
          <select
            name="cabin"
            id="cabin"
            {...register("travelClass")}
            className="bg-transparent border-b"
            defaultValue={""}
          >
            <option value="">Todas</option>
            <option value="ECONOMY">Económica</option>
            <option value="BUSINESS">Ejecutiva</option>
          </select>
        </label>

        <div className="relative">
          <section
            onClick={() => handleDisplay("duration")}
            id="duration-header"
            className="cursor-pointer"
          >
            <header className="flex gap-2 items-center py-1 px-2 rounded-md bg-lightBlue">
              <h2 className="font-low">Duración del vuelo</h2>
              <img
                src={arrow}
                alt="arrow"
                className="w-4 h-2 rotate-0 transition-all"
                id="duration-arrow"
              />
            </header>
          </section>
          <section
            className="absolute top-10 scale-y-0 h-0 rounded-md bg-white w-full py-1 px-2 mb-2 grid gap-2 overflow-y-hidden transition-all duration-300 origin-top"
            id="duration-body"
          >
            <label htmlFor="maxDuration">
              {translateToHex(min > 500 ? 0 : min).toFixed(2)}hs -{" "}
              {translateToHex(
                filters.maxDuration === 0 ? 100 : filters.maxDuration
              ).toFixed(2)}
              hs
            </label>
            <input
              type="range"
              max={max === 0 ? 100 : max}
              min={min === Infinity ? 0 : min}
              id="maxDuration"
              step={0.01}
              value={filters.maxDuration}
              onChange={handleRange}
            />
          </section>
        </div>

        <div className="relative">
          <section
            onClick={() => handleDisplay("airlines")}
            id="airlines-header"
            className="cursor-pointer"
          >
            <header className="flex gap-2 items-center py-1 px-2 rounded-md bg-lightBlue">
              <h2 className="font-low">Aerolíneas</h2>
              <img
                src={arrow}
                alt="arrow"
                className="w-4 h-2 rotate-0 transition-all"
                id="airlines-arrow"
              />
            </header>
          </section>
          <section
            className="absolute top-10 scale-y-0 h-0 rounded-md bg-white w-60 py-1 px-2 mb-2 grid gap-2 overflow-y-hidden transition-all duration-200 origin-top"
            id="airlines-body"
          >
            {Object.keys(filters.airlines).map((el) => {
              const data = AIRLINES_DICTIONARY[el] ?? null;
              return (
                <div className="flex" key={el}>
                  <input
                    type="checkbox"
                    id={el}
                    name="airlines"
                    checked={filters.airlines[el]}
                    onChange={(evt) => handleChecks(evt, "airlines")}
                  />
                  <label htmlFor={el} className="flex gap-2 items-center">
                    <img
                      src={data ? data.img : ""}
                      alt={data ? data.label : el}
                      className="w-8 h-4 mr-1 ml-2"
                    />
                    <p className="">{data ? data.label : el}</p>
                  </label>
                </div>
              );
            })}
          </section>
        </div>

        <div className="relative">
          <section
            onClick={() => handleDisplay("scales")}
            id="scales-header"
            className="cursor-pointer"
          >
            <header className="flex gap-2 items-center py-1 px-2 rounded-md bg-lightBlue">
              <h2 className="font-low">Escalas máximas</h2>
              <img
                src={arrow}
                alt="arrow"
                className="w-4 h-2 rotate-0 transition-all"
                id="scales-arrow"
              />
            </header>
          </section>
          <section
            className="absolute top-10 scale-y-0 h-0 rounded-md bg-white w-full py-1 px-2 mb-2 grid gap-2 overflow-y-hidden transition-all duration-300 origin-top"
            id="scales-body"
          >
            {Object.keys(filters.scales).map((el) => (
              <div className="flex items-center gap-2" key={el}>
                <input
                  type="checkbox"
                  key={SCALES_DICTIONARY[el].key}
                  id={el}
                  name="scales"
                  checked={filters.scales[el]}
                  onChange={(evt) => handleChecks(evt, "scales")}
                />
                <label htmlFor={el}>{SCALES_DICTIONARY[el].label}</label>
              </div>
            ))}
          </section>
        </div>
      </div>
    </section>
  );
}
