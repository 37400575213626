import { format } from "date-fns";
import { Link, useParams } from "react-router-dom";
import sun from "../../iconos/sun.png";
// import moon from "../../iconos/moon.png";
import iconoLuna from '../../iconos/moon_.svg';
import IconInfo from '../../iconos/info.png';
import calendar from '../../iconos/icono_calendar.png';

export default function RecommendationCardPackages({ pack, currencies }) {
  const {
    title,
    descode,
    departure_date,
    return_date,
    price,
    tax_package,
    portrait,
    currency_type_id,
  } = pack;

  const { tour_op_id } = useParams();
  const departureMonth =
    departure_date.split("T")[0].split("-", 2).join("-") + "-01";

  const href = `/${tour_op_id}/search-packages?destinationLocationCode=${descode}&departureDate=${departureMonth}&adults=1`;

  const nights =
    (new Date(return_date) - new Date(departure_date)) / 1000 / 60 / 60 / 24;
    
  const translateCurrency = (id) =>
    currencies?.find((currency) => currency.id === id)?.symbol;

  return (
    <Link
      to={href}
      className="rounded-[20px] bg-[#EFEFEF] max-w-[250px] p-2 shadow-[0px_8.111881256103516px_12.16782283782959px_4.055940628051758px_#00000026] relative hover:scale-105 transition-transform border-[#B8B8B8] hover:border-px hover:border-black">
      <section className="bg-[#EFEFEF] rounded-[20px] w-full text-center">
        <picture className="w-[220px]">
          <img
            className="w-full rounded-[20px] h-[190px]"
            src={portrait}
            alt={title}
          />
        </picture>
        <section className="p-2 flex flex-col gap-2 justify-between mt-2">
          <section className="bg-[#EFEFEF] flex flex-col items-center justify-center w-full pb-2">
            <h2 className="font-bold text-xl text-[#242423]">
              {title?.toUpperCase()}
            </h2>
            <p className="capitalize mt-2 text-base">{descode?.toLowerCase()}</p>
          </section>
          <section className="relative flex flex-col items-center justify-between">
            <div className="flex gap-5 items-center">
              <span className="relative bg-[#FAF2AE] h-7 flex justify-end rounded-full py-0.5 px-4 w-[80px]">
                <span className="absolute flex items-center h-7 justify-center left-0 top-0 rounded-full px-4 bg-[#EAD40B]">
                  <img src={sun} alt="sun" className="w-4" />
                </span>
                <p className="bottom-1.5 w-2 font-bold text-ms">{nights + 1}</p>
              </span>
              <span className="relative bg-[#1E0F5033] h-7 flex justify-end rounded-full py-0.5 px-4 w-[80px]">
                <span className="absolute flex items-center h-7 justify-center left-0 top-0 rounded-full px-4 bg-[#120250BD]">
                  <img src={iconoLuna} alt="sun" className="w-4" />
                </span>
                <p className="bottom-1.5 w-2 font-bold text-ms">{nights}</p>
              </span>
            </div>
            <span className="flex items-center gap-2 mt-4">
              <img src={calendar} alt="icono_calendar" className="w-[12px] h-[12px] p-0" />
              <p className="flex flex-row font-bold text-sm">{format(new Date(departure_date), "dd/MM/yyyy")}</p>-
              <p className="font-bold text-sm">{format(new Date(return_date), "dd/MM/yyyy")}</p>
            </span>
          </section>
        </section>
        <section className="bg-white hover:bg-[#091832] text-center rounded-[15px] w-full p-2 mt-2 border-[#B8B8B8] hover:border-px hover:border-solid hover:text-white">
            <p className="font-light text-base">Precio por persona:</p>
            <div className="flex flex-row items-center justify-center">
              <img className='h-[10px] w-[10px] mr-[5px] justify-center' src={IconInfo} alt="img-info" />
              <p className="text-[14px] font-bold">
                {translateCurrency(currency_type_id) ?? "USD"}{" "}
                {Number(price) + Number(tax_package)}
              </p>
            </div>
          </section>
      </section>
    </Link>
  );
}
