import search from "../../iconos/search.png";
import { VersionHeader } from "../staff/versions/ControlVersions";
import { useState } from "react";
import { Link } from "react-router-dom";
import { WhatsApp } from "@mui/icons-material";
import SEO from "../../utils/SEO";
import Pagination from "../../utils/Pagination";
import { useFunctionalities } from "../../hooks/useFunctionalities";
import { useToggleVisibility } from "../../hooks/useToggleVisibility";
import { ModalGeneric } from "../../utils/ModalGeneric";
import CreateFeature from "./CreateFunctionality";
import Versions from "./components/Versions";
import { useVersions } from "../../hooks/useVersion";
import { Toaster, toast } from "react-hot-toast";
import FunctionalitiesTableRow from "./FunctionalitiesTableRow";
import EditFeature from "./EditFunctionality";

export const Faq = () => {
  const [wspText, setWspText] = useState("");
  const [inputSearch, setInputSearch] = useState("");
  const [page, setPage] = useState(0);
  const [selectedModal, setSelectedModal] = useState("");
  const [selectedFunctionality, setSelectedFunctionality] = useState(null);
  const [isVisible, toggleVisibility] = useToggleVisibility();
  const user = JSON.parse(
    localStorage.getItem("userData") || sessionStorage.getItem("userData")
  );

  const {
    functionalities,
    getFunctionalities,
    postFunctionality,
    deleteFunctionality,
    voteFunctionality,
    editFunctionality,
  } = useFunctionalities();

  const { versions } = useVersions();

  const openModal = (functionality) => {
    setSelectedFunctionality(functionality);
    toggleVisibility();
  };

  const createFunctionality = (functionality) => {
    toast.promise(
      postFunctionality({ functionality }).then(() => {
        getFunctionalities();
        toggleVisibility();
      }),
      {
        loading: "Cargando...",
        success: <b>Funcionalidad cargada con éxito</b>,
        error: <b>Hubo un error, intenta de nuevo.</b>,
      }
    );
  };

  const handleDeleteFunctionality = () => {
    deleteFunctionality(selectedFunctionality).finally(() => {
      getFunctionalities();
      toggleVisibility();
    });
  };

  const actionVote = (id, votesquantity, option) => {
    const newVotesQuantity = option ? votesquantity + 1 : votesquantity - 1;

    voteFunctionality({
      functionality_id: id,
      votesquantity: newVotesQuantity,
    }).then(() => getFunctionalities());
  };

  const handleEditFunctionality = (editedFunctionality) => {
    const { id, ...restOfFunctionality } = editedFunctionality;
    editFunctionality({
      functionality_id: id,
      functionality: restOfFunctionality,
    })
      .then((e) => {
        getFunctionalities();
        toggleVisibility();
      })
  };

  return (
    <>
      <SEO title={"Ayuda"} />
      <div>
        <Toaster />
      </div>
      <main className="mx-auto w-full mt-10 font-body">
        {/* Header Functionalities */}
        <header className="flex justify-between items-center mb-4">
          <div>
            <h1 className="font-[700] text-darkBlue text-3xl">
              Nuevas Funcionalidades
            </h1>
            <span className="text-darkBlue">Dashboard Overview</span>
          </div>
          <div className="flex items-center w-[604px] justify-end">
            <button
              onClick={() => {
                toggleVisibility();
                setSelectedModal("post");
              }}
              className="button bg-darkBlue text-white whitespace-nowrap px-2 hover:shadow-md transition-all"
            >
              Agregar Funcionalidad
            </button>
          </div>
        </header>
        {/* Table */}
        <section className="p-4 border-2 border-gray2 bg-white rounded-lg">
          <div className="mb-4 border bg-lightBlue2 border-[#CDCDCD] outline-none w-[368px] h-12 rounded-xl relative">
            <img
              src={search}
              alt="Filtrar por nombre de gestion"
              className="w-4 h-4 absolute left-3 top-4"
            />
            <input
              onChange={(e) => setInputSearch(e.target.value)}
              value={inputSearch}
              placeholder="Buscar por nombre"
              className="w-full pl-10 outline-none h-full rounded-xl bg-transparent"
            />
          </div>
          <header className="bg-lightBlue grid grid-flow-row grid-cols-12 gap-8 py-2 px-8 rounded-lg">
            <h4 className="font-medium text-brightBlue col-span-2">Nombre</h4>
            <h4 className="font-medium text-brightBlue mx-auto col-span-2">
              Votar
            </h4>
            <h4 className="font-medium text-brightBlue col-span-6">
              Descripción
            </h4>
            <h4 className="font-medium text-brightBlue mx-auto">Estado</h4>
            <h4 className="font-medium text-brightBlue mx-auto whitespace-nowrap">
              Video
            </h4>
          </header>

          {functionalities ? (
            functionalities
              .filter((element) => {
                return inputSearch?.length
                  ? element.name
                      .toLowerCase()
                      .includes(inputSearch?.toLowerCase())
                  : true;
              })
              ?.map((functionality, index) => (
                <FunctionalitiesTableRow
                  key={functionality.id}
                  functionality={functionality}
                  index={index}
                  actionVote={actionVote}
                  openModal={openModal}
                  setSelectedModal={setSelectedModal}
                  isAdmin={user?.id === 1}
                />
              ))
          ) : (
            <div>
              <p className="p-2.5">No se encontraron registros.</p>
            </div>
          )}
        </section>

        <ModalGeneric show={isVisible} toggle={toggleVisibility}>
          {selectedModal === "post" && (
            <CreateFeature createFunctionality={createFunctionality} />
          )}
          {selectedModal === "delete" && (
            <div className="flex flex-col items-center gap-2">
              <h2>
                ¿Estás seguro de eliminar <b>{selectedFunctionality}</b>?
              </h2>
              <div className="flex justify-center gap-2">
                <button
                  className="py-1 px-2 rounded font-medium border border-gray2"
                  onClick={toggleVisibility}
                >
                  Cancelar
                </button>
                <button
                  className="py-1 px-2 rounded font-medium border text-white border-red-500 bg-red-500"
                  onClick={handleDeleteFunctionality}
                >
                  Eliminar
                </button>
              </div>
            </div>
          )}
          {selectedModal === "edit" && (
            <EditFeature
              editFunctionality={handleEditFunctionality}
              functionality={selectedFunctionality}
            />
          )}
        </ModalGeneric>

        <Pagination
          items={functionalities}
          itemsPerPage={5}
          page={page}
          setPage={setPage}
        />

        {/* State Product Title */}
        <section className="flex justify-between mt-14">
          <div>
            <h1 className="font-[700] text-darkBlue text-3xl">
              Estado del Producto
            </h1>
            <span className="text-darkBlue">Dashboard Overview</span>
          </div>
        </section>

        {/* Versions */}
        <section className="my-8 w-full">
          <div className="r-0 flex justify-end">
            <VersionHeader />
          </div>
          {versions && versions.length > 0 ? (
            versions?.map((version) => (
              <Versions key={version.id} version={version} />
            ))
          ) : (
            <span>No hay registros de versiones.</span>
          )}
        </section>

        {/* Send comments */}
        <section className="flex justify-between gap-4 mb-12">
          <div className="flex flex-col gap-4 w-3/4">
            <input
              onChange={(e) => setWspText(e.target.value)}
              className="border border-[#D9D9D9] h-full outline-none rounded-md px-4 bg-white"
              placeholder="Dejanos tus comentarios"
            />
          </div>
          <Link
            className="w-fit whitespace-nowrap flex justify-between"
            to={`http://wa.me/3512051784?text=${wspText}`}
          >
            <p className="h-full bg-[#28B15C] px-4 text-white flex items-center gap-2 justify-center rounded-lg w-4/5 font-semibold">
              Escribinos por WhatsApp
            </p>
            <WhatsApp
              alt="enviar por wsahp"
              sx={{
                width: "50px",
                height: "50px",
                backgroundColor: "#28B15C",
                borderRadius: "8px",
                color: "white",
                padding: "5px",
              }}
            />
          </Link>
        </section>
      </main>
    </>
  );
};
