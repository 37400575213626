import { useEffect, useState } from "react";
import useAgencies from "../../hooks/useAgencies";
import useSendEmail from "../../hooks/useSendEmail";
import { Toaster, toast } from "react-hot-toast";
import { generateEmailBody } from "./generateEmailBody";
import { useParams } from "react-router-dom";
import arrow from "../../iconos/chevron_down_gray.png";

const handleDisplay = (item) => {
  const body = document.getElementById(`${item}-body`);
  const arrow = document.getElementById(`${item}-arrow`);
  body.classList.toggle("scale-y-0");
  body.classList.toggle("h-0");
  arrow.classList.toggle("-rotate-180");
  arrow.classList.toggle("rotate-0");
};

const generateEmailBodyForOp = (
  selectedState,
  numberOfEmailsSent,
  subject,
  selectedPackage,
  agenciesEmail,
  tour_op_id
) => {
  const currentHREF = window.location.href.split("/").slice(0, -4).join("/");
  const checkedRegions = Object.entries(selectedState)
    .filter(([_, checked]) => checked)
    .map(([region]) => (region === "-" ? "Sin Región" : region));

  return `<h3>Tu envío está en marcha.</h3>
<p>Cantidad de envíos: ${numberOfEmailsSent} (Recuento)</p>
<p>Asunto: ${subject}</p>
<p>Paquete: ${selectedPackage?.id}</p>
<p>Link: ${`${currentHREF}/${tour_op_id}/package-detail/${selectedPackage?.id}`}</p>
<p>Regiones: ${
    checkedRegions.length > 0 ? checkedRegions.join(", ") : "Todas"
  }</p>
<p>Lista de emails: ${agenciesEmail.join(", ")}
`;
};

export default function ModalSendEmail({
  selectedPackage,
  tourOP,
  closeModal,
}) {
  const { tour_op_id } = useParams();
  const {
    agencies,
    agenciesStates,
    getAgenciesByTourOperator,
    getStatesByAgencies,
  } = useAgencies();
  const { sendEmail } = useSendEmail();
  const [subject, setSubject] = useState("");
  const [checkAllAgencies, setCheckAllAgencies] = useState(true);
  const [checkedAgencies, setCheckedAgencies] = useState({});
  const [selectedState, setSelectedState] = useState();
  const [buttonType, setButtonType] = useState("button");
  const [firstClick, setFirstClick] = useState(true);

  useEffect(() => {
    getAgenciesByTourOperator();
    getStatesByAgencies();
  }, []);

  useEffect(() => {
    if (!agencies) return;

    setSelectedState(
      agenciesStates.reduce(
        (obj, item) => ({
          ...obj,
          [item]: false,
        }),
        {}
      )
    );
  }, [agenciesStates]);

  useEffect(() => {
    if (!agencies) return;

    const states = Object.entries(selectedState)
      .filter(([_, checked]) => checked)
      .map(([region]) => region);

    getAgenciesByTourOperator(states);
  }, [selectedState]);

  useEffect(() => {
    if (!agencies) return;

    // resetear selectedStates cuando !!checkAllAgencies
    if (checkAllAgencies) {
      setSelectedState((prevState) =>
        Object.keys(prevState).reduce(
          (acc, key) => ({ ...acc, [key]: false }),
          {}
        )
      );
    }
  }, [checkAllAgencies]);

  useEffect(() => {
    if (!agencies) return;

    // resetear checkedAgencies a todo true o todo false según checkedAllAgencies
    setCheckedAgencies(
      agencies.reduce(
        (obj, item) => ({
          ...obj,
          [item.id]: checkAllAgencies
            ? true
            : Object.values(selectedState || {}).some((checked) => !!checked),
        }),
        {}
      )
    );
  }, [checkAllAgencies, agencies]);

  const handleCheckboxChange = (checkObject, id) => {
    checkObject((prevCheckedItems) => {
      return {
        ...prevCheckedItems,
        [id]: !prevCheckedItems[id],
      };
    });
  };

  const sendAutomaticEmail = (e) => {
    e.preventDefault();

    const agenciesEmail = agencies
      ?.filter((item) => checkedAgencies[item.id])
      ?.map(({ email }) => email);

    toast.promise(
      sendEmail({
        type: tourOP.id === 1 ? "Gmail" : "",
        tour_op_id: tourOP.id,
        options: {
          from: tourOP?.email,
          bcc: agenciesEmail,
          subject,
          html: generateEmailBody(selectedPackage, tourOP, tour_op_id),
        },
      })
        .then((e) => {
          const numberOfEmailsSent =
            e?.data?.results?.[0]?.info?.accepted?.length;

          if (!numberOfEmailsSent)
            throw new Error("No se pudieron enviar mails.");

          sendEmail({
            type: "Gmail",
            tour_op_id: 1,
            options: {
              from: "wearewalltrip@gmail.com",
              bcc: [tourOP.id === 1 ? tourOP?.email : "mkt@newplaces.com.ar"],
              subject: "Envío #1 OK",
              html: generateEmailBodyForOp(
                selectedState,
                numberOfEmailsSent,
                subject,
                selectedPackage,
                agenciesEmail,
                tour_op_id
              ),
            },
          });
          setTimeout(() => closeModal(), 5500);
        })
        .catch((e) => {
          console.log(e);
          throw new Error(e);
        }),
      {
        loading: "Enviando...",
        success: <b>Mail enviado con éxito.</b>,
        error: <b>Hubo un error, intenta de nuevo.</b>,
      }
    );
  };

  return (
    <section className="min-w-[24rem] max-h-[80vh] overflow-y-scroll">
      <div>
        <Toaster
          toastOptions={{
            success: {
              duration: 5000,
            },
          }}
        />
      </div>
      <h1 className="text-center text-xl font-semibold mb-4">
        Mandar Email a Agencias
      </h1>
      <form
        className="relative w-full flex flex-col items-center gap-2"
        onSubmit={sendAutomaticEmail}
      >
        <label htmlFor="subject" className="w-4/5">
          <input
            placeholder="Asunto para el mail"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            className="addPackInput w-full"
          />
        </label>

        {selectedPackage?.flyer && (
          <img
            src={selectedPackage?.flyer}
            alt="flyer"
            className="max-h-[500px] max-w-[16rem] min-w-[8rem]"
          />
        )}

        <label className="flex items-center gap-1">
          <input
            type="checkbox"
            checked={checkAllAgencies}
            onChange={() => setCheckAllAgencies(!checkAllAgencies)}
          />
          Enviar a todas las agencias
        </label>

        {!checkAllAgencies && agencies?.length > 0 && (
          <div className="grid gap-1 w-4/5">
            <section
              onClick={() => handleDisplay("state")}
              id="state-header"
              className="cursor-pointer"
            >
              <header className="flex justify-between items-center py-2 px-4 rounded-md bg-lightBlue/80">
                <h2 className="font-low">Región</h2>
                <img
                  src={arrow}
                  alt="arrow"
                  className="w-4 h-2 rotate-0 transition-all"
                  id="state-arrow"
                />
              </header>
            </section>
            <section
              className="bg-lightBlue/20 rounded-md py-2 px-4 mb-2 grid gap-2 overflow-y-hidden transition-all duration-200 origin-top scale-y-0 h-0"
              id="state-body"
            >
              {selectedState &&
                Object.entries(selectedState).map(([name, checked]) => {
                  return (
                    <div className="flex gap-2 items-center" key={name}>
                      <input
                        type="checkbox"
                        id={name}
                        name="state"
                        checked={checked}
                        onChange={() =>
                          handleCheckboxChange(setSelectedState, name)
                        }
                      />
                      <label
                        htmlFor={name}
                        className="flex gap-2 items-center capitalize"
                      >
                        <p>
                          {name === "-" ? "Sin región" : name.toLowerCase()}
                        </p>
                      </label>
                    </div>
                  );
                })}
            </section>

            {agencies.map((item) => (
              <label
                key={item.id}
                htmlFor={`checkbox-${item.id}`}
                className="flex items-center gap-1 capitalize"
              >
                <input
                  type="checkbox"
                  id={`checkbox-${item.id}`}
                  name={item.name}
                  checked={checkedAgencies[item.id] || false}
                  onChange={() =>
                    handleCheckboxChange(setCheckedAgencies, item.id)
                  }
                />
                {item.name.toLowerCase()}
              </label>
            ))}
          </div>
        )}

        <button
          type={buttonType}
          className={`button text-white font-semibold transition-colors ${
            agencies?.length > 20 && !checkAllAgencies && "sticky bottom-2"
          } ${buttonType === "submit" ? "bg-brightGreen" : "bg-brightBlue"}`}
          onClick={() =>
            firstClick &&
            toast((t) => (
              <span>
                ¿Estás seguro que este es tu primer envío del día?
                <div className="flex gap-2">
                  <button
                    className="button border border-darkBlue w-1/3"
                    onClick={() => toast.dismiss(t.id)}
                  >
                    No.
                  </button>
                  <button
                    type="submit"
                    className="button bg-darkBlue text-white w-2/3"
                    onClick={() => {
                      toast.dismiss(t.id);
                      setButtonType("submit");
                      setFirstClick(false);
                    }}
                  >
                    Sí, no hice envíos hoy.
                  </button>
                </div>
              </span>
            ))
          }
        >
          Enviar Email
        </button>
      </form>
    </section>
  );
}
