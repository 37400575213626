import { useEffect, useState } from "react";
import valija from "../../iconos/equipaje.png";
import { useSelectFlights } from "./useSelectFlights";

const getInitialValues = (data) => {
  const initialCheckedOptionsValue = {};
  data.map((segment) => (initialCheckedOptionsValue[segment.segmentId] = []));
  return initialCheckedOptionsValue;
};

export default function ModalAddOptions({ thisOption }) {
  const setOriginal = useSelectFlights((st) => st.setOriginal);
  const { fareDetailsBySegment } = thisOption?.travelerPricings?.[0];
  const [checkedOptions, setCheckedOptions] = useState(
    getInitialValues(fareDetailsBySegment)
  );
  const hasAmenities = fareDetailsBySegment.some((segment) =>
    segment.hasOwnProperty("amenities")
  );

  useEffect(() => {
    setOriginal({ ...thisOption, selectedAmenities: checkedOptions });
  }, [checkedOptions]);

  const handleChecks = (amenity, e) => {
    const [segmentId] = e.target.name.split("-");

    setCheckedOptions((curr) => {
      let thisArr = curr?.[segmentId];
      if (thisArr.includes(amenity)) {
        thisArr = thisArr.filter((am) => am !== amenity);
      } else {
        thisArr = thisArr.concat(amenity);
      }

      return {
        ...curr,
        [segmentId]: thisArr,
      };
    });
  };

  return (
    <section className="p-2 rounded-xl text-center max-h-[75vh] overflow-y-auto">
      {!hasAmenities ? (
        <h1>Esta aerolínea no ofrece opcionales</h1>
      ) : (
        <section>
          <h1 className="text-2xl mb-4">Opcionales Disponibles</h1>
          {fareDetailsBySegment.map((segment, index) => {
            return (
              <section key={segment.segmentI-index} className="my-2">
                <h1 className="bg-black p-2 rounded-md text-white mb-1">
                  Vuelo {index + 1}
                </h1>
                {segment.amenities.map((amenity, i) => {
                  if (amenity.amenityType !== "BAGGAGE") return <></>;

                  return (
                    <OptionalBag
                      key={`${segment.segmentId}-${i}`}
                      name={`${segment.segmentId}-${index}`}
                      flight={{ ...segment, amenities: amenity }}
                      handleChecks={handleChecks}
                    />
                  );
                })}
              </section>
            );
          })}
        </section>
      )}
    </section>
  );
}

const OptionalBag = ({ flight, name, handleChecks }) => {
  const { brandedFare, amenities } = flight;

  return (
    <label className="flex gap-4 mx-auto my-0.5">
      <p>{brandedFare}</p>
      <div className="flex gap-2 w-full">
        <img src={valija} alt="valija" className="h-5" />
        <p className="capitalize">{amenities.description.toLowerCase()}</p>
      </div>
      <p>{flight.class}</p>
      <button className="bg-brightBlue px-2 rounded-full text-white text-xs whitespace-nowrap">
        + $0,00
      </button>
      <input
        type="checkbox"
        name={name}
        onChange={(e) => handleChecks(amenities.description, e)}
      />
    </label>
  );
};
