import arrow from "../../iconos/chevron_down_gray.png"

export default function TableHeader({ title, handleDisplay }) {
  return (
    <header
      className="datailTableRow bg-lightBlue cursor-pointer"
      onClick={() => handleDisplay(title)}
    >
      <h2 className="uppercase font-medium text-xl">{title}</h2>
      <img
        id={title +"-arrow"}
        src={arrow}
        alt="arrow"
        className="w-4 -rotate-180 transition-all"
      />
    </header>
  );
}
