import trash from "../../iconos/delete.png";
import edit from "../../iconos/edit_icon.png";
import {
  ThumbUpOffAlt,
  ThumbDownOffAlt,
  ArrowCircleUp,
} from "@mui/icons-material";
import { dateDiff } from "../../utils/moment.util";

export default function FunctionalitiesTableRow({
  functionality,
  index,
  actionVote,
  openModal,
  setSelectedModal,
  isAdmin,
}) {
  const {
    id,
    name,
    description,
    status,
    vote,
    votesquantity,
    link,
    dateofcreation,
  } = functionality;

  return (
    <div
      className={`relative grid grid-flow-row grid-cols-12 gap-8 py-2 px-8 items-center text-gray rounded-lg ${
        index % 2 === 1 && "bg-lightGray2"
      }`}
      key={index}
    >
      <p className="font-semibold text-base col-span-2">
        <span className="text-left">
          {name.length > 30 ? name.slice(0, 27) + "..." : name}
        </span>
      </p>

      <p className="ml-auto flex items-center gap-2 col-span-2">
        {vote === "Y" ? (
          <span className="flex gap-1">
            <button
              onClick={() => actionVote(id, votesquantity, true)}
              className="h-6 flex items-center justify-center w-11 rounded-sm bg-[#28B15C] border-[#28B15C] border-2"
            >
              <ThumbUpOffAlt
                sx={{
                  color: "#FFFFFF",
                  height: "17px",
                  width: "14px",
                }}
                alt="votar"
              />
            </button>

            <button
              onClick={() => actionVote(id, votesquantity, false)}
              className={`h-6 flex items-center justify-center w-11 rounded-sm bg-[#C12601] border-[#C12601] border-2 ${
                votesquantity === 1 && "pointer-events-none"
              }`}
            >
              <ThumbDownOffAlt
                sx={{
                  color: "#FFFFFF",
                  height: "17px",
                  width: "14px",
                }}
                alt="votar"
              />
            </button>
          </span>
        ) : null}
        {votesquantity > 0 ? (
          <span className="flex items-center gap-1">
            <ArrowCircleUp
              sx={{
                width: "17.22px",
                height: "17.22px",
                color: "#28B15C",
              }}
              alt={"votos" + votesquantity || 0}
            />
            <p className="font-thin w-2 text-[#28B15C]">{votesquantity}</p>
          </span>
        ) : null}
      </p>

      <p className="col-span-6">
        <div className="flex justify-between items-center">
          <p>
            {description.length > 72
              ? description.slice(0, 69) + "..."
              : description}
          </p>
          {dateDiff(dateofcreation, "days") <= 20 ? (
            <p className="rounded-md py-[3px] px-[6px] bg-[red] text-white text-center w-fit h-fit">
              Nuevo
            </p>
          ) : (
            ""
          )}
        </div>
      </p>

      <p className="rounded-md whitespace-nowrap p-1 bg-[#FFF3D9] text-[#ffb300] text-center w-full">
        {status}
      </p>

      <a
        href={link}
        target="_blank"
        rel="noreferrer"
        className="rounded-md p-1 underline text-center w-full"
      >
        Link
      </a>

      {isAdmin && (
        <div className="absolute right-2 flex items-center">
          <button
            className="w-7 h-7 cursor-pointer hover:bg-[#EEE] rounded-full transition-colors group/edit"
            onClick={() => {
              openModal(functionality);
              setSelectedModal("edit");
            }}
          >
            <img src={edit} alt="edit" />
            <span className="absolute whitespace-nowrap bg-white border bottom-full -left-4 py-0.5 px-1 rounded scale-0 transition-transform group-hover/edit:scale-100">
              Editar
            </span>
          </button>
          <button
            className="w-7 h-7 cursor-pointer hover:bg-[#EEE] rounded-full transition-colors group/delete"
            onClick={() => {
              openModal(id);
              setSelectedModal("delete");
            }}
          >
            <img src={trash} alt="delete" />
            <span className="absolute whitespace-nowrap bg-white border bottom-full -left-4 py-0.5 px-1 rounded scale-0 transition-transform group-hover/delete:scale-100">
              Eliminar
            </span>
          </button>
        </div>
      )}
    </div>
  );
}
