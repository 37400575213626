export default function ViewAgencyModal({ agency }) {
  const {
    name,
    email,
    address,
    phone,
    category,
    contact_name,
    contact_lastname,
    contact_position,
    city,
    state,
    country,
    web,
    license_number,
  } = agency;

  return (
    <section className="min-w-[420px] grid grid-cols-2 items-center gap-2">
      {name?.length > 1 && (
        <div className="w-full relative flex flex-col">
          <label>Nombre</label>
          <input
            readOnly
            className="addPackInput capitalize"
            name="name"
            value={name.toLowerCase()}
          />
        </div>
      )}
      {email?.length > 1 && (
        <div className="w-full relative flex flex-col">
          <label>Email</label>
          <input readOnly className="addPackInput" name="email" value={email} />
        </div>
      )}
      {phone?.length > 1 && (
        <div className="w-full relative flex flex-col">
          <label>Número de teléfono</label>
          <input readOnly className="addPackInput" name="phone" value={phone} />
        </div>
      )}
      {address?.length > 1 && (
        <div className="w-full relative flex flex-col col-span-1">
          <label>Dirección</label>
          <input
            readOnly
            className="addPackInput capitalize"
            name="address"
            value={address.toLowerCase()}
          />
        </div>
      )}
      {city?.length > 1 && (
        <div className="w-full relative flex flex-col">
          <label>Ciudad</label>
          <input
            readOnly
            className="addPackInput capitalize"
            name="city"
            value={city.toLowerCase()}
          />
        </div>
      )}
      {state?.length > 1 && (
        <div className="w-full relative flex flex-col">
          <label>Región</label>
          <input
            readOnly
            className="addPackInput capitalize"
            name="state"
            value={state.toLowerCase()}
          />
        </div>
      )}
      {country?.length > 1 && (
        <div className="w-full relative flex flex-col">
          <label>País</label>
          <input
            readOnly
            className="addPackInput capitalize"
            name="country"
            value={country.toLowerCase()}
          />
        </div>
      )}
      {category?.length > 1 && (
        <div className="w-full relative flex flex-col">
          <label>Tipo</label>
          <input
            readOnly
            className="addPackInput"
            name="category"
            value={category}
          />
        </div>
      )}
      {(contact_name?.length > 1 || contact_lastname?.length > 1) && (
        <div className="w-full relative flex flex-col">
          <label>
            Contacto{" "}
            {contact_position?.length > 1 &&
              `(${contact_position.toLowerCase()})`}
          </label>
          <input
            readOnly
            className="addPackInput capitalize"
            name="contact_name"
            value={
              contact_name.toLowerCase() + " " + contact_lastname.toLowerCase()
            }
          />
        </div>
      )}
      {web?.length > 1 && (
        <div className="w-full relative flex flex-col">
          <label>Página Web</label>
          <input readOnly className="addPackInput" name="web" value={web} />
        </div>
      )}
      {license_number?.length > 1 && (
        <div className="w-full relative flex flex-col">
          <label>Número de licencia</label>
          <input
            readOnly
            className="addPackInput"
            name="license_number"
            value={license_number}
          />
        </div>
      )}
    </section>
  );
}
