/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

import salidaAvion from "../../iconos/salida-del-avion.png";
import "../../styles/opcionvuelo.css";
import { useSelectFlights } from "./useSelectFlights";
import _ from "lodash";
import { ModalGeneric } from "../../utils/ModalGeneric";
import { useState } from "react";
import { useToggleVisibility } from "../../hooks/useToggleVisibility";
import { format } from "date-fns";
import { useLocationQuery } from "../../hooks/useLocationQuery";
import { es } from "date-fns/locale";
import { useMemo } from "react";
import TramoModal from "../detalle/TramoModal";
import FlightSegment from "./FlightSegment";

export const WrapperFlights = ({
  airlinesFormatted,
  ciudadesFormatted,
  destino,
  origen,
  original,
  totalPrice,
  type,
}) => {
  const flight = useSelectFlights((state) => state.flight);
  const selectFlight = useSelectFlights((state) => state.selectFlight);
  const selectOpcionesVuelo = useSelectFlights(
    (state) => state.selectOpcionesVuelo
  );
  const setOriginal = useSelectFlights((state) => state.setOriginal);

  const [scalesSelected, setScalesSelected] = useState({});

  const [isVisible, toggleVisibility] = useToggleVisibility();
  const { parsedHash } = useLocationQuery();

  const { travelerPricings } = original?.find(
    (x) => x.price.total === totalPrice
  );

  const contarEscalas = (segments) => {
    return segments?.length - 1 < 1 ? null : segments?.length - 1;
  };

  const obtenerOpcionVuelo = () => {
    const comparation = (op) => {
      if (parsedHash?.returnDate) {
        return (
          _.isEqual(op.itineraries[0], flight["IDA"]) &&
          _.isEqual(op.itineraries[1], flight["Vuelta"])
        );
      } else {
        return _.isEqual(op.itineraries[0], flight["IDA"]);
      }
    };
    return original
      .map((op) => (comparation(op) ? op : {}))
      .filter((x) => x.id)?.[0];
  };

  const opcionVuelo = useMemo(() => obtenerOpcionVuelo(), [flight]);

  const date =
    type === "IDA"
      ? format(
          new Date(parsedHash?.departureDate + "T00:00:00"),
          `d 'de' MMMM`,
          {
            locale: es,
          }
        )
      : parsedHash?.returnDate
      ? format(new Date(parsedHash?.returnDate + "T00:00:00"), `d 'de' MMMM`, {
          locale: es,
        })
      : "";

  return (
    <>
      {origen?.length || destino.filter((v) => v)?.length ? (
        <section className="flex flex-col">
          {/* ida o vuelta */}
          <header className="flex items-center justify-between gap-2 pb-1 border-b">
            <span className="flex gap-2 items-center">
              <img
                className="w-5 mr-2"
                alt="salidadelavion"
                src={salidaAvion}
              />
              <p className="font-medium xl:text-xl">{type.toUpperCase()}</p>
              <p className="xl:text-xl">{date}</p>
            </span>
            <span className="flex gap-2 relative">
              <p className="xl:text-xl">
                {
                  ciudadesFormatted?.[
                    (origen || destino)?.[0]?.segments?.[0]?.departure?.iataCode
                  ]?.airport
                }
              </p>
              <p className="xl:text-xl"> - </p>
              <p className="xl:text-xl">
                {
                  ciudadesFormatted?.[
                    (origen || destino)?.at(-1)?.segments?.[
                      (origen || destino)?.at(-1)?.segments?.length - 1
                    ]?.arrival?.iataCode
                  ]?.airport
                }
              </p>
            </span>
          </header>

          <div className="flex flex-col">
            <div
              className={`mt-4 ${type !== "IDA" ? "relative bottom-0" : ""}`}
            >
              <form>
                <fieldset className="flex flex-col gap-2">
                  {(origen || destino)?.map((item, index) => {
                    const salida = item?.segments?.[0];
                    const llegada = item?.segments?.at(-1);

                    return (
                      <FlightSegment
                        key={`${salida.id}-${llegada.id}`}
                        isModal={false}
                        {...{
                          airlinesFormatted,
                          ciudadesFormatted,
                          contarEscalas,
                          destino,
                          flight,
                          index,
                          item,
                          llegada,
                          opcionVuelo,
                          origen,
                          original,
                          salida,
                          selectFlight,
                          selectOpcionesVuelo,
                          setOriginal,
                          setScalesSelected,
                          toggleVisibility,
                          totalPrice,
                          type,
                        }}
                      />
                    );
                  })}
                </fieldset>
              </form>
            </div>
            <ModalGeneric show={isVisible} toggle={toggleVisibility}>
              <TramoModal
                data={travelerPricings}
                duration={flight?.[type]?.duration}
                formattedCities={ciudadesFormatted}
                title={
                  ciudadesFormatted?.[
                    flight?.[type]?.segments?.at(-1)?.arrival?.iataCode
                  ]?.city
                }
                type={type}
              />
            </ModalGeneric>
          </div>
          {/* {type === "IDA" ? (
              <SubTotalFlights
                breakdownPrices={breakdownPrices}
                price={totalPrice}
                original={original}
                idOffer={idOffer}
              />
            ) : null} */}
        </section>
      ) : null}
    </>
  );
};
