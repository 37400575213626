export const MisActividadesBusqueda = ({ searcher }) => {
  const [searcherValue, setSearcherValue] = searcher;

  return (
    <section className="flex gap-4 pb-4 mb-4 justify-between border-b border-gray2">
      <input
        className="py-1 px-2 border border-gray2 w-80 rounded"
        placeholder="Buscar por nombre, origen y destino..."
        value={searcherValue}
        onChange={(e) => setSearcherValue(e.target.value)}
      />
    </section>
  );
};
