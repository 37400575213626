import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { ErrorMessage } from "@hookform/error-message";
import { useState } from "react";
import AutoCompleteCountryName from "../../utils/AutoCompleteCountryName";

const commissionSchema = z.object({
  name: z.string().min(1, { message: "Campo Requerido" }),
  percentage: z
    .string()
    .min(1, { message: "Campo Requerido" })
    .regex(/^[+]?\d+([.]\d+)?$/, { message: "El valor debe ser numérico" }),
  service: z.string().min(1, { message: "Campo Requerido" }),
});

const taxSchemma = z.object({
  name: z.string().min(1, { message: "Campo Requerido" }),
  country_id: z
    .string()
    .min(1, { message: "Campo Requerido" })
    .regex(/^[+]?\d+([.]\d+)?$/, { message: "El valor debe ser numérico" }),
});

export default function FormAddCommission({ onSubmit }) {
  const [type, setType] = useState("commission");
  const [countryId, setCountryId] = useState("");
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(
      type === "commission" ? commissionSchema : taxSchemma
    ),
  });

  return (
    <form onSubmit={handleSubmit((data) => onSubmit(data, type))}>
      <section className="flex flex-col gap-8">
        <header className="relative w-56 mx-auto flex items-center border-2 border-lightBlue rounded-3xl h-10">
          <button
            type="button"
            className="px-4 rounded-3xl w-28 z-10"
            onClick={() => {
              setType("commission");
              setCountryId("");
              reset();
            }}
          >
            Comisión
          </button>
          <button
            type="button"
            className="px-4 rounded-3xl w-28 z-10"
            onClick={() => {
              setType("tax");
              setCountryId("");
              reset();
            }}
          >
            Impuesto
          </button>
          <span
            className={`absolute h-10 w-28 bg-lightBlue transition-all rounded-3xl ${
              type === "commission" ? "right-28" : "right-0"
            }`}
          />
        </header>

        <div className="flex flex-col gap-2 relative">
          <label className="font-medium w-[300px]">
            Nombre de {type === "commission" ? "Comisión" : "Impuesto"}
          </label>
          <input
            placeholder={
              type === "commission"
                ? "Comisión de Operadora, Comisión Bancaria, ..."
                : "Impuesto País, Impuesto Aéreo, ..."
            }
            className="outline-none border-b border-[#CEDEF1]"
            name="name"
            {...register("name")}
          />
          <div className="absolute text-red-600 text-xs -bottom-[15px] whitespace-nowrap">
            <ErrorMessage errors={errors} name="name" as="p" />
          </div>
        </div>

        {type === "commission" ? (
          <>
            <div className="flex flex-col gap-2 relative">
              <label className="font-medium w-[300px]">Porcentaje</label>
              <input
                placeholder="12%, 25%, 48%, 100% ..."
                className="outline-none border-b border-[#CEDEF1]"
                name="percentage"
                {...register("percentage")}
              />
              <div className="absolute text-red-600 text-xs -bottom-[15px] whitespace-nowrap">
                <ErrorMessage errors={errors} name="percentage" as="p" />
              </div>
            </div>

            <div className="flex flex-col gap-2 relative">
              <label className="font-medium w-[300px]">Servicio</label>
              <input
                placeholder="Pago de Vuelo, Reserva de Paquete..."
                className="outline-none border-b border-[#CEDEF1]"
                name="service"
                {...register("service")}
              />
              <div className="absolute text-red-600 text-xs -bottom-[15px] whitespace-nowrap">
                <ErrorMessage errors={errors} name="service" as="p" />
              </div>
            </div>
          </>
        ) : (
          <div className="flex flex-col gap-2 relative">
            <label className="font-medium w-[300px]">Código de País</label>
            <AutoCompleteCountryName
              classname="outline-none border-b border-[#CEDEF1] w-full"
              placeholder="Argentina, España, Italia, Brasil..."
              register={register}
              name="country_id"
              handleAction={(d) => setValue("country_id", d.toString())}
              inputState={[countryId, setCountryId]}
            />
            <div className="absolute text-red-600 text-xs -bottom-[15px] whitespace-nowrap">
              <ErrorMessage errors={errors} name="country_id" as="p" />
            </div>
          </div>
        )}
      </section>

      <section className="flex items-center justify-end mt-4">
        <button
          type="submit"
          className="px-4 py-2 rounded bg-brightBlue text-white"
        >
          Cargar
        </button>
      </section>
    </form>
  );
}
