import { Outlet } from "react-router-dom";
import { StepperProfile } from "../../../componentes/panelConfig/StepperProfile";

export default function PanelConfigPage() {
  return (
    <div className="flex flex-col max-w-[1400px] items-center gap-4 mx-auto my-6">
      <StepperProfile />
      <div>
        <Outlet />
      </div>
    </div>
  );
}
