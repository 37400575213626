import axios from "axios";
import { useEffect, useState } from "react";
import { saveTourOperator } from "../utils/saveTourOperator";
import { getLocalStorageValue, getSessionStorageValue } from "./useCookie";

export default function useCommissionsAndTaxes() {
  const BASE_URL = process.env.REACT_APP_API_URL;
  const tourOP = saveTourOperator((st) => st.tourOP);
  const [commissions, setCommissions] = useState([]);

  const token =
    getLocalStorageValue("token") || getSessionStorageValue("token");

  const headers = {
    headers: { Authorization: `${token}` },
  };

  const createCommission = async ({ commission }) => {
    return axios.post(
      BASE_URL + "v2/commissions",
      {
        commission: { ...commission, tour_op_id: tourOP.id },
      },
      headers
    );
  };

  const getCommissions = async () => {
    return await axios
      .get(BASE_URL + "v2/commissions", headers)
      .then((e) => {
        setCommissions(
          e.data.response.filter((com) => com.tour_op_id === tourOP.id)
        );
      })
      .catch((e) => console.log(e));
  };

  const createTax = async ({ tax }) => {
    return axios.post(
      BASE_URL + "v2/taxes",
      {
        taxe: { ...tax },
      },
      headers
    );
  };

  useEffect(() => {
    getCommissions();
  }, []);

  return {
    commissions,
    createCommission,
    getCommissions,
    createTax,
  };
}
