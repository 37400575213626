import _ from "lodash";
import { useSelectPanelConfig } from "./useSelectPanelConfig";
import chevron_down_green from "../../iconos/chevron_down_green.png";
import { useLocation } from "react-router-dom";
import clsx from "clsx";

export const StepperProfile = () => {
  const menu = useSelectPanelConfig((st) => st.menu);
  const location = useLocation();
  const actualStep = location.pathname.split("/").pop();

  return (
    <div className="flex">
      {_.keys(menu).map((h, index, arr) => {
        const isActive = actualStep.toUpperCase().includes(h.toUpperCase());
        return (
          <span className="flex items-center gap-1 mx-2 " key={index}>
            <div className="flex flex-col items-center justify-end gap-1 min-h-[110px]">
              {isActive ? (
                <img
                  src={chevron_down_green}
                  className="w-[14px] h-[8px]"
                  alt={h}
                />
              ) : null}
              <div className="w-[62px]  h-[62px] border flex items-center justify-center rounded-full border-[#DAE0E640] text-center">
                <p
                  className={clsx(
                    "font-medium text-[30px]",
                    {
                      "text-[#28B15C]": isActive,
                    },
                    { "text-[#CEDEF1]": !isActive }
                  )}
                >
                  {index + 1}
                </p>
              </div>
              <p className="text-[#8D99AE]">{h}</p>
            </div>
            {index !== arr?.length - 1 ? (
              <div className="w-[38px] mb-2 ml-1 bg-[#DAE0E6] h-[1px]"></div>
            ) : null}
          </span>
        );
      })}
    </div>
  );
};
