/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { usePackages } from "../../hooks/usePackages";
import { ModalGeneric } from "../../utils/ModalGeneric";
import { MisPaquetesBusqueda } from "./MisPaquetesBusqueda";
import { useToggleVisibility } from "../../hooks/useToggleVisibility";
import TableHeaderPaquetes from "./TableHeaderPaquetes";
import TableRowPaquete from "./TableRowPaquete";
import Pagination from "../../utils/Pagination";
import SEO from "../../utils/SEO";
import { saveTourOperator } from "../../utils/saveTourOperator";
import useGetCurrencies from "../../hooks/useGetCurrencies";
import ModalViewPackage from "./ModalViewPackage";
import ModalSendEmail from "./ModalSendEmail";

export const MisPaquetes = () => {
  const tourOP = saveTourOperator((st) => st.tourOP);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [selectedModal, setSelectedModal] = useState("");
  const [page, setPage] = useState(0);
  const [searcherValue, setSearcherValue] = useState({
    textValue: "",
    monthValue: "",
  });
  const { currencies } = useGetCurrencies();
  const { packages, loading, getPackages, createPackage, deletePackage } =
    usePackages();
  const { tour_op_id } = useParams();
  const [isVisible, toggleVisibility] = useToggleVisibility();

  useEffect(() => {
    getPackages();
  }, [tourOP]);

  const openModal = (pack) => {
    setSelectedPackage(pack);
    toggleVisibility();
  };

  const lowerSearch = searcherValue.textValue.toLowerCase();
  const filteredPaquetes = packages
    .filter((pack) => {
      const { title, descode, oricode } = pack;

      return (
        title.toLowerCase().includes(lowerSearch) ||
        descode.toLowerCase().includes(lowerSearch) ||
        oricode.toLowerCase().includes(lowerSearch)
      );
    })
    .filter((pack) => {
      if (!searcherValue.monthValue) return true;

      const [packYear, packMonth] = pack.departure_date.split("-");
      const [searchYear, searchMonth] = searcherValue.monthValue.split("-");

      return packYear === searchYear && packMonth === searchMonth;
    })
    .slice(page * 10, page * 10 + 10);

  const handleCopyPackage = () => {
    const { id, fechaCreacion, Pictures, price, ...restOfPackage } =
      selectedPackage;

    createPackage({
      package: {
        ...restOfPackage,
        title: restOfPackage.title + " (1)",
        price: Number(price),
      },
      pictures: Pictures.map((pic) => {
        return { url: pic.url };
      }),
    }).finally(() => {
      getPackages();
      toggleVisibility();
    });
  };

  const handleDeletePackage = () => {
    deletePackage(selectedPackage.id).finally(() => {
      getPackages();
      toggleVisibility();
    });
  };

  const translateCurrency = (id) =>
    currencies?.find((currency) => currency.id === id)?.symbol;

  return (
    <>
      <SEO title={"Mis Paquetes"} />
      <main className="flex flex-col w-full mt-8 font-body">
        <header className="flex justify-between items-center mb-4">
          <span>
            <h1 className="font-[700] text-darkBlue text-3xl">Mis Paquetes</h1>
            <p className="text-darkBlue">
              Controlá, Gestioná y Personalizá cada uno de tus paquetes.
            </p>
          </span>
          <Link
            className="text-center button bg-darkBlue text-white w-44 hover:shadow-md transition-all"
            to={`/${tour_op_id}/panel/packages/add-package`}
          >
            Agregar Paquete
          </Link>
        </header>

        <section className="p-4 border-2 border-gray2 bg-white rounded-lg">
          <MisPaquetesBusqueda searcher={[searcherValue, setSearcherValue]} />

          <section>
            <TableHeaderPaquetes />

            {!loading && filteredPaquetes.length === 0 ? (
              <span className="text-red-600 mt-4 text-lg">
                No se encontraron paquetes
              </span>
            ) : (
              filteredPaquetes.map((pack, index) => {
                return (
                  <TableRowPaquete
                    key={pack.id}
                    index={index}
                    pack={pack}
                    currency={translateCurrency(pack.currency_type_id)}
                    openModal={openModal}
                    setSelectedModal={setSelectedModal}
                  />
                );
              })
            )}
          </section>

          <ModalGeneric
            show={isVisible}
            toggle={toggleVisibility}
            toggleOutside={selectedModal !== "email"}
          >
            {/* modal "copiar paquete" */}
            {selectedPackage && selectedModal === "copy" && (
              <div className="flex flex-col items-center gap-2">
                <h2>
                  ¿Estás seguro de copiar <b>{selectedPackage.name}</b>?
                </h2>
                <div className="flex justify-center gap-2">
                  <button
                    className="py-1 px-2 rounded font-medium border border-gray2"
                    onClick={toggleVisibility}
                  >
                    Cancelar
                  </button>
                  <button
                    className="py-1 px-2 rounded font-medium border border-brightBlue bg-brightBlue text-white"
                    onClick={handleCopyPackage}
                  >
                    Copiar
                  </button>
                </div>
              </div>
            )}

            {/* modal "enviar paquete por mail" */}
            {selectedPackage && selectedModal === "email" && (
              <ModalSendEmail
                selectedPackage={selectedPackage}
                tourOP={tourOP}
                closeModal={toggleVisibility}
              />
            )}

            {/* modal "eliminar paquete" */}
            {selectedPackage && selectedModal === "delete" && (
              <div className="flex flex-col items-center gap-2">
                <h2>
                  ¿Estás seguro de eliminar <b>{selectedPackage.name}</b>?
                </h2>
                <div className="flex justify-center gap-2">
                  <button
                    className="py-1 px-2 rounded font-medium border border-gray2"
                    onClick={toggleVisibility}
                  >
                    Cancelar
                  </button>
                  <button
                    className="py-1 px-2 rounded font-medium border text-white border-red-500 bg-red-500"
                    onClick={handleDeletePackage}
                  >
                    Eliminar
                  </button>
                </div>
              </div>
            )}

            {/* modal "ver paquete" */}
            {selectedPackage && selectedModal === "view" && (
              <ModalViewPackage
                selectedPackage={selectedPackage}
                translateCurrency={translateCurrency}
                tour_op_id={tour_op_id}
              />
            )}
          </ModalGeneric>
        </section>

        <Pagination
          items={packages}
          itemsPerPage={10}
          page={page}
          setPage={setPage}
        />
      </main>
    </>
  );
};
