import React from "react";
import fallback from "../../iconos/fallback.png";
import destino from "../../iconos/destino_gris.png";
import sun from "../../iconos/sun.png";
import moon from "../../iconos/moon.png";
import gym from "../../iconos/gym.png";
import swim from "../../iconos/swim.png";
import dinner from "../../iconos/dinner.png";
import shop from "../../iconos/shop.png";
import wifi from "../../iconos/wifi.png";
import valijas from "../../iconos/valijas_gris.png";
import info from "../../iconos/info.png";
import numberWithCommas from "../../utils/numberWithCommas";
import { useSelectPaquetes } from "./useSelectPaquetes";
import clsx from "clsx";
import { useToggleVisibility } from "../../hooks/useToggleVisibility";
import { ModalGeneric } from "../../utils/ModalGeneric";
import { format } from "date-fns";
import { useLocationQuery } from "../../hooks/useLocationQuery";
import { useParams } from "react-router-dom";

export const CardPaquete = ({ paquete, currencies }) => {
  const { package_id } = useParams();
  const { parsedHash } = useLocationQuery();
  const selectPackage = useSelectPaquetes((st) => st.selectPackage);
  const packageSelected = useSelectPaquetes((st) => st.packageSelected);
  const [isVisible, toggleVisibility] = useToggleVisibility();

  if (package_id && !paquete?.id) {
    return <h1>No se encontró el paquete con el id: {package_id}</h1>;
  }

  const {
    id,
    title,
    descode,
    description,
    departure_date,
    return_date,
    oricode,
    original_stock,
    price,
    operador,
    stock,
    creation_date,
    currency_type_id,
    portrait,
    tax_package,
  } = paquete;

  const nights =
    (new Date(return_date) - new Date(departure_date)) / 1000 / 60 / 60 / 24;
  const ADULTOS = parsedHash?.adults ?? 1;
  const translateCurrency = (id) =>
    currencies?.find((currency) => currency.id === id)?.symbol;

  return (
    <>
      <section
        onClick={() => selectPackage(paquete)}
        className={clsx(
          "w-full font-body flex justify-between items-center p-4 rounded-2xl border-2 gap-8 cursor-pointer transition-all",
          {
            "border-2 border-brightBlue": packageSelected.id === id,
          },
          {
            "border-lightGray2 hover:border-lightBlue":
              packageSelected.id !== id,
          }
        )}
      >
        <div className="w-1/4">
          <img
            className="my-auto rounded-lg max-h-44"
            src={portrait ?? fallback}
            alt="paquete_imagen"
          />
        </div>

        <div className="w-1/2 flex flex-col h-full">
          <span className="flex flex-col gap-1">
            <h2 className="text-[#242423] text-2xl font-[700] capitalize">
              {title?.toLowerCase()}
            </h2>
            <span className="flex items-center gap-1">
              <img className="w-3" src={destino} alt="destino" />
              <p className="text-[#8C8C8C] text-xs">{descode}</p>
            </span>
          </span>
          <span>
            <div className="text-[#242423] font-[500]">
              {description?.split("<br>").map((item, index) => (
                <p key={index}>{item}</p>
              ))}
            </div>
            <span className="flex gap-4 mt-2">
              <p className="text-[#8C8C8C] text-sm whitespace-nowrap">
                ({ADULTOS} {ADULTOS > 1 ? "pasajeros" : "pasajero"})
              </p>
              <span className="flex items-center gap-3">
                <img src={gym} alt="gym" className="w-3" />
                <img src={swim} alt="swim" className="w-3" />
                <img src={dinner} alt="dinner" className="w-3" />
                <img src={shop} alt="shop" className="w-3" />
                <img src={wifi} alt="wifi" className="w-3" />
              </span>
            </span>
          </span>
          <div className="flex items-end justify-between">
            <div className="flex gap-4">
              <span className="relative bg-[#FAF2AE] h-7 flex justify-end rounded-full py-1 px-4 w-[80px]">
                <span className="absolute flex items-center h-7 justify-center left-0 top-0 rounded-full px-4 bg-[#EAD40B]">
                  <img src={sun} alt="sun" className="w-4" />
                </span>
                <p className="bottom-1.5 w2">{nights + 1}</p>
              </span>
              <span className="relative bg-[#1E0F5033] h-7 flex justify-end rounded-full py-1 px-4 w-[80px]">
                <span className="absolute flex items-center h-7 justify-center left-0 top-0 rounded-full px-4 bg-[#120250BD]">
                  <img src={moon} alt="sun" className="w-4" />
                </span>
                <p className="bottom-1.5 w2">{nights}</p>
              </span>
            </div>
            <span className="flex items-center gap-2 ml-4 mt-4">
              <p>{format(new Date(departure_date), "dd/MM/yyyy")}</p>-
              <p>{format(new Date(return_date), "dd/MM/yyyy")}</p>
            </span>
          </div>
        </div>

        <div className="flex flex-col justify-between items-end w-1/4 h-full">
          <span className="flex flex-col items-end">
            <p className="text-sm">Precio final por persona</p>
            <span className="flex items-center gap-2 mt-1">
              <img src={info} alt="icon_info" className="w-5" />
              <p className="text-xl text-[#242423] font-bold ">
                {translateCurrency(currency_type_id) ?? "USD"}{" "}
                {numberWithCommas(
                  Number(price + Number(tax_package ?? "0")),
                  2
                )}
              </p>
            </span>
          </span>
          <button
            onClick={toggleVisibility}
            className="bg-[#cedef1] px-4 py-2 text-center rounded-full text-sm shadow-lg mt-6 mb-1"
          >
            Ver detalle
          </button>
        </div>
      </section>

      <ModalGeneric show={isVisible} toggle={toggleVisibility}>
        <section className="py-3 px-4">
          <h2 className="font-medium text-2xl mb-4 capitalize">
            {title.toLowerCase()}
          </h2>
          <img
            src={portrait ?? fallback}
            alt="bg"
            className="max-h-64 mx-auto rounded-md"
          />
          <h2 className="font-medium text-2xl mb-4 mt-4">Descripción</h2>
          <div>
            {description?.split("<br>").map((item) => (
              <p>{item}</p>
            ))}
          </div>
          <p className="font-medium mt-4">
            Cupos Disponibles: <span>{stock}</span>
          </p>

          <div className="flex justify-between mt-4">
            <div>
              <p className="font-medium mb-2">Incluye</p>
              <span className="flex items-center gap-4">
                <img src={gym} alt="gym" className="w-4" />
                <img src={swim} alt="swim" className="w-4" />
                <img src={dinner} alt="dinner" className="w-4" />
                <img src={shop} alt="shop" className="w-4" />
                <img src={wifi} alt="wifi" className="w-4" />
              </span>
            </div>
            <div>
              <p className="font-medium mb-2">Fechas</p>
              <span className="flex items-center gap-4">
                <p>{format(new Date(departure_date), "dd/MM/yyyy")}</p>-
                <p>{format(new Date(return_date), "dd/MM/yyyy")}</p>
              </span>
            </div>
          </div>
        </section>
      </ModalGeneric>
    </>
  );
};
