import React, { useEffect, useState } from "react";
// import { AutoComplete } from "../../utils/AutoComplete";
import { useForm } from "react-hook-form";
import { useToggleVisibility } from "../../hooks/useToggleVisibility";
import { format } from "date-fns";
import { usePackages } from "../../hooks/usePackages";
import { Toaster, toast } from "react-hot-toast";
import { Link, useNavigate, useParams } from "react-router-dom";
import SEO from "../../utils/SEO";
import NewCalendar from "../../utils/NewCalendar";
import usePictures from "../../hooks/usePictures";
import EditPicture from "./FormEditPicture";
import { AutoComplete } from "../../utils/AutoComplete";
import useGetCurrencies from "../../hooks/useGetCurrencies";

export const FormEditPaquete = () => {
  const { tour_op_id, package_id } = useParams();
  const { individualPackage, packagesCategories, getPackage, updatePackage } =
    usePackages();
  const { currencies } = useGetCurrencies();
  const { postPicture } = usePictures();
  const [selectedPackage, setSelectedPackage] = useState();
  const [rangeDate, setRangeDate] = useState();
  const { register, handleSubmit, setValue } = useForm();
  const [oricodeInput, setOricodeInput] = useState(
    selectedPackage?.oricode ?? ""
  );
  const [descodeInput, setDescodeInput] = useState(
    selectedPackage?.descode ?? ""
  );
  const [pictureError, setPictureError] = useState(false);
  const [flyerDataUrl, setFlyerDataUrl] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    getPackage(package_id);
  }, []);

  useEffect(() => {
    setSelectedPackage(individualPackage);
  }, [individualPackage, package_id]);

  useEffect(() => {
    if (selectedPackage) {
      setRangeDate({
        from: selectedPackage.departure_date,
        to: selectedPackage.return_date,
      });
      !flyerDataUrl && setFlyerDataUrl(selectedPackage?.flyer);
    }
  }, [selectedPackage]);

  const [openFsalida, toggleSalida] = useToggleVisibility();

  const onSubmitEdit = async (data) => {
    toast.promise(
      updatePackage({
        ...selectedPackage,
        oricode: data.oricode || selectedPackage?.oricode,
        descode: data.descode || selectedPackage?.descode,
        departure_date: rangeDate.from,
        return_date: rangeDate.to,
        arrival_date: rangeDate.to,
        stock: selectedPackage.original_stock,
        portrait: selectedPackage?.Pictures?.[0]?.url,
        flyer: flyerDataUrl,
        description: selectedPackage.description.replace(/\n/g, "<br>"),
        // adultos: Number(selectedPackage.adultos),
      }).then(() =>
        navigate(`/${tour_op_id}/panel/packages/my-packages`, { replace: true })
      ),
      {
        loading: "Cargando...",
        error: <b>Hubo un error, intenta de nuevo.</b>,
      }
    );
  };

  const handleEdit = (e) => {
    setSelectedPackage((curr) => {
      return {
        ...curr,
        [e.target.name]: e.target.value,
      };
    });
    if (pictureError) return setPictureError(false);
  };

  const handleAddPhoto = () => {
    if (selectedPackage?.photo?.length > 255) return setPictureError(true);

    postPicture({
      package_id: Number(package_id),
      url: selectedPackage?.photo,
    }).then(() => window.location.reload());
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setFlyerDataUrl(e.target.result);
        setValue("flyer", e.target.result);
      };

      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <SEO title={"Editar Paquete"} />
      <div className="w-full">
        <header className="flex justify-between my-16 max-w-[1200px] mx-auto">
          <h1 className="text-darkBlue font-semibold text-3xl">
            Editar Paquete
          </h1>
          <Link
            to={`/${tour_op_id}/panel/packages/my-packages`}
            className="ml-auto button text-center border-2 text-darkBlue font-medium border-gray hover:border-darkBlue transition-colors"
          >
            Volver a Mis Paquetes
          </Link>
        </header>

        <form
          onSubmit={handleSubmit(onSubmitEdit)}
          className="my-10 mt-16 mx-auto max-w-[1200px] bg-white rounded-lg p-8"
        >
          <div>
            <Toaster />
          </div>
          <div className="grid grid-cols-12 gap-12">
            <section className="flex flex-col gap-4 col-span-5">
              <h2 className="text-darkBlue font-medium">INFORMACIÓN GENERAL</h2>
              <div className="grid grid-cols-2 gap-3">
                <input
                  className="addPackInput col-span-2"
                  name="title"
                  value={selectedPackage?.title}
                  onChange={handleEdit}
                />
                {/* <input
                  className="addPackInput w-full"
                  value={selectedPackage?.oricode}
                  name="oricode"
                  onChange={handleEdit}
                />

                <input
                  value={selectedPackage?.descode}
                  className="addPackInput w-full"
                  name="descode"
                  onChange={handleEdit}
                /> */}
                <AutoComplete
                  justCities
                  notIcon
                  classname="addPackInput w-full"
                  register={register}
                  defaultValue={selectedPackage?.oricode}
                  name="oricode"
                  placeholder={selectedPackage?.oricode}
                  handleAction={(d) => setValue("oricode", d.toUpperCase())}
                  inputState={[oricodeInput, setOricodeInput]}
                />
                <AutoComplete
                  justCities
                  notIcon
                  classname="addPackInput w-full"
                  register={register}
                  defaultValue={selectedPackage?.descode}
                  name="descode"
                  placeholder={selectedPackage?.descode}
                  handleAction={(d) => setValue("descode", d.toUpperCase())}
                  inputState={[descodeInput, setDescodeInput]}
                />
                <div className="flex flex-col">
                  <div className="flex flex-col items-start gap-2">
                    <input
                      autoComplete="off"
                      name="departure_date"
                      placeholder="Ida"
                      onClick={() => {
                        toggleSalida();
                        setRangeDate({
                          from: "",
                          to: "",
                        });
                      }}
                      value={
                        rangeDate?.from
                          ? format(new Date(rangeDate?.from), "dd/MM/yyyy")
                          : ""
                      }
                      className="addPackInput w-full"
                    />
                    {openFsalida ? (
                      <NewCalendar
                        handlers={[
                          rangeDate,
                          (date) => {
                            setRangeDate(date);
                          },
                        ]}
                        mode={"range"}
                        numberOfMonths={2}
                        close={toggleSalida}
                      />
                    ) : null}
                  </div>
                </div>
                <div className="flex flex-col">
                  <div className="flex flex-col items-start gap-2">
                    <input
                      autoComplete="off"
                      name="return_date"
                      placeholder="Vuelta"
                      onClick={() => toggleSalida()}
                      value={
                        rangeDate?.to
                          ? format(new Date(rangeDate?.to), "dd/MM/yyyy")
                          : ""
                      }
                      className="addPackInput w-full"
                    />
                  </div>
                </div>
                <select
                  className="addPackInput"
                  name="package_category_id"
                  value={selectedPackage?.package_category_id}
                  onChange={handleEdit}
                >
                  {packagesCategories &&
                  packagesCategories?.data?.response?.length > 0 ? (
                    packagesCategories?.data?.response?.map((element) => (
                      <option
                        key={element.id}
                        value={element.id}
                        selected={
                          selectedPackage?.package_category_id === element.id
                        }
                      >
                        {element.title}
                      </option>
                    ))
                  ) : (
                    <option value="">Empty</option>
                  )}
                </select>
                <input
                  type="number"
                  className="addPackInput"
                  name="original_stock"
                  value={selectedPackage?.original_stock}
                  onChange={handleEdit}
                />

                <div className="flex flex-col col-span-2">
                  <label
                    htmlFor="description"
                    className="text-darkBlue font-medium mt-3 my-2"
                  >
                    DESCRIPCIÓN
                  </label>
                  <textarea
                    id="description"
                    name="description"
                    value={selectedPackage?.description?.replace(/<br>/g, "\n")}
                    onChange={handleEdit}
                    className="addPackInput"
                  />
                </div>
              </div>

              <div>
                <h3 className="text-darkBlue font-medium mt-3 mb-2">
                  PRECIO POR PERSONA
                </h3>
                <div className="grid grid-cols-3 gap-4">
                  <label className="relative w-full">
                    <select
                      name="currency_type_id"
                      id="currency_type_id"
                      className="addPackInput w-full"
                      onChange={handleEdit}
                    >
                      {currencies?.map(({ name, symbol, id }) => {
                        return (
                          <option
                            key={`${id}`}
                            value={id}
                            selected={selectedPackage?.currency_type_id === id}
                          >
                            {name} ({symbol})
                          </option>
                        );
                      })}
                    </select>
                  </label>
                  <input
                    type="number"
                    placeholder="Tarifa por pasajero"
                    className="addPackInput w-full"
                    name="price"
                    value={selectedPackage?.price}
                    onChange={handleEdit}
                  />
                  <input
                    type="number"
                    placeholder="Impuesto por pasajero"
                    className="addPackInput w-full"
                    name="tax_package"
                    value={selectedPackage?.tax_package}
                    onChange={handleEdit}
                  />
                </div>
              </div>
            </section>

            <section className="relative col-span-7 pb-16">
              <h2 className="text-darkBlue font-medium mb-4">
                FOTOS DEL PAQUETE
              </h2>
              <div className="w-full flex gap-2 items-center mb-4">
                <label htmlFor="photo" className="relative w-full">
                  <input
                    type="text"
                    placeholder="Link a imagen"
                    className="addPackInput w-full"
                    name="photo"
                    value={selectedPackage?.photo || ""}
                    onChange={handleEdit}
                  />
                  <div className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
                    {pictureError && <p>El máximo es de 255 carácteres</p>}
                  </div>
                </label>
                <button
                  type="button"
                  className="py-1.5 px-4 rounded bg-darkBlue text-white ml-4"
                  onClick={handleAddPhoto}
                >
                  Agregar
                </button>
              </div>
              {selectedPackage?.Pictures?.length > 0 && (
                <div className="grid gap-2">
                  {selectedPackage?.Pictures.map((pic, index) => (
                    <EditPicture key={pic.id} pic={pic} index={index + 1} />
                  ))}
                </div>
              )}

              <h2 className="text-darkBlue font-medium my-4">FLYER</h2>
              <div className="flex flex-col gap-2 items-center mb-4">
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                  className="addPackInput w-full"
                />

                {flyerDataUrl && (
                  <img
                    src={flyerDataUrl}
                    alt="Selected"
                    className="max-w-[16rem] max-h-[500px]"
                  />
                )}
              </div>

              <button className="button ml-auto absolute bottom-0 right-0 text-lg w-1/2 bg-darkBlue text-white hover:shadow-lg transition-all">
                Actualizar Paquete
              </button>
            </section>
          </div>
        </form>
      </div>
    </>
  );
};
