import { ErrorMessage } from "@hookform/error-message";
import { AutoCompleteCities } from "../../utils/AutoCompleteCities";
import icono_calendar from "../../iconos/icono_calendar.png";
import oricode_chevron from "../../iconos/oricode_chevron.png";
import descode_point from "../../iconos/descode_point.png";
import passengers from "../../iconos/passengers.png";
import { useEffect } from "react";
import { PASSENGER_DICTIONARY } from "../../utils/dictionaries/passengerDictionary";

const MONTHS = [
  { label: "Enero", date: "01" },
  { label: "Febrero", date: "02" },
  { label: "Marzo", date: "03" },
  { label: "Abril", date: "04" },
  { label: "Mayo", date: "05" },
  { label: "Junio", date: "06" },
  { label: "Julio", date: "07" },
  { label: "Agosto", date: "08" },
  { label: "Septiembre", date: "09" },
  { label: "Octubre", date: "10" },
  { label: "Noviembre", date: "11" },
  { label: "Diciembre", date: "12" },
];

export default function SearcherPackages({
  descodeState,
  oricodeState,
  passangerState,
  sumPassengers,
  handlePassenger,
  parsedHash,
  possibleCitiesAndMonth,
  useForm,
}) {
  const [passengerCounter, setPassengerCounter] = passangerState;
  const { register, setValue, errors, watch } = useForm;
  const month = watch("month");
  const year = watch("year");

  useEffect(() => {
    if (year || month) {
      setValue(
        "departureDate",
        `${year ?? new Date().getFullYear().toString()}-${month}`
      );
    }
  }, [month, year]);

  const possibleMonth = MONTHS.filter((item) => {
    return possibleCitiesAndMonth?.departure_date.length > 0
      ? possibleCitiesAndMonth?.departure_date.some(
          (departureMonth) => departureMonth.split("-")[1] === item.date
        )
      : true;
  });

  const possibleYear =
    possibleCitiesAndMonth?.departure_date.length > 0
      ? possibleCitiesAndMonth?.departure_date?.reduce((acc, departureDate) => {
          const thisYear = departureDate.split("-")[0];
          if (!acc.includes(thisYear)) return [...acc, thisYear];
          return acc;
        }, [])
      : [new Date().getFullYear().toString()];

  return (
    <>
      <p className="text-xl font-medium text-lightBlue">Paquetes</p>
      <div className="flex gap-3 justify-between">
        {/* descode y oricode */}
        <div className="flex flex-col relative bg-[#EFEFEF] p-2 h-20 pt-2 rounded-2xl w-1/4">
          <span className="flex gap-2 items-center justify-center">
            <img src={descode_point} alt="descode_point" className="w-4" />
            <p>
              Destino <span className="text-red-600 text-xs">(*)</span>
            </p>
          </span>

          <AutoCompleteCities
            acceptedCities={possibleCitiesAndMonth?.descode}
            classname={"bg-[#EFEFEF] outline-none px-2 py-2"}
            register={register}
            defaultValue={parsedHash?.descode}
            name="destinationLocationCode"
            handleAction={(d) => setValue("destinationLocationCode", d.city)}
            inputState={descodeState}
          />
          <div className="absolute text-red-600 text-xs -bottom-0.5 left-4 whitespace-nowrap">
            <ErrorMessage
              errors={errors}
              name="destinationLocationCode"
              as="p"
            />
          </div>
        </div>

        <div className="relative flex items-center justify-center bg-[#EFEFEF] p-2 h-20 pt-2 rounded-2xl w-1/4">
          <span className="flex gap-2 items-center absolute top-2">
            <img src={icono_calendar} alt="icono_calendar" className="w-4" />
            <p>
              Mes de Salida <span className="text-red-600 text-xs">(*)</span>
            </p>
          </span>

          <div className="flex gap-1">
            <select
              name="departureDate"
              className="w-full mt-6 p-2 text-sm focus:outline-none bg-[#CEDEF1] rounded-md"
              onChange={(e) =>
                setValue(
                  "month",
                  e.target.value === "00" ? undefined : e.target.value
                )
              }
            >
              <option value="00">- Elegir Mes -</option>
              {possibleMonth.map(({ label, date }) => {
                return (
                  <option key={date} value={date}>
                    {label}
                  </option>
                );
              })}
            </select>

            <select
              name="departureDate"
              className="mt-6 p-2 text-sm focus:outline-none bg-[#CEDEF1] rounded-md"
              onChange={(e) => setValue("year", e.target.value)}
              defaultValue={
                possibleYear?.[0] || new Date().getFullYear().toString()
              }
            >
              {possibleYear?.map((year) => {
                return (
                  <option key={year} value={year}>
                    {year}
                  </option>
                );
              })}
            </select>
          </div>
        </div>

        {/* passengers */}
        <div className="relative group/passengers">
          <div className="bg-[#EFEFEF] p-1 h-20 pt-2 rounded-2xl flex flex-col items-center gap-1 justify-evenly cursor-pointer">
            <span className="flex gap-2 items-center">
              <img src={passengers} alt="passengers" className="w-4 h-3" />
              <p className="text-sm font-medium">Pasajeros</p>
            </span>
            <p className="w-[120px] py-1 px-2 mx-auto text-sm focus:outline-none bg-[#CEDEF1] rounded-md">
              {sumPassengers} {sumPassengers > 1 ? " pasajeros" : "pasajero"}
            </p>
          </div>
          <div className="absolute w-64 top-full mt-2 bg-[#EFEFEF] rounded-2xl p-4 flex flex-col gap-2 scale-0 group-hover/passengers:scale-100 transition-transform origin-top-left">
            <section className="flex flex-col gap-2">
              {Object.entries(passengerCounter).map((passenger) => {
                const { key, label, description, register, min } =
                  PASSENGER_DICTIONARY[passenger[0]];
                return (
                  <div className="flex justify-between items-center" key={key}>
                    <div>
                      <h2 className="font-medium">{label}</h2>
                      <p className="text-xs text-gray">{description}</p>
                    </div>
                    <div className="flex gap-2 items-center">
                      <button
                        type="button"
                        onClick={() => handlePassenger(key, register, min, "-")}
                        className="border w-7 h-7 rounded-full text-sm font-[700]"
                      >
                        -
                      </button>
                      <p className="text-xl">{passenger[1]}</p>
                      <button
                        type="button"
                        onClick={() => handlePassenger(key, register, min, "+")}
                        className="border w-7 h-7 rounded-full text-sm font-[700]"
                      >
                        +
                      </button>
                    </div>
                  </div>
                );
              })}
            </section>
          </div>
        </div>

        <div className="flex flex-col relative bg-[#EFEFEF] p-2 h-20 pt-2 rounded-2xl w-1/4">
          <span className="flex gap-2 items-center justify-center">
            <img src={oricode_chevron} alt="oricode_chevron" className="w-4" />
            <p>Origen</p>
          </span>

          <AutoCompleteCities
            acceptedCities={possibleCitiesAndMonth?.oricode}
            classname={"bg-[#EFEFEF] outline-none py-2 w-[190px] px-2"}
            register={register}
            defaultValue={parsedHash?.originLocationCode}
            name="originLocationCode"
            placeholder=""
            handleAction={(d) => setValue("originLocationCode", d.city)}
            inputState={oricodeState}
          />
          <div className="absolute text-red-600 text-xs -bottom-1 left-4 whitespace-nowrap">
            <ErrorMessage errors={errors} name="originLocationCode" as="p" />
          </div>
        </div>

        <button
          type="submit"
          className="bg-[#CEDEF1] rounded-xl py-1 px-12 text-black shadow-sm shadow-lightBlue2"
        >
          <span className="text-black font-medium">Buscar</span>
        </button>
      </div>
    </>
  );
}
