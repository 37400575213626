import axios from "axios";
import { getLocalStorageValue, getSessionStorageValue } from "./useCookie";

export default function useSendEmail() {
  const BASE_URL = process.env.REACT_APP_API_URL;
  const token =
    getLocalStorageValue("token") || getSessionStorageValue("token");

  const headers = {
    headers: { Authorization: `${token}` },
  };

  const sendEmail = async (body) => {
    return await axios.post(BASE_URL + "mailer/send", body, headers);
  };

  return { sendEmail };
}
