export default function HistorySearcher({ setInputValue }) {
  const handleSubmit = (e) => {
    e.preventDefault();
    const passengerName = e.target.elements.passenger.value;
    const bookingId = e.target.elements.booking_id.value;

    setInputValue({ passengerName, bookingId });
  };

  return (
    <form onSubmit={handleSubmit} className="flex gap-4">
      <input
        autoFocus
        name="passenger"
        placeholder="Nombre y/o Apellido"
        className="addPackInput"
      />
      <input
        name="booking_id"
        placeholder="ID de Reserva"
        className="addPackInput"
      />
      <button className="button w-fit px-4 text-lg bg-darkBlue text-white hover:shadow-lg transition-al">
        Buscar
      </button>
    </form>
  );
}
