import TableHeader from "./BookingDetailHeader";
import PackageRow from "./PackageRow";

export default function PackageTable({ pack, handleDisplay }) {
  const { title } = pack ?? {};

  return (
    <section>
      <TableHeader title={title} handleDisplay={handleDisplay} />
      <section
        id={title + "-body"}
        className="detailTable mt-2 transition-transform duration-200 origin-top"
      >
        <section className="detailTableRow">
          <PackageRow pack={pack} />
        </section>
      </section>
    </section>
  );
}
