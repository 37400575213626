/* 
import Home from "./componentes/home";
import Vuelos from "./componentes/vuelos";
import Detalle from "./componentes/detalle/Detalle";
import Admin from "./admin/admin";
import Usuarios from "./admin/usuarios";
import Asiento from "./admin/asiento";
import Reservas from "./admin/reservas";
import AdminEditorRouter from "./routes/AdminEditorRouter";
import Caja from "./admin/caja/caja";
import { MisPaquetes } from "./componentes/paquetes/MisPaquetes";
import { Paquetes } from "./componentes/paquetes/Paquetes";
import { BookingDetail } from "./componentes/booking/BookingDetail";
import PanelRouter from "./routes/PanelRouter";
import { QueryClientProvider } from "@tanstack/react-query"; */
//import { createBrowserRouter, RouterProvider, useNavigate, redirect } from "react-router-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Actividades from "./routes/actividades/Actividades";
import AddActividad from "./routes/actividades/AddActividad";
import AddPaquete from "./routes/paquetes/AddPaquete";
import AgregarPago from "./routes/facturacion/AgregarPago";
import Cotizador from "./routes/cotizaciones/Cotizador";
import DetalleOrden from "./routes/panel/DetalleOrden";
import DetallePage from "./routes/vuelos/DetallePage";
import DetallePaquete from "./routes/paquetes/DetallePaquete";
import EditActividad from "./routes/actividades/EditPaquete";
import EditPago from "./routes/facturacion/EditPago";
import EditPaquete from "./routes/paquetes/EditPaquete";
import ErrorPage from "./routes/ErrorPage";
import Facturacion from "./routes/facturacion/Facturacion";
import FaqPage from "./routes/panel/panelConfig/FaqPage";
import File from "./routes/facturacion/File";
import Health from "./routes/health";
import HistorialDetalle from "./routes/historial/HistorialDetalle";
import HistorialHome from "./routes/historial/HistorialHome";
import MisCotizaciones from "./routes/cotizaciones/MisCotizaciones";
import MisPaquetesPage from "./routes/paquetes/MisPaquetesPage";
import Ordenes from "./routes/panel/Ordenes";
import PanelConfigPage from "./routes/panel/panelConfig/PanelConfigPage";
import PanelGeneralAgency from "./routes/panel/panelConfig/PanelProfileGeneralAgency";
import PanelGeneralTourOperator from "./routes/panel/panelConfig/PanelProfileGeneralTourOperator";
import PanelPage from "./routes/panel/PanelPage";
import PanelProfileAgencies from "./routes/panel/panelConfig/PanelProfileAgencies";
import PanelProfileDesign from "./routes/panel/panelConfig/PanelProfileDesign";
import PanelProfileFees from "./routes/panel/panelConfig/PanelProfileFees";
import PanelProfileGeneralAgency from "./routes/panel/panelConfig/PanelProfileGeneralAgency";
import PanelProfileGeneralTourOperator from "./routes/panel/panelConfig/PanelProfileGeneralTourOperator";
import PanelProfileUsers from "./routes/panel/panelConfig/PanelProfileUsers";
import PedidoPage from "./routes/pedido/pedido";
import RequestFormPage from "./routes/requestForm/RequestFormPage";
import RequestHoteles from "./routes/hoteles/RequestHoteles";
import Root from "./routes/root";
import SearchActividades from "./routes/actividades/SearchActividades";
import SearchFlight from "./routes/vuelos/SearchFlight";
import SearchHoteles from "./routes/hoteles/SearchHoteles";
import SearchPackages from "./routes/paquetes/SearchPackage";
import StaffUserPage from "./routes/staff/StaffUserPage";
import ViajerosPage from "./routes/viajeros/ViajerosPage";
import VoteFeaturePreview from "./routes/voteFeature/voteFeaturePreviewPage";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route path="" element={<Root />} />
          <Route path="health" element={<Health />} />
          <Route path=":tour_op_id">
            <Route path="" element={<Root />} />
            <Route path="login" element={<Root />} />
            <Route path=":type" element={<Root />} />
            <Route path="search-flight" element={<SearchFlight />} />
            <Route path="detail" element={<DetallePage />} />
            <Route path="search-packages" element={<SearchPackages />} />
            <Route path="search-hotels" element={<SearchHoteles />} />
            <Route path="hotels-request" element={<RequestHoteles />} />
            <Route path="search-activities" element={<SearchActividades />} />
            <Route path="pedido" element={<PedidoPage />} />
            <Route path="request" element={<RequestFormPage />} />
            <Route path="history">
              <Route path="" element={<HistorialHome />} />
              <Route
                path="detail/:type/:booking_id"
                element={<HistorialDetalle />}
              />
            </Route>
            <Route
              path="package-detail/:package_id"
              element={<DetallePaquete />}
            />

            {/* Admin Routes */}
            <Route path="panel" element={<PanelPage />}>
              <Route index path="" element={<Ordenes />} />
              <Route path="sales">
                {/* Quotes */}
                <Route path="quotes">
                  <Route path="my-quotes">
                    <Route path=":type" element={<MisCotizaciones />} />
                  </Route>
                  <Route path="create-quote" element={<Cotizador />} />
                </Route>
                {/* Orders */}
                <Route path="bookings">
                  <Route path=":type" element={<Ordenes />} />
                  <Route path=":type/:booking_id" element={<DetalleOrden />} />
                </Route>
              </Route>
              {/* Packages */}
              <Route path="packages">
                <Route path="my-packages" element={<MisPaquetesPage />} />
                <Route path="add-package" element={<AddPaquete />} />
                <Route
                  path="update-package/:package_id"
                  element={<EditPaquete />}
                />
              </Route>
              {/* Activities */}
              <Route path="activities">
                <Route path="my-activities" element={<Actividades />} />
                <Route path="add-activity" element={<AddActividad />} />
                <Route
                  path="update-activity/:activity_id"
                  element={<EditActividad />}
                />
              </Route>
              {/* Providers */}
              <Route path="providers">
                <Route path="" element={<VoteFeaturePreview />} />
              </Route>
              {/* Travelers */}
              <Route path="travelers">
                <Route path="" element={<ViajerosPage />} />
              </Route>
              {/* Sales */}
              <Route path="invoicing">
                <Route path="my-invoices" element={<Facturacion />} />
                <Route path="my-invoices/:invoice_id" element={<File />} />
                <Route path="add-payment" element={<AgregarPago />} />
                <Route
                  path="update-payment/:payment_id"
                  element={<EditPago />}
                />
              </Route>
              {/* Sales */}
              {/* <Route path="sales">
                <Route path="" element={<VoteFeaturePreview />} />
              </Route> */}
              {/* Config */}
              <Route path="config">
                <Route path="faq" element={<FaqPage />} />
              </Route>
              {/* Profile */}
              <Route path="profile">
                <Route path="" element={<PanelConfigPage />} />
                <Route path="admin" element={<StaffUserPage />} />
                <Route
                  path="my-tour-operator"
                  element={<PanelProfileGeneralTourOperator />}
                />
                <Route
                  path="my-agency"
                  element={<PanelProfileGeneralAgency />}
                />
                <Route path="fees" element={<PanelProfileFees />} />
                <Route path="agencies" element={<PanelProfileAgencies />} />
                <Route path="users" element={<PanelProfileUsers />} />
                <Route path="design" element={<PanelProfileDesign />} />
              </Route>
            </Route>
          </Route>
          <Route path="not-found" element={<ErrorPage />} />
        </Route>
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
