import { useEffect, useState } from "react";
import { useBookings } from "../../hooks/useBookings";
import { useParams } from "react-router-dom";
import SEO from "../../utils/SEO";
import HistoryHeader from "./HistoryHeader";
import HistorySearcher from "./HistorySearcher";
import HistoryTableRow from "./HistoryTableRow";

export default function History() {
  const { tour_op_id } = useParams();
  const { bookings, getFlightBookings } = useBookings();
  const [inputValue, setInputValue] = useState({
    passengerName: "",
    bookingId: "",
  });

  useEffect(() => {
    getFlightBookings();
  }, []);

  const filteredBookings = bookings?.filter(({ id, Travelers }) => {
    const { passengerName, bookingId } = inputValue;
    const { first_name, last_name } = Travelers[0];

    if (!passengerName && !bookingId) return false;

    const includesNames = passengerName
      ? first_name.toLowerCase().includes(passengerName.toLowerCase()) ||
        last_name.toLowerCase().includes(passengerName.toLowerCase())
      : false;

    const includesID = bookingId ? id === Number(bookingId) : false;

    return (
      (includesNames && !bookingId) ||
      (includesID && !passengerName) ||
      (includesNames && includesID)
    );
  });

  return (
    <div className="min-h-screen flex flex-col">
      <SEO title={"Historial"} />
      <HistoryHeader />

      <main className="m-auto bg-lightBlue rounded-xl p-8 shadow-xl">
        <HistorySearcher setInputValue={setInputValue} />

        <section className="grid gap-2 mt-4 p-4 rounded-lg bg-white border border-gray">
          {filteredBookings.length > 0 ? (
            filteredBookings.map((booking, index) => (
              <HistoryTableRow
                key={booking.id}
                booking={booking}
                tour_op_id={tour_op_id}
                index={index}
              />
            ))
          ) : (
            <h1 className="font-medium text-red-700">
              No hay coincidencias con la búsqueda
            </h1>
          )}
        </section>
      </main>
    </div>
  );
}
