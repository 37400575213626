import { useState } from "react";
import Cookies from "js-cookie";

export const useCookie = (cookieName, initialValue) => {
  const initialCookieValue = Cookies.get(cookieName);

  const [cookieValue, setCookieValue] = useState(
    initialCookieValue ? JSON.parse(initialCookieValue) : initialValue
  );

  const updateCookieValue = (newValue) => {
    setCookieValue(newValue);
    Cookies.set(cookieName, JSON.stringify(newValue), { expires: 7 }); // La cookie expirará en 7 días
  };

  const deleteCookie = () => {
    setCookieValue(initialValue);
    Cookies.remove(cookieName);
  };

  return { cookieValue, updateCookieValue, deleteCookie };
};

export const updateCookie = (cookieName, newValue) => {
  const defaultValue = Cookies.get(cookieName);

  Cookies.set(cookieName, JSON.stringify(newValue || defaultValue), {
    expires: 7,
  });
};

export const getCookieValue = (cookieName) => {
  const cookieValue = Cookies.get(cookieName);

  return cookieValue ? JSON.parse(cookieValue) : null;
};

export const deleteCookie = (cookieName) => {
  const cookie = Cookies.get(cookieName);

  if (!cookie) {
    return;
  }

  Cookies.remove(cookieName);
};

export const updateLocalStorage = (itemName, newValue) => {
  const storedValue = localStorage.getItem(itemName);
  const parsedValue = storedValue ? JSON.parse(storedValue) : null;

  const updatedValue = newValue || parsedValue;

  localStorage.setItem(itemName, JSON.stringify(updatedValue));
};

export const getLocalStorageValue = (itemName) => {
  const storedValue = localStorage.getItem(itemName);

  return storedValue ? JSON.parse(storedValue) : null;
};

export const deleteLocalStorageItem = (itemName) => {
  localStorage.removeItem(itemName);
};

export const updateSessionStorage = (itemName, newValue) => {
  const storedValue = sessionStorage.getItem(itemName);
  const parsedValue = storedValue ? JSON.parse(storedValue) : null;

  const updatedValue = newValue || parsedValue;

  sessionStorage.setItem(itemName, JSON.stringify(updatedValue));
};

export const getSessionStorageValue = (itemName) => {
  const storedValue = sessionStorage.getItem(itemName);

  return storedValue ? JSON.parse(storedValue) : null;
};

export const deleteSessionStorageItem = (itemName) => {
  sessionStorage.removeItem(itemName);
};
