import axios from "axios";
import { useState } from "react";
import { saveTourOperator } from "../utils/saveTourOperator";
import { getLocalStorageValue, getSessionStorageValue } from "./useCookie";

export default function usePayments() {
  const BASE_URL = process.env.REACT_APP_API_URL;
  const tourOP = saveTourOperator((st) => st.tourOP);
  const [loading, setLoading] = useState(false);
  // const [payment, setPayment] = useState([]);
  const [payments, setPayments] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);

  const token =
    getLocalStorageValue("token") || getSessionStorageValue("token");

  const headers = {
    headers: { Authorization: `${token}` },
  };

  const postPayment = async ({ accounting }) => {
    await axios
      .post(BASE_URL + "v2/accounting", { accounting }, headers)
      .then((e) => console.log(e))
      .catch((err) => {
        console.log(err);
        throw new Error(err);
      });
  };

  const getPaymentMethods = async () => {
    const res = await axios
      .get(BASE_URL + "v2/payments-methods")
      .then((e) => {
        console.log(e);
        e?.data && setPaymentMethods(e?.data?.response);
      })
      .catch((error) => console.log(error));
    if (res) {
      return res?.data || {};
    }
  };
  // const getPayment = async (package_id) => {
  //   setLoading(true);
  //   axios
  //     .get(BASE_URL + "v2/payments/detail/" + package_id)
  //     .then((e) => {
  //       console.log(e);
  //       setPayment(e.data.response);
  //     })
  //     .catch((err) => console.log(err))
  //     .finally(() => setLoading(false));
  // };

  const getPayments = async () => {
    setLoading(true);
    await axios
      .get(BASE_URL + "v2/accounting", headers)
      .then((e) => {
        setPayments(e.data.response);
      })
      .catch((err) => {
        console.log(err);
        throw new Error(err);
      })
      .finally(() => setLoading(false));
  };

  const updatePayment = async (payment) => {
    const { id, ...restOfPayment } = payment;

    return await axios
      .patch(
        BASE_URL + `v2/accounting/${id}`,
        {
          payment: { ...restOfPayment },
        },
        headers
      )
      .then((e) => console.log(e));
  };

  const deletePayment = async (id) => {
    return await axios.delete(BASE_URL + `v2/accounting/${id}`, headers);
  };

  return {
    // payment,
    payments,
    paymentMethods: paymentMethods || [],
    loading,
    postPayment,
    // getPayment,
    getPaymentMethods,
    getPayments,
    updatePayment,
    deletePayment,
  };
}
