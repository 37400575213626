export const monthDictionary = (month) => {
  if (month === "ENE") return "01";
  if (month === "FEB") return "02";
  if (month === "MAR") return "03";
  if (month === "ABR") return "04";
  if (month === "MAY") return "05";
  if (month === "JUN") return "06";
  if (month === "JUL") return "07";
  if (month === "AGO") return "08";
  if (month === "SEP") return "09";
  if (month === "OCT") return "10";
  if (month === "NOV") return "11";
  return "12";
};
