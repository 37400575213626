import { useForm } from "react-hook-form";
import { Toaster, toast } from "react-hot-toast";
import { ErrorMessage } from "@hookform/error-message";
import { zodResolver } from "@hookform/resolvers/zod";
import { useTourOperators } from "../../hooks/useTourOperators";
import { useEffect, useState } from "react";
import AutoCompleteCountryName from "../../utils/AutoCompleteCountryName";
import AutoCompleteCountryISO3166 from "../../utils/AutoCompleteCountryISO3166";
import { tourOperatorWithModulesSchema } from "../../schemas/tourOperator";
import { capitalizeString } from "../../utils/capitalizeString";

export default function FormEditTourOperator({ selectedID, closeModal }) {
  const { tourOperator, getTourOperatorByID, updateTourOperator } =
    useTourOperators();
  const [cityInput, setCityInput] = useState("");
  const [countrCodeInput, setCountrCodeInput] = useState("");

  useEffect(() => {
    getTourOperatorByID({ tourOperatorID: selectedID });
  }, [selectedID]);

  const {
    TourOperatorPhones,
    TourOperatorRemarks,
    TourOperatorTicketingAgreements,
    TourOperatorSocialMedia,
    TourOperatorAddresses,
    TourOperatorContacts,
  } = tourOperator || {};

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(tourOperatorWithModulesSchema.partial()),
  });

  useEffect(() => {
    if (tourOperator) {
      for (const [key, value] of Object.entries(tourOperator)) {
        if (key === "TourOperatorModules") {
          const moduleNames = new Set();
          const modules = value.reduce((uniqueModules, module) => {
            if (!moduleNames.has(module.name)) {
              moduleNames.add(module.name);
              uniqueModules.push({
                id: module.id,
                name: module.name,
                active: module.active,
              });
            }
            return uniqueModules;
          }, []);
          setValue("modules", modules);
          continue;
        }
        if (!tourOperatorWithModulesSchema.shape.hasOwnProperty(key)) {
          continue;
        }
        setValue(key, value);
      }
      if (TourOperatorPhones?.[0]?.device_type) {
        setValue("device_type", TourOperatorPhones[0].device_type);
      }
      if (TourOperatorContacts?.[0]?.purpose) {
        setValue("purpose", TourOperatorContacts[0].purpose);
      }
    }
  }, [tourOperator, setValue]);

  useEffect(() => {
    getTourOperatorByID({ tourOperatorID: selectedID }).then((data) => {
      reset(data);
    });
  }, [selectedID, reset]);

  const onSubmit = async (data) => {
    const tourOperator = {
      id: selectedID,
      data: {
        name: data.name,
        logo: data.logo,
        domain: data.domain,
        license: data.license || "Por definir",
        file: data.file || "Por definir",
        country_id: data.country_id,
        email: data.tour_op_email,
      },
      phones: {
        id: TourOperatorPhones[0].id,
        device_type: data.device_type,
        country_calling_code: data.country_calling_code,
        number: data.number,
        tour_op_id: selectedID,
      },

      remarks: {
        id: TourOperatorRemarks[0].id,
        sub_type: data.sub_type,
        text: data.text || "Por definir",
        tour_op_id: selectedID,
      },

      social_medias: [
        {
          id: TourOperatorSocialMedia.find(
            ({ redSocial }) => redSocial === "Facebook"
          ).id,
          redSocial: "Facebook",
          url: data.facebook,
          tour_op_id: selectedID,
        },
        {
          id: TourOperatorSocialMedia.find(
            ({ redSocial }) => redSocial === "Instagram"
          ).id,
          redSocial: "Instagram",
          url: data.instagram,
          tour_op_id: selectedID,
        },
        {
          id: TourOperatorSocialMedia.find(
            ({ redSocial }) => redSocial === "Whatsapp"
          ).id,
          redSocial: "Whatsapp",
          url: data.whatsapp,
          tour_op_id: selectedID,
        },
      ],
      ticketing_agreements: {
        id: TourOperatorTicketingAgreements[0].id,
        option: data.option,
        delay: data.delay ? `${data.delay}D` : "3D",
        tour_op_id: selectedID,
      },

      addresses: {
        id: TourOperatorAddresses[0].id,
        street_name: data.street_name,
        postal_code: data.postal_code,
        city_name: data.city_name,
        country_code: data.country_code,
        tour_op_id: selectedID,
      },

      contacts: {
        id: TourOperatorContacts[0].id,
        addressee_first_name: data.addressee_first_name,
        addressee_last_name: data.addressee_last_name,
        email_address: data.email_address,
        purpose: data.purpose,
        tour_op_id: selectedID,
      },
    };

    toast.promise(
      updateTourOperator(tourOperator)
        .then(() => {
          closeModal();
        })

        .catch((e) => {
          console.log(e);
          throw new Error(e);
        }),
      {
        loading: "Cargando...",
        success: <b>Operadora editada con éxito</b>,
        error: <b>Hubo un error, intenta de nuevo.</b>,
      }
    );
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="mx-auto bg-white rounded-lg"
    >
      <div>
        <Toaster />
      </div>

      <h1 className="text-darkBlue font-semibold text-2xl text-center mb-2">
        Modificar Operadora
      </h1>

      <div className="grid grid-cols-4 gap-2">
        <h2 className="col-span-4 font-semibold text-lg mt-2 -mb-2">
          Datos de la Empresa
        </h2>
        <label className="w-full relative">
          <p>Nombre Comercial*</p>
          <input
            className="addPackInput"
            name="name"
            {...register("name")}
            defaultValue={tourOperator?.name}
          />
          <div className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
            <ErrorMessage errors={errors} name="name" as="p" />
          </div>
        </label>
        <label className="w-full relative">
          <p>Logo* (link)</p>
          <input
            className="addPackInput"
            name="logo"
            {...register("logo")}
            defaultValue={tourOperator?.logo}
          />
          <div className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
            <ErrorMessage errors={errors} name="logo" as="p" />
          </div>
        </label>
        <label className="w-full relative">
          <p>Dominio*</p>
          <input
            className="addPackInput"
            name="domain"
            {...register("domain")}
            defaultValue={tourOperator?.domain}
          />
          <div className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
            <ErrorMessage errors={errors} name="domain" as="p" />
          </div>
        </label>
        <label className="w-full relative">
          <p>Email*</p>
          <input
            className="addPackInput"
            name="tour_op_email"
            {...register("tour_op_email")}
            defaultValue={tourOperator?.email}
          />
          <div className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
            <ErrorMessage errors={errors} name="tour_op_email" as="p" />
          </div>
        </label>
        <label className="w-full relative">
          <p>Legajo</p>
          <input
            className="addPackInput"
            name="license"
            {...register("license")}
            defaultValue={tourOperator?.license}
          />
          <div className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
            <ErrorMessage errors={errors} name="license" as="p" />
          </div>
        </label>
        <label className="w-full relative">
          <p>Link QR Data Fiscal</p>
          <input
            className="addPackInput"
            name="file"
            {...register("file")}
            defaultValue={tourOperator?.file}
          />

          <div className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
            <ErrorMessage errors={errors} name="file" as="p" />
          </div>
        </label>
        <label className="w-full relative">
          <p>País*</p>
          <AutoCompleteCountryName
            classname="addPackInput"
            register={register}
            name="country_id"
            handleAction={(d) => setValue("country_id", d)}
            inputState={[cityInput, setCityInput]}
          />
          <div className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
            <ErrorMessage errors={errors} name="country_id" as="p" />
          </div>
        </label>
        <h2 className="col-span-4 font-semibold text-lg mt-2 -mb-2">
          Contacto
        </h2>
        <label className="w-full relative">
          <p>Dispositivo</p>
          <select
            name="device_type"
            id="device_type"
            className="addPackInput w-full"
            {...register("device_type")}
          >
            <option value="MOBILE">MOBILE</option>
            <option value="LANDLINE">LANDLINE</option>
            <option value="FAX">FAX</option>
          </select>
          <div className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
            <ErrorMessage errors={errors} name="device_type" as="p" />
          </div>
        </label>
        <label className="w-full relative">
          <p>Código de Área* (ej: 54)</p>
          <input
            type="number"
            className="addPackInput"
            name="country_calling_code"
            {...register("country_calling_code")}
            defaultValue={TourOperatorPhones?.[0]?.country_calling_code}
          />
          <div className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
            <ErrorMessage errors={errors} name="country_calling_code" as="p" />
          </div>
        </label>
        <label className="w-full relative">
          <p>Teléfono* (ej: 351...)</p>
          <input
            type="number"
            className="addPackInput"
            name="number"
            {...register("number")}
            defaultValue={TourOperatorPhones?.[0]?.number}
          />
          <div className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
            <ErrorMessage errors={errors} name="number" as="p" />
          </div>
        </label>
        <label className="w-full relative">
          <p>Purpose</p>
          <select
            name="purpose"
            id="purpose"
            className="addPackInput w-full"
            {...register("purpose")}
          >
            <option value="STANDARD">STANDARD</option>
            <option value="INVOICE">INVOICE</option>
            <option value="STANDARD_WITHOUT_TRANSMISSION ">
              STANDARD_WITHOUT_TRANSMISSION
            </option>
          </select>
          <div className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
            <ErrorMessage errors={errors} name="purpose" as="p" />
          </div>
        </label>
        <label className="w-full relative">
          <p>Facebook</p>
          <input
            className="addPackInput"
            name="facebook"
            {...register("facebook")}
            defaultValue={
              TourOperatorSocialMedia?.find(
                (red) => red?.redSocial === "Facebook"
              )?.url
            }
          />
          <div className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
            <ErrorMessage errors={errors} name="facebook" as="p" />
          </div>
        </label>
        <label className="w-full relative">
          <p>Instagram</p>
          <input
            className="addPackInput"
            name="instagram"
            {...register("instagram")}
            defaultValue={
              TourOperatorSocialMedia?.find(
                (red) => red?.redSocial === "Instagram"
              )?.url
            }
          />
          <div className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
            <ErrorMessage errors={errors} name="instagram" as="p" />
          </div>
        </label>
        <label className="w-full relative">
          <p>WhatsApp (link)</p>
          <input
            className="addPackInput"
            name="whatsapp"
            {...register("whatsapp")}
            defaultValue={
              TourOperatorSocialMedia?.find(
                (red) => red?.redSocial === "Whatsapp"
              )?.url
            }
          />
          <div className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
            <ErrorMessage errors={errors} name="whatsapp" as="p" />
          </div>
        </label>
        <h2 className="col-span-4 font-semibold text-lg mt-2 -mb-2">
          Dirección
        </h2>
        <label className="w-full relative">
          <p>Calle*</p>
          <input
            className="addPackInput"
            name="street_name"
            {...register("street_name")}
            defaultValue={TourOperatorAddresses?.[0]?.street_name}
          />
          <div className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
            <ErrorMessage errors={errors} name="street_name" as="p" />
          </div>
        </label>
        <label className="w-full relative">
          <p>Código Postal*</p>
          <input
            className="addPackInput"
            name="postal_code"
            {...register("postal_code")}
            defaultValue={TourOperatorAddresses?.[0]?.postal_code}
          />
          <div className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
            <ErrorMessage errors={errors} name="postal_code" as="p" />
          </div>
        </label>
        <label className="w-full relative">
          <p>Ciudad*</p>
          <input
            className="addPackInput"
            name="city_name"
            {...register("city_name")}
            defaultValue={TourOperatorAddresses?.[0]?.city_name}
          />
          <div className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
            <ErrorMessage errors={errors} name="city_name" as="p" />
          </div>
        </label>
        <label className="w-full relative">
          <p>Código de País*</p>
          <AutoCompleteCountryISO3166
            className="addPackInput"
            register={register}
            name="country_code"
            returnCode={true}
            handleAction={(d) => setValue("country_code", d)}
            inputState={[countrCodeInput, setCountrCodeInput]}
          />
          {/* <input
            className="addPackInput"
            name="country_code"
            defaultValue={"AR"}
            {...register("country_code")}
          />
          <a
            href="https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2"
            target="_blank"
            rel="noreferrer"
            className="block text-xs underline"
          >
            ISO Country Codes
          </a> */}
          <div className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
            <ErrorMessage errors={errors} name="country_code" as="p" />
          </div>
        </label>
        <h2 className="col-span-4 font-semibold text-lg mt-2 -mb-2">
          Datos del Administrador
        </h2>
        <label className="w-full relative">
          <p>Nombre Encargado*</p>
          <input
            className="addPackInput"
            name="addressee_first_name"
            {...register("addressee_first_name")}
            defaultValue={TourOperatorContacts?.[0]?.addressee_first_name}
          />
          <div className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
            <ErrorMessage errors={errors} name="addressee_first_name" as="p" />
          </div>
        </label>
        <label className="w-full relative">
          <p>Apellido Encargado*</p>
          <input
            className="addPackInput"
            name="addressee_last_name"
            {...register("addressee_last_name")}
            defaultValue={TourOperatorContacts?.[0]?.addressee_last_name}
          />
          <div className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
            <ErrorMessage errors={errors} name="addressee_last_name" as="p" />
          </div>
        </label>
        <label className="w-full relative">
          <p>Email*</p>
          <input
            className="addPackInput"
            name="email_address"
            {...register("email_address")}
            defaultValue={TourOperatorContacts?.[0]?.email_address}
          />
          <div className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
            <ErrorMessage errors={errors} name="email_address" as="p" />
          </div>
        </label>

        <h2 className="col-span-4 font-semibold text-lg mt-2 -mb-2">
          Datos de Amadeus
        </h2>

        <label className="w-full relative col-span-2">
          <p>queuingOfficeId (no funciona)</p>
          <input
            className="addPackInput"
            placeholder="NCE1A0955"
            // name="addressee_last_name"
            // {...register("addressee_last_name")}
          />
          <div className="absolute text-xs -bottom-[15px] left-4 whitespace-nowrap">
            {/* <ErrorMessage errors={errors} name="addressee_last_name" as="p" /> */}
            <p>office Id where to queue the order</p>
          </div>
        </label>
        <label className="w-full relative col-span-2">
          <p>ownerOfficeId (no funciona)</p>
          <input
            className="addPackInput"
            placeholder="NCE1A0955"
            // name="addressee_last_name"
            // {...register("addressee_last_name")}
          />
          <div className="absolute text-xs -bottom-[15px] left-4 whitespace-nowrap">
            {/* <ErrorMessage errors={errors} name="addressee_last_name" as="p" /> */}
            <p>office Id where will be transfered the ownership of the order</p>
          </div>
        </label>
        {/* <label className="w-full relative">
          <p>Subtipo</p>
          <select
            className="addPackInput w-full"
            name="sub_type"
            {...register("sub_type")}
          >
            <option value="GENERAL_MISCELLANEOUS">GENERAL_MISCELLANEOUS</option>
            <option value="CONFIDENTIAL">CONFIDENTIAL</option>
            <option value="INVOICE">INVOICE</option>
            <option value="QUALITY_CONTROL">QUALITY_CONTROL</option>
            <option value="BACKOFFICE">BACKOFFICE</option>
            <option value="FULFILLMENT">FULFILLMENT</option>
            <option value="ITINERARY">ITINERARY</option>
            <option value="TICKETING_MISCELLANEOUS">
              TICKETING_MISCELLANEOUS
            </option>
            <option value="TOUR_CODE">TOUR_CODE</option>
          </select>
          <div className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
            <ErrorMessage errors={errors} name="sub_type" as="p" />
          </div>
        </label>
        <label className="w-full relative">
          <p>Texto</p>
          <input
            className="addPackInput"
            placeholder="Notas y/o aclaraciones"
            name="text"
            {...register("text")}
          />
          <div className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
            <ErrorMessage errors={errors} name="text" as="p" />
          </div>
        </label>
        <label className="w-full relative">
          <p>OPCION</p>
          <select
            className="addPackInput w-full"
            name="option"
            {...register("option")}
          >
            <option value="DELAY_TO_QUEUE" selected>
              DELAY_TO_QUEUE
            </option>
            <option value="DELAY_TO_CANCEL ">DELAY_TO_CANCEL </option>
            <option value="CONFIRM">CONFIRM</option>
          </select>
          <div className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
            <ErrorMessage errors={errors} name="option" as="p" />
          </div>
        </label>
        <label className="w-full relative">
          <p>Días de demora</p>
          <input className="addPackInput" name="delay" {...register("delay")} />
          <div className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
            <ErrorMessage errors={errors} name="delay" as="p" />
          </div>
        </label> */}

        <button className="col-span-4 font-semibold text-lg ml-auto mt-4 button w-1/2 bg-darkBlue text-white hover:shadow-lg transition-all">
          Guardar Operadora
        </button>
      </div>
    </form>
  );
}
