import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useEffect, useState } from "react";
import {useTranslation} from "react-i18next"
import LanguageButtons from "../LanguageButtons";

const schema = z.object({
  quote: z.object({
    type: z.string().nonempty("Selecciona una opción"), //lo agregue para el tipo de cotizacion
    askItineraries: z.object({
      priority: z.string().nonempty("Selecciona una opción"),
      departure: z.string().nonempty("Campo requerido"),
      arrival: z.string().nonempty("Campo requerido"),
      dates: z.string().nonempty("Campo requerido"),
      numberOfStops: z.string(),
      duration: z.number(),
      checkedBags: z.string(),
      budget: z.string().nonempty("Campo requerido"),
      comments: z.string(),
      nextstep: z.string().nonempty("Campo requerido"),
    }),
    travelers: z.array(
      z.object({
        data: z.object({
          first_name: z.string().nonempty("Nombre es requerido"),
          email_address: z
            .string()
            .nonempty("Email requerido")
            .email("Email inválido"),
        }),
        contact_phones: z.array(
          z.object({
            number: z
              .string()
              .nonempty("Numero de WhatsApp requerido")
              .min(10, "Número de WhatsApp inválido")
          })
        ),
      })
    ),
  }),
});

const RequestForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema)
  });
  const [sendingForm, setSendingForm] = useState(false)
  const { t } = useTranslation("global")

useEffect(() => {
  console.log('errors', errors)
})
  
  const onSubmit = (data) => {
    console.log("data", data);
    setSendingForm(true)
    const formData = {
        quote: {
         creation_date: new Date(),
         status: "Quote",
         comments: data.quote.askItineraries.comments,
         type: data.quote.type,
         tour_op_id: 1,
         askItineraries:
         {
          departure: data.quote.askItineraries.departure,
          arrival: data.quote.askItineraries.arrival,
          dates: data.quote.askItineraries.dates,
          priority: data.quote.askItineraries.priority ,
          numberOfStops: data.quote.askItineraries.numberOfStops,
          duration: data.quote.askItineraries.duration,
          checkedBags: data.quote.askItineraries.checkedBags,
          budget: data.quote.askItineraries.budget,
          comments: data.quote.askItineraries.comments,
          nextstep: data.quote.askItineraries.nextstep,
         },
         travelers: [
          {
            data: {
             first_name: data.quote.travelers[0]?.data.first_name,
             email_address: data.quote.travelers[0]?.data.email_address,
            },
            contact_phones: [
              {
                // device_type: "cellphone",
                // country_calling_code: "54",
                number: data.quote.travelers[0]?.contact_phones[0]?.number,
                // traveler_id: 1
               }
             ],
            }
            ]
           },
         user_id: 1,
       }

       console.log('form data', formData)
       setSendingForm(false)
    }
   

  return (
    <div className="flex flex-col w-full items-center justify-center h-auto font-body">
      <section className="flex flex-col lg:w-[40%] sm:w-[95%] lg:p-0 p-3">
        <div className="flex flex-row justify-between">
        <h1 className="font-semibold text-left lg:text-5xl text-3xl text-darkGray lg:mt-28 md:mt-24 mt-8">
          {t("form_request.title")}
        </h1>
        <LanguageButtons />    
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className="mt-6">
          <div
            className={`flex flex-col w-full  items-start gap-1.5 mt-3 h-auto`}
          >
            <p
              className={`w-full h-5  text-md font-semibold leading-6 tracking-normal text-darkGray`}
            >
              {t("form_request.type.description")}
            </p>
            <div className="flex flex-row gap-8">
              <article className="flex flex-row items-center  cursor-pointer">
                <input type="radio" id="vuelo" value={'Vuelo'} {...register("quote.type")} />
                <label
                  htmlFor="vuelo"
                  className="text-sm font-normal leading-6 tracking-wide text-darkGray ml-2"
                >
                  {t("form_request.type.op1")}
                </label>
              </article>
              <article className="flex flex-row items-center cursor-pointer">
                <input type="radio" id="hoteles" value={'Hoteles'} {...register("quote.type")}/>
                <label
                  htmlFor="hoteles"
                  className="text-sm font-normal leading-6 tracking-wide text-darkGray ml-2"
                >
                  {t("form_request.type.op2")}
                </label>
              </article>
              <article className="flex flex-row items-center cursor-pointer">
                <input type="radio" id="paquete" value={'Paquete'} {...register("quote.type")}/>
                <label
                  htmlFor="paquete"
                  className="text-sm font-normal leading-6 tracking-wide text-darkGray ml-2"
                >
                  {t("form_request.type.op3")}
                </label>
              </article>
            </div>
            {errors?.quote?.type &&
            <span className="text-red-600 text-sm font-body">{errors.quote.type.message}</span> }
          </div>

          {/* ------------ origen ---------- */}
          <div
            className={`flex flex-col w-full  items-start gap-1.5 mt-6 h-36`}
          >
            <label
              className={`w-full h-5  text-sm font-semibold leading-6 tracking-wide text-darkGray`}
            >
              {t("form_request.departure.description")}
            </label>
            <textarea
              {...register("quote.askItineraries.departure", {
                required: true,
              })}
              className={
                "border h-40 rounded border-gray2 w-full text-md font-normal leading-tight focus:outline-none bg-white text-darkGray p-3"
              }
              placeholder={
                t("form_request.departure.placeholder")
              }
            ></textarea>
            {errors?.quote?.askItineraries?.departure &&
            <span className="text-red-600 text-sm font-body">{errors.quote.askItineraries.departure.message}</span> }
          </div>

          {/* ------------- destino --------- */}
          <div className={`flex flex-col w-full items-start gap-1.5 mt-5 h-36`}>
            <label
              className={`w-full h-5 text-sm font-semibold tracking-wide leading-6 text-darkGray`}
              htmlFor="arrival"
            >
              {t("form_request.arrival.description")}
            </label>
            <textarea
            id="arrival"
              {...register("quote.askItineraries.arrival", {
                required: true,
              })}
              className={
                "border h-40 rounded border-gray2 w-full text-md font-normal leading-tight focus:outline-none bg-white text-darkGray p-3"
              }
              placeholder={
                t("form_request.arrival.placeholder")
              }
            ></textarea>
                       {errors?.quote?.askItineraries?.arrival &&
            <span className="text-red-600 text-sm font-body">{errors.quote.askItineraries.arrival.message}</span> }
          </div>

          {/* -------- fechas --------- */}
          <div className={`flex flex-col w-full items-start gap-1.5 mt-5 h-36`}>
            <label
            htmlFor="dates"
              className={`w-full h-5 text-sm font-semibold tracking-wide leading-6 text-darkGray`}
            >
              {t("form_request.dates.description")}
            </label>
            <textarea
            id="dates"
              {...register("quote.askItineraries.dates", {
                required: true,
              })}
              className={
                "border h-40 rounded border-gray2 w-full text-md font-normal leading-tight focus:outline-none bg-white text-darkGray p-3"
              }
              placeholder={
                t("form_request.dates.placeholder")
              }
            ></textarea>
                       {errors?.quote?.askItineraries?.dates &&
            <span className="text-red-600 text-sm font-body">{errors.quote.askItineraries.dates.message}</span> }
          </div>

          {/* --------- prioridades ------ */}
          <div
            className={`flex flex-col w-full  items-start gap-1.5 mt-5 h-auto`}
          >
            <p
              className={`w-full h-auto text-sm font-semibold leading-6 tracking-wide text-darkGray`}
            >
              {t("form_request.priority.description")}
            </p>
            <div className="flex flex-col gap-2">
              <article className="flex flex-row items-center cursor-pointer">
                <input type="radio" id="MenosEscalas" value={'Menos Escalas'} {...register("quote.askItineraries.priority")}/>
                <label
                  htmlFor="MenosEscalas"
                  className="text-sm font-normal leading-6 tracking-wide text-darkGray ml-2"
                >
                  {t("form_request.priority.op1")}
                </label>
              </article>
              <article className="flex flex-row items-center cursor-pointer">
                <input type="radio" id="MenorPrecioVuelo" value={"Menor precio vuelo"} {...register("quote.askItineraries.priority")}/>
                <label
                  htmlFor="MenorPrecioVuelo"
                  className="text-sm font-normal leading-6 tracking-wide text-darkGray ml-2"
                >
                  {t("form_request.priority.op2")}
                </label>
              </article>
              <article className="flex flex-row items-center cursor-pointer">
                <input type="radio" id="MenorDuracionRecorrido" value={'Menor duración recorrido'}{...register("quote.askItineraries.priority")}/>
                <label
                  htmlFor="MenorDuracionRecorido"
                  className="text-sm font-normal leading-6 tracking-wide text-darkGray ml-2"
                >
                  {t("form_request.priority.op3")}
                </label>
              </article>
              <article className="flex flex-row items-center cursor-pointer">
                <input type="radio" id="MenosHorasEnEscala" value={'Menos horas en escala'} {...register("quote.askItineraries.priority")}/>
                <label
                  htmlFor="MenosHorasEnEscala"
                  className="text-sm font-normal leading-6 tracking-wide text-darkGray ml-2"
                >
                 {t("form_request.priority.op4")}
                </label>
              </article>
              <article className="flex flex-row items-center cursor-pointer">
                <input type="radio" id="MasEquipaje" value={'Mas equipaje'} {...register("quote.askItineraries.priority")}/>
                <label
                  htmlFor="MasEquipaje"
                  className="text-sm font-normal leading-6 tracking-wide text-darkGray ml-2"
                >
                  {t("form_request.priority.op5")}
                </label>
              </article>
              <article className="flex flex-row items-center cursor-pointer">
                <input type="radio" id="ElegirAerolinea" value={'Elegir Aerolinea'} {...register("quote.askItineraries.priority")}/>
                <label
                  htmlFor="ElegirAerolinea"
                  className="text-sm font-normal leading-6 tracking-wide text-darkGray ml-2"
                >
                  {t("form_request.priority.op6")}
                </label>
              </article>
            </div>
            {errors?.quote?.askItineraries?.priority &&
            <span className="text-red-600 text-sm font-body">{errors.quote.askItineraries.priority.message}</span> }
          </div>


          <div className="mb-4 flex flex-col lg:flex-row items-center justify-between gap-2 mt-5">
        <div className="w-full">
          <label className="mb-1 block font-semibold text-darkGray text-sm" htmlFor="stops">
           {t("form_request.escalas.description")}
          </label>
          <select
            className="rounded border border-gray2 px-3 py-2 w-full text-sm text-darkGray cursor-pointer"
            id="stops"
            {...register("quote.askItineraries.numberOfStops")}
          >
            <option className="text-darkGray text-sm p-1.5" defaultChecked>{t("form_request.escalas.op1")}</option>
            <option className="text-darkGray text-sm p-1.5">{t("form_request.escalas.op2")}</option>
            <option className="text-darkGray text-sm p-1.5">{t("form_request.escalas.op3")}</option>
            <option className="text-darkGray text-sm p-1.5">{t("form_request.escalas.op4")}</option>
          </select>
        </div>


        <div className="w-full">
          <label className="mb-1 block font-semibold text-darkGray text-sm cursor-pointer" htmlFor="duration">
            {t("form_request.flightsMaxTime")}
          </label>
          <input
            className="text-start rounded border border-gray2 px-3 py-2  w-full  text-sm text-darkGray"
            type="number"
            min={0}
            id="duration"
            {...register("quote.askItineraries.duration", { valueAsNumber: true })}
          />
        </div>


        <div className="w-full">
          <label className="mb-1 block font-semibold text-darkGray text-sm" htmlFor="luggage">
            {t("form_request.luggage.description")}
          </label>
          <select
            className="rounded border border-gray2 px-3 py-2 w-full text-sm text-darkGray cursor-pointer"
            id="luggage"
            {...register("quote.askItineraries.checkedBags")}
            style={{ padding: '8px 12px', lineHeight: '1.5', cursor: 'pointer' }}
          >
            <option className="text-darkGray text-sm " >{t("form_request.luggage.op1")}</option>
            <option className="text-darkGray text-sm ">{t("form_request.luggage.op2")}</option>
            <option className="text-darkGray text-sm" defaultChecked>{t("form_request.luggage.op3")}</option>
          </select>
        </div>
      </div>

          {/* --------- presupuesto .----------- */}
          <div
            className={`flex flex-col w-full  items-start gap-1.5 mt-5 h-36`}
          >
            <label
              className={`w-full h-5  text-sm font-semibold leading-6 tracking-wide text-darkGray`}
            >
              {t("form_request.budget.description")}
            </label>
            <textarea
              {...register("quote.askItineraries.budget", {
                required: true,
              })}
              className={
                "border h-40 rounded border-gray2 w-full text-md font-normal leading-tight focus:outline-none bg-white text-darkGray p-3"
              }
              placeholder={
                t("form_request.budget.placeholder")
              }
            ></textarea>
                       {errors?.quote?.askItineraries?.budget &&
            <span className="text-red-600 text-sm font-body">{errors.quote.askItineraries.budget.message}</span> }
          </div>

          {/* ------- comentarios .----------- */}
          <textarea
            {...register("quote.askItineraries.comments")}
            className={
              "border max-h-24 mt-3 rounded border-gray2 w-full text-md font-normal leading-tight focus:outline-none bg-white text-darkGray p-3"
            }
            placeholder={t("form_request.comments.placeholder")}
          ></textarea>

          {/* --------------- datos de contacto --------- */}
          <section className="mt-6">
            <h2 className="text-darkGray text-md font-semibold mt-5">
              {t("form_request.contactInfo.description")}
            </h2>
            <div
              className={`flex flex-col w-full items-start gap-1.5 mt-3 h-auto`}
            >
              <label
              htmlFor="first_name"
                className={`w-full h-5 text-sm font-semibold tracking-wide leading-6 text-darkGray`}
              >
               {t("form_request.contactInfo.op1.title")}
              </label>
              <input
              id="first_name"
                className="border border-gray2 w-full rounded p-2"
                type="text"
                {...register("quote.travelers[0].data.first_name", {
                  required: true,
                })}
                placeholder={t("form_request.contactInfo.op1.placeholder")}
              />
                         {errors?.quote?.travelers?.[0]?.data?.first_name &&
            <span className="text-red-600 text-sm font-body">{errors.quote.travelers[0].data.first_name.message}</span> }
            </div>

            <div className="flex flex-col w-full items-start gap-1.5 mt-3 h-auto">
              <label className="w-full h-5 text-sm font-semibold tracking-wide leading-6 text-darkGray"
              htmlFor="email">
                E-mail
              </label>
              <input
                className="border border-gray2 w-full rounded p-2"
                type="email"
                {...register("quote.travelers[0].data.email_address", {
                  required: true,
                })}
                placeholder="E-mail"
              />
              {errors.quote?.travelers?.[0]?.data?.email_address &&
            <span className="text-red-600 text-sm font-body">{errors.quote.travelers[0].data.email_address.message}</span> }
            </div>

            <div
              className="flex flex-col w-full items-start gap-1.5 mt-3 h-auto"
            >
              <label
                className="w-full h-5 text-sm font-semibold tracking-wide leading-6 text-darkGray"
              >
                WhatsApp
              </label>
              <input
                className="border border-gray2 w-full rounded p-2"
                type="text"
                {...register("quote.travelers[0].contact_phones[0].number", {
                  required: true,
                })}
                placeholder="(+54…)"
              />
              {errors.quote?.travelers?.[0]?.contact_phones?.[0]?.number &&
            (<span className="text-red-600 text-sm font-body">{errors.quote.travelers[0].contact_phones[0].number.message}</span>) }
            </div>

          </section>

          <div className="flex flex-col mt-3">
            <p className="w-full h-auto text-sm font-semibold leading-6 tracking-wide text-darkGray">
            {t("form_request.nextStep.description")}
                </p>

                <div className="flex flex-col gap-2 mt-2">
              <article className="flex flex-row items-center cursor-pointer">
                <input type="radio" id="nada" value={'Nada'} {...register("quote.askItineraries.nextstep")}/>
                <label
                  htmlFor="nada"
                  className="text-sm font-normal leading-6 tracking-wide text-darkGray ml-2"
                >
                  {t("form_request.nextStep.op1")}
                </label>
              </article>

              <article className="flex flex-row items-center cursor-pointer">
                <input type="radio" id="notificar" value={'Notificar por email'} {...register("quote.askItineraries.nextstep")}/>
                <label
                  htmlFor="notificar"
                  className="text-sm font-normal leading-6 tracking-wide text-darkGray ml-2"
                >
                  {t("form_request.nextStep.op2")}
                </label>
              </article>

              <article className="flex flex-row items-center cursor-pointer">
                <input type="radio" id="reservar" value={'Reservar'} {...register("quote.askItineraries.nextstep")}/>
                <label
                  htmlFor="reservar"
                  className="text-sm font-normal leading-6 tracking-wide text-darkGray ml-2"
                >
                  {t("form_request.nextStep.op3")}
                </label>
              </article>

              <article className="flex flex-row items-center cursor-pointer">
                <input type="radio" id="comprar" value={'Comprar'} {...register("quote.askItineraries.nextstep")}/>
                <label
                  htmlFor="comprar"
                  className="text-sm font-normal leading-6 tracking-wide text-darkGray ml-2"
                >
                {t("form_request.nextStep.op4")}
                </label>
              </article>

              {errors?.quote?.askItineraries?.nextstep &&
            <span className="text-red-600 text-sm font-body">{errors.quote.askItineraries.nextstep.message}</span> }

              </div>
          </div>

          <button
            type="submit"
            className="lg:w-[120px] w-full bg-brightBlue hover:bg-darkBlue tracking-widest text-[14px] text-white px-4 py-2 rounded text-center h-auto my-8"
          >
            {sendingForm ? t("form_request.button.sending") : t("form_request.button.send")}
          </button>
        </form>
      </section>
    </div>
  );
};

export default RequestForm;
