import { useEffect } from "react";
import { useGetHotels } from "../../hooks/useGetHotels";
import { Footer } from "../landing/Footer";
import SEO from "../../utils/SEO";
import Head from "../head";
// import { BuscadorPaquetes } from "../packages/BuscadorPaquetes";
import { HotelSearch } from "./HotelSearch";
import HotelsItem from "./HotelsItem";
import Anicarga from "../anicarga";

export default function Hotels() {
  const { loading, hotels, getJuniperHotels } = useGetHotels();

  useEffect(() => {
    getJuniperHotels();
  }, []);

  if (loading) {
    return (
      <div>
        <Head />
        <div className="w-full flex mt-32 justify-center">
          <Anicarga />
        </div>
      </div>
    );
  }

  return (
    <main className="font-body">
      <SEO title={"Hoteles"} />
      <Head />
      <div className="mx-auto flex gap-10 my-10 max-w-[1338.46px]">
        <div className="flex flex-col gap-4">
          <HotelSearch />
        </div>
        <div className="w-full flex flex-col gap-8">
          <div className="font-body flex justify-between w-full gap-4 bg-lightBlue/30 rounded-lg p-4">
            <h3>Busca entre los mejores hoteles a los precios mas bajos!</h3>
          </div>
          {hotels.length === 0 && <h1>No hay hoteles disponibles</h1>}
          {hotels.length !== 0 &&
            hotels.map((hotel) => {
              return <HotelsItem key={hotel.$.Code} entry={hotel} />;
            })}
        </div>
      </div>
      <Footer />
    </main>
  );
}
