import numberWithCommas from "../../utils/numberWithCommas";

export default function OptionQuoteFees({
  details,
  price,
  exchangeRate,
  numberOfPassengers,
  thisOption,
  wider,
  toggleVisibility,
}) {
  const { dolar, euro } = exchangeRate;
  const thisId = thisOption.segments[0].id;
  const { totalBase, totalFee, currency } =
    details[`${thisId}-${price}`].totalPrices;

  const grandTotal =
    currency === "EUR"
      ? Number(price) * Number(numberOfPassengers)
      : Number(price);

  const totalToARS = (number) => (currency === "ARS" ? number : euro * number);

  return (
    <section className="flex flex-col w-full">
      {/* <button
        onClick={() => toggleVisibility()}
        className="ml-auto font-semibold underline"
      >
        Más tarifas
      </button> */}

      {/* DESCOMENTAR CUANDO HAYA CONVERSOR DE DIVISAS ---------------- */}
      {/* {currency === "EUR" && (
        <div className="text-xs">
          <p>
            Original Total: {currency} {Number(price).toFixed(2)} * {euro}
          </p>
          <p>Cotización BNA.</p>
        </div>
      )} */}
      {/* ----------------------------------------- */}

      <div className="flex justify-between gap-2">
        <p className={`font-semibold ${!wider && "text-sm"}`}>
          ADT x{details[`${thisId}-${price}`].travelerPricings.length}
        </p>
        {/* <p className={wider ? "text-sm" : "text-xs"}>
          {currency === "EUR" ? "~" : ""}
          {numberWithCommas(totalToARS(totalBase), 0)} ARS
        </p> */}

        {/* --------------------------- */}
        <p className={wider ? "text-sm" : "text-xs"}>
          {numberWithCommas(totalBase, 0)} EUR
        </p>
        {/* --------------------------- */}
      </div>
      <div className="flex justify-between gap-2">
        <p className={`font-semibold ${!wider && "text-sm"}`}>IMP</p>
        {/* <p className={wider ? "text-sm" : "text-xs"}>
          {currency === "EUR" ? "~" : ""}
          {numberWithCommas(totalToARS(totalFee), 0)} ARS
        </p> */}

        {/* --------------------------- */}
        <p className={wider ? "text-sm" : "text-xs"}>
          {numberWithCommas(totalFee, 0)} EUR
        </p>
        {/* --------------------------- */}
      </div>
      <div className="flex justify-between gap-2">
        <p className={`font-semibold ${!wider && "text-sm"}`}>FEE</p>
        <p className={wider ? "text-sm" : "text-xs"}>
          {/* {currency === "EUR" ? "~" : ""}0 ARS */}0 EUR
        </p>
      </div>
      <div className="flex justify-between">
        <p className={`font-semibold ${!wider && "text-sm"}`}>COM</p>
        <p className={wider ? "text-sm" : "text-xs"}>
          {/* (0%) {currency === "EUR" ? "~" : ""}0 ARS */}
          (0%) 0 EUR
        </p>
      </div>
      <div className="flex justify-between">
        <p className={`font-semibold ${!wider && "text-sm"}`}>OVR</p>
        <p className={wider ? "text-sm" : "text-xs"}>
          {/* (0.00%) {currency === "EUR" ? "~" : ""}0 ARS */}
          (0.00%) 0 EUR
        </p>
      </div>

      {/* ---------- ONLY LAPTOP ----------------- */}
      <section className="block mt-4 ml-auto 2xl:hidden">
        <p className={`text-right ${wider ? "text-sm" : "text-xs"}`}>
          Comisión del 0%
        </p>
        <p
          className={`text-brightBlue font-[700] transition-all ${
            wider ? "text-2xl 2xl:text-4xl" : "text-lg 2xl:text-2xl"
          }`}
        >
          {/* {currency === "EUR" ? "~" : ""}
          {numberWithCommas(totalToARS(grandTotal), 0)}
          <span
            className={`text-brightBlue transition-all ${
              wider ? "text-base 2xl:text-lg" : "text-sm"
            }`}
          >
            ARS
          </span> */}

          {/* --------------------------- */}
          {numberWithCommas(grandTotal, 0)}
          <span
            className={`text-brightBlue transition-all ${
              wider ? "text-base 2xl:text-lg" : "text-sm"
            }`}
          >
            EUR
          </span>
          {/* --------------------------- */}
        </p>
      </section>
    </section>
  );
}
