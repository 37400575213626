/* eslint-disable react-hooks/exhaustive-deps */
// eslint-disable-next-line react-hooks/exhaustive-deps

import mochila from "../../iconos/mochila.png";
import equimano from "../../iconos/equimano.png";
import equivalija from "../../iconos/equivalija.png";
import valijas from "../../iconos/valijas_gris.png";
import idaIcon from "../../iconos/salida-del-avion.png";
import vueltaIcon from "../../iconos/llegada-del-avion.png";
import { useSelectFlights } from "../flights/useSelectFlights";
import { format } from "date-fns";
import { ModalGeneric } from "../../utils/ModalGeneric";
import { useToggleVisibility } from "../../hooks/useToggleVisibility";
import TramoModal from "./TramoModal";
import { AIRLINES_DICTIONARY } from "../../utils/dictionaries/airlinesDictionary";
import ImageComponent from "../../utils/ImageComponent";

export const Tramo = ({
  type,
  travelerPricing,
  title,
  ciudadesFormatted,
  airlinesFormatted,
}) => {
  const [isVisible, toggleVisibility] = useToggleVisibility();
  const flight = useSelectFlights((st) => st?.flight);

  const firstFlight = flight?.[type]?.segments?.[0];
  const flDeparture = firstFlight?.departure;
  const lastFlight = flight?.[type]?.segments?.at(-1);
  const flArrival = lastFlight?.arrival;
  const scales = flight?.[type]?.segments?.length - 1;
  const carrierCode = flight?.[type]?.segments?.[0]?.carrierCode;

  const firstPassenger = travelerPricing?.[0];
  const flightsDetails =
    type === "IDA"
      ? firstPassenger.fareDetailsBySegment.slice(0, scales + 1)
      : firstPassenger.fareDetailsBySegment.slice(scales + 1);

  const howManyPCs = flightsDetails
    .map((el) => el.includedCheckedBags.quantity)
    .sort((a, b) => a - b);

  const data =
    AIRLINES_DICTIONARY.hasOwnProperty(carrierCode) &&
    AIRLINES_DICTIONARY[carrierCode];

  const escalas =
    scales === 0 ? "Directo" : scales === 1 ? "1 escala" : "escalas";

  const logo =
    airlinesFormatted[carrierCode] &&
    JSON.parse(airlinesFormatted?.[carrierCode]?.logo)?.data;

  return (
    <div className={`py-4 ${type === "Vuelta" && "border-t border-gray2"}`}>
      <div className="flex flex-col gap-1 my-1 ">
        <span className="flex items-center gap-2">
          <img
            src={type === "IDA" ? idaIcon : vueltaIcon}
            alt={type === "IDA" ? "idaIcon" : "vueltaIcon"}
            className="w-6"
          />
          <h2 className="font-medium text-left text-black">
            {ciudadesFormatted?.[flDeparture?.iataCode]?.airport} -{" "}
            {ciudadesFormatted?.[flArrival?.iataCode]?.airport}
          </h2>
        </span>
        {type !== "paquete" ? (
          <div className="flex gap-6 items-center justify-between mt-4">
            {logo ? (
              <ImageComponent image={logo} fallbackMessage={carrierCode} />
            ) : (
              <span className="w-fit px-2 font-medium whitespace-nowrap rounded border border-gray bg-white">
                {carrierCode}
              </span>
            )}
            <div className="flex flex-col">
              <span className="flex justify-between items-center gap-4">
                <p className="text-xs bg-lightBlue px-1 rounded">
                  {flDeparture?.at
                    ? format(new Date(flDeparture?.at), "dd MMM. yy")
                    : ""}{" "}
                </p>
                <p className="text-xs bg-lightBlue px-1 rounded">
                  {flArrival?.at
                    ? format(new Date(flArrival?.at), "dd MMM. yy")
                    : ""}{" "}
                </p>
              </span>
              <span className="flex items-center justify-between">
                <p className="text-2xl font-semibold">
                  {flDeparture?.at
                    ? format(new Date(flDeparture?.at), "HH:mm")
                    : ""}{" "}
                </p>
                <p> - </p>
                <p className="text-2xl font-semibold">
                  {flArrival?.at
                    ? format(new Date(flArrival?.at), "HH:mm")
                    : ""}{" "}
                </p>
              </span>
              <p>Operado por {data ? data.label : carrierCode}</p>
            </div>
            <button
              className="border px-2 py-1 rounded h-fit hover:shadow-md transition-shadow"
              onClick={() => {
                toggleVisibility();
              }}
            >
              {scales > 1 && scales} {escalas}
            </button>
            <div className="flex flex-col gap-1 items-center">
              {howManyPCs[0] > 0 ? (
                <div className="flex gap-[2px] items-baseline">
                  <img className="w-5" src={mochila} alt="mochila" />
                  <img
                    className="w-5 h-10"
                    src={equimano}
                    alt="valija de mano"
                  />
                  <img
                    className="h-10 w-5"
                    src={equivalija}
                    alt="valija grande"
                  />
                </div>
              ) : (
                <img src={valijas} alt="Valijas" className="h-12" />
              )}
              <p className="text-[12px] m-0 font-medium ">
                PC: {howManyPCs[0]}
              </p>
            </div>
          </div>
        ) : null}
      </div>
      <ModalGeneric show={isVisible} toggle={toggleVisibility}>
        <TramoModal
          type={type}
          data={travelerPricing}
          duration={flight?.[type]?.duration}
          title={title}
          formattedCities={ciudadesFormatted}
        />
      </ModalGeneric>
    </div>
  );
};
