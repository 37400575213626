import { format } from "date-fns";

export default function OptionQuoteRow({ data, details, price, index, wider }) {
  const { departure, arrival, carrierCode, number } = data;
  const dataDetails = details[`${data.id}-${price}`];

  return (
    <section className="flex items-center text-darkBlue">
      <div className="flex items-center">
        <p className={`font-[700] ${wider ? "w-10" : "text-sm w-8"}`}>
          {index === 0 && departure.iataCode}
        </p>
        <p className="font-[700] w-3 mx-1">{index === 0 && " > "}</p>
        <p className={`font-[700] mr-3 ${wider ? "w-10" : "text-sm w-8"}`}>
          {arrival.iataCode}
        </p>
        <p className={`${wider ? "w-14 text-sm" : "w-12 text-xs"} uppercase`}>
          {format(new Date(departure.at), "dd MMM")}
        </p>
        <p className={`${wider ? "w-10 text-sm" : "w-9 text-xs"} uppercase`}>
          {format(new Date(departure.at), "HH:mm")}
        </p>
        <strong className="mx-1">{">"}</strong>
        <p className={`${wider ? "w-14 text-sm" : "w-12 text-xs"} uppercase`}>
          {format(new Date(arrival.at), "dd MMM")}
        </p>
        <p className={`${wider ? "w-10 text-sm" : "w-9 text-xs"} uppercase`}>
          {format(new Date(arrival.at), "HH:mm")}
        </p>
      </div>
      <div
        className={`flex items-center ${wider ? "ml-4 gap-2" : "ml-2 gap-1"}`}
      >
        <p className={`font-[700] ${wider ? "w-6" : "text-sm w-5"}`}>
          {carrierCode}
        </p>
        <p className={`${wider ? "w-10 text-sm" : "text-xs w-8"}`}>{number}</p>
        <p className={wider ? "w-4 text-sm" : "w-4 text-xs"}>
          {dataDetails.flight.class}
        </p>
        {wider && (
          <p className="w-[88px] text-sm">{dataDetails.flight.fareBasis}</p>
        )}
        <p className={`${wider ? "w-10" : "text-sm w-9"}`}>
          {dataDetails.flight.includedCheckedBags.quantity} PC
        </p>
      </div>
    </section>
  );
}
