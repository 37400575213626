/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react";
import Head from "../head";
import { BuscadorPaquetes } from "./BuscadorPaquetes";
import { CardPaquete } from "./CardPaquete";
import { usePackages } from "../../hooks/usePackages";
import clsx from "clsx";
import numberWithCommas from "../../utils/numberWithCommas";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelectPaquetes } from "./useSelectPaquetes";
import SEO from "../../utils/SEO";
import Anicarga from "../anicarga";
import { useLocationQuery } from "../../hooks/useLocationQuery";
import { saveTourOperator } from "../../utils/saveTourOperator";
import useGetCurrencies from "../../hooks/useGetCurrencies";
import DetailPaquete from "./DetailPaquete";

export const Paquetes = () => {
  const tourOP = saveTourOperator((st) => st.tourOP);
  const { tour_op_id, package_id } = useParams();
  const { parsedHash } = useLocationQuery();
  const { currencies } = useGetCurrencies();
  const { individualPackage, packages, loading, getPackages, getPackage } =
    usePackages();
  const packageSelected = useSelectPaquetes((st) => st.packageSelected);
  const selectPackage = useSelectPaquetes((st) => st.selectPackage);
  const setNumberOfPassengers = useSelectPaquetes(
    (st) => st.setNumberOfPassengers
  );
  const location = useLocation();
  const navigate = useNavigate();

  const {
    originLocationCode,
    destinationLocationCode,
    departureDate,
    adults,
    children,
    infants,
  } = parsedHash;

  useEffect(() => {
    selectPackage({});
  }, [location.search]);

  useEffect(() => {
    if (package_id) {
      getPackage(package_id);
    } else {
      getPackages();
    }
  }, [tourOP, package_id]);

  useEffect(() => {
    if (!package_id) {
      setNumberOfPassengers({
        ADT: Number(adults),
        CHD: Number(children || "0"),
        INF: Number(infants || "0"),
      });
    }
  }, [parsedHash]);

  const nextStep = () => {
    navigate(`/${tour_op_id}/detail?type=packages`);
  };

  const filteredPackages = useMemo(
    () =>
      packages?.filter(({ oricode, descode, arrival_date, stock }) => {
        return (
          (originLocationCode?.length > 0
            ? oricode?.toUpperCase() === originLocationCode.toUpperCase()
            : true) &&
          descode.toUpperCase() === destinationLocationCode.toUpperCase() &&
          arrival_date.split("-")[1] === departureDate.split("-")[1] &&
          stock >= Number(adults)
        );
      }),
    [parsedHash]
  );

  return (
    <>
      <SEO title={"Paquetes"} />
      <Head />
      <main
        className={`flex gap-10 mt-10 mr-24 mb-4 ${
          !!package_id ? "ml-72" : "ml-20"
        }`}
      >
        {!package_id && <BuscadorPaquetes />}
        {loading && packages.length === 0 ? (
          <div className="w-full flex mt-28 justify-center">
            <Anicarga />
          </div>
        ) : (
          <section className="flex gap-4">
            {package_id ? (
              <DetailPaquete
                pack={individualPackage}
                postPaquete={nextStep}
                selectPackage={selectPackage}
              />
            ) : (
              <>
                <div className="flex flex-col gap-4 mt-8 w-full">
                  {(filteredPackages || [])?.map((p, index) => (
                    <CardPaquete
                      key={index}
                      paquete={p}
                      currencies={currencies}
                    />
                  ))}
                </div>
                <div
                  className={`transition-all ${
                    packageSelected?.price ? "scale-100 w-1/4" : "scale-0 w-0"
                  }`}
                >
                  {packageSelected?.price ? (
                    <Subtotal
                      postPaquete={nextStep}
                      packageSelected={packageSelected}
                      prices={[
                        packageSelected?.price,
                        packageSelected?.tax_package ?? "0",
                      ]}
                      currencies={currencies}
                    />
                  ) : null}
                </div>
              </>
            )}
          </section>
        )}
      </main>
    </>
  );
};

const Subtotal = ({ prices, postPaquete, packageSelected, currencies }) => {
  const { parsedHash } = useLocationQuery();
  const { adults, children, infants } = parsedHash;
  const [price, tax] = prices;
  const translateCurrency = (id) =>
    currencies?.find((currency) => currency.id === id)?.symbol;

  return (
    <section className="text-center font-body flex flex-col bg-white relative min-w-[250px]">
      <div className="w-full flex flex-col py-6 pb-1 z-20 pt-6 relative top-8 border-2 rounded-3xl justify-between border-[#DAE0E6]">
        <h2 className="bg-[#DAE0E6] text-2xl font-medium absolute z-10 top-0 h-20 left-0 rounded-xl py-3 pb-6 w-full">
          Precio final
        </h2>
        <span className="flex flex-col bg-white gap-2 rounded-t-2xl w-full relative z-30 pt-2 pb-2 left-0 right-0 top-7">
          <p className="my-2 text-xl font-medium whitespace-nowrap w-full">
            {translateCurrency(packageSelected.currency_type_id) ?? "USD"}
            {numberWithCommas(
              Number(price + Number(tax)) *
                (Number(adults ?? "1") +
                  Number(children ?? "0") +
                  Number(infants ?? "0")),
              2
            )}
          </p>
        </span>
      </div>
      <div
        onClick={() => postPaquete(packageSelected)}
        className={clsx(
          // {
          //   "opacity-75 text-white cursor-not-allowed": loading,
          // },
          "w-full pt-3 pb-2 my-3 relative top-9 text-center rounded-xl text-xl items-center justify-center cursor-pointer text-white bg-[#091832] flex gap-4 shadow-[0px_4px_8px_0px_#00000040]",
          "hover:opacity-90"
        )}
      >
        Confirmar
        {/* {loading ? (
        <svg
          class="animate-spin -ml-1 mr-3 h-5 w-5 text-gray"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            class="opacity-25"
            stroke="currentColor"
            stroke-width="4"
            cx="12"
            cy="12"
            r="10"
          ></circle>
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      ) : null} */}
      </div>
    </section>
  );
};
