import PasajeroRow from "./PasajeroRow";
import TableHeader from "./BookingDetailHeader";

export const adaptPassenger = (passenger) => {
  if (passenger === "ADULT") return "ADT";
  if (passenger === "CHILD") return "CHD";
  if (passenger === "HELD_INFANT") return "INF";
};

export default function Pasajeros({ pasajeros, passengerType, handleDisplay }) {
  return (
    <section className="pb-32">
      <TableHeader title={"pasajeros"} handleDisplay={handleDisplay} />
      <div
        id="pasajeros-body"
        className="overflow-y-hidden transition-transform duration-200 origin-top"
      >
        <header className="mt-2 mb-0 grid grid-cols-9">
          <h3 className="text-sm font-semibold col-span-2 ml-4">Nombres</h3>
          <h3 className="text-sm font-semibold col-span-2 ml-4">Apellidos</h3>
          <h3 className="text-sm font-semibold col-span-2 ml-4">
            Pasaporte/DNI
          </h3>
          <h3 className="text-sm font-semibold col-span-2 ml-4">Fecha Nac.</h3>
          <h3 className="text-sm font-semibold w-full text-center">Tipo</h3>
        </header>
        <section className="detailTable">
          {pasajeros?.map((pasajero, index) => {
            return (
              <PasajeroRow
                key={pasajero.id}
                pasajero={pasajero}
                passengerType={adaptPassenger(
                  passengerType?.[index]?.travelerType
                )}
                className={index % 2 === 0 && "bg-lightBlue2"}
              />
            );
          })}
        </section>
      </div>
    </section>
  );
}
