import { z } from "zod";

export const tourOperatorSchema = z.object({
  name: z.string().min(1, { message: "Campo incompleto" }),
  logo: z.string().url(),
  domain: z.string().url(),
  tour_op_email: z.string().min(1, { message: "Campo incompleto" }),
  license: z.string().optional(), // OPCIONAL
  file: z.string().optional(), // OPCIONAL
  country_id: z.string().regex(/^[+]?\d+([.]\d+)?$/),
  device_type: z.string().default("MOBILE"),
  country_calling_code: z.string().regex(/^[+]?\d+([.]\d+)?$/),
  number: z.string().regex(/^[+]?\d+([.]\d+)?$/),
  sub_type: z.string().default("GENERAL_MISCELLANEOUS"),
  text: z.string().optional(), // OPCIONAL
  option: z.string().default("DELAY_TO_QUEUE"),
  delay: z
    .string()
    .regex(/^[+]?\d+([.]\d+)?$/)
    .optional(), // OPCIONAL
  facebook: z.string().optional(), // OPCIONAL
  instagram: z.string().optional(), // OPCIONAL
  whatsapp: z.string().optional(), // OPCIONAL
  street_name: z.string().min(1, { message: "Campo incompleto" }),
  postal_code: z.string().min(1, { message: "Campo incompleto" }),
  city_name: z.string().min(1, { message: "Campo incompleto" }),
  country_code: z
    .string()
    .min(2, { message: "Campo incompleto" })
    .max(2, { message: "Máximo 2 caracteres" })
    .default("AR"),
  addressee_first_name: z.string().min(1, { message: "Campo incompleto" }),
  addressee_last_name: z.string().min(1, { message: "Campo incompleto" }),
  purpose: z.string().default("STANDARD"),
  email_address: z.string().min(1, { message: "Campo incompleto" }),
});

export const tourOperatorWithModulesSchema = z.object({
  name: z.string().min(1, { message: "Campo incompleto" }),
  logo: z.string().url().max(255, { message: "Máximo 255 caracteres" }),
  domain: z.string().url(),
  tour_op_email: z.string().min(1, { message: "Campo incompleto" }),
  license: z.string().optional(), // OPCIONAL
  file: z.string().optional(), // OPCIONAL
  country_id: z.string().regex(/^[+]?\d+([.]\d+)?$/),
  device_type: z.string().default("MOBILE"),
  country_calling_code: z.string().regex(/^[+]?\d+([.]\d+)?$/),
  number: z.string().regex(/^[+]?\d+([.]\d+)?$/),
  sub_type: z.string().default("GENERAL_MISCELLANEOUS"),
  text: z.string().optional(), // OPCIONAL
  option: z.string().default("DELAY_TO_QUEUE"),
  delay: z
    .string()
    .regex(/^[+]?\d+([.]\d+)?$/)
    .optional(), // OPCIONAL
  facebook: z.string().optional(), // OPCIONAL
  instagram: z.string().optional(), // OPCIONAL
  whatsapp: z.string().optional(), // OPCIONALv
  street_name: z.string().min(1, { message: "Campo incompleto" }),
  postal_code: z.string().min(1, { message: "Campo incompleto" }),
  city_name: z.string().min(1, { message: "Campo incompleto" }),
  country_code: z
    .string()
    .min(2, { message: "Campo incompleto" })
    .max(2, { message: "Máximo 2 caracteres" })
    .default("AR"),
  addressee_first_name: z.string().min(1, { message: "Campo incompleto" }),
  addressee_last_name: z.string().min(1, { message: "Campo incompleto" }),
  purpose: z.string().default("STANDARD"),
  email_address: z.string().min(1, { message: "Campo incompleto" }),
  modules: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
      active: z.boolean(),
    })
  ),
});
