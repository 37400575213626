import Head from "./head";
import RecommendationWrapper from "./landing/RecommendationWrapper";
import { Footer } from "./landing/Footer";
// import { useParams, useNavigate, useLocation } from "react-router";
// import { operadores } from "../utils/config";
import { useTourOperators } from "../hooks/useTourOperators";
import SEO from "../utils/SEO";
import { saveTourOperator } from "../utils/saveTourOperator";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { usePackages } from "../hooks/usePackages";
import useGetCurrencies from "../hooks/useGetCurrencies";
import Buscador from "./searcher/buscador";


// import { saveTourOperator } from "../utils/saveTourOperator";

const Home = () => {
  // const tourOP = saveTourOperator((st) => st.tourOP);
  // const navigate = useNavigate()
  // const { pathname } = useLocation()
  const { type } = useParams();
  const tourOP = saveTourOperator((st) => st.tourOP);
  const { tourOperator, getTourOperatorByID } = useTourOperators();
  const { packages, getPackages } = usePackages();
  const { currencies } = useGetCurrencies();

  const module =
    type === "flights" ? "Vuelos" : type === "packages" ? "Paquetes" : null;
  const [renderFlights, setRenderFlights] = useState(true);

  useEffect(() => {
    if (tourOP?.id) {
      getTourOperatorByID({ tourOperatorID: tourOP?.id });
    }

    if (tourOP) {
      getPackages();
    }
  }, [tourOP?.id]);

  useEffect(() => {
    if (tourOperator === undefined) {
      setRenderFlights(true);
      return;
    }

    // check if the tour operator has any modules entry with the name Vuelos disabled

    tourOperator?.TourOperatorModules.slice(0, 4).forEach((module) => {
      if (
        (module.name === "Vuelos" && module.active === false) ||
        module !== "Vuelos"
      ) {
        setRenderFlights(false);
      }
    });
  }, [tourOperator]);

  return (
    <>
      <SEO />
      <Head />
      <section className="relative w-full rounded-md max-w-[1338.46px] mx-auto h-[444.18px] bg-no-repeat bg-cover bg-beach-bg">
        <div className="max-w-6xl py-32 pl-3 m-auto">
          <Buscador />
        </div>
      </section>
      <section className="w-full flex flex-col items-center mx-auto my-14 px-32 max-w-[1338.46px]">
        {(renderFlights ||
          tourOperator?.TourOperatorModules.slice(0, 4).some(
            (item) => item.name === module && item.active
          )) && (
          <RecommendationWrapper
            isFlight={type === "flights"}
            packages={packages}
            currencies={currencies}
          />
        )}
      </section>
      <Footer />
    </>
  );
};

export default Home;
