import { format } from "date-fns";
import { adaptPassenger } from "../booking/Pasajeros";
import numberWithCommas from "../../utils/numberWithCommas";

export default function textMessage(
  options,
  isRoundtrip,
  client,
  page,
  [dolar, euro]
) {
  const textBySelectedOption = options.map((option, optionIndex) => {
    if (option.length === 0) return null;

    return option
      .map((opt, index) => {
        const idas = opt?.itineraries?.[0]?.segments;
        let ida = idas?.[0];
        const vueltas = isRoundtrip && opt?.itineraries?.[1]?.segments;
        let vuelta = isRoundtrip && vueltas?.[0];

        const vuelostext = (flights, type) => {
          return flights.map((flight, index) => {
            if ((type === "ida") & (index > 0)) ida = idas[index];
            else if ((type === "vuelta") & (index > 0)) vuelta = vueltas[index];

            let isScale = "";
            if (index > 0) isScale = `  < ${index}° Escala >  `;
            const arrivalNextDay =
              Number(format(new Date(flight?.arrival?.at), "dd")) >
              Number(format(new Date(flight?.departure?.at), "dd"));

            const flightClass =
              opt?.travelerPricings?.[0]?.fareDetailsBySegment.filter(
                (el) => el.segmentId === flight.id
              );

            const text = `${flight?.carrierCode} ${flight?.number} ${format(
              new Date(flight?.departure?.at),
              "dd/MMM"
            )} ${flight.departure.iataCode} ${format(
              new Date(flight?.departure?.at),
              "H:mm"
            )} >  ${format(new Date(flight?.arrival?.at), "H:mm")}${
              arrivalNextDay ? "*1" : ""
            } ${
              flight.arrival.iataCode
            } ${`${flightClass?.[0]?.includedCheckedBags?.quantity}PC`}\n`;

            if (index === 0) return text;
            return isScale + text;
          });
        };

        const idaText = vuelostext(opt.itineraries[0].segments, "ida").join(
          "  "
        );
        const vueltaText =
          isRoundtrip &&
          vuelostext(opt.itineraries[1].segments, "vuelta").join(" ");

        const passengerTypeCounter = {
          ADULT: 0,
          CHILD: 0,
          HELD_INFANT: 0,
        };

        const { currency } = opt.price;
        const newCurrency = currency === "ARS" ? "USD" : "ARS";

        const convertCurrency = (value) => {
          if (currency === "ARS") return Number(value) / Number(dolar);
          return Number(value) * Number(euro);
        };

        const tarifaPerPassenger = opt?.travelerPricings
          .map((el) => {
            passengerTypeCounter[el.travelerType] += 1;
            const traveler = adaptPassenger(el.travelerType);
            const indexTraveler = passengerTypeCounter[el.travelerType];
            const { total } = el.price;

            return `PRECIO ${traveler} ${indexTraveler} ${currency}${numberWithCommas(
              Number(total),
              2
            )}${
              (newCurrency === "USD" && dolar) ||
              (newCurrency === "ARS" && euro)
                ? ` (${newCurrency}${numberWithCommas(
                    Number(convertCurrency(total)),
                    2
                  )})`
                : ""
            }\n`;
          })
          .join("   ");

        const totalPrice = opt?.travelerPricings.reduce(
          (acc, el) => (acc += Number(el.price.total)),
          0
        );

        const tarifaText = `${tarifaPerPassenger} ${
          page === "wpp" ? "*" : ""
        }PRECIO TOTAL ${currency}${numberWithCommas(totalPrice, 2)}${
          (newCurrency === "USD" && dolar) || (newCurrency === "ARS" && euro)
            ? ` (${newCurrency}${numberWithCommas(
                Number(convertCurrency(totalPrice)),
                2
              )})`
            : ""
        }${page === "wpp" ? "*" : ""}
      `;

        const text = `
      -----------------  ${
        page === "mail"
          ? `${options.length > 1 ? `TRAMO ${optionIndex + 1} - ` : ""}OPCIÓN ${
              index + 1
            }`
          : `${
              options.length > 1 ? `TRAMO ${optionIndex + 1} - ` : ""
            }_*OPCIÓN ${index + 1}*_`
      } ------------------------
      ${page === "mail" ? "IDA" : "_*IDA*_"}   --->   ${idaText}\n
      ${
        isRoundtrip
          ? `${
              page === "mail" ? "VUELTA" : "_*VUELTA*_"
            }   --->   ${vueltaText}`
          : ""
      }\n
      ${page === "mail" ? "TARIFA" : "_*TARIFA*_"}   --->   ${tarifaText}`;

        return text;
      })
      .join("   ");
  });

  const finalMsg = `Hola${
    client && ` ${client}`
  }! Esta es la información de su cotización:
    ${textBySelectedOption.map((el) => el).join("    ")}
    `;

  return finalMsg;
}
