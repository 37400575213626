import axios from "axios";
import { useState } from "react";

export const useGetAirlines = () => {
  const BASE_URL = process.env.REACT_APP_API_URL;

  const [airlines, setAirlines] = useState({});

  const getAirlines = async () => {
    return await axios
      .get(BASE_URL + "v2/airlines")
      .then((e) => {
        // console.log(e.data.response);
        setAirlines(e.data?.response);
      })
      .catch((err) => console.log(err));
  };

  return { airlines, getAirlines };
};
