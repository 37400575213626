import { z } from "zod";

export const packageSchema = z.object({
  price: z.string().min(1, { message: "Campo incompleto" }),
  currency_type_id: z
    .number({ invalid_type_error: "Seleccione una moneda" })
    .default(1),
  name: z.string().min(1, { message: "Campo incompleto" }),
  descode: z.string().min(3, { message: "Campo incompleto" }),
  oricode: z.string().min(3, { message: "Campo incompleto" }),
  description: z.string().min(1, { message: "Campo incompleto" }),
  fsalida: z.string().min(1, { message: "Campo incompleto" }),
  fvuelta: z.string().min(1, { message: "Campo incompleto" }),
  cupos: z.string().regex(/^[+]?\d+([.]\d+)?$/),
  category: z.number().default(1),
  tax_package: z.string().min(1, { message: "Campo incompleto" }),
  portrait: z.string().min(1, { message: "Campo incompleto" }).optional(),
  flyer: z.string(),
});
