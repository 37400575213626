import { useForm } from "react-hook-form";
import { Toaster, toast } from "react-hot-toast";
import { z } from "zod";
import { ErrorMessage } from "@hookform/error-message";
import { zodResolver } from "@hookform/resolvers/zod";
import { useTourOperators } from "../../hooks/useTourOperators";
import { useEffect, useState } from "react";
import AutoCompleteCountryName from "../../utils/AutoCompleteCountryName";
import AutoCompleteCountryISO3166 from "../../utils/AutoCompleteCountryISO3166";

const schema = z
  .object({
    vuelos: z.boolean(),
    paquetes: z.boolean(),
    hoteles: z.boolean(),
    actividades: z.boolean(),
  })
  .partial();

export default function FormEditModules({ selectedID, closeModal }) {
  const { tourOperator, getTourOperatorByID, updateTourOperator } =
    useTourOperators();

  useEffect(() => {
    getTourOperatorByID({ tourOperatorID: selectedID });
    console.log(tourOperator);
  }, [selectedID]);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm({ resolver: zodResolver(schema.partial()) });

  useEffect(() => {
    console.log("tourOperator", tourOperator);
    if (tourOperator) {
      setValue("vuelos", tourOperator.TourOperatorModules[0].active);
      setValue("paquetes", tourOperator.TourOperatorModules[1].active);
      setValue("hoteles", tourOperator.TourOperatorModules[2].active);
      setValue("actividades", tourOperator.TourOperatorModules[3].active);
    }
    console.log(getValues());
  }, [tourOperator, setValue]);

  useEffect(() => {
    getTourOperatorByID({ tourOperatorID: selectedID }).then((data) => {
      reset(data);
      console.log(tourOperator);
    });
  }, [selectedID, reset]);

  const onSubmit = async (data) => {
    console.log("data", data);
    const response = {
      id: selectedID,
      modules: [
        { id: tourOperator.TourOperatorModules[0].id, active: data.vuelos },
        { id: tourOperator.TourOperatorModules[1].id, active: data.paquetes },
        { id: tourOperator.TourOperatorModules[2].id, active: data.hoteles },
        {
          id: tourOperator.TourOperatorModules[3].id,
          active: data.actividades,
        },
      ],
    };
    console.log("tourOperator", response);

    toast.promise(
      updateTourOperator(response).then(() => closeModal()),
      {
        loading: "Cargando...",
        success: <b>Operadora editada con éxito</b>,
        error: <b>Hubo un error, intenta de nuevo.</b>,
      }
    );
  };

  const { TourOperatorModules } = tourOperator || {};

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="mx-auto bg-white rounded-lg"
    >
      <div>
        <Toaster />
      </div>

      <h1 className="text-darkBlue font-semibold text-2xl text-center mb-2">
        Modificar Operadora
      </h1>

      <div className="grid grid-cols-4 gap-2">
        <h2 className="col-span-4 font-semibold text-lg mt-2 -mb-2">Modulos</h2>
        <label className="w-full relative">
          <p>Vuelos</p>
          <input type="checkbox" name="vuelos" {...register("vuelos")} />
          <div className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
            <ErrorMessage errors={errors} name="name" as="p" />
          </div>
        </label>
        <label className="w-full relative">
          <p>Paquetes</p>
          <input type="checkbox" name="paquetes" {...register("paquetes")} />
          <div className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
            <ErrorMessage errors={errors} name="name" as="p" />
          </div>
        </label>
        <label className="w-full relative">
          <p>Hoteles</p>
          <input type="checkbox" name="hoteles" {...register("hoteles")} />
          <div className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
            <ErrorMessage errors={errors} name="name" as="p" />
          </div>
        </label>
        <label className="w-full relative">
          <p>Actividades</p>
          <input
            type="checkbox"
            name="actividades"
            {...register("actividades")}
          />
          <div className="absolute text-red-600 text-xs -bottom-[15px] left-4 whitespace-nowrap">
            <ErrorMessage errors={errors} name="name" as="p" />
          </div>
        </label>

        <button className="col-span-4 font-semibold text-lg ml-auto mt-4 button w-1/2 bg-darkBlue text-white hover:shadow-lg transition-all">
          Guardar Operadora
        </button>
      </div>
    </form>
  );
}
