import { Link } from "react-router-dom";
import eye from "../../iconos/eye-blue.png";

export default function HistoryTableRow({ booking, tour_op_id, index }) {
  const { id, status, Flights, Travelers } = booking;
  const parsedFlight = JSON.parse(Flights[0].options);
  const { first_name, last_name } = Travelers[0];

  return (
    <div
      className={`flex justify-between p-2 rounded ${
        index % 2 === 0 ? "bg-lightGray" : "bg-white"
      }`}
    >
      <p>{id}</p>
      <p>{status}</p>
      <p>
        {last_name} {first_name}
      </p>
      <Link to={`detail/flight/${id}`}>
        <img src={eye} alt="eye" className="h-7" />
      </Link>
    </div>
  );
}
