import React from 'react'
import { versionTypes } from "../../staff/versions/commons";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import chevron_down_gray from "../../../iconos/chevron_down_gray.png";
import { memo, useState } from "react";

const Versions = memo(({ version }) => {
    const { name, status } = version;
  
    const [open, setOpen] = useState(true);
    const [parent] = useAutoAnimate({ duration: 220 });
  
    return (
      <div className="bg-white px-2 py-1 my-4 rounded-[10px] shadow-[0_0px_5px_1px_lightBlue]">
        <span
          onClick={() => setOpen(!open)}
          className="flex items-center gap-2 cursor-pointer"
        >
          {/* <img
            src={chevron_down_gray}
            className="w-[8px] h-[6.08px]"
            alt={"abrir version" + name}
          /> */}
          <h2 className="text-[#242423] font-medium text-[16.22px] my-4">
            {name}
          </h2>
        </span>
        {/* <div ref={parent}>
          {open ? (
            <div className="flex flex-col gap-6 pl-6">
              {(JSON.parse(status) || [])?.map(({ label, value }) => {
                const versionColor = versionTypes.find(
                  (v) => value === v.label
                )?.color;
  
                return (
                  <div className="flex justify-between ">
                    <p className="text-[14.2px] text-[#242423] font-[500]">
                      {label}
                    </p>
                    <p
                      className="text-[16.22px] font-medium text-[#242423]"
                      style={{ color: versionColor || "inherit" }}
                    >
                      {value}
                    </p>
                  </div>
                );
              })}
            </div>
          ) : null}
        </div> */}
      </div>
    );
  });

export default Versions