import _ from "lodash";
import QueryString from "qs";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

export const useLocationQuery = () => {
  let [, setSearchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const parsedHash = QueryString.parse(location.search.replace("?", ""));

  const changeQuery = ({ query, go, route }) => {
    const serializedQuery = QueryString.stringify(
      _.omitBy({ ...query }, (a) => a !== 0 && !a)
    );
    setSearchParams(serializedQuery);
    if (go) {
      navigate(`/${route}?` + serializedQuery, { replace: true });
    }
  };

  return { parsedHash, changeQuery };
};
