import FlightRow from "./FlightRow";
import TableHeader from "./BookingDetailHeader";

export default function FlightsTable({ title, flights, ciudadesFormatted, handleDisplay }) {
  const [scales, details] = flights;

  return (
    <section>
      <TableHeader title={title} handleDisplay={handleDisplay} />
      <section
        id={title + "-body"}
        className="detailTable mt-2 transition-transform duration-200 origin-top"
      >
        <section className="detailTableRow">
          {scales?.map((flight, index) => {
            const detail = details.filter((el) => el.segmentId === flight.id);
            return (
              <FlightRow
                key={flight.vuelo + "-" + index}
                flight={flight}
                detail={detail[0]}
                ciudadesFormatted={ciudadesFormatted}
                className={`${index % 2 === 0 && "bg-lightBlue2"}`}
              />
            );
          })}
        </section>
      </section>
    </section>
  );
}
