import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import axios from "axios";

const schema = z.object({
  quote: z.object({
    askItineraries: z.object({
      priority: z.string(),
      departure: z.string(),
      arrival: z.string(),
      dates: z.string(),
      numberOfStops: z.string(),
      duration: z.number(),
      checkedBags: z.string(),
      budget: z.string(),
      comments: z.string(),
      nextstep: z.string(),
    }),
    travelers: z.array(
      z.object({
        data: z.object({
          first_name: z.string().nonempty("Nombre es requerido"),
          email_address: z
            .string()
            .nonempty("Email requerido")
            .email("Email inválido"),
        }),
        contact_phones: z.array(
          z.object({
            number: z
              .string()
              .min(10, "Número de WhatsApp inválido")
              .nonempty("Numero de WhatsApp requerido"),
          })
        ),
      })
    ),
  }),
});

export default function PedidoElem() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
  });

  const onSubmit = (data) => {
    console.log(data);
    axios
      .post(`${process.env.REACT_APP_API_URL}v2/bookings/flights`, data)
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };

  return (
    <form
      className="rounded-lg bg-white p-8 shadow-md font-body"
      onSubmit={handleSubmit(onSubmit)}
    >
      <h1 className="mb-6 text-3xl font-bold">Hola Viajero/a!</h1>

      <h2 className=" text-2xl">Primero, ayudamos con tus datos</h2>
      <div className="mb-4">
        <label className="mb-1 block font-semibold" htmlFor="firs_name">
          Nombre (sin apellido):
        </label>
        {errors.quote?.travelers?.[0]?.data?.first_name && (
          <p className=" text-red-500 ">{errors.quote.travelers[0].data.first_name.message}</p>
        )}
        <input
          className="w-full rounded-md border border-gray-300 px-3 py-2"
          type="text"
          id="firs_name"
          placeholder="Nombre"
          {...register("quote.travelers[0].data.first_name")}
        />
      </div>
      <div className="mb-4">
        <label className="mb-1 block font-semibold" htmlFor="email">
          Email:
        </label>
        {errors.quote?.travelers?.[0]?.data?.email_address && (
          <p className=" text-red-500 ">{errors.quote.travelers[0].data.email_address.message}</p>
        )}
        <input
          className="w-full rounded-md border border-gray-300 px-3 py-2"
          type="email"
          id="email"
          placeholder="Email"
          {...register("quote.travelers[0].data.email_address")}
        />
      </div>
      <div className="mb-4">
        <label className="mb-1 block font-semibold" htmlFor="whatsapp">
          WhatsApp:
        </label>
        {errors.quote?.travelers?.[0]?.contact_phones?.[0]?.number && (
          <p className=" text-red-500 ">{errors.quote.travelers[0].contact_phones[0].number.message}</p>
        )}

        <input
          className=" w-full rounded-md border border-gray-300 px-3 py-2"
          type="text"
          id="whatsapp"
          placeholder="+54 ..."
          {...register("quote.travelers[0].contact_phones[0].number")}
        />
      </div>

      <div className="mb-4">
        <label className="mb-1 block font-semibold" htmlFor="priority">
          ¿Qué tipo de cotizacion es?
        </label>
        <select
          className="w-full rounded-md border border-gray-300 px-3 py-2"
          id="priority"
          {...register("quote.askItineraries.priority")}
        >
          <option>Vuelo</option>
          <option>Hoteles</option>
          <option>Paquetes</option>
        </select>
      </div>
      <div className="mb-4">
        <label className="mb-1 block font-semibold" htmlFor="departure">
          ¿Desde dónde salis?
        </label>
        <textarea
          className="w-full rounded-md border border-gray-300 px-3 py-2"
          id="departure"
          placeholder="Preferentemente Córdoba, sino cualquiera de Buenos Aires. Si hay algo más barato desde otra ciudad de Argentina, también."
          {...register("quote.askItineraries.departure")}
        />
      </div>

      <div className="mb-4">
        <label className="mb-1 block font-semibold" htmlFor="destination">
          ¿A dónde vas?
        </label>
        <input
          className="w-full rounded-md border border-gray-300 px-3 py-2"
          type="text"
          id="destination"
          placeholder="Voy a Barcelona, pero cualquier ciudad de España me sirve. Si no alguna otra ciudad de Europa también."
          {...register("quote.askItineraries.arrival")}
        />
      </div>

      <div className="mb-4">
        <label className="mb-1 block font-semibold" htmlFor="dates">
          ¿Cómo son tus fechas?
        </label>
        <textarea
          className="w-full rounded-md border border-gray-300 px-3 py-2"
          id="dates"
          rows="3"
          placeholder="A la ida puedo salir desde el 23 de Septiembre hasta el 19 de Octubre. La vuelta más barata en el mes de Noviembre. Me quiero quedar por lo menos 40 dias"
          {...register("quote.askItineraries.dates")}
        ></textarea>
      </div>

      <div className="mb-4">
        <label className="mb-1 block font-semibold" htmlFor="priority">
          En esta elección, ¿qué tiene más prioridad para vos?
        </label>
        <select
          className="w-full rounded-md border border-gray-300 px-3 py-2"
          id="priority"
          {...register("quote.askItineraries.priority")}
        >
          <option>Menos Escalas</option>
          <option>Menos Precio del Vuelo</option>
          <option>Menos Duracion Total del Recorrido</option>
          <option>Menos Horas de Espera en Escala</option>
          <option>Mas Equipaje</option>
          <option>Elejir mi Aerolinea</option>
        </select>
      </div>

      <div className="mb-4 flex flex-col items-center justify-between gap-2">
        <div className="w-full">
          <label className="mb-1 block font-semibold" htmlFor="stops">
            Escalas Max
          </label>
          <select
            className="rounded-md border border-gray-300 px-3 py-2 w-full"
            id="stops"
            {...register("quote.askItineraries.numberOfStops")}
          >
            <option>3 o más escalas</option>
            <option>2 escalas</option>
            <option>1 escalas</option>
            <option>Directo</option>
          </select>
        </div>

        <div className="w-full">
          <label className="mb-1 block font-semibold" htmlFor="duration">
            Duración Total Vuelos
          </label>
          <input
            className="text-start rounded-md border border-gray-300 px-3 py-2  w-full"
            type="number"
            id="duration"
            {...register("quote.askItineraries.duration", { valueAsNumber: true })}
          />
        </div>

        <div className="w-full">
          <label className="mb-1 block font-semibold" htmlFor="luggage">
            Equipaje (+23 kg)
          </label>
          <select
            className="rounded-md border border-gray-300 px-3 py-2 w-full"
            id="luggage"
            {...register("quote.askItineraries.checkedBags")}
          >
            <option>2 valijas</option>
            <option>1 valija</option>
            <option>Me da igual</option>
          </select>
        </div>
      </div>

      <div className="mb-4 w-full">
        <label className="mb-1 block font-semibold" htmlFor="budget">
          ¿Cuánto es tu presupuesto final total por persona en AR$? Forma de
          pago?
        </label>
        <textarea
          className="w-full rounded-md border border-gray-300 px-3 py-2"
          id="budget"
          placeholder="$325.000 final por persona, los pagaría en pesos cash o transferencia, sino tengo usd cash o cripto"
          {...register("quote.askItineraries.budget")}
        ></textarea>
      </div>

      <div className="mb-4">
        <textarea
          className="w-full rounded-md border border-gray-300 px-3 py-2"
          id="comments"
          rows="3"
          placeholder="Otras especificaciones, dudas o sugerencias"
          {...register("quote.askItineraries.comments")}
        ></textarea>
      </div>

      <div className="mb-4">
        <label className="mb-1 block font-semibold" htmlFor="nextstep">
          ¿Qué te gustaría que hagamos?
        </label>
        <select
          className="w-full rounded-md border border-gray-300 px-3 py-2"
          id="nextstep"
          {...register("quote.askItineraries.nextstep")}
        >
          <option>Nada</option>
          <option>Notificar via Email</option>
          <option>Reservarlo</option>
          <option>Comprarlo</option>
        </select>
      </div>

      <button
        className="rounded bg-blue-500 px-4 py-2 font-semibold text-white hover:bg-blue-700"
        type="submit"
      >
        ENVIAR
      </button>
    </form>
  );
}
