export const initialWeekdays = {
  Lu: {
    label: "Lu",
    selected: false,
  },
  Ma: {
    label: "Ma",
    selected: false,
  },
  Mi: {
    label: "Mi",
    selected: false,
  },
  Ju: {
    label: "Ju",
    selected: false,
  },
  Vi: {
    label: "Vi",
    selected: false,
  },
  Sá: {
    label: "Sá",
    selected: false,
  },
  Do: {
    label: "Do",
    selected: false,
  },
};