import { Link, useParams } from "react-router-dom";
import SEO from "../../utils/SEO";
import { useToggleVisibility } from "../../hooks/useToggleVisibility";
import useCommissionsAndTaxes from "../../hooks/useCommissionsAndTaxes";
import { ModalGeneric } from "../../utils/ModalGeneric";
import FormAddCommission from "./FormAddCommission";

export const PanelFees = () => {
  const { tour_op_id } = useParams();
  const { role_id, agency_id } = JSON.parse(
    localStorage.getItem("userData") || sessionStorage.getItem("userData")
  );
  const [isVisible, toggleVisibility] = useToggleVisibility();
  const { commissions, getCommissions, createCommission, createTax } =
    useCommissionsAndTaxes();

  const postCommission = (data, type) => {
    if (type === "commission") {
      createCommission({
        commission: {
          ...data,
          role_id,
        },
      }).then(() => {
        getCommissions();
        toggleVisibility();
      });
    } else if (type === "tax") {
      createTax({
        tax: {
          ...data,
          type: data.name,
        },
      }).then(() => {
        getCommissions();
        toggleVisibility();
      });
    }
  };

  return (
    <>
      <SEO title={"Impuestos y Comisiones"} />
      <main className="relative font-body max-w-7xl mx-auto">
        <header className="my-8 flex justify-between items-center">
          <div>
            <h1 className="text-darkBlue font-[700] text-3xl">
              Impuestos y Comisiones
            </h1>
            <p className="text-darkBlue/70 text-lg">
              Controlá, personalizá y gestioná los impuestos y comisiones.
            </p>
          </div>
          <button
            className="bg-darkBlue text-white font-semibold py-2 px-4 rounded"
            onClick={toggleVisibility}
          >
            Agregar
          </button>
        </header>

        {commissions.length > 0 && (
          <section className="bg-white p-8 rounded-3xl text-lg flex flex-col gap-2 mt-16">
            <div className="w-full p-4 rounded flex justify-between">
              <h3 className="w-full whitespace-nowrap font-semibold">Nombre</h3>
              <h3 className="w-full whitespace-nowrap font-semibold">
                Porcentaje (%)
              </h3>
              <h3 className="w-full whitespace-nowrap font-semibold">
                Servicio
              </h3>
            </div>
            {commissions.map(({ id, name, percentage, service }, index) => (
              <div
                key={id}
                className={`w-full p-4 rounded flex justify-between ${
                  index % 2 === 0 ? "bg-lightGray3" : "bg-lightGray/70"
                }`}
              >
                <p className="w-full whitespace-nowrap">{name}</p>
                <p className="w-full whitespace-nowrap">{percentage}%</p>
                <p className="w-full whitespace-nowrap">{service}</p>
              </div>
            ))}
          </section>
        )}

        <ModalGeneric show={isVisible} toggle={toggleVisibility}>
          <FormAddCommission onSubmit={postCommission} />
        </ModalGeneric>

        <Link
          to={`/${tour_op_id}/panel/profile/${
            agency_id === 1 ? "my-tour-operator" : "my-agency"
          }`}
          className="absolute left-0 mt-8 text-center text-xl px-8 py-4 bg-brightBlue text-white font-semibold rounded"
        >
          Anterior
        </Link>
        <Link
          to={`/${tour_op_id}/panel/profile/${
            agency_id === 1 ? "agencies" : "users"
          }`}
          className="absolute right-0 mt-8 text-center text-xl px-8 py-4 bg-brightBlue text-white font-semibold rounded"
        >
          Siguiente
        </Link>
      </main>
    </>
  );
};
