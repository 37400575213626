import "../../styles/buscador.css";
import icono_calendar from "../../iconos/icono_calendar.png";
import oricode_chevron from "../../iconos/oricode_chevron.png";
import { ErrorMessage } from "@hookform/error-message";
import { format } from "date-fns";
import NewCalendar from "../../utils/NewCalendar";
import { PASSENGER_DICTIONARY } from "../../utils/dictionaries/passengerDictionary";
import { useState } from "react";
import { useToggleVisibility } from "../../hooks/useToggleVisibility";
import { AutoCompleteCities } from "../../utils/AutoCompleteCities";

export default function SearcherHotels({
  useForm,
  descodeState,
  oricodeState,
  passangerState,
  parsedHash,
}) {
  // const [oricodeInput, setOricodeInput] = oricodeState;
  // const [descodeInput, setDescodeInput] = descodeState;
  const [passengerCounter, setPassengerCounter] = passangerState;
  const { register, setValue, watch, errors } = useForm;
  const [rooms, setRooms] = useState(1);

  const [openFsalida, toggleSalida] = useToggleVisibility();
  const [rangeDate, setRangeDate] = useState();
  // const [showOptions, setShowOptions] = useState(false);
  const { ADT, CHD, INF } = passengerCounter;
  const sumPassengers = ADT + CHD + INF;

  const handlePassenger = (passenger, register, min, op) => {
    const passengerValue = passengerCounter[passenger];
    const newValue =
      op === "+"
        ? passengerValue + 1
        : passengerValue > min
        ? passengerValue - 1
        : passengerValue;

    setValue(register, newValue.toString());

    setPassengerCounter((curr) => {
      return {
        ...curr,
        [passenger]: newValue,
      };
    });
  };

  const handleRooms = (op) => {
    const newValue = op === "+" ? rooms + 1 : rooms > 0 ? rooms - 1 : rooms;

    setValue("rooms", newValue.toString());

    setRooms(newValue);
  };

  return (
    <>
      <span className="flex gap-20 items-center">
        <p className="text-xl font-medium text-lightBlue">Hoteles</p>
      </span>

      <div className="flex gap-3 justify-between">
        {/* cityCode */}
        <div className="bg-[#EFEFEF] p-1 h-20 pt-2 rounded-2xl flex items-center gap-1 justify-between">
          <div className="flex flex-col relative">
            <span className="flex gap-2 items-center justify-center">
              <img
                src={oricode_chevron}
                alt="oricode_chevron"
                className="w-4"
              />
              <p>Ciudad</p>
            </span>
            <AutoCompleteCities
              classname={"bg-[#EFEFEF] outline-none py-2 w-[306px] px-2"}
              register={register}
              defaultValue={parsedHash?.cityCode}
              name="cityCode"
              placeholder="Ciudad de Destino"
              handleAction={(d) => {
                setValue("cityCode", d?.iata);
                setValue("cityName", d?.city);
              }}
              inputState={oricodeState}
            />
            <div className="absolute text-red-600 text-xs -bottom-1 left-4 whitespace-nowrap">
              <ErrorMessage errors={errors} name="cityCode" as="p" />
            </div>
          </div>
        </div>

        {/* fechas */}
        <div className="bg-[#EFEFEF] relative p-1 h-20 pt-2 rounded-2xl flex items-center gap-1 justify-between w-72">
          <div className="flex flex-col max-w-[135px]">
            <span className="flex gap-2 items-center justify-center absolute top-2 left-6">
              <img src={icono_calendar} alt="icono_calendar" className="w-4" />
              <p>Fechas</p>
            </span>
            <div className="flex flex-col">
              <div className="flex flex-col h-5 items-start gap-2">
                <input
                  autocomplete="off"
                  name="departureDate"
                  placeholder="Ida"
                  onClick={() => toggleSalida()}
                  value={
                    !watch("departureDate")
                      ? parsedHash?.departureDate
                        ? format(
                            new Date(parsedHash?.departureDate + "T00:00:00"),
                            "dd/MM/yyyy"
                          )
                        : "Ida"
                      : format(
                          new Date(watch("departureDate") + "T00:00:00"),
                          "dd/MM/yyyy"
                        )
                  }
                  className="text-center outline-none px-2 pt-4 text-sm flex items-center justify-center w-full bg-inherit h-7"
                />
                {openFsalida ? (
                  <NewCalendar
                    handlers={[
                      rangeDate,
                      (date) => {
                        setRangeDate(date);
                        setValue(
                          "departureDate",
                          date?.from ? format(date?.from, "yyyy-MM-dd") : ""
                        );
                        setValue(
                          "returnDate",
                          date?.to ? format(date?.to, "yyyy-MM-dd") : ""
                        );
                        date?.from && date?.to && toggleSalida();
                      },
                    ]}
                    mode={"range"}
                    numberOfMonths={2}
                    close={toggleSalida}
                  />
                ) : null}
              </div>
              <div className="absolute text-red-600 text-xs bottom-0.5 left-8 whitespace-nowrap">
                <ErrorMessage errors={errors} name="departureDate" as="p" />
              </div>
            </div>
          </div>

          <div className="h-[80%] w-[1px] bg-black" />

          <div className="flex flex-col">
            <div className="flex flex-col h-5 items-start gap-2">
              <input
                name="returnDate"
                placeholder="Vuelta"
                onClick={() => toggleSalida()}
                value={
                  !watch("returnDate")
                    ? parsedHash?.returnDate
                      ? format(
                          new Date(parsedHash?.returnDate + "T00:00:00"),
                          "dd/MM/yyyy"
                        )
                      : "Vuelta"
                    : format(
                        new Date(watch("returnDate") + "T00:00:00"),
                        "dd/MM/yyyy"
                      )
                }
                className="text-center outline-none px-2 text-sm pt-4 flex items-center justify-center w-full bg-inherit h-7"
              />
            </div>
            <div className="absolute text-red-600 text-xs bottom-0.5 right-8 whitespace-nowrap">
              <ErrorMessage errors={errors} name="departureDate" as="p" />
            </div>
          </div>
        </div>

        {/* passengers */}
        <div className="relative group/passengers">
          <div className="bg-[#EFEFEF] p-1 h-20 pt-2 rounded-2xl flex flex-col items-center gap-1 justify-evenly cursor-pointer">
            <span className="flex gap-2 items-center">
              <p className="text-sm font-medium">Pasajeros y Habitaciones</p>
            </span>
            <p className="w-[120px] py-1 px-2 mx-auto text-sm focus:outline-none bg-[#CEDEF1] rounded-md">
              {sumPassengers} {sumPassengers > 1 ? " pasajeros" : "pasajero"}
              {/* {cabin === "ECO" ? "Económica" : "Ejecutiva"} */}
            </p>
          </div>
          <div className="absolute w-64 top-full mt-2 bg-[#EFEFEF] rounded-2xl p-4 flex flex-col gap-2 scale-0 group-hover/passengers:scale-100 transition-transform origin-top-left">
            <section className="flex flex-col gap-2">
              {Object.entries(passengerCounter).map((passenger) => {
                const { key, label, description, register, min } =
                  PASSENGER_DICTIONARY[passenger[0]];
                return (
                  <div className="flex justify-between items-center" key={key}>
                    <div>
                      <h2 className="font-medium">{label}</h2>
                      <p className="text-xs text-gray">{description}</p>
                    </div>
                    <div className="flex gap-2 items-center">
                      <button
                        type="button"
                        onClick={() => handlePassenger(key, register, min, "-")}
                        className="border w-7 h-7 rounded-full text-sm font-[700]"
                      >
                        -
                      </button>
                      <p className="text-xl">{passenger[1]}</p>
                      <button
                        type="button"
                        onClick={() => handlePassenger(key, register, min, "+")}
                        className="border w-7 h-7 rounded-full text-sm font-[700]"
                      >
                        +
                      </button>
                    </div>
                  </div>
                );
              })}
              <div className="flex justify-between items-center border-t pt-2">
                <p className="font-medium">Habitaciones</p>
                <div className="flex gap-2 items-center">
                  <button
                    type="button"
                    onClick={() => handleRooms("-")}
                    className="border w-7 h-7 rounded-full text-sm font-[700]"
                  >
                    -
                  </button>
                  <p className="text-xl">{rooms}</p>
                  <button
                    type="button"
                    onClick={() => handleRooms("+")}
                    className="border w-7 h-7 rounded-full text-sm font-[700]"
                  >
                    +
                  </button>
                </div>
              </div>
            </section>
          </div>
        </div>
        <button
          type="submit"
          className="bg-[#CEDEF1] rounded-xl py-1 px-12 text-black shadow-sm shadow-lightBlue2"
        >
          <span className="text-black font-medium">Buscar</span>
        </button>
      </div>
    </>
  );
}
