// import { useState } from "react";
import axios from "axios";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { getLocalStorageValue, getSessionStorageValue } from "./useCookie";

export const useTourOperators = () => {
  const BASE_URL = process.env.REACT_APP_API_URL;
  const { tour_op_id } = useParams();
  const [tourOperator, setTourOperator] = useState();
  const [tourOperators, setTourOperators] = useState();

  const token =
    getLocalStorageValue("token") || getSessionStorageValue("token");

  const headers = {
    headers: { Authorization: `${token}` },
  };

  const postTourOperator = async (tourOperator) => {
    return axios.post(
      BASE_URL + "v2/tour-operators",
      {
        tourOperator,
      },
      headers
    );
  };

  const getTourOperators = async () => {
    return axios
      .get(BASE_URL + "v2/tour-operators")
      .then((e) => {
        setTourOperators(e.data.response);
        return e.data.response;
      })
      .catch((err) => console.log(err));
  };

  const getTourOperatorByID = async ({ tourOperatorID }) => {
    return axios
      .get(BASE_URL + `v2/tour-operators/detail/${tourOperatorID}`)
      .then((e) => {
        setTourOperator(e.data.response);
      })
      .catch((err) => console.log(err));
  };

  const getTourOperator = async () => {
    getTourOperators().then((e) => {
      const operator = tour_op_id
        ? e.find(
            (op) => op.name.split(" ").join("").toLowerCase() === tour_op_id
          )?.id
        : "1";
      getTourOperatorByID({ tourOperatorID: operator });
    });
  };

  const updateTourOperator = async (tourOperator) => {
    const { id, ...restOfTourOperator } = tourOperator;

    return await axios.patch(
      BASE_URL + `v2/tour-operators/${id}`,
      {
        tourOperator: { ...restOfTourOperator },
      },
      headers
    );
  };

  return {
    tourOperator,
    tourOperators,
    postTourOperator,
    getTourOperator,
    getTourOperatorByID,
    getTourOperators,
    updateTourOperator,
  };
};
