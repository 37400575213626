import { format } from "date-fns";
import { defaultDateFormat } from "../../utils/commons";
import numberWithCommas from "../../utils/numberWithCommas";

export default function PasajeroRow({ pasajero, passengerType, className }) {
  const { doc_type, number } = pasajero?.TravelerDocument;
  if (!doc_type || !number) return;

  const documentNumber =
    doc_type === "IDENTITY_CARD" ? numberWithCommas(Number(number), 0) : number;

  return (
    <section className={`grid grid-cols-9 rounded ${className}`}>
      <p className="pasajerosCell border-r-2">{pasajero?.first_name}</p>
      <p className="pasajerosCell border-r-2">{pasajero?.last_name}</p>
      <p className="pasajerosCell border-r-2">{documentNumber}</p>
      <p className="pasajerosCell border-r-2">
        {format(new Date(pasajero?.date_of_birth), defaultDateFormat)}
      </p>
      <p className="pasajerosCell col-span-1">{passengerType}</p>
    </section>
  );
}
