import { useState } from "react";
import { useForm } from "react-hook-form";
import filter from "../../iconos/filter.png";
import SearcherRow from "./SearchQuotesHeaderRow";
import passenger from "../../iconos/passenger.png";
import SearchQuotesFilters from "./SearchQuotesFilters";
import { PASSENGER_DICTIONARY } from "../../utils/dictionaries/passengerDictionary";

const ROUTE_OPTIONS = [
  {
    key: "roundtrip",
    label: "Ida y Vuelta",
  },
  {
    key: "oneway",
    label: "Solo Ida",
  },
  {
    key: "multidestination",
    label: "Multidestino",
  },
  {
    key: "flexible",
    label: "Días Flexibles",
  },
];

export default function Searcher({
  onSubmit,
  setNewRouteOption,
  routeOption,
  inputState,
  filterState,
  minmaxDuration,
}) {
  const [inputValue, setInputValue] = inputState;
  const [showFilters, setShowFilters] = useState(true);
  const [showPassengerType, setShowPassengerType] = useState(false);
  const { register, handleSubmit, setValue, watch } = useForm();

  const handlePassenger = (passenger, min, op) => {
    const passengerValue = inputValue[passenger];
    const newValue =
      op === "+"
        ? passengerValue + 1
        : passengerValue > min
        ? passengerValue - 1
        : passengerValue;

    setInputValue((curr) => {
      return {
        ...curr,
        [passenger]: newValue,
      };
    });
  };

  const sumOfPassengers = inputValue.ADT + inputValue.CHD + inputValue.INF;

  return (
    <navbar>
      {/* FIRST ROW */}
      <section className="flex gap-3 mb-3">
        {ROUTE_OPTIONS.map(({ key, label }) => (
          <button
            key={key}
            type="button"
            className={`rounded-3xl py-1 px-3 text-sm text-white bg-brightBlue transition-all hover:shadow ${
              routeOption === key && "pointer-events-none bg-darkBlue"
            }`}
            onClick={() => setNewRouteOption(key)}
          >
            {label}
          </button>
        ))}
      </section>

      <form
        onSubmit={handleSubmit((data) => onSubmit(data))}
        className="grid bg-darkBlue p-4 rounded-xl"
      >
        {/* SECOND ROW */}
        <section className="grid grid-cols-6 gap-4 items-start 2xl:gap-8">
          <div className="col-span-4 grid gap-4">
            {[0, 1].map((row, index) => {
              if (index === 1 && routeOption !== "multidestination")
                return null;

              return (
                <SearcherRow
                  key={row}
                  index={row + 1}
                  inputState={inputState}
                  type={routeOption}
                  routeOption={routeOption}
                  isLastIndex={
                    routeOption !== "multidestination" || index === 1
                  }
                  useForm={{ register, setValue, watch }}
                />
              );
            })}
          </div>

          <label className="relative flex items-center group/passengers">
            <div className="border-l border-white h-10 absolute -left-2 2xl:-left-4" />
            <img
              src={passenger}
              alt="passenger"
              className="absolute left-3 w-4 z-10"
              onClick={() => setShowPassengerType(!showPassengerType)}
            />
            <input
              readOnly
              type="number"
              name="adults"
              value={sumOfPassengers}
              className="border border-gray2 h-10 pl-10 rounded-md p-2 px-4 w-full"
            />
            <div className="absolute z-40 w-64 top-full mt-2 bg-[#EFEFEF] rounded-2xl p-4 flex flex-col gap-2 scale-0 group-hover/passengers:scale-100 group-focus/passengers:scale-100 transition-transform origin-top-left">
              <section className="flex flex-col gap-2">
                {["ADT", "CHD", "INF"].map((passenger) => {
                  const { key, label, description, min } =
                    PASSENGER_DICTIONARY[passenger];
                  return (
                    <div
                      className="flex justify-between items-center"
                      key={key}
                    >
                      <div>
                        <h2 className="font-medium">{label}</h2>
                        <p className="text-xs text-gray">{description}</p>
                      </div>
                      <div className="flex gap-2 justify-center items-center">
                        <button
                          type="button"
                          onClick={() => handlePassenger(key, min, "-")}
                          className="border w-7 h-7 rounded-full text-sm font-[700]"
                        >
                          -
                        </button>
                        <p className="text-xl w-4 text-center">
                          {inputValue[passenger]}
                        </p>
                        <button
                          type="button"
                          onClick={() => handlePassenger(key, min, "+")}
                          className="border w-7 h-7 rounded-full text-sm font-[700]"
                        >
                          +
                        </button>
                      </div>
                    </div>
                  );
                })}
              </section>
            </div>
          </label>

          <div className="relative flex gap-4">
            <button
              type="button"
              className={`border border-gray2 h-10 w-10 p-2 rounded-md hover:bg-lightBlue transition-colors ${
                showFilters ? "bg-lightBlue" : "bg-white"
              }`}
              onClick={() => setShowFilters(!showFilters)}
            >
              <img src={filter} alt="Filtros avanzados" />
            </button>

            <button
              type="submit"
              className="bg-brightBlue text-white font-medium h-10 w-full rounded-md"
            >
              Buscar
            </button>
          </div>
        </section>

        {/* THIRD ROW */}
        <SearchQuotesFilters
          filterState={filterState}
          showFilters={showFilters}
          minmaxDuration={minmaxDuration}
          register={register}
        />
      </form>
    </navbar>
  );
}
