import { format } from "date-fns";

export default function FlightRow({
  flight,
  detail,
  ciudadesFormatted,
  className,
}) {
  const { departure, arrival, carrierCode, number } = flight;
  const departurePlace = ciudadesFormatted?.[departure?.iataCode];
  const arrivalPlace = ciudadesFormatted?.[arrival?.iataCode];

  return (
    <section className={`grid grid-cols-8 rounded ${className}`}>
      <div className="tableCell border-r-2 relative cursor-pointer group/departure">
        <span className="absolute left-1 -top-8 w-fit px-2 hidden whitespace-nowrap rounded border border-gray bg-white group-hover/departure:block">
          {departurePlace?.name}, {departurePlace?.City?.name}
        </span>
        <span>{departure?.iataCode}</span>
      </div>
      <div className="tableCell border-r-2 relative cursor-pointer group/arrival">
        <span className="absolute left-1 -top-8 w-fit px-2 hidden whitespace-nowrap rounded border border-gray bg-white group-hover/arrival:block">
          {arrivalPlace?.name}, {arrivalPlace?.City?.name}
        </span>
        <span>{arrival?.iataCode}</span>
      </div>
      <span className="tableCell border-r-2">
        {departure?.at ? format(new Date(departure?.at), "H:mm") : "-"}
      </span>
      <span className="tableCell border-r-2 col-span-2">
        {arrival?.at ? format(new Date(arrival?.at), "dd/MM/yyyy") : "-"}
      </span>
      <span className="tableCell border-r-2">
        {carrierCode ?? ""} {number ?? ""}
      </span>
      <span className="tableCell border-r-2">
        {detail?.includedCheckedBags?.quantity} PC
      </span>
      <span className="tableCell">{detail?.class}</span>
    </section>
  );
}
