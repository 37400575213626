export default function TableHeaderPaquetes() {
  return (
    <header className="bg-lightBlue tableGrid py-2 px-8 rounded-lg">
      <h4 className="font-medium text-brightBlue col-span-3">Nombre</h4>
      <h4 className="font-medium text-brightBlue col-span-2">Origen</h4>
      <h4 className="font-medium text-brightBlue col-span-2">Destino</h4>
      <h4 className="font-medium text-brightBlue col-span-2">Fecha Salida</h4>
      <h4 className="font-medium text-brightBlue col-span-2">Dispo / Cupos</h4>
      <h4 className="font-medium text-brightBlue col-span-2">Precio</h4>
      <h4 className="font-medium text-brightBlue col-span-1">Acción</h4>
      <h4 className="font-medium text-brightBlue col-span-1 ml-auto">Ver</h4>
    </header>
  );
}
