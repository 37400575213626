import { useState } from "react";
import { format } from "date-fns";
import Pagination from "../../utils/Pagination";

export default function TravelersTable({ travelers }) {
  const [page, setPage] = useState(0);

  return (
    <>
      <section className="p-4 border-2 border-gray2 bg-white rounded-lg">
        <header className="bg-lightBlue grid grid-flow-row grid-cols-4 py-2 px-8 rounded-lg">
          <p className="font-medium text-brightBlue">Nombre</p>
          <p className="font-medium text-brightBlue">Fecha de nacimiento</p>
          <p className="font-medium text-brightBlue">Email</p>
          <p className="font-medium text-brightBlue">Género</p>
        </header>
        <main>
          {travelers
            ?.slice(page * 10, page * 10 + 10)
            ?.map((traveler, index) => (
              <div
                key={traveler.id}
                className={`grid grid-flow-row grid-cols-4 py-2.5 px-8 items-center text-gray rounded-lg ${
                  index % 2 === 1 && "bg-lightGray2"
                }`}
              >
                <h2>
                  {traveler.first_name} {traveler.last_name}
                </h2>
                <p>{format(new Date(traveler.date_of_birth), "dd/MM/yyyy")}</p>
                <p>{traveler.email_address}</p>
                <p>{traveler.gender === "F" ? "Femenino" : "Masculino"}</p>
              </div>
            ))}
        </main>
      </section>

      <Pagination
        items={travelers}
        itemsPerPage={10}
        page={page}
        setPage={setPage}
      />
    </>
  );
}
