import cargando from "../iconos/cargando.png";
import "../styles/anicarga.css";

const Anicarga = () => {
  return (
    <div className=" select-none">
      <img className="img-carga w-80" src={cargando} alt="loading" />
    </div>
  );
};

export default Anicarga;
