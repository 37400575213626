import { create } from "zustand";

export const useSelectPaquetes = create((set) => ({
  packageSelected: {},
  numberOfPassengers: {
    ADT: 1,
    CHD: 0,
    INF: 0,
  },
  selectPackage: (p) =>
    set(() => ({
      packageSelected: p,
    })),
  setNumberOfPassengers: (newPassangersValue) =>
    set(() => ({
      numberOfPassengers: newPassangersValue,
    })),
}));
