import clsx from "clsx";
import numberWithCommas from "../../utils/numberWithCommas";
import { taxesTypes } from "../../utils/commons";
import ticket from "../../iconos/ticket.png";
import _ from "lodash";
import { Fragment } from "react";
import { Link, useParams } from "react-router-dom";
import { useLocationQuery } from "../../hooks/useLocationQuery";

export const SubTotalFlights = ({
  breakdownPrices,
  commissions,
  currency,
  exchangeRate,
  hasReturnFlight,
  idOffer,
  original,
  price,
  showModal,
}) => {
  const { parsedHash } = useLocationQuery();
  const { tour_op_id } = useParams();
  const thisOption = original.find((x) => x.id === idOffer);
  const originalPrice = thisOption?.price?.total;
  const { dolar, euro } = exchangeRate;
  const sumOfTaxes = _.chain(breakdownPrices)
    .pickBy((a) => a?.taxes)
    .reduce((a, b) => (a || 0) + (b?.taxes || 0), 0)
    .value();

  const totalToARS = (number) => (currency === "ARS" ? number : euro * number);
  const totalPassengers = original?.[0]?.travelerPricings?.length;

  const sumEveryPassenger = {
    ADULT: parsedHash?.adults || 1,
    CHILD: parsedHash?.children || 0,
    HELD_INFANT: parsedHash?.infants || 0,
  };

  const sumEveryPassengerToDisplay = breakdownPrices.reduce(
    (acc, curr) => {
      if (curr.type === "ADULT") {
        acc.ADULT += Number(curr.amount);
      } else if (curr.type === "CHILD") {
        acc.CHILD += Number(curr.amount);
      } else if (curr.type === "HELD_INFANT") {
        acc.HELD_INFANT += Number(curr.amount);
      }
      return acc;
    },
    { ADULT: 0, CHILD: 0, HELD_INFANT: 0 }
  );

  return (
    <aside
      className={`flex flex-col pl-4 sticky top-0 h-fit min-w-[300px] ${
        hasReturnFlight ? "mt-6" : "mt-0"
      }`}
    >
      <section
        className={`flex flex-col py-6 pb-1 z-20 relative rounded-2xl justify-between ${
          hasReturnFlight ? "top-6" : "top-0"
        }`}
      >
        <div className="bg-darkBlue absolute z-10 top-0 h-20 left-0 rounded-t-2xl py-3 pb-5 px-5 w-full">
          <span className="flex items-center gap-2 text-lightGray2">
            <img src={ticket} className="w-5 h-4" alt="ticketCompra" />
            <p className="font-medium whitespace-nowrap">
              Detalle de su compra
            </p>
          </span>
        </div>
        <span className="relative z-30 flex flex-col w-full gap-2 p-4 bg-white rounded-2xl top-5 border-2 border-darkbg-darkBlue">
          <div className="grid grid-cols-3 gap-2">
            <div className="text-xs col-span-2">
              {/* DESCOMENTAR CUANDO HAYA CONVERSOR DE DIVISA */}
              {/* {currency === "EUR" && (
                <>
                  <p>
                    Original Total: {currency}{" "}
                    {Number(price * totalPassengers).toFixed(2)} * {euro}
                  </p>
                  <p>Cotización BNA.</p>
                </>
              )} */}
            </div>

            {/* DESCOMENTAR CUANDO SE USE AMADEUS */}
            {/* <button
              onClick={() => showModal(thisOption)}
              className={clsx(
                "rounded py-1 px-2 bg-brightBlue w-min whitespace-nowrap text-white text-sm ml-auto scale-0 transition-all hover:bg-brightBlue/80",
                {
                  "scale-100": price === originalPrice,
                }
              )}
            >
              + Tarifas
            </button> */}
          </div>

          {/* DESCOMENTAR CUANDO HAYA CONVERSOR DE DIVISA */}
          {/* <p className="text-3xl font-medium whitespace-nowrap -mt-4">
            ARS {currency === "EUR" ? "~" : ""}
            {numberWithCommas(totalToARS(Number(price * totalPassengers)), 2)}
          </p> */}

          {/* ----------------------------- */}
          <span className="text-right -mt-4">
            Precio por Adulto
            <p className="text-3xl font-medium whitespace-nowrap">
              EUR {numberWithCommas(Number(price), 2)}
            </p>
          </span>
          {/* ----------------------------- */}
          <div>
            {Object.keys(sumEveryPassengerToDisplay)?.map((key, index) => {
              if (!sumEveryPassengerToDisplay[key]) return null;

              return (
                <Fragment key={index}>
                  <span className="flex justify-between">
                    <p>{sumEveryPassenger[key] + " " + taxesTypes[key]}</p>
                    {/* DESCOMENTAR CUANDO HAYA CONVERSOR DE DIVISA */}
                    {/* <p>
                        ARS {currency === "EUR" ? "~" : ""}
                        {numberWithCommas(totalToARS(Number(amount)))}
                        </p> */}
                    {/* ----------------------------- */}
                    <p className="whitespace-nowrap">
                      EUR {numberWithCommas(sumEveryPassengerToDisplay[key], 2)}
                    </p>
                    {/* ----------------------------- */}
                  </span>
                </Fragment>
              );
            })}

            <span className="flex justify-between pb-1">
              Impuestos, tasas y cargos
              {/* DESCOMENTAR CUANDO HAYA CONVERSOR DE DIVISA */}
              {/* <p>
                ARS {currency === "EUR" ? "~" : ""}
                {numberWithCommas(totalToARS(sumOfTaxes), 2)}
              </p> */}
              {/* ----------------------------- */}
              <p className="whitespace-nowrap">
                EUR {numberWithCommas(sumOfTaxes, 2)}
              </p>
              {/* ----------------------------- */}
            </span>

            {/* DESCOMENTAR CUANDO SE QUIERA USAR COMMISIONES */}
            {/* {commissions.length > 0 && (  
              <section>
                {commissions.map(({ id, name, percentage }) => (
                  <span key={id} className="flex items-center">
                    <p>
                      {name} ({percentage}%)
                    </p>
                  </span>
                ))}
              </section>
            )} */}
            <span className="flex justify-between pt-1 font-semibold border-t">
              Total
              {/* DESCOMENTAR CUANDO HAYA CONVERSOR DE DIVISA */}
              {/* <p>
                ARS {currency === "EUR" ? "~" : ""}
                {numberWithCommas(totalToARS(Number(price * totalPassengers)), 2)}
              </p> */}
              {/* ----------------------------- */}
              <p>EUR {numberWithCommas(Number(price * totalPassengers), 2)}</p>
              {/* ----------------------------- */}
            </span>
          </div>

          <a
            href="https://thewalltrip.com.ar/promociones"
            className="mt-1 text-xs text-gray underline"
            rel="noreferrer"
            target="_blank"
          >
            Ver promociones bancarias
          </a>
        </span>
      </section>

      <Link
        to={`/${tour_op_id}/detail?type=flights`}
        className={clsx(
          {
            "bg-white border text-[#091832] border-[#091832] pointer-events-none":
              price !== originalPrice,
          },
          {
            "text-white border-[#091832]": price === originalPrice,
          },
          "px-3 py-2 my-6 relative top-6 text-center rounded-2xl text-xl items-center justify-center cursor-pointer bg-[#091832] flex gap-4",
          "hover:opacity-90"
        )}
      >
        Confirmar
      </Link>
    </aside>
  );
};
