import AA from "../../iconos/aerolineas/AA.png";
import AF from "../../iconos/aerolineas/AF.png";
import AR from "../../iconos/aerolineas/AR.png";
import AV from "../../iconos/aerolineas/AV.png";
import CM from "../../iconos/aerolineas/CM.png";
import FO from "../../iconos/aerolineas/FO.png";
import G3 from "../../iconos/aerolineas/G3.png";
import IB from "../../iconos/aerolineas/IB.png";
import JA from "../../iconos/aerolineas/JA.png";
import KL from "../../iconos/aerolineas/KL.png";
import LA from "../../iconos/aerolineas/LA.png";
import UX from "../../iconos/aerolineas/UX.png";

export const AIRLINES_DICTIONARY = {
  AA: {
    label: "American Airlines",
    key: "AA",
    img: AA,
  },
  AF: {
    label: "Air France",
    key: "AF",
    img: AF,
  },
  AR: {
    label: "Aerolíneas Argentinas",
    key: "AR",
    img: AR,
  },
  AV: {
    label: "Avianca",
    key: "AV",
    img: AV,
  },
  CM: {
    label: "Copa Airlines",
    key: "CM",
    img: CM,
  },
  FO: {
    label: "FlyBondi",
    key: "FO",
    img: FO,
  },
  G3: {
    label: "GOL",
    key: "G3",
    img: G3,
  },
  IB: {
    label: "Iberia",
    key: "IB",
    img: IB,
  },
  JA: {
    label: "JetSmart",
    key: "JA",
    img: JA,
  },
  KL: {
    label: "KLM",
    key: "KL",
    img: KL,
  },
  LA: {
    label: "LATAM",
    key: "LA",
    img: LA,
  },
  UX: {
    label: "AirEuropa",
    key: "UX",
    img: UX,
  },
};