export const SCALES_DICTIONARY = {
  0: {
    label: "Directo",
    key: "zero",
  },
  1: {
    label: "1 Escala",
    key: "one",
  },
  2: {
    label: "2 Escalas",
    key: "two",
  },
};