import Head from "../head";
import userIcon from "../../iconos/fi-rr-user.png";
import down_arrow from "../../iconos/down_arrow.png";
import { FormUserData } from "./FormUserData";
import { PurchaseDetail } from "./PurchaseDetail";
import { Tramo } from "./Tramo";
import { useSelectFlights } from "../flights/useSelectFlights";
import { useBookings } from "../../hooks/useBookings";
import { useLocationQuery } from "../../hooks/useLocationQuery";
import _ from "lodash";
import { Toaster, toast } from "react-hot-toast";
import { useEffect, useState } from "react";
import { passengerTypes } from "../../utils/commons";
import { useSelectPaquetes } from "../packages/useSelectPaquetes";
import useGetAirports from "../../hooks/useGetAirports";
import { format } from "date-fns";
import SEO from "../../utils/SEO";
import { useGetAirlines } from "../../hooks/useGetAirlines";
import useCommissionsAndTaxes from "../../hooks/useCommissionsAndTaxes";
import useGetCurrencies from "../../hooks/useGetCurrencies";
import { useUserStore } from "../../hooks/useUserStore";
import { useToggleVisibility } from "../../hooks/useToggleVisibility";
import { ModalGeneric } from "../../utils/ModalGeneric";
import { Login } from "../auth/Login";
import useSendEmail from "../../hooks/useSendEmail";
import { saveTourOperator } from "../../utils/saveTourOperator";
import { setMailOptions } from "./setMailOptions";

const translateCabin = (cabin) => {
  if (cabin === "ECONOMY") return "ECO";
  return "EJE";
};

const checkErrors = (errors, firstPassenger, type) => {
  const errorLength = Object.keys(errors)?.filter((key) => {
    return !errors[key]?.correct;
  }).length;

  if (
    errorLength > 0 ||
    Object.keys(errors).length <
      (type === "flights" ? (firstPassenger ? 8 : 7) : 4)
  ) {
    const btn = document.getElementById("reserve_btn");
    btn.classList.add("bg-red-400");
    btn.classList.add("hover:bg-red-400");
    btn.textContent = "Completar todos los campos";

    setTimeout(() => {
      btn.classList.remove("bg-red-400");
      btn.classList.remove("hover:bg-red-400");
      btn.textContent = "Reserva";
    }, 2000);

    return false;
  }
  return true;
};

const handleDisplay = (title) => {
  const body = document.getElementById(`${title}-body`);
  const arrow = document.getElementById(`${title}-arrow`);

  body.classList.toggle("scale-y-0");
  body.classList.toggle("h-0");
  body.classList.toggle("p-4");
  arrow.classList.toggle("-rotate-180");
  arrow.classList.toggle("rotate-0");
};

export default function Detalle() {
  const { user } = useUserStore();
  const { parsedHash } = useLocationQuery();
  const tourOP = saveTourOperator((st) => st.tourOP);
  const type = parsedHash?.type;
  const flight = useSelectFlights((st) => st.flight);
  const original = useSelectFlights((st) => st.original);
  const packageSelected = useSelectPaquetes((st) => st.packageSelected);
  const numberOfPassengers = useSelectPaquetes((st) => st.numberOfPassengers);
  const { travelerPricings, selectedAmenities } = original;
  const { currencies } = useGetCurrencies();
  const { createBooking } = useBookings();
  const { commissions, getCommissions } = useCommissionsAndTaxes();
  const [isVisible, toggleVisibility] = useToggleVisibility();
  const { sendEmail } = useSendEmail();
  const [passengerData, setPassengerData] = useState({});
  const [errors, setErrors] = useState({});
  const hasReturnDate = type === "flights" && flight?.hasOwnProperty("Vuelta");

  const book = async () => {
    // VALIDAR TODOS LOS CAMPOS DE TODOS LOS PASAJEROS
    const separatedErrors = Object.values(
      errors?.["1"] ? errors : { 1: { name: { correct: false, value: "" } } }
    );

    if (
      separatedErrors.some(
        (errors, index) => !checkErrors(errors, index === 0, type)
      )
    )
      return;

    const currency_type_id =
      type === "flights"
        ? currencies.find(({ symbol }) =>
            original?.price?.currency.includes(symbol)
          )?.id
        : packageSelected.currency_type_id;

    toast.promise(
      createBooking({
        selectedItem: type === "flights" ? original : packageSelected?.id,
        passenger: _.values(passengerData),
        type: type === "flights" ? "flight" : "package",
        currency_type_id,
      })
        .then((e) => {
          sendEmail({
            tour_op_id: tourOP.id,
            options: {
              ...setMailOptions(
                tourOP?.email,
                user?.email,
                type,
                e?.data?.detail
              ),
            },
          });
        })
        .catch(() => {
          !user?.id && toggleVisibility(true);
          throw new Error();
        }),
      {
        loading: "Cargando...",
        success: <b>Reserva con éxito.</b>,
        error: (
          <b>
            {!user?.id ? "Inicia sesión." : "Hubo un error, intenta de nuevo."}
          </b>
        ),
      }
    );
  };

  const flArrival = flight?.IDA?.segments?.at(-1)?.arrival;
  const departureDate = flight?.IDA?.segments?.[0]?.departure?.at;
  const arrivalDate = flight?.Vuelta?.segments?.at(-1)?.arrival.at;

  const { getAirports, airports } = useGetAirports();
  const { getAirlines, airlines } = useGetAirlines();
  const [ciudadesFormatted, setCiudadesFormatted] = useState({});
  const [airlinesFormatted, setAirlinesFormatted] = useState({});

  useEffect(() => {
    getAirports();
    getAirlines();
    getCommissions();
  }, []);

  useEffect(() => {
    if (airports) {
      setCiudadesFormatted(() => {
        let formattedCities = {};
        airports.forEach((airport) => {
          formattedCities[airport.iata] = {
            city: airport.City.name,
            airport: airport.name,
          };
        });
        return formattedCities;
      });
    }
  }, [airports]);

  useEffect(() => {
    if (airlines.length > 0) {
      setAirlinesFormatted(() => {
        let formattedAirlines = {};
        airlines.forEach((airline) => {
          formattedAirlines[airline.code] = airline;
        });
        return formattedAirlines;
      });
    }
  }, [airlines]);

  const formattedNumberOfPassengers = () => {
    const { ADT, CHD, INF } = numberOfPassengers;
    const totalPassangers = ADT + CHD + INF;

    return new Array(totalPassangers).fill(0).map((_, index) => {
      const travelerType =
        index < ADT ? "ADULT" : index < ADT + CHD ? "CHILD" : "HELD_INFANT";

      return {
        travelerType,
        travelerId: index + 1,
      };
    });
  };

  return (
    <section className="h-full">
      <SEO title={"Detalle"} />
      <div>
        <Toaster />
      </div>
      <Head />
      <main className="max-w-[1100px] mx-auto my-8">
        <div className="grid justify-center grid-cols-12 gap-1 grid-rows-12">
          <div className="col-span-12 px-4 pt-2 pb-1 rounded-lg ">
            <h1 className="text-xl text-[#242423] font-[700]">
              ¡Ya casi estás! Completá tus datos y finalizá tu compra
            </h1>
          </div>
          <div className="relative col-span-6 flex flex-col gap-4 mr-2">
            {/* Pasajeros */}
            {(travelerPricings || formattedNumberOfPassengers() || []).map(
              (v, index) => {
                const title = `Pasajero ${index + 1} (${
                  passengerTypes?.[v?.travelerType] || "Adulto"
                })`;

                return (
                  <div
                    key={v?.travelerId || index}
                    className="border border-gray2 rounded-lg"
                  >
                    <PassengerHeader
                      first={index === 0}
                      key={v?.travelerId || index}
                      row={6}
                      title={title}
                      icon={userIcon}
                      handleDisplay={handleDisplay}
                    />
                    <FormUserData
                      id={v?.travelerId}
                      index={index}
                      idBody={`${title}-body`}
                      isFlight={type === "flights"}
                      passengerState={[passengerData, setPassengerData]}
                      error={[errors, setErrors]}
                    />
                  </div>
                );
              }
            )}
          </div>

          <div className="col-span-6 ml-2 row-span-12 ">
            <div className="sticky top-2 flex flex-col gap-4">
              <section
                className={`col-span-2 row-span-2 rounded-lg border border-gray2 overflow-hidden relative`}
              >
                <PurchaseDetail
                  type={type}
                  selectedAmenities={selectedAmenities}
                  commissions={commissions}
                  currencies={currencies}
                  numberOfPassengers={formattedNumberOfPassengers()}
                />
              </section>

              <section className="col-span-2 row-span-2 rounded-lg border border-gray2 relative">
                <header className="w-full bg-lightBlue rounded-lg flex justify-between">
                  <p className="font-medium text-xl px-8 py-3">
                    {type === "flights"
                      ? ciudadesFormatted?.[flArrival?.iataCode]?.city
                      : packageSelected?.title}
                  </p>
                  <div className="flex gap-4 items-center">
                    {type === "flights" && (
                      <div className="flex flex-col justify-center">
                        <span className="flex items-center gap-1 text-brightBlue font-medium">
                          <img src={userIcon} alt="user_icon" className="h-3" />
                          <p className="text-sm">
                            {travelerPricings?.length}{" "}
                            {travelerPricings?.length === 1
                              ? "pasajero"
                              : "pasajeros"}
                          </p>
                        </span>
                        <p className="text-sm font-medium text-brightBlue capitalize">
                          Clase{" "}
                          {translateCabin(
                            travelerPricings?.[0]?.fareDetailsBySegment?.[0]
                              ?.cabin
                          )}
                        </p>
                      </div>
                    )}
                    <div className="grid items-center bg-white h-full px-4 border-2 border-brightBlue text-brightBlue font-semibold rounded-md">
                      {(flight || packageSelected) &&
                        format(
                          new Date(
                            type === "flights"
                              ? departureDate && departureDate
                              : packageSelected?.departure_date
                          ),
                          "dd/MM"
                        )}
                      {" > "}
                      {(flight || packageSelected) &&
                        format(
                          new Date(
                            type === "flights"
                              ? hasReturnDate && arrivalDate
                              : packageSelected?.return_date
                          ),
                          "dd/MM"
                        )}
                    </div>
                  </div>
                </header>
                <div className="grid px-8">
                  {type === "flights" ? (
                    <>
                      <Tramo
                        type={"IDA"}
                        travelerPricing={travelerPricings}
                        ciudadesFormatted={ciudadesFormatted}
                        airlinesFormatted={airlinesFormatted}
                        title={
                          ciudadesFormatted?.[flArrival?.iataCode]?.airport
                        }
                      />
                      {hasReturnDate && (
                        <Tramo
                          type={"Vuelta"}
                          travelerPricing={travelerPricings}
                          ciudadesFormatted={ciudadesFormatted}
                          airlinesFormatted={airlinesFormatted}
                          title={
                            ciudadesFormatted?.[flArrival?.iataCode]?.airport
                          }
                        />
                      )}
                    </>
                  ) : (
                    <>
                      <p>
                        <span className="font-semibold">Origen:</span>{" "}
                        {packageSelected?.oricode}
                      </p>
                      <p>
                        <span className="font-semibold">Destino:</span>{" "}
                        {packageSelected?.descode}
                      </p>
                      <p>
                        <span className="font-semibold">Descripción:</span>
                      </p>
                      <div>
                        {packageSelected?.description
                          ?.split("<br>")
                          .map((item, index) => (
                            <p key={index}>{item}</p>
                          ))}
                      </div>
                      <img
                        src={packageSelected?.portrait}
                        alt={packageSelected?.title}
                        className="h-40 pb-4"
                      />
                    </>
                  )}
                </div>
              </section>

              <button
                id="reserve_btn"
                className={`relative col-span-2 row-span-2 text-darkBlue text-center py-4 border-2 border-darkBlue text-2xl font-semibold w-full rounded-md mb-2 cursor-pointer hover:bg-darkBlue hover:text-white active:opacity-75 transition-all`}
                onClick={book}
              >
                RESERVAR
              </button>
            </div>
          </div>
        </div>
      </main>
      <ModalGeneric show={isVisible} toggle={toggleVisibility}>
        <Login keepRoute closeModal={toggleVisibility} />
      </ModalGeneric>
    </section>
  );
}

const PassengerHeader = ({ icon, title, first, handleDisplay }) => {
  return (
    <div
      className="py-3 px-8 flex justify-between items-center rounded-md bg-lightBlue cursor-pointer"
      onClick={() => handleDisplay(title)}
    >
      <span className="flex items-center gap-2">
        <img src={icon} alt="passenger" className="h-6" />
        <h2 className="font-medium text-xl">{title}</h2>
      </span>
      <img
        src={down_arrow}
        alt="arrow"
        className={`h-4 transition-transform ${
          first ? "-rotate-180" : "rotate-0"
        }`}
        id={`${title}-arrow`}
      />
    </div>
  );
};
