import { Link, useParams } from "react-router-dom";
import { saveTourOperator } from "../../utils/saveTourOperator";
import logo_walltrip from "../../iconos/logos/walltrip.png";


export default function HistoryHeader() {
  const tourOP = saveTourOperator((st) => st.tourOP);
  const { tour_op_id } = useParams();

  return (
    <header className="p-8">
      <Link
          className="cursor-pointer"
          to={`/${tour_op_id || "walltrip"}/flights`}
        >
          <img
            className="max-w-[128px] max-h-16"
            src={tourOP?.logo !== "-" ? tourOP?.logo : logo_walltrip}
            alt={tourOP?.name}
          />
        </Link>
    </header>
  );
}