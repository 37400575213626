import valija from "../../iconos/equipaje.png";

export default function OptionModal({ details, price, toggleVisibility }) {
  const keys = Object.keys(details);
  const firstFlight = details[keys[0]].flight;
  const lastFlight = details[keys.at(-1)].flight;

  return (
    <section className="p-2 rounded-xl text-center">
      <h1 className="text-2xl mb-4">Opcionales Disponibles</h1>
      <section>
        <header className="grid grid-cols-3 gap-24 bg-black p-2 rounded-md text-white mb-2">
          <h2 className="mx-auto">Ida</h2>
          <h2 className="mx-auto">Vuelta</h2>
          <h2 className="mx-auto">Precio</h2>
        </header>
        <div className="grid grid-cols-3 gap-24 bg-lightGray p-2 rounded-md">
          <OptionalBag flight={firstFlight} />
          <OptionalBag flight={lastFlight} />
          <button
            className="bg-brightBlue mx-auto py-1 px-2 rounded-full text-white"
            onClick={() => toggleVisibility()}
          >
            + $0,00
          </button>
        </div>
      </section>
    </section>
  );
}

const OptionalBag = ({ flight }) => {
  const { brandedFare, includedCheckedBags } = flight;
  return (
    <div className="flex gap-4 mx-auto">
      <p>{brandedFare}</p>
      <div className="flex gap-1">
        {includedCheckedBags.quantity}
        <img src={valija} alt="valija" className="h-5" />
      </div>
      <p>{flight.class}</p>
    </div>
  );
};
