import { useState } from "react";
import { getLocalStorageValue, getSessionStorageValue } from "./useCookie";
import axios from "axios";

export const useTravelers = () => {
  const BASE_URL = process.env.REACT_APP_API_URL;
  const [travelers, setTravelers] = useState([]);

  const token =
    getLocalStorageValue("token") || getSessionStorageValue("token");

  const headers = {
    headers: { Authorization: `${token}` },
  };


  const getTravelers = async () => {
    return axios
      .get(BASE_URL + "v2/travelers")
      .then((e) => {
        setTravelers(e.data.response);
      })
      .catch((err) => console.log(err));
  };

  return {
    travelers,
    getTravelers,
  };
};
