import { useState } from "react";

export default function HotelsItemTabs({
  HotelInfo,
  HotelOption,
  handleReserve,
}) {
  const [tab, setTab] = useState("rooms");

  const { Description, Address, Latitude, Longitude } = HotelInfo;
  const { HotelRoom } = HotelOption.HotelRooms[0];

  return (
    <section>
      <header className="grid grid-cols-3 mb-4">
        <button
          className={`border-b border-brightBlue rounded-t font-bold py-1 px-4 cursor-pointer hover:bg-lightBlue transition-colors ${
            tab === "rooms" && "bg-darkBlue text-white border-b-4"
          }`}
          onClick={() => setTab("rooms")}
        >
          Habitación
        </button>
        <button
          className={`border-b border-brightBlue rounded-t font-bold py-1 px-4 cursor-pointer hover:bg-lightBlue transition-colors ${
            tab === "description" && "bg-darkBlue text-white border-b-4"
          }`}
          onClick={() => setTab("description")}
        >
          Descripción
        </button>
        <button
          className={`border-b border-brightBlue rounded-t font-bold py-1 px-4 cursor-pointer hover:bg-lightBlue transition-colors ${
            tab === "address" && "bg-darkBlue text-white border-b-4"
          }`}
          onClick={() => setTab("address")}
        >
          Dirección
        </button>
      </header>
      {tab === "rooms" && (
        <div className="flex flex-col gap-2">
          {HotelRoom.map((room, index) => {
            const { Name, RoomCategory, RoomOccupancy } = room;
            return (
              <section key={index} className="grid grid-cols-4 items-center">
                <p>
                  <span className="font-bold">Nombre:</span> {Name[0]}
                </p>
                <p>
                  <span className="font-bold">Categoría:</span>{" "}
                  {RoomCategory[0]._ || "-"}
                </p>
                <p>
                  <span className="font-bold">Máximo:</span>{" "}
                  {RoomOccupancy[0].$.MaxOccupancy}
                </p>
                <button
                  className="button bg-brightBlue text-white text-center"
                  onClick={handleReserve}
                >
                  Reservar
                </button>
              </section>
            );
          })}
        </div>
      )}
      {tab === "description" && <p>{Description[0]}</p>}
      {tab === "address" && (
        <div className="flex flex-col gap-2">
          <p>
            <span className="font-bold">Dirección:</span>{" "}
            {Address?.[0] || "COMPLETAR"}
          </p>
          <a
            href={`https://www.google.com.ar/maps/dir//${Latitude?.[0]},${Longitude?.[0]}/@${Latitude?.[0]},${Longitude?.[0]},17z/data=!4m2!4m1!3e0?entry=ttu`}
            target="_blank"
            rel="noreferrer"
            className="button bg-darkBlue text-white text-center"
          >
            Abrir en Google Maps
          </a>
        </div>
      )}
    </section>
  );
}
