/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "../../styles/buscador.css";
import { useForm } from "react-hook-form";
import { useLocationQuery } from "../../hooks/useLocationQuery";
import { zodResolver } from "@hookform/resolvers/zod";
import { useParams } from "react-router-dom";
import { usePackages } from "../../hooks/usePackages";
import { saveTourOperator } from "../../utils/saveTourOperator";
import SearcherPackages from "./SearcherPackages";
import SearcherFlights from "./SearcherFlights";
import SearcherHotels from "./SearcherHotels";
import {
  flightSearchSchema,
  packageSearchSchema,
  hotelSearchSchema,
} from "../../schemas/searchEngine";

export default function Buscador() {
  const tourOP = saveTourOperator((st) => st.tourOP);
  const { tour_op_id, type } = useParams();
  const { parsedHash, changeQuery } = useLocationQuery();
  const { packages, getPackages } = usePackages();
  const [opcionrutas, setOpcionRutas] = useState(
    parsedHash?.opcionrutas || "idayvuelta"
  );
  const [oricodeInput, setOricodeInput] = useState("");
  const [descodeInput, setDescodeInput] = useState("");
  const [filteredPackages, setFilteredPackages] = useState();
  const [passengerCounter, setPassengerCounter] = useState({
    ADT: 1,
    CHD: 0,
    INF: 0,
  });
  const { ADT, CHD, INF } = passengerCounter;
  const sumPassengers = ADT + CHD + INF;

  const handlePassenger = (passenger, register, min, op) => {
    const passengerValue = passengerCounter[passenger];
    const newValue =
      op === "+"
        ? passengerValue + 1
        : passengerValue > min
        ? passengerValue - 1
        : passengerValue;

    setValue(register, newValue.toString());

    setPassengerCounter((curr) => {
      return {
        ...curr,
        [passenger]: newValue,
      };
    });
  };

  useEffect(() => {
    if (tourOP) {
      getPackages();
    }
  }, [tourOP]);

  // REDIRECCIÓN AL PASO 2 UNA VEZ COMPLETADA EL FORM DE BÚSQUEDA
  const onSubmit = (data) => {
    if (type === "packages") {
      data.departureDate = `${data.departureDate}-01`;
      data.originLocationCode = data.originLocationCode ?? null;
    }

    changeQuery({
      query: data,
      route:
        type === "flights"
          ? `${tour_op_id}/search-flight`
          : type === "packages"
          ? `${tour_op_id}/search-packages`
          : `${tour_op_id}/search-hotels`,
      go: true,
    });
  };

  const schema =
    type === "flights"
      ? flightSearchSchema(opcionrutas)
      : type === "packages"
      ? packageSearchSchema
      : hotelSearchSchema;

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
  });

  // FILTRO DE PAQUETES SEGÚN INPUTS DE BÚSQUEDA
  useEffect(() => {
    if (type !== "packages" || !packages) return;

    const monthInput = watch("month");
    const yearInput = watch("year");

    const possiblePackages = packages.filter(
      ({ descode, oricode, departure_date }) => {
        const filterByDescode = !!descodeInput
          ? descode.toUpperCase().includes(descodeInput.toUpperCase())
          : true;

        const filterByOricode = !!oricodeInput
          ? oricode.toUpperCase().includes(oricodeInput.toUpperCase())
          : true;

        const filterByDepartureMonth = monthInput
          ? departure_date.includes(monthInput)
          : true;

        const filterByDepartureYear = yearInput
          ? departure_date.includes(yearInput)
          : true;

        return (
          filterByDescode &&
          filterByOricode &&
          filterByDepartureMonth &&
          filterByDepartureYear
        );
      }
    );
    setFilteredPackages(possiblePackages);
  }, [
    type,
    packages,
    oricodeInput,
    descodeInput,
    watch("month"),
    watch("year"),
  ]);

  // MANEJO DE LA DATA DE PAQUETES PARA LUEGO LLEVAR A <SearcherPackages /> LA INFO JUSTA Y NECESARIA
  const possibleCitiesAndMonth = filteredPackages?.reduce(
    (acc, { oricode, descode, departure_date }) => {
      return (acc = {
        oricode: [...acc.oricode, oricode],
        descode: [...acc.descode, descode],
        departure_date: [...acc.departure_date, departure_date.split("T")[0]],
      });
    },
    { oricode: [], descode: [], departure_date: [] }
  );

  return (
    <form
      onSubmit={handleSubmit((data) => onSubmit(data))}
      className="buscador select-none px-8 pb-6 pt-4 bg-black mx-auto max-w-[1050px] flex flex-col gap-6 rounded-3xl"
    >
      {type === "hotels" ? (
        <SearcherHotels
          useForm={{ register, setValue, watch, errors }}
          descodeState={[descodeInput, setDescodeInput]}
          oricodeState={[oricodeInput, setOricodeInput]}
          passangerState={[passengerCounter, setPassengerCounter]}
          parsedHash={parsedHash}
        />
      ) : type === "packages" ? (
        <SearcherPackages
          descodeState={[descodeInput, setDescodeInput]}
          oricodeState={[oricodeInput, setOricodeInput]}
          passangerState={[passengerCounter, setPassengerCounter]}
          sumPassengers={sumPassengers}
          handlePassenger={handlePassenger}
          parsedHash={parsedHash}
          possibleCitiesAndMonth={possibleCitiesAndMonth}
          useForm={{ register, setValue, watch, errors }}
        />
      ) : (
        // type === "flights"
        <SearcherFlights
          routeOptionState={[opcionrutas, setOpcionRutas]}
          useForm={{ register, setValue, watch, errors }}
          descodeState={[descodeInput, setDescodeInput]}
          oricodeState={[oricodeInput, setOricodeInput]}
          passangerState={[passengerCounter, setPassengerCounter]}
          sumPassengers={sumPassengers}
          handlePassenger={handlePassenger}
          parsedHash={parsedHash}
        />
      )}
    </form>
  );
}
