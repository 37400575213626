import arrow from "../../iconos/chevron_down_gray.png";

const translatePassenger = (passenger) => {
  if (passenger === "ADT") return "Adulto";
  if (passenger === "CHD") return "Niño";
  return "Bebé";
};

export default function SelectedQuotePassenger({
  passenger,
  clientState,
  openPassengerState,
  index,
  isOpen,
}) {
  const [passengerType, passengerIndex] = passenger.split("-");
  const [passengersInfo, setPassengersInfo] = clientState;
  const [openPassenger, setOpenPassenger] = openPassengerState;

  const handlePassengersInfo = (e) => {
    const { name, value } = e.target;

    setPassengersInfo((curr) => ({
      ...curr,
      [index]: {
        ...curr[index],
        [name]: value,
      },
    }));
  };

  return (
    <div className="grid gap-2">
      <header
        className="py-2 px-4 rounded-md bg-white cursor-pointer flex justify-between items-center"
        onClick={() => setOpenPassenger(openPassenger === index ? -1 : index)}
      >
        <h3 className="font-medium">
          {index + 1}) {translatePassenger(passengerType)} {passengerIndex}
        </h3>
        <img
          src={arrow}
          alt="arrow"
          className={`w-4 h-2 transition-all ${
            isOpen ? "-rotate-180" : "rotate-0"
          }`}
        />
      </header>
      <div
        className={`grid grid-cols-2 gap-2 transition-transform duration-200 origin-top ${
          isOpen ? "scale-y-100" : "scale-y-0 h-0"
        }`}
      >
        <input
          className="bg-lightBlue2 rounded-md w-full p-2 border border-brightBlue"
          placeholder="Nombre del Pasajero"
          name="name"
          value={passengersInfo?.[index]?.name ?? ""}
          onChange={handlePassengersInfo}
        />
        <input
          className="bg-lightBlue2 rounded-md w-full p-2 border border-brightBlue"
          placeholder="Apellido del Pasajero"
          name="lastname"
          value={passengersInfo?.[index]?.lastname ?? ""}
          onChange={handlePassengersInfo}
        />
        <input
          className="bg-lightBlue2 rounded-md w-full p-2 border border-brightBlue"
          placeholder="Número de Documento"
          name="doc"
          value={passengersInfo?.[index]?.doc ?? ""}
          onChange={handlePassengersInfo}
        />
        <input
          type="date"
          className="bg-lightBlue2 rounded-md w-full p-2 border border-brightBlue"
          placeholder="Fecha de nacimiento"
          name="birth"
          value={passengersInfo?.[index]?.birth ?? ""}
          onChange={handlePassengersInfo}
        />
      </div>
    </div>
  );
}
