import { format } from "date-fns";
import { Link } from "react-router-dom";

export default function ModalViewPackage({selectedPackage, translateCurrency, tour_op_id}) {
  return (
    <div className="flex flex-col gap-2 -mt-2">
      <div className="flex justify-between items-center">
        <h2 className="font-medium text-darkBlue text-2xl mb-2">
          {selectedPackage.title}
        </h2>
        <Link
          to={`/${tour_op_id}/package-detail/${selectedPackage.id}`}
          className="bg-brightBlue py-1 px-4 text-white rounded"
        >
          Ver Detalle de Paquete
        </Link>
      </div>
      <div className="flex gap-8">
        <div className="flex flex-col h-full justify-between">
          <div className="grid grid-cols-2 gap-4">
            <input
              value={selectedPackage.oricode}
              className="w-full px-4 py-2 border border-gray2 rounded"
              readOnly
            />
            <input
              value={selectedPackage.descode}
              className="w-full px-4 py-2 border border-gray2 rounded"
              readOnly
            />
            <input
              value={format(
                new Date(selectedPackage.departure_date),
                "dd/MM/yy"
              )}
              className="w-full px-4 py-2 border border-gray2 rounded"
              readOnly
            />
            <input
              value={format(new Date(selectedPackage.return_date), "dd/MM/yy")}
              className="w-full px-4 py-2 border border-gray2 rounded"
              readOnly
            />
            <input
              value={`${
                translateCurrency(selectedPackage.currency_type_id) ?? "USD"
              } ${
                Number(selectedPackage.price) +
                Number(selectedPackage.tax_package ?? "0")
              }`}
              className="w-full px-4 py-2 border border-gray2 rounded"
              readOnly
            />
            <input
              value={`${selectedPackage.stock} Cupos Totales`}
              className="w-full px-4 py-2 border border-gray2 rounded"
              readOnly
            />
          </div>
          <div className="mt-4">
            <label
              htmlFor="description"
              className="text-darkBlue text-lg font-medium"
            >
              Descripción
            </label>
            <textarea
              name="description"
              id="description"
              value={selectedPackage.description?.replace(/<br>/g, "\n")}
              className="w-full px-4 py-2 border border-gray2 rounded"
              readOnly
            />
          </div>
        </div>
        {selectedPackage.Pictures.length > 0 && (
          <img
            src={selectedPackage.Pictures[0].url}
            alt={`${selectedPackage.title} foto`}
            className="max-h-72 max-w-[50%] rounded"
          />
        )}
      </div>
    </div>
  );
}
