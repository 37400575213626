export default function RolesTable() {
  return (
    <section className="bg-white w-full mt-4 rounded-xl p-4 border-2 border-lightBlue">
      <header className="grid grid-cols-5 bg-lightBlue/30 rounded py-2">
        <p className="font-medium pl-16">Roles</p>
        <p className="font-medium mx-auto">Home</p>
        <p className="font-medium mx-auto">Ventas</p>
        <p className="font-medium mx-auto">CRM</p>
        <p className="font-medium mx-auto">Fact/Adm</p>
      </header>

      <div className="grid grid-cols-5 rounded py-2">
        <p className="pl-16">SuperAdmin</p>
        <input type="checkbox" readOnly checked className="h-5 mt-1" />
        <input type="checkbox" readOnly checked className="h-5 mt-1" />
        <input type="checkbox" readOnly checked className="h-5 mt-1" />
        <input type="checkbox" readOnly checked className="h-5 mt-1" />
      </div>

      <div className="grid grid-cols-5 rounded py-2">
        <p className="pl-16">Agencia</p>
        <input type="checkbox" readOnly checked className="h-5 mt-1" />
        <input type="checkbox" readOnly checked={false} className="h-5 mt-1" />
        <input type="checkbox" readOnly checked={false} className="h-5 mt-1" />
        <input type="checkbox" readOnly checked={false} className="h-5 mt-1" />
      </div>

      <div className="grid grid-cols-5 rounded py-2">
        <p className="pl-16">Vendedor</p>
        <input type="checkbox" readOnly checked={false} className="h-5 mt-1" />
        <input type="checkbox" readOnly checked className="h-5 mt-1" />
        <input type="checkbox" readOnly checked className="h-5 mt-1" />
        <input type="checkbox" readOnly checked={false} className="h-5 mt-1" />
      </div>
    </section>
  );
}
