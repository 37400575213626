import "../../styles/buscador.css";
import icono_calendar from "../../iconos/icono_calendar.png";
import oricode_chevron from "../../iconos/oricode_chevron.png";
import descode_point from "../../iconos/descode_point.png";
import left from "../../iconos/left.png";
import right from "../../iconos/right.png";
import { radioClass } from "../../styles/utils/utilsClassnames";
import { ErrorMessage } from "@hookform/error-message";
import { AutoComplete } from "../../utils/AutoComplete";
import { format } from "date-fns";
import NewCalendar from "../../utils/NewCalendar";
import { PASSENGER_DICTIONARY } from "../../utils/dictionaries/passengerDictionary";
import { useState } from "react";
import { useToggleVisibility } from "../../hooks/useToggleVisibility";

export default function SearcherFlights({
  routeOptionState,
  useForm,
  descodeState,
  oricodeState,
  passangerState,
  sumPassengers,
  handlePassenger,
  parsedHash,
}) {
  const [opcionrutas, setOpcionRutas] = routeOptionState;
  const [oricodeInput, setOricodeInput] = oricodeState;
  const [descodeInput, setDescodeInput] = descodeState;
  const [passengerCounter, setPassengerCounter] = passangerState;
  const { register, setValue, watch, errors } = useForm;

  const [openFsalida, toggleSalida] = useToggleVisibility();
  const [rangeDate, setRangeDate] = useState();
  // const [showOptions, setShowOptions] = useState(false);

  const handleChangeRoutes = (e) => {
    e.preventDefault();

    const currentOricode = watch("originLocationCode");
    const currentDescode = watch("destinationLocationCode");
    const currentOricodeInput = oricodeInput;
    const currentDescodeInput = descodeInput;

    if (currentOricode && currentDescode) {
      setValue("originLocationCode", currentDescode);
      setValue("destinationLocationCode", currentOricode);
      setOricodeInput(currentDescodeInput);
      setDescodeInput(currentOricodeInput);
    }
  };

  return (
    <>
      <span className="flex gap-20 items-center">
        <p className="text-xl font-medium text-lightBlue">Vuelos</p>
        {/* opciones ida */}
        <div className="flex gap-12">
          <div className="buscador-centro-checks-div">
            <label className="flex justify-center text-white cursor-pointer ">
              <input
                type="radio"
                name="opcionrutas"
                id="round-trip"
                onClick={() => {
                  setOpcionRutas("idayvuelta");
                }}
                defaultChecked={opcionrutas === "idayvuelta"}
                className={radioClass}
              />
              <p className="pt-1 pl-2 text-sm text-white cursor-pointer">
                Ida y vuelta
              </p>
            </label>
          </div>
          <div className="buscador-centro-checks-div">
            <label className="flex justify-center text-white cursor-pointer ">
              <input
                type="radio"
                name="opcionrutas"
                id="just-going"
                onClick={() => {
                  setOpcionRutas("soloida");
                }}
                defaultChecked={opcionrutas === "soloida"}
                className={radioClass}
              />
              <p className="pt-1 pl-2 text-sm text-white cursor-pointer">
                Solo ida
              </p>
            </label>
          </div>
          <div className="buscador-centro-checks-div">
            <label className="flex justify-center text-white cursor-pointer ">
              <input
                type="radio"
                name="opcionrutas"
                id="multi-destination"
                onClick={() => {
                  setOpcionRutas("multidestino");
                }}
                defaultChecked={opcionrutas === "multidestino"}
                className={radioClass}
              />
              <p className="pt-1 pl-2 text-sm text-white cursor-pointer">
                Multidestino
              </p>
            </label>
          </div>
        </div>
        {/* <div>
                <label
                  htmlFor="fechasFlexibles"
                  className="flex items-center gap-2"
                  onChange={(e) => setFechasFlexibles(e.target.checked)}
                >
                  <input type="checkbox" id="fechasFlexibles" />
                  <p className="text-sm text-white cursor-pointer">Fechas Flexibles</p>
                </label>
              </div> */}
      </span>

      <div className="flex gap-3 justify-between">
        {/* descode y oricode */}
        <div className="bg-[#EFEFEF] p-1 h-20 pt-2 rounded-2xl flex items-center gap-1 justify-between">
          <div className="flex flex-col relative">
            <span className="flex gap-2 items-center justify-center">
              <img
                src={oricode_chevron}
                alt="oricode_chevron"
                className="w-4"
              />
              <p>Origen</p>
            </span>
            <AutoComplete
              justCities={false}
              notPlaceholder
              notIcon
              classname={"bg-[#EFEFEF] outline-none py-2 w-[141px] px-2"}
              register={register}
              defaultValue={parsedHash?.originLocationCode}
              name="originLocationCode"
              placeholder="Origen"
              handleAction={(d) => setValue("originLocationCode", d)}
              inputState={oricodeState}
            />
            <div className="absolute text-red-600 text-xs -bottom-1 left-4 whitespace-nowrap">
              <ErrorMessage errors={errors} name="originLocationCode" as="p" />
            </div>
          </div>

          <button
            className="flex flex-col gap-1 items-center"
            onClick={handleChangeRoutes}
          >
            <img src={right} className="w-4" alt="right" />
            <img src={left} alt="left" className="w-4" />
          </button>

          <div className="flex flex-col relative">
            <span className="flex gap-2 items-center justify-center">
              <img src={descode_point} alt="descode_point" className="w-4" />
              <p>Destino</p>
            </span>
            <AutoComplete
              notPlaceholder
              justCities={false}
              notIcon
              register={register}
              classname={"bg-[#EFEFEF] outline-none w-[141px] px-2 py-2"}
              defaultValue={parsedHash?.descode}
              name="destinationLocationCode"
              placeholder="Destino"
              handleAction={(d) => setValue("destinationLocationCode", d)}
              inputState={descodeState}
            />
            <div className="absolute text-red-600 text-xs -bottom-1 left-4 whitespace-nowrap">
              <ErrorMessage
                errors={errors}
                name="destinationLocationCode"
                as="p"
              />
            </div>
          </div>
        </div>

        {/* fechas */}
        <div className="bg-[#EFEFEF] relative p-1 h-20 pt-2 rounded-2xl flex items-center gap-1 justify-between w-72">
          <div className="flex flex-col max-w-[135px]">
            <span className="flex gap-2 items-center justify-center absolute top-2 left-6">
              <img src={icono_calendar} alt="icono_calendar" className="w-4" />
              <p>{opcionrutas === "soloida" ? "Fecha" : "Fechas"}</p>
            </span>
            <div className="flex flex-col">
              <div className="flex flex-col h-5 items-start gap-2">
                <input
                  autocomplete="off"
                  name="departureDate"
                  placeholder="Ida"
                  onClick={() => toggleSalida()}
                  value={
                    !watch("departureDate")
                      ? parsedHash?.departureDate
                        ? format(
                            new Date(parsedHash?.departureDate + "T00:00:00"),
                            "dd/MM/yyyy"
                          )
                        : "Ida"
                      : format(
                          new Date(watch("departureDate") + "T00:00:00"),
                          "dd/MM/yyyy"
                        )
                  }
                  className="text-center outline-none px-2 pt-4 text-sm flex items-center justify-center w-full bg-inherit h-7"
                />
                {openFsalida ? (
                  <NewCalendar
                    handlers={[
                      rangeDate,
                      (date) => {
                        setRangeDate(date);
                        if (opcionrutas !== "soloida") {
                          setValue(
                            "departureDate",
                            date?.from ? format(date?.from, "yyyy-MM-dd") : ""
                          );
                          setValue(
                            "returnDate",
                            date?.to ? format(date?.to, "yyyy-MM-dd") : ""
                          );
                          date?.from && date?.to && toggleSalida();
                        } else {
                          setValue(
                            "departureDate",
                            date ? format(date, "yyyy-MM-dd") : ""
                          );
                          date && toggleSalida();
                        }
                      },
                    ]}
                    mode={opcionrutas === "soloida" ? "single" : "range"}
                    numberOfMonths={2}
                    close={toggleSalida}
                  />
                ) : null}
              </div>
              <div className="absolute text-red-600 text-xs bottom-0.5 left-8 whitespace-nowrap">
                <ErrorMessage errors={errors} name="departureDate" as="p" />
              </div>
            </div>
          </div>

          {opcionrutas !== "soloida" && (
            <div className="h-[80%] w-[1px] bg-black" />
          )}

          {opcionrutas !== "soloida" && (
            <div className="flex flex-col">
              <div className="flex flex-col h-5 items-start gap-2">
                <input
                  name="returnDate"
                  placeholder="Vuelta"
                  onClick={() => opcionrutas !== "soloida" && toggleSalida()}
                  value={
                    !watch("returnDate")
                      ? parsedHash?.returnDate
                        ? format(
                            new Date(parsedHash?.returnDate + "T00:00:00"),
                            "dd/MM/yyyy"
                          )
                        : "Vuelta"
                      : format(
                          new Date(watch("returnDate") + "T00:00:00"),
                          "dd/MM/yyyy"
                        )
                  }
                  className="text-center outline-none px-2 text-sm pt-4 flex items-center justify-center w-full bg-inherit h-7"
                />
              </div>
              <div className="absolute text-red-600 text-xs bottom-0.5 right-8 whitespace-nowrap">
                <ErrorMessage errors={errors} name="departureDate" as="p" />
              </div>
            </div>
          )}
        </div>

        {/* passengers */}
        <div className="relative group/passengers">
          <div className="bg-[#EFEFEF] p-1 h-20 pt-2 rounded-2xl flex flex-col items-center gap-1 justify-evenly cursor-pointer">
            <span className="flex gap-2 items-center">
              <p className="text-sm font-medium">Pasajeros y Cabina</p>
            </span>
            <p className="w-[120px] py-1 px-2 mx-auto text-sm focus:outline-none bg-[#CEDEF1] rounded-md">
              {sumPassengers} {sumPassengers > 1 ? " pasajeros" : "pasajero"}
              {/* {cabin === "ECO" ? "Económica" : "Ejecutiva"} */}
            </p>
          </div>
          <div className="absolute w-64 top-full mt-2 bg-[#EFEFEF] rounded-2xl p-4 flex flex-col gap-2 scale-0 group-hover/passengers:scale-100 transition-transform origin-top-left">
            <section className="flex flex-col gap-2">
              {Object.entries(passengerCounter).map((passenger) => {
                const { key, label, description, register, min } =
                  PASSENGER_DICTIONARY[passenger[0]];
                return (
                  <div className="flex justify-between items-center" key={key}>
                    <div>
                      <h2 className="font-medium">{label}</h2>
                      <p className="text-xs text-gray">{description}</p>
                    </div>
                    <div className="flex gap-2 items-center">
                      <button
                        type="button"
                        onClick={() => handlePassenger(key, register, min, "-")}
                        className="border w-7 h-7 rounded-full text-sm font-[700]"
                      >
                        -
                      </button>
                      <p className="text-xl">{passenger[1]}</p>
                      <button
                        type="button"
                        onClick={() => handlePassenger(key, register, min, "+")}
                        className="border w-7 h-7 rounded-full text-sm font-[700]"
                      >
                        +
                      </button>
                    </div>
                  </div>
                );
              })}
              <div className="flex justify-between items-center border-t pt-2">
                <p className="font-medium">Cabina</p>
                <select
                  name="cabin"
                  id="cabin"
                  {...register("travelClass")}
                  className="bg-transparent border rounded"
                >
                  <option value="" selected>
                    Todas
                  </option>
                  <option value="ECONOMY">Económica</option>
                  <option value="BUSINESS">Ejecutiva</option>
                </select>
              </div>
            </section>
          </div>
        </div>
        <button
          type="submit"
          className="bg-[#CEDEF1] rounded-xl py-1 px-12 text-black shadow-sm shadow-lightBlue2"
        >
          <span className="text-black font-medium">Buscar</span>
        </button>
      </div>
    </>
  );
}
