import axios from "axios";
import { useEffect, useState } from "react";

export default function useGetCurrencies() {
  const BASE_URL = process.env.REACT_APP_API_URL;
  const [currencies, setCurrencies] = useState([]);
  const [exchangeRate, setExchangeRate] = useState([]);

  const getCurrencies = async () => {
    const res = await axios
      .get(BASE_URL + "v2/currency-types")
      .then((e) => {
        e?.data && setCurrencies(e?.data?.response);
      })
      .catch((error) => console.log(error));
    if (res) {
      return res?.data || {};
    }
  };

  const getExchangeRate = async () => {
    return await axios
      .get(BASE_URL + "v2/scraping/jazz")
      .then((e) => {
        e?.data && setExchangeRate(e?.data?.response);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getCurrencies();
  }, []);

  return { currencies: currencies || [], exchangeRate, getExchangeRate };
}
