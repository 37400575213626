/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useBookings } from "../../hooks/useBookings";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { format } from "date-fns";
import { defaultDateFormat } from "../../utils/commons";
import useGetAirports from "../../hooks/useGetAirports";
import { useState } from "react";
import FlightsTable from "./FlightsTable";
import Pasajeros from "./Pasajeros";
import Tarifa from "./Tarifa";
import SEO from "../../utils/SEO";
import useMessage from "./useMessages";
import PackageTable from "./PackageTable";
import useCommissionsAndTaxes from "../../hooks/useCommissionsAndTaxes";

const handleDisplay = (item) => {
  const body = document.getElementById(`${item}-body`);
  const arrow = document.getElementById(`${item}-arrow`);
  body.classList.toggle("scale-y-0");
  body.classList.toggle("h-0");
  arrow.classList.toggle("-rotate-180");
  arrow.classList.toggle("rotate-0");
};

const getTravelerType = (birthDate) => {
  const today = new Date();
  const dob = new Date(birthDate);

  let age = today.getFullYear() - dob.getFullYear();
  const monthDiff = today.getMonth() - dob.getMonth();

  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dob.getDate())) {
    age--;
  }

  if (age > 17) return "ADULT";
  if (age > 1) return "CHILD";
  return "HELD_INFANT";
};

export default function BookingDetail({ isHistory = false }) {
  const { tour_op_id, booking_id, type } = useParams();
  const { getBooking, booking } = useBookings();
  const { getAirports, airports } = useGetAirports();
  const { commissions, getCommissions } = useCommissionsAndTaxes();

  const [ciudadesFormatted, setCiudadesFormatted] = useState({});

  const {
    status,
    creation_date,
    Flights = [],
    Packages = [],
    Travelers = [],
    travelers_packages = [],
  } = booking || {};

  const parsedFlights =
    Flights.length > 0 ? JSON.parse(Flights[0]?.options || "{}") : {};

  let {
    lastTicketingDate,
    itineraries = [],
    travelerPricings = [],
    selectedAmenities = {},
  } = parsedFlights;
  
  const idas = itineraries?.[0]?.segments;
  const vueltas = itineraries?.[1]?.segments;
  const flightsDetails = travelerPricings[0]?.fareDetailsBySegment;
  
  useEffect(() => {
    if (booking_id) {
      getBooking({ bookingID: booking_id });
    }
    getAirports();
    getCommissions();
  }, []);

  useEffect(() => {
    if (airports) {
      setCiudadesFormatted(() => {
        let formattedCities = {};
        airports.forEach((airport) => {
          formattedCities[airport.iata] = airport;
        });
        return formattedCities;
      });
    }
  }, [airports]);

  const { copyItinerarytoAmadeus, copyItinerarytoWpp } = useMessage(
    parsedFlights,
    ciudadesFormatted,
    Travelers,
    parsedFlights?.totalPrice
  );

  travelerPricings =
    type === "flight"
      ? travelerPricings
      : travelers_packages.map((passenger) => {
          return {
            travelerType: getTravelerType(passenger.date_of_birth),
          };
        });

  return (
    <div className="h-full flex flex-col gap-6 w-[55vw] mt-16 mx-auto text-darkBlue">
      <SEO title={"Detalle de Reserva"} />
      <div className="flex justify-end">
        <Link
          to={`/${tour_op_id}/${
            isHistory ? "history" : `panel/sales/bookings/${type}`
          }`}
          className="ml-auto button text-center bg-darkBlue font-medium text-white hover:bg-darkBlue/80 transition-colors"
        >
          {isHistory ? "Volver a Mi Historial" : "Volver a Mis Reservas"}
        </Link>
      </div>
      <section className="detailTable">
        <section className="datailTableRow bg-lightBlue">
          <div className="flex items-center gap-1">
            <p>Reserva:</p>
            <span className="font-medium text-xl"> {booking_id}</span>
          </div>
          <div className="flex items-center gap-1">
            <p>Estado:</p>
            <span className="font-medium text-lg"> {status}</span>
          </div>
        </section>
        <section className="datailTableRow">
          <div className="flex items-center gap-1">
            <p>Pax Principal:</p>
            <span className="font-medium text-lg">
              {`${
                Travelers?.[0]?.first_name ||
                travelers_packages?.[0]?.first_name
              } ${
                Travelers?.[0]?.last_name || travelers_packages?.[0]?.last_name
              }`}
            </span>
          </div>
          <div className="flex items-center gap-1">
            <p>Fecha de Creación:</p>
            <span className="font-medium text-lg">
              {" "}
              {creation_date &&
                format(new Date(creation_date), defaultDateFormat)}
            </span>
          </div>
        </section>
      </section>

      {type === "flight" ? (
        <>
          <FlightsTable
            title={"ida"}
            flights={[idas, flightsDetails]}
            ciudadesFormatted={ciudadesFormatted}
            handleDisplay={handleDisplay}
          />
          {vueltas && <FlightsTable
            title={"vuelta"}
            flights={[vueltas, flightsDetails]}
            ciudadesFormatted={ciudadesFormatted}
            handleDisplay={handleDisplay}
          />}
          <Tarifa
            tarifa={travelerPricings}
            emisionDate={lastTicketingDate}
            selectedAmenities={selectedAmenities}
            commissions={commissions}
            handleDisplay={handleDisplay}
          />
        </>
      ) : (
        <>
          <PackageTable pack={Packages?.[0]} handleDisplay={handleDisplay} />
        </>
      )}

      <Pasajeros
        pasajeros={Travelers.length > 0 ? Travelers : travelers_packages}
        passengerType={travelerPricings}
        handleDisplay={handleDisplay}
      />

      <footer
        className={`fixed bottom-0 w-[55vw] h-28 flex justify-end gap-4 items-center ${
          isHistory ? "bg-white" : "bg-[#F2F6FD]"
        }`}
      >
        {/* <button className="reservaButton w-fit bg-primary border-primary text-black">
          Enviar por correo
        </button>
        <button className="reservaButton">Descargar (PDF)</button> */}
        <button
          className="reservaButton bg-white"
          onClick={copyItinerarytoAmadeus}
        >
          Amadeus
        </button>
        <button className="reservaButton bg-white" onClick={copyItinerarytoWpp}>
          Whatsapp
        </button>
      </footer>
    </div>
  );
}
