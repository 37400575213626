export const MisPaquetesBusqueda = ({ searcher }) => {
  const [{ textValue, monthValue }, setSearcherValue] = searcher;

  return (
    <section className="flex gap-4 pb-4 mb-4 justify-between border-b border-gray2">
      <input
        className="py-1 px-2 border border-gray2 w-80 rounded"
        placeholder="Buscar por nombre, origen y destino..."
        value={textValue}
        onChange={(e) =>
          setSearcherValue((curr) => ({ ...curr, textValue: e.target.value }))
        }
      />
      <input
        type="month"
        className="py-1 px-4 border border-gray2 w-80 rounded"
        value={monthValue}
        onChange={(e) =>
          setSearcherValue((curr) => ({ ...curr, monthValue: e.target.value }))
        }
      />
    </section>
  );
};
