import { useState } from "react";
import OptionQuoteRow from "./OptionQuoteRow";

export default function OptionQuoteFlights({
  details,
  flights,
  stage,
  original,
  price,
  thisSelectedOption,
  handleSelectedOption,
  wider,
}) {
  const [showOptions, setShowOptions] = useState(false);
  const firstOption = flights[0];
  const numberStage = stage === "origin" ? 0 : 1;

  const getID = (option) => {
    return original
      .filter(og => Number(og.price.total) === Number(price))
      .filter((og) => {
        const itinerariesIDs = og.itineraries[numberStage].segments.map(
          (el) => el.id
        );
        const checkIDs = option.segments.every((segment) =>
          itinerariesIDs.includes(segment.id)
        );

        return checkIDs;
      })
      ?.map((el) => el.id);
  };

  return (
    <>
      <section className="rounded-md p-4 mr-2 relative w-fit flex bg-[#f6f6f680]">
        <div>
          {firstOption.segments.map((segment, index) => {
            return (
              <OptionQuoteRow
                key={`${index} - ${segment.id}`}
                data={segment}
                details={details}
                price={price}
                index={index}
                wider={wider}
              />
            );
          })}
        </div>
        <input
          type="checkbox"
          className="my-auto ml-2 w-4 h-4"
          checked={getID(firstOption).some((id) =>
            thisSelectedOption.includes(id)
          )}
          id={`${price} - ${getID(firstOption)?.join("-")}`}
          onChange={(evt) =>
            handleSelectedOption(evt, getID(firstOption), stage)
          }
        />
        {flights.length > 1 && (
          <div className="h-full absolute grid justify-center -right-6 top-0">
            <button
              className="my-auto bg-brightBlue grid justify-center rounded-full w-4 h-4"
              onClick={() => setShowOptions((curr) => !curr)}
            >
              <span className="text-white text-xs font-semibold">
                {showOptions ? "-" : "+"}
              </span>
            </button>
          </div>
        )}
      </section>

      <section
        className={`transition-transform origin-top flex flex-col gap-1 ${
          showOptions ? "scale-y-100  h-fit" : "scale-y-0 h-0"
        }`}
      >
        {flights.slice(1).map((option, index) => {
          return (
            <div
              key={`${index} - ${option.duration}`}
              className={`rounded-md p-4 mr-2 relative w-fit flex ${
                index % 2 === 0 ? "bg-white" : "bg-[#f6f6f680]"
              }`}
            >
              <div>
                {option.segments.map((segment, index) => {
                  return (
                    <OptionQuoteRow
                      key={`${index} - ${segment.id}`}
                      data={segment}
                      details={details}
                      price={price}
                      index={index}
                      wider={wider}
                    />
                  );
                })}
              </div>
              <input
                type="checkbox"
                className="my-auto ml-2 w-4 h-4"
                checked={getID(option).some((id) =>
                  thisSelectedOption.includes(id)
                )}
                id={`${price} - ${getID(option)?.join("-")}`}
                onChange={(evt) =>
                  handleSelectedOption(evt, getID(option), stage)
                }
              />
            </div>
          );
        })}
      </section>
    </>
  );
}
