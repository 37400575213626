import axios from "axios";
import { useEffect, useState } from "react";

export default function Health() {
  const URL = process.env.REACT_APP_API_URL;

  const [response, setResponse] = useState();

  useEffect(() => {
    axios
      .get(URL.slice(0, -4) + "health")
      .then((e) => setResponse(e?.status))
      .catch((e) => setResponse(e?.message));
  }, []);

  return (
    <>
      <h1>Health</h1>
      <span>{response}</span>
    </>
  );
}
