import arrow from ".././iconos/chevron_down_gray.png";
import { AIRLINES_DICTIONARY } from "../utils/dictionaries/airlinesDictionary";
import { SCALES_DICTIONARY } from "../utils/dictionaries/scalesDIctionary";
import { BAGS_DICTIONARY } from "../utils/dictionaries/bagsDictionary";

export const translateToHex = (time) => {
  const inter = Math.floor(time);
  const float = time % 1;

  return inter + float * 0.6;
};

export default function SearchFilters({ filters, setFilters, minmaxDuration }) {
  const { airlines, scales, includedBags, departureTime, arrivalTime } =
    filters;

  const handleChecks = (evt, filter) => {
    setFilters((curr) => {
      return {
        ...curr,
        [filter]: {
          ...curr[filter],
          [evt.target.id]: evt.target.checked,
        },
      };
    });
  };

  const handleRange = (evt) => {
    setFilters((curr) => {
      return {
        ...curr,
        maxDuration: evt.target.value,
      };
    });
  };

  const handleTime = (evt, stage, minmax) => {
    setFilters((curr) => {
      return {
        ...curr,
        [stage]: {
          ...curr[stage],
          [minmax]: evt.target.value,
        },
      };
    });
  };

  const handleDisplay = (item) => {
    const body = document.getElementById(`${item}-body`);
    const arrow = document.getElementById(`${item}-arrow`);
    body.classList.toggle("scale-y-0");
    body.classList.toggle("h-0");
    arrow.classList.toggle("-rotate-180");
    arrow.classList.toggle("rotate-0");
  };

  return (
    <div className="flex flex-col pr-4 w-[230px]">
      <h2 className="font-medium p-2 text-xl">Filtros</h2>

      <div>
        <section
          onClick={() => handleDisplay("airlines")}
          id="airlines-header"
          className="cursor-pointer"
        >
          <header className="flex justify-between items-center py-2 px-4 rounded-md bg-lightBlue/80">
            <h2 className="font-low">Aerolíneas</h2>
            <img
              src={arrow}
              alt="arrow"
              className="w-4 h-2 -rotate-180 transition-all"
              id="airlines-arrow"
            />
          </header>
        </section>
        <section
          className="py-2 px-4 mb-2 grid gap-2 overflow-y-hidden transition-all duration-200 origin-top"
          id="airlines-body"
        >
          {Object.keys(airlines).map((el) => {
            const data = AIRLINES_DICTIONARY[el] ?? null;
            return (
              <div className="flex" key={el}>
                <input
                  type="checkbox"
                  id={el}
                  name="airlines"
                  checked={filters.airlines[el]}
                  onChange={(evt) => handleChecks(evt, "airlines")}
                />
                <label htmlFor={el} className="flex gap-2 items-center">
                  <img
                    src={data ? data.img : ""}
                    alt={data ? data.label : el}
                    className="w-8 h-4 mr-1 ml-2"
                  />
                  <p className="max-w-[10ch]">{data ? data.label : el}</p>
                </label>
              </div>
            );
          })}
        </section>
      </div>

      <div>
        <section
          onClick={() => handleDisplay("scales")}
          id="scales-header"
          className="cursor-pointer"
        >
          <header className="flex justify-between items-center py-2 px-4 rounded-md bg-lightBlue/80">
            <h2 className="font-low">Escalas máximas</h2>
            <img
              src={arrow}
              alt="arrow"
              className="w-4 h-2 -rotate-180 transition-all"
              id="scales-arrow"
            />
          </header>
        </section>
        <section
          className="py-2 px-4 mb-2 grid gap-2 overflow-y-hidden transition-all duration-300 origin-top"
          id="scales-body"
        >
          {Object.keys(scales).map((el) => (
            <div className="flex items-center gap-2" key={el}>
              <input
                type="checkbox"
                key={SCALES_DICTIONARY[el].key}
                id={el}
                name="scales"
                checked={filters.scales[el]}
                onChange={(evt) => handleChecks(evt, "scales")}
              />
              <label htmlFor={el}>{SCALES_DICTIONARY[el].label}</label>
            </div>
          ))}
        </section>
      </div>

      <div>
        <section
          onClick={() => handleDisplay("flightTimes")}
          id="flightTimes-header"
          className="cursor-pointer"
        >
          <header className="flex justify-between items-center py-2 px-4 rounded-md bg-lightBlue/80">
            <h2 className="font-low">Hora de Salida</h2>
            <img
              src={arrow}
              alt="arrow"
              className="w-4 h-2 -rotate-180 transition-all"
              id="flightTimes-arrow"
            />
          </header>
        </section>
        <section
          className="py-2 px-4 mb-2 grid gap-2 overflow-y-hidden transition-all duration-300 origin-top"
          id="flightTimes-body"
        >
          <div className="grid gap-0.5">
            <h2>Ida</h2>
            <div className="flex gap-1">
              <input
                type="time"
                value={departureTime.from}
                onChange={(e) => handleTime(e, "departureTime", "from")}
                className="border rounded w-fit"
              />
              -
              <input
                type="time"
                value={departureTime.to}
                onChange={(e) => handleTime(e, "departureTime", "to")}
                className="border rounded w-fit"
              />
            </div>
          </div>
          <div className="grid gap-0.5">
            <h2>Vuelta</h2>
            <div className="flex gap-1">
              <input
                type="time"
                value={arrivalTime.from}
                onChange={(e) => handleTime(e, "arrivalTime", "from")}
                className="border rounded w-fit"
              />
              -
              <input
                type="time"
                value={arrivalTime.to}
                onChange={(e) => handleTime(e, "arrivalTime", "to")}
                className="border rounded w-fit"
              />
            </div>
          </div>
        </section>
      </div>

      <div>
        <section
          onClick={() => handleDisplay("duration")}
          id="duration-header"
          className="cursor-pointer"
        >
          <header className="flex justify-between items-center py-2 px-4 rounded-md bg-lightBlue/80">
            <h2 className="font-low">Duración del vuelo</h2>
            <img
              src={arrow}
              alt="arrow"
              className="w-4 h-2 -rotate-180 transition-all"
              id="duration-arrow"
            />
          </header>
        </section>
        <section
          className="py-2 px-4 mb-2 grid gap-2 overflow-y-hidden transition-all duration-300 origin-top"
          id="duration-body"
        >
          <label htmlFor="maxDuration">
            {translateToHex(minmaxDuration[0]).toFixed(2)}hs -{" "}
            {translateToHex(filters.maxDuration).toFixed(2)}hs
          </label>
          <input
            type="range"
            max={minmaxDuration?.[1]}
            min={minmaxDuration?.[0]}
            id="maxDuration"
            step={0.01}
            value={filters.maxDuration}
            onChange={handleRange}
          />
        </section>
      </div>

      <div>
        <section
          onClick={() => handleDisplay("includedBags")}
          id="includedBags-header"
          className="cursor-pointer"
        >
          <header className="flex justify-between items-center py-2 px-4 rounded-md bg-lightBlue/80">
            <h2 className="font-low">Equipaje</h2>
            <img
              src={arrow}
              alt="arrow"
              className="w-4 h-2 -rotate-180 transition-all"
              id="includedBags-arrow"
            />
          </header>
        </section>
        <section
          className="py-2 px-4 mb-2 grid gap-2 overflow-y-hidden transition-all duration-300 origin-top"
          id="includedBags-body"
        >
          {Object.keys(includedBags).map((el) => (
            <div className="flex items-center gap-2" key={el}>
              <input
                type="checkbox"
                key={BAGS_DICTIONARY[el].key}
                id={el}
                name="includedBags"
                checked={filters.scales[el]}
                onChange={(evt) => handleChecks(evt, "includedBags")}
              />
              <label htmlFor={el}>{BAGS_DICTIONARY[el].label}</label>
            </div>
          ))}
        </section>
      </div>
    </div>
  );
}
