export const boletosHeaders = [
  {
    label: "Pasajero",
    col: "2",
  },
  {
    label: "TKT",
    col: "1",
  },
  {
    label: "F. Emisión",
    col: "2",
  },
  {
    label: "CIA",
    col: "1",
  },
  {
    label: "Tipo",
    col: "1",
  },
  {
    label: "Ruta",
    col: "2",
  },
  {
    label: "F. Salida",
    col: "2",
  },
  {
    label: "Tarifa",
    col: "1",
  },
  {
    label: "Tasas",
    col: "1",
  },
  {
    label: "IVA",
    col: "1",
  },
  {
    label: "Dto",
    col: "1",
  },
  {
    label: "Neto",
    col: "1",
  },
  {
    label: "Uatp",
    col: "1",
  },
];