import { format } from "date-fns";

export default function PackageRow({ pack }) {
  const { oricode, descode, departure_date, return_date } = pack ?? {};

  return (
    <section className="grid grid-cols-4 rounded">
      <div className="tableCell border-r-2">
        <span>{oricode}</span>
      </div>
      <div className="tableCell border-r-2">
        <span>{descode}</span>
      </div>
      <span className="tableCell border-r-2">
        {departure_date ? format(new Date(departure_date), "dd/MM/yyyy") : "-"}
      </span>
      <span className="tableCell border-r-2">
        {return_date ? format(new Date(return_date), "dd/MM/yyyy") : "-"}
      </span>
    </section>
  );
}
