import { useEffect } from "react";
import useGetAirports from "../../hooks/useGetAirports";
import { boletosHeaders } from "./boletosHeader";
import { format } from "date-fns";
import { defaultDateFormat } from "../../utils/commons";
import numberWithCommas from "../../utils/numberWithCommas";

export default function InvoiceFileBooking({ booking }) {
  const { getAirports, airports } = useGetAirports();

  useEffect(() => {
    getAirports();
  }, []);

  let formattedCities = {};
  airports &&
    airports.forEach((airport) => {
      formattedCities[airport.iata] = airport.City.name;
    });

  const { Flights, Travelers } = booking;
  if (!Flights) return;
  const { first_name, last_name } = Travelers?.[0];
  const parsedFlights = JSON.parse(Flights[0].options);
  const { carrierCode } = parsedFlights.itineraries[0].segments[0];
  const departureDate = parsedFlights.itineraries[0].segments[0].departure.at;
  const oricode = parsedFlights.itineraries[0].segments[0].departure.iataCode;
  const descode = parsedFlights.itineraries[1].segments[0].departure.iataCode;
  const { base, grandTotal } = parsedFlights.price;

  return (
    <section>
      <h2 className="font-semibold mb-2">BOLETOS</h2>

      <div className="grid grid-cols-18 p-2 bg-white rounded-md">
        {boletosHeaders.map((el) => {
          return (
            <p
              key={el.label}
              className={`border-r border-gray text-center font-medium col-span-${el.col}`}
            >
              {el.label}
            </p>
          );
        })}
        <p className="text-center font-medium">Saldo</p>
      </div>

      <div className="grid grid-cols-18 p-2">
        <p className="text-center text-xs col-span-2">
          {last_name} {first_name}
        </p>
        <p className="text-center text-xs">-</p>
        <p className="text-center text-xs col-span-2">-/-/-</p>
        <p className="text-center text-xs">{carrierCode}</p>
        <p className="text-center text-xs">Vuelo</p>
        <p className="text-center text-xs col-span-2">
          {formattedCities?.[oricode]} - {formattedCities?.[descode]}
        </p>
        <p className="text-center text-xs col-span-2">
          {format(new Date(departureDate), defaultDateFormat)}
        </p>
        <p className="text-center text-xs">{numberWithCommas(Number(base), 2)}</p>
        <p className="text-center text-xs">{numberWithCommas(Number(grandTotal - base), 2)}</p>
        <p className="text-center text-xs">{numberWithCommas(0, 2)}</p>
        <p className="text-center text-xs">{numberWithCommas(0, 2)}</p>
        <p className="text-center text-xs">{numberWithCommas(Number(grandTotal), 2)}</p>
        <p className="text-center text-xs">{numberWithCommas(0, 2)}</p>
        <p className="text-center text-xs">{numberWithCommas(0, 2)}</p>
      </div>
    </section>
  );
}
