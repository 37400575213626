import { format } from "date-fns";

export const setMailOptions = (tourOperatorEmail, userEmail, type, detail) => {
  const { booking, travelers } = detail;
  const html = `
  <h3>Datos de Reserva</h3>
  <ul>
    <li>ID: ${booking.id}</li>
    <li>Fecha y Hora: ${format(
      new Date(booking.creation_date),
      "dd/MM/yyyy - HH:mm"
    )}hs</li>
  </ul>

  <h3>Datos de Pasajeros</h3>
  <ol>
    ${travelers.map(
      ({ traveler, travelerDocument }) =>
        `<li>${traveler.last_name} ${traveler.first_name} - ${format(
          new Date(traveler.date_of_birth),
          "dd/MM/yyyy"
        )} - ${
          travelerDocument.doc_type === "IDENTITY_CARD" ? "DNI" : "Pasaporte"
        }: ${travelerDocument.number}</li>`
    ).join("")}
  </ol>
  `;

  const getType = () => {
    if (type === "flights") return "Vuelo";
    if (type === "packages") return "Paquete";
    return "Hotel";
  };

  return {
    from: "Test envío de emails",
    to: [tourOperatorEmail, userEmail],
    subject: `#${booking.id} - NUEVA RESERVA de ${getType()}`,
    html,
  };
};
