import stop_icon from "../../../iconos/stop_icon.png";
import starting_icon from "../../../iconos/starting_icon.png";
import online_icon from "../../../iconos/online_icon.png";
import done_icon from "../../../iconos/done.png";
import wait_icon from "../../../iconos/pause.png";

export const versionTypes = [
  {
    label: "Detenido",
    icon: stop_icon,
    color: "#C12601",
  },
  {
    label: "Iniciando",
    icon: starting_icon,
    color: "#E47D01",
  },
  {
    label: "Espera",
    icon: wait_icon,
    color: "#E4BF3C",
  },
  {
    label: "Hecho",
    icon: done_icon,
    color: "#2063E3",
  },
  {
    label: "Online",
    icon: online_icon,
    color: "#28B15C",
  },
];
