import arrow from "../../iconos/chevron_down_gray.png";

export default function FiltrosActividades({
  filters,
  setFilters,
  citiesByProvince,
}) {
  const handleChecks = (evt, filter) => {
    let arr = filters[filter].slice();

    if (arr.includes(evt.target.name)) {
      arr = arr.filter((el) => el !== evt.target.name);
    } else {
      arr.push(evt.target.name);
    }

    setFilters((curr) => {
      return {
        ...curr,
        [filter]: arr,
      };
    });
  };

  const handleCitiesByProvince = () => {
    let cities = filters.city.filter((city) => {
      return filters.province.some((prov) => {
        return citiesByProvince[prov].includes(city);
      });
    });

    setFilters((curr) => {
      return {
        ...curr,
        city: cities,
      };
    });
  };

  const handleDisplay = (item) => {
    const body = document.getElementById(`${item}-body`);
    const arrow = document.getElementById(`${item}-arrow`);
    body.classList.toggle("scale-y-0");
    body.classList.toggle("h-0");
    arrow.classList.toggle("-rotate-180");
    arrow.classList.toggle("rotate-0");
  };

  const provinces = Object.keys(citiesByProvince);
  const selectedProvinces =
    filters.province.length === 0 ? provinces : filters.province;

  return (
    <aside className="rounded-3xl w-[230px] max-h-[470px] font-body mt-10">
      <h1 className="pb-6 leading-0 whitespace-nowrap text-xl font-[700] pl-2 m-0">
        Actividades Turísticas
      </h1>
      {/* <section>
        <h2 className="flex justify-between items-center py-2 px-4 rounded-md bg-lightBlue">
          Categoría
        </h2>
        <p>Checkboxes</p>
      </section> */}
      <div>
        <header
          onClick={() => handleDisplay("province")}
          id="province-header"
          className="flex justify-between items-center py-2 px-4 rounded-md bg-lightBlue cursor-pointer"
        >
          <h2>Provincia</h2>
          <img
            src={arrow}
            alt="arrow"
            className="w-4 h-2 -rotate-180 transition-all"
            id="province-arrow"
          />
        </header>

        <section
          className="py-2 px-4 mb-2 grid gap-2 overflow-y-hidden transition-all duration-200 origin-top"
          id="province-body"
        >
          {provinces.map((prov) => {
            return (
              <div key={prov} className="flex gap-1 items-center pl-2">
                <input
                  id={`${prov}-prov`}
                  name={prov}
                  type="checkbox"
                  checked={filters.province.includes(prov)}
                  onChange={(evt) => {
                    handleChecks(evt, "province");
                    handleCitiesByProvince();
                  }}
                />
                <label htmlFor={`${prov}-prov`}>{prov}</label>
              </div>
            );
          })}
        </section>
      </div>

      <div>
        <header
          onClick={() => handleDisplay("city")}
          id="city-header"
          className="flex justify-between items-center py-2 px-4 rounded-md bg-lightBlue cursor-pointer"
        >
          <h2>Ciudad</h2>
          <img
            src={arrow}
            alt="arrow"
            className="w-4 h-2 -rotate-180 transition-all"
            id="city-arrow"
          />
        </header>
        <section
          className="py-2 px-4 mb-2 grid gap-2 overflow-y-hidden transition-all duration-200 origin-top"
          id="city-body"
        >
          {selectedProvinces.map((prov) => {
            return citiesByProvince[prov].map((city) => (
              <div key={city} className="flex gap-1 items-center pl-2">
                <input
                  id={`${city}-city`}
                  name={city}
                  type="checkbox"
                  checked={filters.city.includes(city)}
                  onChange={(evt) => handleChecks(evt, "city")}
                />
                <label htmlFor={`${city}-city`}>{city}</label>
              </div>
            ));
          })}
        </section>
      </div>
    </aside>
  );
}
