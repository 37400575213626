import { format } from "date-fns";
import separator from "../../iconos/decoracion-modal-paso3.png";
import { AIRLINES_DICTIONARY } from "../../utils/dictionaries/airlinesDictionary";

export default function TramoModalFlight({
  index,
  flights,
  translatedCabin,
  formattedCities,
}) {
  const flight = flights[index];
  const { departure, arrival, carrierCode, number } = flight;
  const departureDate = departure?.at;
  const arrivalDate = arrival.at;

  const millisecondsToHoursAndMinutes = (milliseconds) => {
    const hours = Math.floor(milliseconds / 3600000);
    const remainingMilliseconds = milliseconds % 3600000;
    const minutes = Math.floor(remainingMilliseconds / 60000);

    return { hours, minutes };
  };

  const lastArrival = new Date(flights?.[index - 1]?.arrival?.at);
  const waitingScale = millisecondsToHoursAndMinutes(
    new Date(departure.at) - lastArrival
  );

  const airline =
    AIRLINES_DICTIONARY.hasOwnProperty("AA") && AIRLINES_DICTIONARY["AA"];

  return (
    <>
      {index > 0 && (
        <section className="ml-8 py-2 pl-4 border-l-2 border-dashed border-gray2">
          <p className="text-gray text-sm font-medium scale-75 origin-left">
            {waitingScale.hours}h {waitingScale.minutes}m de espera en{" "}
            {formattedCities?.[departure?.iataCode]?.name} ({departure.iataCode}
            ).
          </p>
        </section>
      )}
      <section className="p-2 rounded-lg border-2 border-gray2 text-center grid grid-cols-3">
        <section className="flex flex-col gap-2">
          <header>
            <h2 className="bg-lightBlue w-full rounded font-[700] text-sm">
              Salida
            </h2>
          </header>
          <div>
            <p className="text-xs whitespace-nowrap">
              {departureDate
                ? format(new Date(departureDate), "dd MMM. yy")
                : ""}
            </p>
            <p className="font-[700]">
              {departureDate ? format(new Date(departureDate), "HH:mm") : ""}
            </p>
            <p className="font-medium">
              {formattedCities?.[departure?.iataCode]?.airport}
            </p>
            <p className="text-xs">
              {formattedCities?.[departure?.iataCode]?.city}
            </p>
          </div>
        </section>

        <section className="m-auto">
          <p className="font-[700] text-sm">
            {airline ? airline.label : carrierCode} - {number}
          </p>
          <p>{translatedCabin}</p>
          <p className="text-xs">
            Operado por {airline ? airline.label : carrierCode}
          </p>
          <img src={separator} alt="separator" className="w-28 mt-2 mx-auto" />
        </section>

        <section className="flex flex-col gap-2">
          <header>
            <h2 className="bg-lightBlue w-full rounded font-[700] text-sm">
              Llegada
            </h2>
          </header>
          <div>
            <p className="text-xs whitespace-nowrap">
              {arrivalDate ? format(new Date(arrivalDate), "dd MMM. yy") : ""}
            </p>
            <p className="font-[700]">
              {arrivalDate ? format(new Date(arrivalDate), "HH:mm") : ""}
            </p>
            <p className="font-medium">
              {formattedCities?.[arrival?.iataCode]?.airport}
            </p>
            <p className="text-xs">
              {formattedCities?.[arrival?.iataCode]?.city}
            </p>
          </div>
        </section>
      </section>
    </>
  );
}
