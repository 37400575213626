import { useState } from "react";
import usePictures from "../../hooks/usePictures";

export default function EditPicture({ pic, index }) {
  const { patchPicture, deletePicture } = usePictures();
  const { url, pictures_packages } = pic;
  const { picture_id, package_id } = pictures_packages;
  const [src, setSrc] = useState(url);

  const handleEditPic = () => {
    patchPicture({
      picture: {
        url: src,
        package_id,
      },
      picture_id,
    }).then(() => window.location.reload());
  };

  const handleDeletePic = () => {
    deletePicture({ picture_id }).then(() => window.location.reload());
  };

  return (
    <div>
      <section className="flex gap-2 w-full">
        <input
          type="url"
          value={src}
          className="rounded addPackInput py-1 px-4 w-full"
          onChange={(e) => setSrc(e.target.value)}
        />
        <button
          type="button"
          onClick={handleEditPic}
          className="rounded py-1 px-4 border border-brightBlue"
        >
          Modificar
        </button>
        <button
          type="button"
          onClick={handleDeletePic}
          className="rounded py-1 px-4 border border-red-500"
        >
          Eliminar
        </button>
      </section>
      <img src={src} alt={`Foto Paquete ${index}`} className="h-32 mt-1" />
    </div>
  );
}
