/* eslint-disable react-hooks/exhaustive-deps */
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import AutoCompleteCountryISO3166 from "../../utils/AutoCompleteCountryISO3166";
import { capitalizeString } from "../../utils/capitalizeString";

// CHEQUEAR QUE LA FECHA SEA MENOR A 6 MESES
const checkExpirationDate = (date) => {
  if (date) {
    const today = new Date().getTime();
    const expirationDate = new Date(date).getTime();
    const sixMonth = 6 * 30 * 24 * 60 * 60 * 1000;

    return expirationDate - today > sixMonth;
  }
};

export const FormUserData = ({
  id,
  index,
  idBody,
  isFlight,
  error,
  passengerState,
}) => {
  const [nationalityInput, setNationalityInput] = useState("");
  const [docNationalityInput, setDocNationalityInput] = useState("");
  const [expirationDateWarning, setExpirationDateWarning] = useState(false);
  const [passengerData, setPassengerData] = passengerState;
  const [errors, setErrors] = error;
  const { register, setValue, watch } = useForm();
  const first = index === 0;

  useEffect(() => {
    setPassengerData((curr) => ({
      ...curr,
      [index + 1]: {
        passengerType: id,
        isForeign: false,
        docType: "IDENTITY_CARD",
      },
    }));
  }, []);

  const handleEdit = (inputName, inputValue) => {
    setPassengerData((curr) => ({
      ...curr,
      [index + 1]: {
        ...curr[index + 1],
        [inputName]: inputValue,
      },
    }));
  };

  const checkInput = (input, inputString, pattern) => {
    handleEdit(input, inputString);
    const regex = new RegExp(pattern);

    const checkDate = () => {
      if (regex.test(inputString)) {
        return new Date(inputString) > new Date();
      }
      return false;
    };

    const isCorrect =
      input === "docExpirationDate" ? checkDate() : regex.test(inputString);

    return setErrors((curr) => {
      return {
        ...curr,
        [index + 1]: {
          ...curr[index + 1],
          [input]: { correct: isCorrect, value: inputString },
        },
      };
    });
  };

  return (
    <div
      className={`grid grid-cols-3 items-center gap-x-4 gap-y-8 origin-top transition-all duration-300 ${
        !first ? "h-0 scale-y-0" : "p-4"
      }`}
      id={idBody}
    >
      <label htmlFor="name" className="mb-1 font-low text-sm text-[#242423]">
        Nombre completo
      </label>
      <div className="relative col-span-2">
        <input
          {...register("name")}
          className="w-full rounded-md px-4 py-2 text-xs text-black focus:outline-none placeholder:text-[#24242399] bg-lightGray3"
          placeholder="Como figura en el documento de viaje"
          name="name"
          id="name"
          onChange={(e) =>
            checkInput(
              e.target.name,
              e.target.value,
              /^[a-zà-ÿ'-]+(?:\s[a-zà-ÿ'-]+)*$/i
            )
          }
          onBlur={(e) =>
            setValue(e.target.name, capitalizeString(e.target.value))
          }
        />
        {errors?.[index + 1]?.hasOwnProperty("name") &&
          !errors?.[index + 1]?.name?.correct && (
            <p className="absolute text-red-600 text-xs -bottom-4 left-4 whitespace-nowrap">
              {errors?.[index + 1]?.name?.value === ""
                ? "Campo Requerido"
                : "Ingrese un nombre válido"}
            </p>
          )}
      </div>

      <label htmlFor="surname" className="mb-1 font-low text-sm text-[#242423]">
        Apellido
      </label>
      <div className="col-span-2 relative">
        <input
          {...register("surname")}
          className="w-full rounded-md  px-4 py-2  text-xs text-black placeholder:text-[#24242399] focus:outline-none bg-lightGray3"
          placeholder="Como figura en el documento de viaje"
          name="surname"
          id="surname"
          onChange={(e) =>
            checkInput(
              e.target.name,
              e.target.value,
              /^[a-zà-ÿ'-]+(?:\s[a-zà-ÿ'-]+)*$/i
            )
          }
          onBlur={(e) =>
            setValue(e.target.name, capitalizeString(e.target.value))
          }
        />
        {errors?.[index + 1]?.hasOwnProperty("surname") &&
          !errors?.[index + 1]?.surname?.correct && (
            <p className="absolute text-red-600 text-xs -bottom-4 left-4 whitespace-nowrap">
              {errors?.[index + 1]?.surname?.value === ""
                ? "Campo Requerido"
                : "Ingrese un apellido válido"}
            </p>
          )}
      </div>

      {isFlight && (
        <>
          <label
            htmlFor="nationality"
            className="mb-1 font-low text-sm text-[#242423]"
          >
            País de residencia
          </label>
          <div className="col-span-2 relative">
            <AutoCompleteCountryISO3166
              className="rounded-md px-4 py-2  text-xs text-black placeholder:text-[#24242399] focus:outline-none bg-lightGray3 w-full"
              register={register}
              bgColor="lightBlue2"
              name="nationality"
              handleAction={(d) => checkInput("nationality", d, /[A-Z]{2}/)}
              inputState={[nationalityInput, setNationalityInput]}
            />
            <div className="flex flex-col gap-2 mt-1">
              <span className="flex gap-2">
                <input
                  type="checkbox"
                  checked={passengerData?.[index + 1]?.isForeign}
                  onClick={(e) => handleEdit("isForeign", e.target.checked)}
                  {...register("isForeign")}
                />
                <p className="text-xs text-[#242423]">
                  Este pasajero es extranjero no residente en Argentina.
                </p>
              </span>
            </div>
            {errors?.[index + 1]?.hasOwnProperty("nationality") &&
              !errors?.[index + 1]?.nationality?.correct && (
                <p className="absolute text-red-600 text-xs top-2 right-8 whitespace-nowrap">
                  {errors?.[index + 1]?.nationality?.value === ""
                    ? "Campo Requerido"
                    : "Ingrese un país válido"}
                </p>
              )}
          </div>
        </>
      )}

      <label
        className="mb-1 font-low text-sm text-[#242423]"
        htmlFor="birthday"
      >
        Fecha de nacimiento
      </label>
      <div className="col-span-2 relative">
        <input
          {...register("birthday")}
          type="date"
          className="w-full rounded-md px-4 py-2 text-xs text-black focus:outline-none placeholder:text-[#24242399] bg-lightGray3"
          id="birthday"
          onBlur={(e) =>
            checkInput(
              e.target.name,
              e.target.value,
              /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/
            )
          }
        />
        {errors?.[index + 1]?.hasOwnProperty("birthday") &&
          !errors?.[index + 1]?.birthday?.correct && (
            <p className="absolute text-red-600 text-xs -bottom-4 left-4 whitespace-nowrap">
              {errors?.[index + 1]?.birthday?.value === ""
                ? "Campo Requerido"
                : "Ingrese una fecha válida"}
            </p>
          )}
      </div>

      {isFlight && (
        <>
          <label className="mb-1 font-low text-sm text-[#242423]">Sexo</label>
          <span className="flex items-center gap-4 col-span-2">
            <fieldset>
              <div className="flex items-center gap-3">
                <input
                  {...register("genre")}
                  type="radio"
                  id={`contactChoice1-${index}`}
                  name={`genre-${index}`}
                  checked={passengerData?.[index + 1]?.genre === "F"}
                  onChange={(e) =>
                    handleEdit("genre", e.target.checked ? "F" : "")
                  }
                />
                <label htmlFor={`contactChoice1-${index}`}>Femenino</label>

                <input
                  {...register("genre")}
                  type="radio"
                  id={`contactChoice2-${index}`}
                  name={`genre-${index}`}
                  checked={passengerData?.[index + 1]?.genre === "M"}
                  onChange={(e) =>
                    handleEdit("genre", e.target.checked ? "M" : "")
                  }
                />
                <label htmlFor={`contactChoice2-${index}`}>Masculino</label>
              </div>
            </fieldset>
          </span>
        </>
      )}

      <label className="mb-1 font-low text-sm text-[#242423]">
        Tipo y número de documento{" "}
      </label>
      <span className="flex col-span-2 gap-4">
        <select
          {...register("docType")}
          className="px-2 py-1 text-xs h-8 bg-lightGray3 rounded-md"
          onChange={(e) => {
            handleEdit("docType", e.target.value);
            setValue("docType", e.target.value);
          }}
        >
          <option className="text-xs" value="IDENTITY_CARD">
            DNI
          </option>
          <option className="text-xs" value="PASSPORT">
            Pasaporte
          </option>
        </select>
        <label className="mb-1 flex flex-col  gap-1 text-xs text-[#242423]  w-full ">
          <div className="relative w-full">
            <input
              {...register("docNumber")}
              className="w-full rounded-md  px-4 py-2  text-xs text-black placeholder:text-[#24242399] focus:outline-none bg-lightGray3"
              name="docNumber"
              placeholder={`Número de ${
                watch("docType") === "IDENTITY_CARD" ? "documento" : "pasaporte"
              }`}
              onChange={(e) =>
                checkInput(
                  e.target.name,
                  e.target.value,
                  watch("docType") === "IDENTITY_CARD"
                    ? /[1-9]\d{6,}/
                    : /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z0-9]{8,12}$/
                )
              }
              ß
            />
            {errors?.[index + 1]?.hasOwnProperty("docNumber") &&
              !errors?.[index + 1]?.docNumber?.correct && (
                <p className="absolute text-red-600 text-xs -bottom-4 left-4 whitespace-nowrap">
                  {errors?.[index + 1]?.docNumber?.value === ""
                    ? "Campo Requerido"
                    : watch("docType") === "IDENTITY_CARD"
                    ? "Ingrese número de documento válido."
                    : "Debe contener letras y números."}
                </p>
              )}
          </div>
        </label>
      </span>

      {isFlight && (
        <>
          <label
            className="mb-1 font-low text-sm text-[#242423]"
            htmlFor="docExpirationDate"
          >
            Fecha de vencimiento del documento de viaje{" "}
          </label>
          <div className="relative col-span-2">
            <input
              {...register("docExpirationDate")}
              className="w-full rounded-md px-4 py-2 text-xs text-black focus:outline-none placeholder:text-[#24242399] bg-lightGray3"
              type="date"
              id="docExpirationDate"
              onBlur={(e) => {
                checkInput(
                  e.target.name,
                  e.target.value,
                  /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/
                );
                setExpirationDateWarning(!checkExpirationDate(e.target.value));
              }}
            />
            {errors?.[index + 1]?.hasOwnProperty("docExpirationDate") &&
            !errors?.[index + 1]?.docExpirationDate?.correct ? (
              <p className="absolute text-red-600 text-xs -bottom-4 left-4 whitespace-nowrap">
                {errors?.[index + 1]?.docExpirationDate?.value === ""
                  ? "Campo Requerido"
                  : "Ingrese fecha válida"}
              </p>
            ) : (
              expirationDateWarning && (
                <p className="absolute text-yellow-400 text-xs -bottom-4 left-4 whitespace-nowrap">
                  Tu documento de viaje vence en menos de 6 meses
                </p>
              )
            )}
          </div>
        </>
      )}

      {isFlight && (
        <>
          <label
            htmlFor="docNationality"
            className="mb-1 font-low text-sm text-[#242423]"
          >
            País de emisión del documento de viaje
          </label>
          <div className="col-span-2 relative">
            <AutoCompleteCountryISO3166
              className="rounded-md px-4 py-2  text-xs text-black placeholder:text-[#24242399] focus:outline-none bg-lightGray3 w-full"
              register={register}
              bgColor="lightBlue2"
              name="docNationality"
              handleAction={(d) => checkInput("docNationality", d, /[A-Z]{2}/)}
              inputState={[docNationalityInput, setDocNationalityInput]}
            />
            {errors?.[index + 1]?.hasOwnProperty("docNationality") &&
              !errors?.[index + 1]?.docNationality?.correct && (
                <p className="absolute text-red-600 text-xs -bottom-4 left-4 whitespace-nowrap">
                  {errors?.[index + 1]?.docNationality?.value === ""
                    ? "Campo Requerido"
                    : "Ingrese un país válido"}
                </p>
              )}
          </div>
        </>
      )}

      {first && isFlight && (
        <>
          <label
            htmlFor="mail"
            className="mb-1 font-low text-sm text-[#242423]"
          >
            Email
          </label>
          <div className="col-span-2 relative">
            <input
              {...register("mail")}
              className="w-full rounded-md  px-4 py-2  text-xs text-black placeholder:text-[#24242399] focus:outline-none bg-lightGray3"
              placeholder="mail@mail.com"
              name="mail"
              id="mail"
              onChange={(e) =>
                checkInput(
                  e.target.name,
                  e.target.value,
                  /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
                )
              }
            />
            {errors?.[index + 1]?.hasOwnProperty("mail") &&
              !errors?.[index + 1]?.mail?.correct && (
                <p className="absolute text-red-600 text-xs -bottom-4 left-4 whitespace-nowrap">
                  {errors?.[index + 1]?.mail?.value === ""
                    ? "Campo Requerido"
                    : "Ingrese un email válido"}
                </p>
              )}
          </div>
        </>
      )}
    </div>
  );
};
