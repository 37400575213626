import { useForm } from "react-hook-form";
import { Toaster, toast } from "react-hot-toast";
import { Link, useParams } from "react-router-dom";
import { z } from "zod";
import { ErrorMessage } from "@hookform/error-message";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { useBookings } from "../../hooks/useBookings";
import SEO from "../../utils/SEO";
import usePayments from "../../hooks/usePayments";
import useGetCurrencies from "../../hooks/useGetCurrencies";
import { saveTourOperator } from "../../utils/saveTourOperator";

export default function FormAddPayment() {
  const { tour_op_id } = useParams();
  const tourOP = saveTourOperator((st) => st.tourOP);
  const { getFlightBookings, getBooking, bookings, booking } = useBookings();
  const { postPayment, getPaymentMethods, paymentMethods } = usePayments();
  const { currencies } = useGetCurrencies();
  const [bookingID, setBookingID] = useState();

  useEffect(() => {
    getFlightBookings();
    getPaymentMethods();
  }, []);

  useEffect(() => {
    getBooking({ bookingID });
  }, [bookingID]);

  const schema = z.object({
    paid: z
      .string({ required_error: "Campo Requerido" })
      .regex(/^[+]?\d+([.]\d+)?$/),
    currency_type_id: z
      .string({ required_error: "Campo Requerido" })
      .regex(/^[+]?\d+([.]\d+)?$/)
      .default("1"),
    payment_method_id: z
      .string({ required_error: "Campo Requerido" })
      .regex(/^[+]?\d+([.]\d+)?$/)
      .default("1"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: zodResolver(schema) });

  const IDs = bookings && bookings.map((booking) => booking.id);

  const onSubmit = async (data) => {
    const parsedFlights = JSON.parse(booking.Flights[0].options);
    const departure_date =
      parsedFlights.itineraries[0].segments[0].departure.at;
    const to_pay = Math.floor(
      Number(parsedFlights.price.grandTotal) - Number(data.paid)
    ).toString();

    toast.promise(
      postPayment({
        accounting: {
          payment_date: new Date(),
          departure_date,
          pax_group:
            booking.Travelers[0].last_name +
            " " +
            booking.Travelers[0].first_name,
          invoiced: 1, // esto es un string pero no sé qué representa, o sea no sé qué tipo de string puede ser
          paid: data.paid,
          to_pay,
          code: "0123456780", // qué representa y de dónde lo saco?
          currency_type_id: data.currency_type_id || "1",
          payment_method_id: data.payment_method_id || "1",
          booking_id: bookingID,
          traveler_id: booking.Travelers[0].id,
          tour_op_id: tourOP.id,
        },
      }),
      {
        loading: "Cargando...",
        success: <b>Pago agregado con éxito</b>,
        error: <b>Hubo un error, intenta de nuevo.</b>,
      }
    );
  };

  return (
    <>
      <SEO title={"Agregar Pago"} />
      <main className="font-body w-full">
        <header className="flex justify-between mt-10 max-w-[1000px] mx-auto">
          <h1 className="text-darkBlue font-semibold text-3xl">Agregar Pago</h1>
          <Link
            to={`/${tour_op_id}/panel/invoicing/my-invoices`}
            className="ml-auto button text-center border-2 whitespace-nowrap w-fit px-2 text-darkBlue font-medium border-gray hover:border-darkBlue transition-colors"
          >
            Volver a Mis Facturaciones
          </Link>
        </header>

        <form
          onSubmit={handleSubmit(onSubmit)}
          className="my-10 mx-auto max-w-[1000px] bg-white rounded-lg p-8"
        >
          <div>
            <Toaster />
          </div>

          <div className="grid grid-cols-2 gap-6">
            <label className="w-full relative group/bookingid">
              <p>Id de reserva</p>
              <input
                className="addPackInput w-full"
                name="booking_id"
                id="booking_id"
                value={bookingID}
                onChange={(e) => setBookingID(e.target.value)}
              />
              <div className="absolute scale-y-0 z-50 addPackInput p-0 bg-white text-base w-full top-16 cursor-pointer group-hover/bookingid:scale-y-100 origin-top">
                {IDs &&
                  IDs.filter((id) =>
                    !bookingID ? true : id.toString().includes(bookingID)
                  )
                    .slice(0, 10)
                    .map((id, index) => (
                      <div
                        key={`${id}-${index}`}
                        className="p-1 transition-colors hover:bg-lightBlue"
                        onClick={() => setBookingID(id)}
                      >
                        <p>{id}</p>
                      </div>
                    ))}
              </div>
              <div className="sticky text-red-600 text-xs bottom-0.5 right-8 whitespace-nowrap">
                <ErrorMessage errors={errors} name="booking_id" as="p" />
              </div>
            </label>

            <label className="w-full">
              <p>Método de Pago</p>
              <select
                name="payment_method_id"
                id="payment_method_id"
                placeholder="Método de Pago"
                className="addPackInput w-full"
                {...register("payment_method_id")}
              >
                {paymentMethods.map(({ method, id }) => {
                  return (
                    <option key={`${id}`} value={id}>
                      {method}
                    </option>
                  );
                })}
              </select>
              <div className="sticky text-red-600 text-xs bottom-0.5 right-8 whitespace-nowrap">
                <ErrorMessage errors={errors} name="payment_method_id" as="p" />
              </div>
            </label>

            <label className="w-full">
              <p>Monto</p>
              <input
                type="number"
                placeholder="Pagado"
                className="addPackInput w-full"
                name="paid"
                {...register("paid")}
              />
              <div className="sticky text-red-600 text-xs bottom-0.5 right-8 whitespace-nowrap">
                <ErrorMessage errors={errors} name="paid" as="p" />
              </div>
            </label>

            <label className="w-full">
              <p>Moneda</p>
              <select
                name="currency_type_id"
                id="currency_type_id"
                placeholder="Método de Pago"
                className="addPackInput w-full"
                {...register("currency_type_id")}
              >
                {currencies.map(({ name, symbol, id }) => {
                  return (
                    <option key={`${id}`} value={id}>
                      {name} ({symbol})
                    </option>
                  );
                })}
              </select>
              <div className="sticky text-red-600 text-xs bottom-0.5 right-8 whitespace-nowrap">
                <ErrorMessage errors={errors} name="currency_type_id" as="p" />
              </div>
            </label>

            {/* <label className="w-full">
              <p>Recibo</p>
              <input
                type="text"
                placeholder="Recibo"
                className="addPackInput w-full"
                name="invoiced"
                {...register("invoiced")}
              />
              <div className="sticky text-red-600 text-xs bottom-0.5 right-8 whitespace-nowrap">
                <ErrorMessage errors={errors} name="invoiced" as="p" />
              </div>
            </label>

            <label className="w-full">
              <p>A Pagar</p>
              <input
                type="number"
                placeholder="A Pagar"
                className="addPackInput w-full"
                name="to_pay"
                {...register("to_pay")}
              />
              <div className="sticky text-red-600 text-xs bottom-0.5 right-8 whitespace-nowrap">
                <ErrorMessage errors={errors} name="to_pay" as="p" />
              </div>
            </label> */}

            <button className="col-span-2 ml-auto button text-lg w-1/4 bg-darkBlue text-white hover:shadow-lg transition-all">
              Guardar Pago
            </button>
          </div>
        </form>
      </main>
    </>
  );
}
