export function generateEmailBody(selectedPackage, tourOP, tour_op_id) {
  const { TourOperatorSocialMedia } = tourOP;
  const { id } = selectedPackage

  const currentHREF = window.location.href.split("/").slice(0, -4).join("/");
  const deepLink = `${currentHREF}/${tour_op_id}/package-detail/${id}`;
  const wppLink = TourOperatorSocialMedia?.find(
    ({ redSocial }) => redSocial === "Whatsapp"
  )?.url;
  const igLink = TourOperatorSocialMedia?.find(
    ({ redSocial }) => redSocial === "Instagram"
  )?.url;
  const fbLink = TourOperatorSocialMedia?.find(
    ({ redSocial }) => redSocial === "Facebook"
  )?.url;

  return `<html>
  <head>
    <style>
      body {
        width: 100%;
        font-family: Arial, sans-serif;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 16px;
      }
  
      .flyer {
        max-width: 500px;
        max-height: 1200px;
        margin: 0 auto;
      }
  
      .separator {
        background-color: white;
        width: 80%;
        height: 1px;
        margin: auto
      }
  
      .footer {
        background-color: #333;
        color: #fff;
        padding: 20px;
        text-align: center;
      }
  
      .footer .contact-info,
      .footer .social-media,
      .footer .unsubscribe {
        margin-bottom: 20px;
      }
  
      .footer a {
        color: #fff;
        text-decoration: none;
      }
  
      .footer a:hover {
        text-decoration: underline;
      }
  
      .footer .social-media a {
        margin: 0 10px;
      }
    </style>
  </head>
  
  <body>
    <a href="${deepLink}" target="_blank" rel="noreferrer"}>
      <img src="${selectedPackage?.flyer}" alt="flyer" class="flyer" />
    </a>

    <span>Si no se puede visualizar, haz
      <a href="${wppLink}">Click Aquí</a>
    </span>

    <footer class="footer">
      <div class="social-media">
        <p>¡Seguinos en nuestras redes sociales!</p>
        <a href="${fbLink}">Facebook</a>
        <a href="${igLink}">Instagram</a>
        <a href="${wppLink}">Whatsapp</a>
      </div>

      <div class="separator"></div>
      
      <div class="unsubscribe">
        <p>Recibes este email porque estás suscrito a nuestra lista de correos.</p>
        <p>Para desuscribirte haz <a href="${wppLink}">click aquí</a>.</p>
      </div>
    </footer>
  </body>
  </html>`;
}
