import axios from "axios";
import { useEffect, useState } from "react";
import { getLocalStorageValue, getSessionStorageValue } from "./useCookie";

export const useVersions = () => {
  const BASE_URL = process.env.REACT_APP_API_URL;
  const [versions, setVersions] = useState();

  const token =
    getLocalStorageValue("token") || getSessionStorageValue("token");

  const headers = {
    headers: { Authorization: `${token}` },
  };

  const postVersion = async ({ version }) => {
    return await axios.post(BASE_URL + "v2/versions", { version }, headers);
  };

  const getVersions = async () => {
    const res = await axios
      .get(BASE_URL + "v2/versions", headers)
      .then((e) => {
        console.log(e);
        e?.data && setVersions(e?.data?.response);
      })
      .catch((error) => console.log(error));
    if (res) {
      return res?.data || {};
    }
  };

  useEffect(() => {
    getVersions();
  }, []);

  return { versions, postVersion, getVersions };
};
