import axios from "axios";
import { useState } from "react";

export default function useCreateAccount() {
  const BASE_URL = process.env.REACT_APP_API_URL;

  const [loading, setLoading] = useState(false);
  const [responseErrors, setErrors] = useState();

  const createNewAccount = async (data) => {
    setLoading(true);
    const btn = document.getElementById("createAccount");
    const originalText = btn.textContent;

    return await axios
      .post(BASE_URL + "v2/users/register", data)
      .then((e) => {
        setLoading(false);
        setErrors(false);
        // console.log(e)
        btn.textContent = "Cuenta Creada";
        btn.classList.add("border-green-600");
        btn.classList.add("text-green-600");

        setTimeout(() => {
          btn.classList.remove("border-green-600");
          btn.classList.remove("text-green-600");
          btn.textContent = originalText;
          setErrors();
        }, 2000);
      })
      .catch((e) => {
        console.log("error", e);
        setLoading(false);
        setErrors(e.response.data.errors);

        btn.textContent = "Error al crear cuenta";
        btn.classList.add("border-red-600");
        btn.classList.add("text-red-600");

        setTimeout(() => {
          btn.classList.remove("border-red-600");
          btn.classList.remove("text-red-600");
          btn.textContent = originalText;
        }, 2000);

        throw new Error(e.response.data.errors)
      });
  };

  return { createNewAccount, loading, responseErrors };
}
