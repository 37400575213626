import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import useAgencies from "../../hooks/useAgencies";
import { Toaster, toast } from "react-hot-toast";
import { agencySchema } from "../../schemas/newAgency";

export default function FormAddAgency({ closeModal }) {
  const { postAgency } = useAgencies();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: zodResolver(agencySchema) });

  const onSubmit = (agency) => {
    postAgency({ agency })
      .then(() => {
        toast.success("Agencia agregada con éxito");
        closeModal();
      })
      .catch((err) => {
        console.log(err);
        const response = JSON.parse(err?.request?.response);
        toast.error(response.error.errors[0].message);
      });
  };

  return (
    <form
      onSubmit={handleSubmit((data) => onSubmit(data))}
      className="grid grid-cols-2 gap-4 w-[420px]"
    >
      <div>
        <Toaster />
      </div>
      <h1 className="col-span-2 text-darkBlue font-semibold text-xl text-center">
        Datos de la Agencia
      </h1>
      <label htmlFor="name" className="relative w-full">
        <input
          placeholder="Nombre"
          className="addPackInput w-full"
          {...register("name")}
        />
        <div className="absolute text-red-600 text-xs -bottom-[15px] whitespace-nowrap">
          <ErrorMessage errors={errors} name="name" as="p" />
        </div>
      </label>
      <label htmlFor="email" className="relative w-full">
        <input
          placeholder="Email"
          className="addPackInput w-full"
          {...register("email")}
        />
        <div className="absolute text-red-600 text-xs -bottom-[15px] whitespace-nowrap">
          <ErrorMessage errors={errors} name="email" as="p" />
        </div>
      </label>
      <label htmlFor="address" className="relative w-full col-span-1">
        <input
          placeholder="Dirección"
          className="addPackInput w-full"
          {...register("address")}
        />
        <div className="absolute text-red-600 text-xs -bottom-[15px] whitespace-nowrap">
          <ErrorMessage errors={errors} name="address" as="p" />
        </div>
      </label>
      <label htmlFor="phone" className="relative w-full">
        <input
          placeholder="Número de teléfono"
          className="addPackInput w-full"
          {...register("phone")}
        />
        <div className="absolute text-red-600 text-xs -bottom-[15px] whitespace-nowrap">
          <ErrorMessage errors={errors} name="phone" as="p" />
        </div>
      </label>
      {/* <label htmlFor="iva" className="relative w-full">
        <input
          type="number"
          placeholder="IVA"
          className="addPackInput w-full"
          {...register("iva")}
        />
        <div className="absolute text-red-600 text-xs -bottom-[15px] whitespace-nowrap">
          <ErrorMessage errors={errors} name="iva" as="p" />
        </div>
      </label> */}

      <div />
      <button className="px-4 py-1.5 rounded bg-brightBlue text-white">
        Agregar Agencia
      </button>
    </form>
  );
}
