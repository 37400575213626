import { AIRLINES_DICTIONARY } from "../../utils/dictionaries/airlinesDictionary";
import clock from "../../iconos/clock_cotizador.png";
import scale from "../../iconos/stop_scales.png";
import bags from "../../iconos/equipaje.png";
import { format } from "date-fns";

const translatedDuration = (time) => {
  const splitTime =
    time &&
    time
      .split("H")
      .map((el) => {
        const regex = /\d+/g;
        const numbers = el.match(regex);

        return numbers ? numbers.map(Number) : null;
      })
      .flat();

  return splitTime?.[0] + splitTime?.[1] / 100;
};

export default function SelectedQuoteFlight({ flights, original }) {
  const { duration, segments } = flights;

  const details = {};
  original.map((opt) => {
    return opt.travelerPricings[0].fareDetailsBySegment.map((flight) => {
      return (details[flight.segmentId] = flight);
    });
  });

  const airline = AIRLINES_DICTIONARY[segments[0].carrierCode] ?? null;
  const scales = segments.length - 1;

  return (
    <>
      <section className="flex flex-col items-center gap-2 p-2 bg-[#EFF4FF] w-full rounded-md relative cursor-pointer group/flight">
        <section className="relative flex items-center">
          <img
            src={airline && airline.img}
            alt={segments[0].carrierCode}
            className="absolute my-auto right-24 max-w-[48px] max-h-5"
          />
          <p className="font-[700] text-darkBlue">
            {segments[0].departure.iataCode} {">"}{" "}
            {segments.at(-1).arrival.iataCode}
          </p>
        </section>
        <section className="grid grid-flow-col grid-cols-3 gap-4 pr-4">
          <div className="flex gap-1 mx-auto">
            <img src={bags} alt="equipaje" className="w-4 h-4 mt-px" />
            <p className="text-sm">
              {details[segments[0].id].includedCheckedBags.quantity}
            </p>
          </div>
          <div className="flex gap-1 mx-auto">
            <img src={scale} alt="escala" className="w-4 h-4 mt-px" />
            <p className="text-sm">{scales}</p>
          </div>
          <div className="flex gap-1 ml-auto">
            <img src={clock} alt="tiempo" className="w-4 h-4 mt-px" />
            <p className="text-sm">
              {translatedDuration(duration).toFixed(2)}hs
            </p>
          </div>
        </section>

        <section className="w-full grid justify-center scale-0 transition-transform bg-[#EFF4FF] border border-lightBlue p-1 rounded-md z-10 top-0 bottom-0 absolute group-hover/flight:scale-100">
          {segments.map((segment, index) => {
            return (
              <div
                className="flex items-center justify-between w-full gap-1"
                key={index}
              >
                <p className="text-xs font-medium w-[70px]">
                  {segment.departure.iataCode} {">"} {segment.arrival.iataCode}
                </p>
                <p className="text-xs">
                  {format(new Date(segment.departure.at), "dd/MM/yy - HH:mm")}
                </p>
                <p className="text-xs">
                  {details[segment.id].cabin.slice(0, 3)}
                </p>
              </div>
            );
          })}
        </section>
      </section>
    </>
  );
}
