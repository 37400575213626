import { useState } from "react";
import { useRole } from "../../hooks/useRole";
import UpdateUserModal from "./components/UpdateUserModal";
import { useToggleVisibility } from "../../hooks/useToggleVisibility";
import { ModalGeneric } from "../../utils/ModalGeneric";
import edit from "../../iconos/edit_icon.png";
import trash from "../../iconos/delete.png";

export default function UsersTable({ useUsers, agencies }) {
  const { roles } = useRole();
  const [isVisible, toggleVisibility] = useToggleVisibility();
  const [selectedUser, setSelectedUser] = useState({});
  const [typeModal, setTypeModal] = useState("");
  const { users, getUsersByTourOperator, deleteUser } = useUsers;

  const openModal = (user, modal) => {
    setSelectedUser(user);
    toggleVisibility();
    setTypeModal(modal);
  };

  const closeModal = () => {
    toggleVisibility();
    getUsersByTourOperator();
  };

  return (
    <section className="bg-white w-full mt-4 rounded-xl p-4 border-2 border-lightBlue">
      <header className="grid grid-cols-5 bg-lightBlue/30 rounded py-2">
        <p className="font-medium pl-16">Usuario</p>
        <p className="font-medium mx-auto">Rol</p>
        <p className="font-medium mx-auto">Email</p>
        <p className="font-medium mx-auto">Agencia</p>
        <p className="font-medium mx-auto">Acción</p>
      </header>

      {users.map((user, key) => (
        <div className="grid grid-cols-5 rounded py-2" key={key}>
          <p className="pl-16">{user.username}</p>
          <p className="mx-auto">
            {roles?.length > 0 &&
              roles.find((rol) => rol.id === user.role_id)?.name}
          </p>
          <p className="mx-auto">{user.email}</p>
          <p className="mx-auto">
            {agencies?.length > 0 &&
              agencies.find((ag) => ag.id === user.agency_id)?.name}
          </p>
          <div className="flex justify-center gap-16">
            <button onClick={() => openModal(user, "edit")}>
              <img src={edit} alt="edit" className="h-8" />
            </button>
            <button onClick={() => openModal(user, "delete")}>
              <img src={trash} alt="delete" className="h-8" />
            </button>
          </div>
        </div>
      ))}

      <ModalGeneric show={isVisible} toggle={toggleVisibility}>
        {typeModal === "edit" && (
          <UpdateUserModal
            roles={roles}
            user={selectedUser}
            agencies={agencies}
            closeModal={closeModal}
          />
        )}
        {typeModal === "delete" && (
          <div className="flex flex-col items-center gap-2">
            <h2>
              ¿Estás seguro de eliminar el usuario{" "}
              <b>{selectedUser?.username || selectedUser?.email}</b>?
            </h2>
            <div className="flex justify-center gap-2">
              <button
                className="py-1 px-2 rounded font-medium border border-gray2"
                onClick={toggleVisibility}
              >
                Cancelar
              </button>
              <button
                className="py-1 px-2 rounded font-medium border text-white border-red-500 bg-red-500"
                onClick={() => {
                  deleteUser(selectedUser.id)
                    .then(() => {
                      getUsersByTourOperator();
                    })
                    .finally(() => {
                      toggleVisibility();
                      setSelectedUser();
                    });
                }}
              >
                Eliminar
              </button>
            </div>
          </div>
        )}
      </ModalGeneric>
    </section>
  );
}
