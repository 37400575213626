import { useState } from "react";
import whatsapp from "../../iconos/whatsapp_logo.png";
import email_blue from "../../iconos/email_blue.png";
import email_white from "../../iconos/email_white.png";

export default function ActividadSeleccionada({ activity }) {
  const [client, setClient] = useState({ name: "", email: "", phone: "" });
  const cityName = activity?.territories?.[0]?.name;
  const provinceName = activity?.territories?.[0]?.province_name;

  const textMessage = `Hola. Vi esta actividad y me interesaría recibir más información para reservar: ${activity?.name}, en ${cityName} - ${provinceName}`;

  return (
    <aside
      className={`sticky top-4 mt-10 h-fit flex flex-col items-center gap-4  transition-all ${
        activity ? "scale-100 w-1/4" : "scale-0 w-0"
      }`}
    >
      <section className="w-full text-center p-4 border-2 border-lightBlue rounded-2xl bg-lightBlue2">
        <h2 className="text-brightBlue font-semibold text-lg">
          {activity?.name}
        </h2>
        <img
          src={activity?.image}
          alt={activity?.name}
          className="rounded my-2"
        />
        <p>
          {cityName}, {provinceName}
        </p>
      </section>

      <section>
        <h2 className="font-[700] text-darkBlue text-lg mb-2 text-center">
          Datos del Cliente
        </h2>
        <input
          type="text"
          className="bg-lightBlue2 rounded-md w-full p-2 border border-lightBlue"
          placeholder="Nombre del Cliente"
          value={client.name}
          onChange={(e) =>
            setClient((curr) => {
              return { ...curr, name: e.target.value };
            })
          }
        />
        <input
          type="tel"
          className="bg-lightBlue2 rounded-md w-full p-2 border border-lightBlue my-2"
          placeholder="Teléfono"
          value={client.phone}
          onChange={(e) =>
            setClient((curr) => {
              return { ...curr, phone: e.target.value };
            })
          }
        />
        <input
          type="email"
          className="bg-lightBlue2 rounded-md w-full p-2 border border-lightBlue"
          placeholder="cliente@email.com"
          value={client.email}
          onChange={(e) =>
            setClient((curr) => {
              return { ...curr, email: e.target.value };
            })
          }
        />
      </section>

      <section className="flex flex-col w-full">
        <h2 className="font-[700] text-darkBlue text-xl mb-2 text-center">
          Enviar Cotización
        </h2>
        <div className="grid grid-cols-2 gap-2">
          <a
            href={`https://api.whatsapp.com/send?phone=${client.phone}&text=${textMessage}`}
            target="_blank"
            rel="noreferrer"
            className={`bg-brightBlue border border-brightBlue py-1.5 rounded hover:opacity-90 shadow-md transition-all ${
              !client.phone && "bg-lightBlue2 pointer-events-none shadow-none"
            }`}
          >
            <img src={whatsapp} alt="whatsapp" className="mx-auto h-6" />
          </a>
          <a
            href={`mailto:${client.email}?subject=Cotización&body=${textMessage}`}
            target="_blank"
            rel="noreferrer"
            className={`bg-brightBlue border border-brightBlue py-1.5 rounded hover:opacity-90 shadow-md transition-all ${
              !client.email && "bg-lightBlue2 pointer-events-none shadow-none"
            }`}
          >
            <img
              src={!client.email ? email_blue : email_white}
              alt="email"
              className="m-auto h-5 transition-all"
            />
          </a>
        </div>
      </section>
    </aside>
  );
}
