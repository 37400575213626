export default function BookingTableHeader({ headers }) {
  return (
    <header className="bg-lightBlue grid grid-flow-row grid-cols-10 py-2 px-8 rounded-lg">
      {headers.map((header, index) => {
        return (
          <h4
            key={`${header}-${index}`}
            className={`font-medium text-brightBlue ${
              header === "Primer Pasajero" && "col-span-2"
            } ${
              (header === "F. Creación" || header === "Estado") && "mx-auto"
            } ${header === "Acción" && "ml-auto"}`}
          >
            {header}
          </h4>
        );
      })}
    </header>
  );
}
