import { create } from "zustand";

export const useSelectPanelConfig = create((set) => ({
  menu: {
    General: {
      razonSocial: "",
      cuit: "",
      phone: "",
      address: "",
      currencyType: "",
      language: "",
      isologo: "",
      isologoName: "",
      mainLogo: "",
      mainLogoName: "",
      colors: [],
    },
    Home: {},
    Ventas: {},
    Adm: {},
    User: {},
    CRM: {},
  },
  editMenu: ({ header, data }) =>
    set((st) => ({
      menu: { ...st.menu, [header]: { ...st.menu[header], ...data } },
    })),
}));
