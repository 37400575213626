import { useState, useEffect } from "react";
import { get } from "../services/axios.service";

export const useRole = () => {
  const [roles, setRoles] = useState([]);

  const getRoles = async () => {
    try {
      const response = await get("v2/roles");

      setRoles(response.data.response);
    } catch (error) {
      console.log("Get Roles Error: ", error);
    }
  };

  useEffect(() => {
    getRoles();
  }, []);

  return {
    roles,
    getRoles,
  };
};
