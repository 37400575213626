import { create } from "zustand";

export const useSelectFlights = create((set) => ({
  flight: {},
  original: {},
  originalFlight: {},
  opcionVueloSelected: {},
  selectFlight: ({ type, fl, totalPrice }) =>
    set((prevFlights) => ({
      flight: { ...prevFlights.flight, [type]: fl, totalPrice },
    })),
  setOriginal: (original) =>
    set(() => ({
      original: original,
    })),
  selectOpcionesVuelo: ({ original, opcionVuelo }) =>
    set(() => ({ originalFlight: original, opcionVueloSelected: opcionVuelo })),
}));
