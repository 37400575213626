import clsx from "clsx";

export const radioClass = clsx(
  "relative float-left -ml-[1.5rem] mt-1.5 h-4 w-4 ",
  "appearance-none",
  "rounded-full border-2 border-solid border-[#CEDEF1]",
  "checked:border-[#CEDEF1] checked:after:absolute ",
  "checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full",
  " checked:after:border-[#CEDEF1] checked:after:bg-[#CEDEF1] checked:after:content-[''] ",
  "checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer"
);

export const buttonDefault = clsx(
  "px-4 py-1",
  "bg-primary hover:opacity-80 active:opacity-100",
  "text-white",
  "text-sm",
  "rounded-md",
  "cursor-pointer"
);

export const buttonDefaultSecondary = clsx(
  "px-4 py-1",
  "bg-gray hover:opacity-90 active:opacity-100",
  "text-white",
  "text-sm",
  "rounded-md"
);
