import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { useLocationQuery } from "./useLocationQuery";
import QueryString from "qs";
import _ from "lodash";

const formatDate = (date) => {
  const [year, month, day] = date.split("-");
  return [day, month, year].join("/");
};

const getPassengers = (string, param) => {
  if (param === "0") return false;

  let arr = [];
  for (let i = 0; i < Number(param); i++) {
    arr.push({
      TravelerType: string,
      IsResident: false,
    });
  }
  return arr.flat().flat();
};

const getTravellers = (parsedHash) => {
  let travelers = [];

  const adultQuery = getPassengers("Adult", parsedHash.adults);
  const childQuery = getPassengers("Child", parsedHash.children);
  const infantQuery = getPassengers("Infant", parsedHash.infants);

  if (adultQuery) travelers.push(adultQuery.flat());
  if (childQuery) travelers.push(childQuery.flat());
  if (infantQuery) travelers.push(infantQuery.flat());

  return travelers.flat();
};

const getAramixQuery = (parsedHash) => {
  let flightOut = {
    DepartureAirportLocationCode: parsedHash?.originLocationCode,
    ArrivalAirportLocationCode: parsedHash?.destinationLocationCode,
    DepartureDate: formatDate(parsedHash?.departureDate),
  };

  let flightRet = parsedHash?.returnDate && {
    DepartureAirportLocationCode: parsedHash?.destinationLocationCode,
    ArrivalAirportLocationCode: parsedHash?.originLocationCode,
    DepartureDate: formatDate(parsedHash?.returnDate),
  };
  let travelers = getTravellers(parsedHash);
  let ClassPref =
    !parsedHash.hasOwnProperty("travelClass") ||
    parsedHash?.travelClass?.length === 0
      ? "NotSet"
      : parsedHash?.travelClass === "BUSINESS"
      ? "Business"
      : "Tourist";

  flightOut = JSON.stringify(flightOut);
  flightRet = JSON.stringify(flightRet);
  travelers = JSON.stringify(travelers);

  return `flightOut=${flightOut}${
    parsedHash?.returnDate ? `&flightRet=${flightRet}` : ""
  }&travelers=${travelers}&ClassPref=${ClassPref}`;
};

export const useGetFlights = () => {
  const BASE_URL = process.env.REACT_APP_API_URL;
  const { parsedHash } = useLocationQuery();
  const [loading, setLoading] = useState(false);
  const [{ flights, original }, setFlights] = useState({
    flights: {},
    original: [],
  });

  const querys = QueryString.stringify(
    _.omitBy({ ...parsedHash }, (a) => a !== 0 && !a)
  ).replace(/\s/g, "");

  const getAmadeusFlights = async (query) => {
    // sacar este return y descomentar el resto el
    // día que se quiera volver a consumir Amadeus
    return { original: [] };

    // return await axios
    //   .get(BASE_URL + "vuelos?" + query)
    //   .then((e) => {
    //     const { grouped, original } = e.data;
    //     return { flights: grouped, original };
    //   })
    //   .catch((e) => {
    //     return JSON.parse(e.request.response);
    //   });
  };

  const getAramixFlights = async (section, objectQuery) => {
    const query = getAramixQuery(objectQuery);

    // setLoading(true);
    return await axios.get(BASE_URL + "v2/aramix?" + query).then((e) => {
      const { grouped, original } = e.data.finalyRes;

      if (section === "flights") {
        return setFlights({
          flights: grouped,
          original: original.flat(),
        });
      }

      if (section === "quotes") {
        return { flights: grouped, original: original.flat() };
      }
    });
    // .catch((e) => {
    //   console.log("aramix", e);
    //   console.log(JSON.parse(e.request.response));
    //   return JSON.parse(e.request.response);
    // });
    // .finally(() => setLoading(false));
  };

  // 'stage' hace referencia a si la función se utiliza en el módulo
  // de vuelos o en el de cotizador, ya que en cada uno se administra
  // la data de una forma distinta
  const getFlights = async ({ stage, amadeusData, aramixData }) => {
    setLoading(true);
    setFlights({
      flights: {},
      original: [],
    });

    return await getAmadeusFlights(amadeusData)
      .then(async (amadeus) => {
        // console.log(amadeus.flights);
        // console.log(amadeus?.original);
        if (stage === "flights" && amadeus?.original?.length > 0) {
          setFlights({
            flights: amadeus.flights,
            original: amadeus?.original,
          });
          setLoading(false);
        }

        return await getAramixFlights("quotes", aramixData)
          .then((aramix) => {
            // console.log(aramix.flights);
            // console.log(aramix.original);
            if (stage === "flights" && typeof aramix?.original !== "string") {
              setFlights((curr) => ({
                flights: {
                  ...curr.flights,
                  ...aramix.flights,
                },
                original: [...curr.original, ...aramix.original],
              }));
            } else {
              if (amadeus?.original?.length > 0) {
                if (typeof aramix?.original !== "string") {
                  return {
                    flights: {
                      ...amadeus.flights,
                      ...aramix.flights,
                    },
                    original: [...amadeus?.original, ...aramix.original],
                  };
                } else {
                  return {
                    flights: {
                      ...amadeus.flights,
                    },
                    original: [...amadeus?.original],
                  };
                }
              } else if (typeof aramix?.original !== "string") {
                return {
                  flights: {
                    ...aramix.flights,
                  },
                  original: [...aramix?.original],
                };
              }
              return undefined;
            }
          })
          .catch((errAramix) => {
            console.log("errAramix", errAramix);
            return {
              flights: {
                ...amadeus.flights,
              },
              original: [...amadeus?.original],
            };
          });
      })
      .catch((errAmadeus) => console.log("errAmadeus", errAmadeus))
      .finally(() => setLoading(false));
  };

  const getFlexibleFlights = async (query) => {
    setLoading(true);
    return await axios
      .get(
        BASE_URL +
          "v2/flights/search/flexible?" +
          query +
          "&children=0&infants=0&max=1&currencyCode=ARS"
      )
      .then((e) => {
        // console.log(e)
        return e.data.response;
      })
      // .catch((e) => console.log(e))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!querys) return;

    getFlights({
      stage: "flights",
      amadeusData: querys,
      aramixData: parsedHash,
    });
  }, [querys]);

  return {
    flights,
    original,
    loading,
    getFlights,
    getFlexibleFlights,
    getAramixFlights,
  };
};
