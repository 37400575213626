import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useAuth } from "./useAuth";
import clsx from "clsx";
import CreateAccount from "./CreateAccount";
import SEO from "../../utils/SEO";

export const Login = ({
  justCreateAccount = false,
  keepRoute = false,
  closeModal,
}) => {
  const { login, errors, loading } = useAuth(keepRoute);
  const [showPassword, setShowPassword] = useState(false);
  const [keepSession, setKeepSession] = useState(false);
  const [showLogin, setShowLogin] = useState(!justCreateAccount);

  const schema = z.object({
    email: z.string(),
    password: z.string(),
  });

  const { register, handleSubmit } = useForm({
    resolver: zodResolver(schema),
  });

  const onSubmit = ({ email, password }) => {
    login({ email, password, keepSession }).then(
      (e) => e.shouldCloseModal && closeModal()
    );
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const formatedErrors =
    errors &&
    errors.reduce((acc, err) => {
      const { path, msg } = err;
      if (acc.hasOwnProperty(path)) {
        acc = {
          ...acc,
          [path]: acc[path].concat(msg),
        };
      } else {
        acc[path] = [msg];
      }
      return acc;
    }, {});

  return (
    <div className="px-4 w-[450px]">
      <SEO title={"Inicio de Sesión"} />
      {!showLogin ? (
        <CreateAccount
          state={[showLogin, setShowLogin]}
          justCreateAccount={justCreateAccount}
          closeModal={closeModal}
        />
      ) : (
        <form onSubmit={handleSubmit((data) => onSubmit(data))}>
          <h2 className="text-center text-2xl font-medium">Inicia sesión</h2>
          {formatedErrors?.tour_op && (
            <span className="text-xs text-red-400">
              {formatedErrors.tour_op[0]}
            </span>
          )}
          <div className="flex flex-col gap-4 my-6">
            <div className="relative">
              <input
                className="bg-[#E8E8E8] py-1 px-3 rounded-md outline-none w-full"
                type="text"
                id="user"
                name="email"
                placeholder="Email o Usuario"
                {...register("email")}
              />
              {formatedErrors?.user && (
                <span className="absolute left-1 -bottom-4 text-xs text-red-400">
                  {formatedErrors.user[0]}
                </span>
              )}
            </div>
            <div className="relative my-1">
              <input
                className="bg-[#E8E8E8] py-1 px-3 rounded-md outline-none w-full"
                id="password"
                name="password"
                placeholder="Contraseña"
                autoComplete="off"
                type={showPassword ? "text" : "password"}
                {...register("password")}
              />
              {formatedErrors?.password && (
                <span className="absolute left-1 -bottom-4 text-xs text-red-400">
                  {formatedErrors.password[0]}
                </span>
              )}
            </div>
            <label className="flex items-center gap-2">
              <input type="checkbox" onChange={togglePasswordVisibility} />
              <p>Mostrar Contraseña</p>
            </label>

            <label className="flex items-center gap-2">
              <input
                type="checkbox"
                id="rememberSession"
                name="rememberSession"
                checked={keepSession}
                onClick={() => setKeepSession(!keepSession)}
                {...register("rememberSession")}
              />
              <p>Recordar Sesión</p>
            </label>
          </div>
          <span className="flex items-center justify-between gap-6">
            <button
              type="button"
              onClick={() => setShowLogin(!showLogin)}
              className="text-sm"
            >
              ¿No tiene una cuenta aún?
            </button>
            <button
              type="submit"
              className={clsx(
                "bg-[#CEDEF1] py-2 px-12 text-center font-medium rounded-md flex items-center gap-3",
                {
                  "opacity-75cursor-not-allowed": loading,
                }
              )}
            >
              Ingresar
              {loading ? (
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-gray"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    stroke="currentColor"
                    strokeWidth="4"
                    cx="12"
                    cy="12"
                    r="10"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : null}
            </button>
          </span>
        </form>
      )}
    </div>
  );
};
