import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import {I18nextProvider} from "react-i18next"; //para que las traducciones esten disponibles en toda la app
import i18next from "i18next";
import global_es from "./Translations/es/global.json"
import global_en from "./Translations/en/global.json"

import App from "./App.js"


i18next.init({
  interpolation: {escapeValue:false},
  lng: "es", //lenguaje por default
  resources: {
    es: {
      global: global_es
    },
    en:{
      global: global_en
    },
  }
})

const root = ReactDOM.createRoot(document.getElementById("root"));

const queryClient = new QueryClient();

root.render(
  <QueryClientProvider client={queryClient}>
    <I18nextProvider i18n={i18next}>
    <App />
    </I18nextProvider>
  </QueryClientProvider>
);

reportWebVitals();