import numberWithCommas from "../../utils/numberWithCommas";
import { AIRLINES_DICTIONARY } from "../../utils/dictionaries/airlinesDictionary";
import OptionQuoteFees from "./OptionQuoteFees";
import OptionQuoteFlights from "./OptionQuoteFlights";
import OptionModal from "./OptionModal";
import { ModalGeneric } from "../../utils/ModalGeneric";
import { useToggleVisibility } from "../../hooks/useToggleVisibility";

export default function OptionQuote({
  sectionIndex,
  price,
  options,
  original,
  exchangeRate,
  details,
  numberOfPassengers,
  isRoundtrip,
  wider,
  state,
}) {
  const { origin, destination } = options;
  const [selectedOption, setSelectedOption] = state;
  const [isVisible, toggleVisibility] = useToggleVisibility();

  const handleSelectedOption = (evt, IDs, stage) => {
    const { checked } = evt.target;

    setSelectedOption((curr) => {
      let copiedArr = curr[stage];
      let newArr = curr[stage][sectionIndex];

      if (newArr.length === 0) {
        newArr = IDs;
      } else {
        IDs.map((id) =>
          checked
            ? (newArr = [...newArr, id])
            : (newArr = newArr.filter((el) => el !== id))
        );
      }
      copiedArr[sectionIndex] = newArr;

      return {
        ...curr,
        [stage]: copiedArr,
      };
    });
  };

  const firstOptionOrigen = origin[0];
  const { currency } =
    details[`${firstOptionOrigen.segments[0].id}-${price}`].totalPrices;
  const airline =
    AIRLINES_DICTIONARY[firstOptionOrigen.segments[0].carrierCode] ?? false;
  const grandTotal = currency === "EUR" ? price * numberOfPassengers : price;
  const totalToARS = (number) =>
    currency === "ARS" ? number : exchangeRate.euro * number;

  return (
    <>
      <section
        className={`border border-brightBlue bg-white overflow-hidden rounded-lg p-4 flex justify-around items-center transition-all ${
          wider ? "gap-12 2xl:gap-24" : "gap-6 2xl:gap-12"
        }`}
      >
        <section
          className={`transition-all ${
            wider ? "w-72 2xl:w-96" : "w-48 2xl:w-64"
          }`}
        >
          <img
            src={airline ? airline.img : ""}
            alt={
              airline
                ? airline.label
                : firstOptionOrigen.segments[0].carrierCode
            }
            className="mx-auto"
          />
        </section>

        <section className="flex flex-col gap-1">
          <OptionQuoteFlights
            details={details}
            flights={origin}
            stage={"origin"}
            original={original}
            price={price}
            thisSelectedOption={selectedOption.origin[sectionIndex]}
            handleSelectedOption={handleSelectedOption}
            wider={wider}
          />
          {isRoundtrip && (
            <>
              <div className="h-[2px] bg-lightBlue rounded w-3/4 mx-auto mt-1 mb-2" />
              <OptionQuoteFlights
                details={details}
                flights={destination}
                stage={"destination"}
                original={original}
                price={price}
                thisSelectedOption={selectedOption.destination[sectionIndex]}
                handleSelectedOption={handleSelectedOption}
                wider={wider}
              />
            </>
          )}
        </section>

        <OptionQuoteFees
          details={details}
          price={price}
          exchangeRate={exchangeRate}
          numberOfPassengers={numberOfPassengers}
          thisOption={firstOptionOrigen}
          wider={wider}
          toggleVisibility={toggleVisibility}
        />

        {/* ---------- ONLY DESKTOP ----------------- */}
        <section className="hidden 2xl:block">
          <p className={wider ? "text-sm" : "text-xs"}>Comisión del 0%</p>
          <p
            className={`text-brightBlue font-[700] transition-all ${
              wider ? "text-2xl 2xl:text-4xl" : "text-lg 2xl:text-2xl"
            }`}
          >
            {/* {currency === "EUR" ? "~" : ""}
            {numberWithCommas(totalToARS(Number(grandTotal)), 0)}
            <span
              className={`text-brightBlue transition-all ${
                wider ? "text-base 2xl:text-lg" : "text-sm"
              }`}
            >
              ARS
            </span> */}

            {/* ----------------------------------- */}
            {numberWithCommas(Number(grandTotal), 0)}
            <span
              className={`text-brightBlue transition-all ${
                wider ? "text-base 2xl:text-lg" : "text-sm"
              }`}
            >
              EUR
            </span>
            {/* ----------------------------------- */}
          </p>
        </section>

        <ModalGeneric show={isVisible} toggle={toggleVisibility}>
          <OptionModal
            details={details}
            price={price}
            toggleVisibility={toggleVisibility}
          />
        </ModalGeneric>
      </section>
    </>
  );
}
