import { useState } from "react";
import UpdateAgencyModal from "./components/UpdateAgencyModal";
import { useToggleVisibility } from "../../hooks/useToggleVisibility";
import { ModalGeneric } from "../../utils/ModalGeneric";
import Pagination from "../../utils/Pagination";
import toast, { Toaster } from "react-hot-toast";
import AgenciesTableRow from "./AgenciesTableRow";
import ViewAgencyModal from "./components/ViewAgencyModal";

export default function AgenciesTable({ useAgencies }) {
  const [isVisible, toggleVisibility] = useToggleVisibility();
  const [selectedAgency, setSelectedAgency] = useState({});
  const [page, setPage] = useState(0);
  const [typeModal, setTypeModal] = useState("");
  const { agencies, deleteAgency, getAgenciesByTourOperator } = useAgencies;

  const openModal = (agency, modal) => {
    setSelectedAgency(agency);
    toggleVisibility();
    setTypeModal(modal);
  };

  const closeModal = () => {
    toggleVisibility();
    getAgenciesByTourOperator();
  };

  const deleteAgencyWithToast = async (id) => {
    toast.promise(
      deleteAgency({ id }).then(() => {
        getAgenciesByTourOperator();
        toggleVisibility();
      }),
      {
        loading: "Eliminando...",
        success: <b>Agencia eliminada con éxito.</b>,
        error: <b>Hubo un error, intenta de nuevo.</b>,
      }
    );
  };

  return (
    <>
      <Toaster />
      <section className="bg-white w-full mt-4 rounded-xl p-4 border-2 border-lightBlue">
        <header className="grid grid-cols-8 bg-lightBlue/30 rounded py-2">
          <p className="font-medium pl-4 col-span-2">Nombre</p>
          <p className="font-medium col-span-2">Email</p>
          <p className="font-medium">Teléfono</p>
          <p className="font-medium">Tipo</p>
          <p className="font-medium">Contacto</p>
          <p className="font-medium">Acción</p>
        </header>

        {agencies?.slice(page * 10, page * 10 + 10)?.map((agency, key) => (
          <AgenciesTableRow key={key} agency={agency} openModal={openModal} />
        ))}

        <ModalGeneric show={isVisible} toggle={toggleVisibility}>
          {typeModal === "edit" && (
            <UpdateAgencyModal
              agency={selectedAgency}
              closeModal={closeModal}
            />
          )}
          {typeModal === "delete" && (
            <div className="flex flex-col items-center gap-2">
              <h2>
                ¿Estás seguro de eliminar la agencia{" "}
                <b>{selectedAgency?.name}</b>?
              </h2>
              <div className="flex justify-center gap-2">
                <button
                  className="py-1 px-2 rounded font-medium border border-gray2"
                  onClick={toggleVisibility}
                >
                  Cancelar
                </button>
                <button
                  className="py-1 px-2 rounded font-medium border text-white border-red-500 bg-red-500"
                  onClick={() => deleteAgencyWithToast(selectedAgency.id)}
                >
                  Eliminar
                </button>
              </div>
            </div>
          )}
          {typeModal === "view" && <ViewAgencyModal agency={selectedAgency} />}
        </ModalGeneric>
      </section>

      <Pagination
        items={agencies}
        itemsPerPage={10}
        page={page}
        setPage={setPage}
      />
    </>
  );
}
