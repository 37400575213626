export default function Pagination({ items, itemsPerPage, page, setPage }) {
  const paginationLength = items && items.length;
  const numbOfPages = Math.ceil(paginationLength / itemsPerPage);

  const pageButtons =
    page === 0
      ? [1, 2, 3]
      : page === numbOfPages - 1
      ? [numbOfPages - 2, numbOfPages - 1, numbOfPages]
      : [page, page + 1, page + 2];

  return (
    <>
      {items && numbOfPages > 1 && (
        <section className="flex justify-between items-center mt-4 font-body">
          <p className="text-darkBlue/70 text-sm font-low">
            Mostrando {page * itemsPerPage + 1} a{" "}
            {paginationLength <= itemsPerPage
              ? paginationLength
              : page * itemsPerPage + itemsPerPage > paginationLength
              ? paginationLength
              : page * itemsPerPage + itemsPerPage}{" "}
            de {paginationLength} Entradas
          </p>
          <div className="flex gap-2">
            {numbOfPages > 1 && (
              <button
                className="py-1 px-2 border bg-light border-lightGray3 rounded bg-white"
                onClick={() => setPage((curr) => (curr > 0 ? curr - 1 : curr))}
              >
                Anterior
              </button>
            )}
            {numbOfPages > 2 ? (
              <>
                {pageButtons.map((btn) => {
                  return (
                    <button
                      key={btn}
                      className={`py-1 px-2 border bg-light border-lightGray3 rounded ${
                        page + 1 === btn
                          ? "bg-lightBlue font-medium"
                          : "bg-white"
                      }`}
                      onClick={() => setPage(btn - 1)}
                    >
                      {btn}
                    </button>
                  );
                })}
              </>
            ) : (
              <>
                {[1, 2].map((btn) => {
                  if (btn === 2 && numbOfPages === 1) return null;
                  return (
                    <button
                      key={btn}
                      className={`py-1 px-2 border bg-light border-lightGray3 rounded bg-white ${
                        page + 1 === btn && "bg-lightBlue font-medium"
                      }`}
                      onClick={() => setPage(btn - 1)}
                    >
                      {btn}
                    </button>
                  );
                })}
              </>
            )}
            {numbOfPages > 1 && (
              <button
                className="py-1 px-2 border bg-light border-lightGray3 rounded bg-white"
                onClick={() =>
                  setPage((curr) => (curr + 1 < numbOfPages ? curr + 1 : curr))
                }
              >
                Siguiente
              </button>
            )}
          </div>
        </section>
      )}
    </>
  );
}
