import axios from "axios";

export default function usePictures() {
  const BASE_URL = process.env.REACT_APP_API_URL;

  const postPicture = async ({ url, package_id }) => {
    return await axios
      .post(BASE_URL + "v2/pictures", {
        url,
        package_id,
      })
      .then((e) => {
        console.log(e);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const patchPicture = async ({ picture, picture_id }) => {
    return await axios
      .patch(BASE_URL + "v2/pictures/" + picture_id, { picture })
      .then((e) => {
        console.log(e);
      })
      .catch((err) => console.log(err));
  };

  const deletePicture = async ({ picture_id }) => {
    return await axios
      .delete(BASE_URL + "v2/pictures/" + picture_id)
      .then((e) => console.log(e))
      .catch((err) => console.log(err));
  };

  return { postPicture, patchPicture, deletePicture };
}
