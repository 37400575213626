import { format } from "date-fns";
import { PASSENGER_DICTIONARY } from "../../utils/dictionaries/passengerDictionary";
import { useSelectPaquetes } from "./useSelectPaquetes";
import numberWithCommas from "../../utils/numberWithCommas";

export default function DetailPaquete({ pack, postPaquete, selectPackage }) {
  const numberOfPassengers = useSelectPaquetes((st) => st.numberOfPassengers);
  const setNumberOfPassengers = useSelectPaquetes(
    (st) => st.setNumberOfPassengers
  );

  const {
    id,
    title,
    descode,
    description,
    departure_date,
    return_date,
    oricode,
    price,
    tax_package,
    portrait,
    Pictures,
    CurrencyType,
  } = pack;

  const goToNextStep = () => {
    selectPackage(pack);
    postPaquete();
  };

  const handlePassenger = (passenger, min, op) => {
    const passengerValue = numberOfPassengers[passenger];
    const newValue =
      op === "+"
        ? passengerValue + 1
        : passengerValue > min
        ? passengerValue - 1
        : passengerValue;

    setNumberOfPassengers({
      ...numberOfPassengers,
      [passenger]: newValue,
    });
  };
  
  return (
    <main className="max-w-[1338.46px] grid grid-cols-2 gap-16">
      <section className="flex flex-col gap-4">
        <h1 className="text-4xl font-semibold">{title}</h1>
        <div className="border rounded-lg">
          <div className="grid grid-cols-2">
            <p className="border-r text-lg p-2">Origen</p>
            <p className="text-lg p-2">{oricode}</p>
          </div>

          <div className="grid grid-cols-2 bg-lightGray3">
            <p className="border-r text-lg p-2">Salida</p>
            {departure_date && (
              <p className="text-lg p-2">
                {format(new Date(departure_date), "dd/MM/yy")}
              </p>
            )}
          </div>

          <div className="grid grid-cols-2">
            <p className="border-r text-lg p-2">Destino</p>
            <p className="text-lg p-2">{descode}</p>
          </div>

          <div className="grid grid-cols-2 bg-lightGray3">
            <p className="border-r text-lg p-2">Llegada</p>
            {departure_date && (
              <p className="text-lg p-2">
                {format(new Date(return_date), "dd/MM/yy")}
              </p>
            )}
          </div>

          <div className="grid grid-cols-2">
            <p className="border-r text-lg p-2">Tarifa por Persona</p>
            <p className="text-lg p-2">
              {CurrencyType?.symbol ?? "USD"} {numberWithCommas(Number(price))}
            </p>
          </div>

          <div className="grid grid-cols-2 bg-lightGray3">
            <p className="border-r text-lg p-2">Impuestos por Persona</p>
            <p className="text-lg p-2">
              {CurrencyType?.symbol ?? "USD"}{" "}
              {numberWithCommas(Number(tax_package ?? "0"))}
            </p>
          </div>

          <div className="grid grid-cols-2">
            <p className="border-r text-lg p-2">Precio Total por Persona</p>
            <p className="text-lg p-2">
              {CurrencyType?.symbol ?? "USD"}{" "}
              {numberWithCommas(Number(price) + Number(tax_package ?? "0"))}
            </p>
          </div>
        </div>
        <div className="text-xl">
          {description?.split("<br>").map((item, index) => (
            item === "" ? <br key={index}></br> : <p key={index}>{item}</p>
          ))}
        </div>
      </section>

      <img src={portrait} alt={title} className="rounded-lg" />

      {Pictures?.length > 1 && (
        <section className="col-span-2 gap-4">
          {Pictures.map(({ id, url }, index) => (
            <img
              key={id}
              src={url}
              alt={`Foto ${index + 1}`}
              className="max-w-[16rem] rounded-md"
            />
          ))}
        </section>
      )}

      <section>
        <div className="flex flex-col gap-2 p-2 rounded-lg border">
          {Object.entries(numberOfPassengers).map((passenger) => {
            const { key, label, description, min } =
              PASSENGER_DICTIONARY[passenger[0]];
            return (
              <div className="flex justify-between items-center" key={key}>
                <div>
                  <h2 className="font-medium">{label}</h2>
                  <p className="text-xs text-gray">{description}</p>
                </div>
                <div className="flex gap-2 items-center">
                  <button
                    type="button"
                    onClick={() => handlePassenger(key, min, "-")}
                    className="bg-lightGray3 border w-7 h-7 rounded-full text-sm font-[700]"
                  >
                    -
                  </button>
                  <p className="text-xl">{passenger[1]}</p>
                  <button
                    type="button"
                    onClick={() => handlePassenger(key, min, "+")}
                    className="bg-lightGray3 border w-7 h-7 rounded-full text-sm font-[700]"
                  >
                    +
                  </button>
                </div>
              </div>
            );
          })}
        </div>
        <button
          onClick={goToNextStep}
          className="mt-4 bg-darkBlue button text-white text-xl font-semibold"
        >
          Reservar
        </button>
      </section>
    </main>
  );
}
