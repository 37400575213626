export const PASSENGER_DICTIONARY = {
  ADT: {
    key: "ADT",
    label: "Adultos",
    register: "adults",
    description: "Mayores de 12 años",
    min: 1,
  },
  CHD: {
    key: "CHD",
    label: "Niños",
    register: "children",
    description: "De 2 a 11 años",
    min: 0,
  },
  INF: {
    key: "INF",
    label: "Infantes",
    register: "infants",
    description: "De 0 ó 1 año",
    min: 0,
  },
};
